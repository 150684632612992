.wrapper {
	position: relative;
}

.label {
	font-weight: 450;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: -0.00533333em;
	padding: 0 4px;
	position: absolute;
	left: 12px;
	top: 9px;
	background: #fff;
	color:  #b4bac4;
	pointer-events: none;
	transition: top 0.2s ease-out;
}

.input {
	font-weight: 450;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: -0.00533333em;
	background-color: #fff;
	width: 100%;
	height: 44px;
	padding: 8px 14px;
	border: 1px solid #dfe1e6;
	border-radius: 8px;
	color:  #1a202c;

	&::placeholder {
		color: transparent;
	}

	&:focus,
	&:active,
	&:not(:placeholder-shown) {
		// border-color: #4a5568;
		outline: none;
		box-shadow: none;

		& + .label {
			display: block;
			top: -10px;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: -0.004em;
			color: #4a5568;
			/* &::after {
				content: "*";
				color: #e95454; 
			} */
		}
		
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		box-shadow: 0 0 0 30px #fff inset !important;
	}

	&:disabled {
		background-color:  #dfe1e6;
		box-shadow: none;
		color:  #b4bac4;
		pointer-events: none;
	}

	&.hasError {
		border-color: #e95454;
		color:  #e95454;
		outline: none;

		& + .label {
			color:  #e95454;
		}
	}

	&:read-only {
		& ~ span {
			display: block;
			color: #028d5e;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: -0.00466667em;
			margin-top: 8px;
		}
	}
}

.error {
	margin-top: 2px;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color:  #e95454;
}
