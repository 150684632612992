/*
    Table of Contents

    Name                 : W3CRM
    Author               : Dexignzone
    Author Portfolio     : https://themeforest.net/user/dexignzone/portfolio
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("../icons/simple-line-icons/css/simple-line-icons.css");
@import url("../icons/fontawesome6/css/all.min.css");
@import url("../icons/fontawesome/css/all.min.css");
@import url("../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("../icons/themify-icons/css/themify-icons.css");
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/avasta/css/style.css");
@import url("../icons/flaticon/flaticon.css");
@import url("../icons/flaticon_1/flaticon_1.css");
@import url("../icons/icomoon/icomoon.css");
@import url("../icons/bootstrap-icons/font/bootstrap-icons.css");
@import url('../vendor/animate/animate.min.css');
@import url('../vendor/aos/css/aos.min.css');
@import url('../vendor/perfect-scrollbar/css/perfect-scrollbar.css');
@import url('../vendor/metismenu/css/metisMenu.min.css');
@import url('../vendor/datatables/css/jquery.dataTables.min.css');
@import url('../vendor/bootstrap-select/dist/css/bootstrap-select.min.css');

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0a907', endColorstr='#f53c79', GradientType=0);
}

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dedf5', endColorstr='#480ceb', GradientType=0);
}

.gradient-3 {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51f5ae', endColorstr='#3fbcda', GradientType=0);
}

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f53c79', endColorstr='#f0a907', GradientType=1);
}

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36b9d8', endColorstr='#4bffa2', GradientType=1);
}

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4400eb', endColorstr='#44e7f5', GradientType=1);
}

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9 {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
   * Bootstrap  v5.3.0 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #5e72e4;
  --bs-indigo: #6610f2;
  --bs-purple: #BB6BD9;
  --bs-pink: #e83e8c;
  --bs-red: #EE3232;
  --bs-orange: #ff9900;
  --bs-yellow: #FFFA6F;
  --bs-green: #297F00;
  --bs-teal: #20c997;
  --bs-cyan: #3065D0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0D99FF;
  --bs-secondary: #2C2C2C;
  --bs-success: #3AC977;
  --bs-info: #58bad7;
  --bs-warning: #FF9F00;
  --bs-danger: #FF5E5E;
  --bs-light: #dfdfdf;
  --bs-dark: #6e6e6e;
  --bs-primary-rgb: 13, 153, 255;
  --bs-secondary-rgb: 44, 44, 44;
  --bs-success-rgb: 58, 201, 119;
  --bs-info-rgb: 88, 186, 215;
  --bs-warning-rgb: 255, 159, 0;
  --bs-danger-rgb: 255, 94, 94;
  --bs-light-rgb: 223, 223, 223;
  --bs-dark-rgb: 110, 110, 110;
  --bs-primary-text-emphasis: #053d66;
  --bs-secondary-text-emphasis: #121212;
  --bs-success-text-emphasis: #175030;
  --bs-info-text-emphasis: #234a56;
  --bs-warning-text-emphasis: #664000;
  --bs-danger-text-emphasis: #662626;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfebff;
  --bs-secondary-bg-subtle: #d5d5d5;
  --bs-success-bg-subtle: #d8f4e4;
  --bs-info-bg-subtle: #def1f7;
  --bs-warning-bg-subtle: #ffeccc;
  --bs-danger-bg-subtle: #ffdfdf;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ed6ff;
  --bs-secondary-border-subtle: #ababab;
  --bs-success-border-subtle: #b0e9c9;
  --bs-info-border-subtle: #bce3ef;
  --bs-warning-border-subtle: #ffd999;
  --bs-danger-border-subtle: #ffbfbf;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "poppins", sans-serif, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #888888;
  --bs-body-color-rgb: 136, 136, 136;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(136, 136, 136, 0.75);
  --bs-secondary-color-rgb: 136, 136, 136;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(136, 136, 136, 0.5);
  --bs-tertiary-color-rgb: 136, 136, 136;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #374557;
  --bs-link-color: #0D99FF;
  --bs-link-color-rgb: 13, 153, 255;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a7acc;
  --bs-link-hover-color-rgb: 10, 122, 204;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fffee2;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #CCCCCC;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 153, 255, 0.25);
  --bs-form-valid-color: #3AC977;
  --bs-form-valid-border-color: #3AC977;
  --bs-form-invalid-color: #FF5E5E;
  --bs-form-invalid-border-color: #FF5E5E;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ec2ff;
  --bs-secondary-text-emphasis: gray;
  --bs-success-text-emphasis: #89dfad;
  --bs-info-text-emphasis: #9bd6e7;
  --bs-warning-text-emphasis: #ffc566;
  --bs-danger-text-emphasis: #ff9e9e;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031f33;
  --bs-secondary-bg-subtle: #090909;
  --bs-success-bg-subtle: #0c2818;
  --bs-info-bg-subtle: #12252b;
  --bs-warning-bg-subtle: #332000;
  --bs-danger-bg-subtle: #331313;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #085c99;
  --bs-secondary-border-subtle: #1a1a1a;
  --bs-success-border-subtle: #237947;
  --bs-info-border-subtle: #357081;
  --bs-warning-border-subtle: #995f00;
  --bs-danger-border-subtle: #993838;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ec2ff;
  --bs-link-hover-color: #8bceff;
  --bs-link-color-rgb: 110, 194, 255;
  --bs-link-hover-color-rgb: 139, 206, 255;
  --bs-code-color: #f18bba;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #7fb266;
  --bs-form-valid-border-color: #7fb266;
  --bs-form-invalid-color: #f58484;
  --bs-form-invalid-border-color: #f58484;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.25rem;
  }
}

h2,
.h2 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 1.875rem;
  }
}

h3,
.h3 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.938rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 25px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1440) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1440;
}

.row {
  --bs-gutter-x: 25px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: var(--bs-border-width) 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 var(--bs-border-width);
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns> :not(caption)>tr> :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfebff;
  --bs-table-border-color: #bad4e6;
  --bs-table-striped-bg: #c5dff2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bad4e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd9ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #d5d5d5;
  --bs-table-border-color: silver;
  --bs-table-striped-bg: #cacaca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: silver;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c5c5c5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d8f4e4;
  --bs-table-border-color: #c2dccd;
  --bs-table-striped-bg: #cde8d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2dccd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8e2d3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #def1f7;
  --bs-table-border-color: #c8d9de;
  --bs-table-striped-bg: #d3e5eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8d9de;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cddfe4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #ffeccc;
  --bs-table-border-color: #e6d4b8;
  --bs-table-striped-bg: #f2e0c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d4b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdabd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffdfdf;
  --bs-table-border-color: #e6c9c9;
  --bs-table-striped-bg: #f2d4d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c9c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eccece;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #dfdfdf;
  --bs-table-border-color: #c9c9c9;
  --bs-table-striped-bg: #d4d4d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c9c9c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cecece;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #6e6e6e;
  --bs-table-border-color: #7d7d7d;
  --bs-table-striped-bg: #757575;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #7d7d7d;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #797979;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
}


@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.76563rem;
}

@media (min-width: 1200px) {
  .col-form-label-sm {
    font-size: 0.76562rem;
  }
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #E6E6E6;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86ccff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 153, 255, 0.25);
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .form-control-sm {
    font-size: 0.76562rem;
  }
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 8px 14px;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #dfe1e6;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11); */
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86ccff;
  outline: 0;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .form-select-sm {
    font-size: 0.76562rem;
  }
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86ccff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 153, 255, 0.25);
}

.form-check-input:checked {
  background-color: #0D99FF;
  border-color: #0D99FF;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0D99FF;
  border-color: #0D99FF;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386ccff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 153, 255, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 153, 255, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0D99FF;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6e0ff;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0D99FF;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6e0ff;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}

.form-floating>.form-control:-webkit-autofill~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
}

.form-floating> :disabled~label {
  color: #6c757d;
}

.form-floating> :disabled~label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select,
.input-group>.form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid #E6E6E6;
  border-radius: var(--bs-border-radius);
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {

  .input-group-sm>.form-control,
  .input-group-sm>.form-select,
  .input-group-sm>.input-group-text,
  .input-group-sm>.btn {
    font-size: 0.76562rem;
  }
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

@media (min-width: 1200px) {
  .valid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233AC977' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233AC977' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group>.form-control:not(:focus):valid,
.input-group>.form-control:not(:focus).is-valid,
.was-validated .input-group>.form-select:not(:focus):valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.input-group>.form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

@media (min-width: 1200px) {
  .invalid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF5E5E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF5E5E' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF5E5E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF5E5E' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group>.form-control:not(:focus):invalid,
.input-group>.form-control:not(:focus).is-invalid,
.was-validated .input-group>.form-select:not(:focus):invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.input-group>.form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check+.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible+.btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked+.btn:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #0D99FF;
  --bs-btn-border-color: #0D99FF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31a8ff;
  --bs-btn-hover-border-color: #25a3ff;
  --bs-btn-focus-shadow-rgb: 11, 130, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dadff;
  --bs-btn-active-border-color: #25a3ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0D99FF;
  --bs-btn-disabled-border-color: #0D99FF;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2C2C2C;
  --bs-btn-border-color: #2C2C2C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #252525;
  --bs-btn-hover-border-color: #232323;
  --bs-btn-focus-shadow-rgb: 76, 76, 76;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #232323;
  --bs-btn-active-border-color: #212121;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2C2C2C;
  --bs-btn-disabled-border-color: #2C2C2C;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #3AC977;
  --bs-btn-border-color: #3AC977;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #58d18b;
  --bs-btn-hover-border-color: #4ece85;
  --bs-btn-focus-shadow-rgb: 49, 171, 101;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #61d492;
  --bs-btn-active-border-color: #4ece85;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3AC977;
  --bs-btn-disabled-border-color: #3AC977;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #58bad7;
  --bs-btn-border-color: #58bad7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #71c4dd;
  --bs-btn-hover-border-color: #69c1db;
  --bs-btn-focus-shadow-rgb: 75, 158, 183;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #79c8df;
  --bs-btn-active-border-color: #69c1db;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #58bad7;
  --bs-btn-disabled-border-color: #58bad7;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #FF9F00;
  --bs-btn-border-color: #FF9F00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffad26;
  --bs-btn-hover-border-color: #ffa91a;
  --bs-btn-focus-shadow-rgb: 217, 135, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffb233;
  --bs-btn-active-border-color: #ffa91a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FF9F00;
  --bs-btn-disabled-border-color: #FF9F00;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #FF5E5E;
  --bs-btn-border-color: #FF5E5E;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff7676;
  --bs-btn-hover-border-color: #ff6e6e;
  --bs-btn-focus-shadow-rgb: 217, 80, 80;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7e7e;
  --bs-btn-active-border-color: #ff6e6e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FF5E5E;
  --bs-btn-disabled-border-color: #FF5E5E;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #dfdfdf;
  --bs-btn-border-color: #dfdfdf;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #bebebe;
  --bs-btn-hover-border-color: #b2b2b2;
  --bs-btn-focus-shadow-rgb: 190, 190, 190;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b2b2b2;
  --bs-btn-active-border-color: #a7a7a7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #dfdfdf;
  --bs-btn-disabled-border-color: #dfdfdf;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6e6e6e;
  --bs-btn-border-color: #6e6e6e;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #848484;
  --bs-btn-hover-border-color: #7d7d7d;
  --bs-btn-focus-shadow-rgb: 132, 132, 132;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #8b8b8b;
  --bs-btn-active-border-color: #7d7d7d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6e6e6e;
  --bs-btn-disabled-border-color: #6e6e6e;
}

.btn-outline-primary {
  --bs-btn-color: #0D99FF;
  --bs-btn-border-color: #0D99FF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0D99FF;
  --bs-btn-hover-border-color: #0D99FF;
  --bs-btn-focus-shadow-rgb: 13, 153, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0D99FF;
  --bs-btn-active-border-color: #0D99FF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0D99FF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0D99FF;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #2C2C2C;
  --bs-btn-border-color: #2C2C2C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2C2C2C;
  --bs-btn-hover-border-color: #2C2C2C;
  --bs-btn-focus-shadow-rgb: 44, 44, 44;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2C2C2C;
  --bs-btn-active-border-color: #2C2C2C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2C2C2C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2C2C2C;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #3AC977;
  --bs-btn-border-color: #3AC977;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3AC977;
  --bs-btn-hover-border-color: #3AC977;
  --bs-btn-focus-shadow-rgb: 58, 201, 119;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3AC977;
  --bs-btn-active-border-color: #3AC977;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3AC977;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3AC977;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #58bad7;
  --bs-btn-border-color: #58bad7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #58bad7;
  --bs-btn-hover-border-color: #58bad7;
  --bs-btn-focus-shadow-rgb: 88, 186, 215;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #58bad7;
  --bs-btn-active-border-color: #58bad7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #58bad7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #58bad7;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FF9F00;
  --bs-btn-border-color: #FF9F00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FF9F00;
  --bs-btn-hover-border-color: #FF9F00;
  --bs-btn-focus-shadow-rgb: 255, 159, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FF9F00;
  --bs-btn-active-border-color: #FF9F00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FF9F00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FF9F00;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #FF5E5E;
  --bs-btn-border-color: #FF5E5E;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FF5E5E;
  --bs-btn-hover-border-color: #FF5E5E;
  --bs-btn-focus-shadow-rgb: 255, 94, 94;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FF5E5E;
  --bs-btn-active-border-color: #FF5E5E;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FF5E5E;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FF5E5E;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #dfdfdf;
  --bs-btn-border-color: #dfdfdf;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dfdfdf;
  --bs-btn-hover-border-color: #dfdfdf;
  --bs-btn-focus-shadow-rgb: 223, 223, 223;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dfdfdf;
  --bs-btn-active-border-color: #dfdfdf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dfdfdf;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dfdfdf;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #6e6e6e;
  --bs-btn-border-color: #6e6e6e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6e6e6e;
  --bs-btn-hover-border-color: #6e6e6e;
  --bs-btn-focus-shadow-rgb: 110, 110, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6e6e6e;
  --bs-btn-active-border-color: #6e6e6e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6e6e6e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6e6e6e;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 11, 130, 217;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg>.btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm,
.btn-group-sm>.btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.76563rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {

  .btn-sm,
  .btn-group-sm>.btn {
    --bs-btn-font-size: 0.76562rem;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0D99FF;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .dropdown-header {
    font-size: 0.76562rem;
  }
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0D99FF;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group> :not(.btn-check:first-child)+.btn,
.btn-group>.btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: #2c2c2c;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 153, 255, 0.25);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  padding: 0.7rem 0.7rem 0 0.7rem;
  font-weight: 600;
  background-color: #2c2c2c;
  border-top: 2px solid #fff;
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;

}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  /* border-color: var(--bs-nav-tabs-link-hover-border-color); */
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000 !important;
  background-color: #fff;
  border-radius: 7px 7px 0 0;
  font-weight: 600;
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0D99FF;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}

.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
  border-radius: 5px 5px 0 0;
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.33594rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28136, 136, 136, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 12.5px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  -webkit-box-shadow: 6px 11px 41px -28px #796eb1;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23888888'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23053d66'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86ccff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 153, 255, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ec2ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ec2ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  /* content: var(--bs-breadcrumb-divider, "https://superadmin.paypandabnk.com/")
    rtl: var(--bs-breadcrumb-divider, "/")
  ; */
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 153, 255, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0D99FF;
  --bs-pagination-active-border-color: #0D99FF;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.76563rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .pagination-sm {
    --bs-pagination-font-size: 0.76562rem;
  }
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0D99FF;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked>.progress {
  overflow: visible;
}

.progress-stacked>.progress>.progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0D99FF;
  --bs-list-group-active-border-color: #0D99FF;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 153, 255, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 25px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);

}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    /* max-width: var(--bs-modal-width); */
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }

  .modal-dialog-centered {
    max-width: 600px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.76563rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "poppins", sans-serif, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

@media (min-width: 1200px) {
  .tooltip {
    --bs-tooltip-font-size: 0.76562rem;
  }
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.76563rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: #374557;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "poppins", sans-serif, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

@media (min-width: 1200px) {
  .popover {
    --bs-popover-font-size: 0.76562rem;
  }
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon,
[data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption,
[data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm,
.offcanvas-md,
.offcanvas-lg,
.offcanvas-xl,
.offcanvas-xxl,
.offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 600px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1439.98) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1440) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(13, 153, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(44, 44, 44, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(58, 201, 119, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(88, 186, 215, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 159, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(255, 94, 94, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(223, 223, 223, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(110, 110, 110, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover,
.link-primary:focus {
  color: RGBA(61, 173, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 173, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: RGBA(35, 35, 35, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(35, 35, 35, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover,
.link-success:focus {
  color: RGBA(97, 212, 146, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(97, 212, 146, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover,
.link-info:focus {
  color: RGBA(121, 200, 223, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(121, 200, 223, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover,
.link-warning:focus {
  color: RGBA(255, 178, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 178, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover,
.link-danger:focus {
  color: RGBA(255, 126, 126, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 126, 126, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover,
.link-light:focus {
  color: RGBA(229, 229, 229, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(229, 229, 229, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover,
.link-dark:focus {
  color: RGBA(88, 88, 88, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(88, 88, 88, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover,
.link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}

.icon-link>.bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link>.bi {
    transition: none;
  }
}

.icon-link-hover:hover>.bi,
.icon-link-hover:focus-visible>.bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.938rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.c-pointer {
  cursor: pointer;
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}

.required {
  color: #FF5E5E;
}

::selection {
  color: #fff;
  background: var(--primary);
}

@media only screen and (max-width: 100rem) {
  :root {
    font-size: 14px;
  }
}

body {
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  display: block;
}

p {
  line-height: 1.8;
}

div,
span,
ol,
ul {
  scrollbar-width: thin;
}

.box-shadow-none {
  box-shadow: none !important;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  position: relative;
}

#main-wrapper.show {
  opacity: 1;
}

.rounded-lg {
  border-radius: 0.375rem;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #888888;
  text-decoration: none;
}

a:hover,
a:focus,
a.active {
  text-decoration: none;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.active {
  text-decoration: none;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.5;
}

.fs-13 {
  font-size: 0.8125rem !important;
  line-height: 1.5;
}

.fs-14 {
  font-size: 0.875rem !important;
  line-height: 1.6;
}

.fs-15 {
  font-size: 0.875rem !important;
  line-height: 1.5;
}

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.6;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-16 {
    font-size: 0.875rem !important;
  }
}

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.5;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-18 {
    font-size: 1rem !important;
  }
}

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.5;
}

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.5;
}

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4;
}

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4;
}

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-28 {
    font-size: 1.5rem !important;
  }
}

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4;
}

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.25;
}

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.25;
}

.fs-35 {
  font-size: 2.1875rem !important;
  line-height: 1.25;
}

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.25;
}

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.25;
}

.fs-46 {
  font-size: 2.875rem !important;
  line-height: 1.25;
}

.fs-48 {
  font-size: 3rem !important;
  line-height: 1.25;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
  line-height: 1.5;
  font-weight: 500;
  color: var(--secondary);
}

.w-space-no {
  white-space: nowrap;
}

.content-body .container {
  margin-top: 2.5rem;
}

.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl,
.content-body .container-xxl {
  padding-top: 1.875rem;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

@media only screen and (max-width: 47.9375rem) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 1.25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 0.9375rem;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .header {
    padding: 0px !important;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    display: none;
  }

  .content-body {
    margin-left: 0px !important;
  }

  .hideMenu {
    left: -100% !important;
    width: 3.75rem;
    transition: all .2s ease;
    overflow: visible;
  }

  /* .mm-active.hide .nav-text {
    display: inline-block !important;
  } */

  /* .deznav.showMenu {
    margin-left: 41px !important;
  }
  .deznav {
left: ;
  } */
}

/*sp*/
.row.sp4,
.sp4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.row.sp4 [class*="col-"],
.sp4 [class*="col-"] {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.content-heading {
  font-size: 1rem;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 0.0625rem solid #E6E6E6;
  padding-bottom: 0.625rem;
}

[direction="rtl"] .content-heading {
  text-align: right;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.dropzone .dz-message {
  text-align: center;
  margin: 5rem 0;
}

.vh-100 {
  height: 100vh !important;
}

.support-ticket {
  position: fixed;
  bottom: 1.875rem;
  right: 0.9375rem;
  z-index: 999999;
}

.support-ticket-btn {
  width: 6.25rem;
  background: #7CB442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 3.125rem;
  color: #fff;
  font-size: 0.5rem;
  font-size: 1rem;
  padding: 0.3125rem 0.625rem 0.4375rem;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0rem 0.5rem 2.1875rem 0rem;
}

.support-ticket-btn:hover,
.support-ticket-btn:focus {
  color: #fff;
}

.text-blue {
  color: #5e72e4;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #BB6BD9;
}

.text-pink {
  color: #e83e8c;
}

.text-red {
  color: #EE3232;
}

.text-orange {
  color: #ff9900;
}

.text-yellow {
  color: #FFFA6F;
}

.text-green {
  color: #297F00;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #3065D0;
}

/* .bg-gray-dark{
	background:$color_pallate_2 !important;
}
.bg-purpal{
	background:$color_pallate_4 !important;
}
.bg-red{
	background:$color_pallate_5 !important;
}
.bg-blue-dark{
	background:$color_pallate_6 !important;
} */
.bg-blue {
  background: #496ecc !important;
}

.bg-orange {
  background: #ed8030 !important;
}

.bg-green {
  background: #299e4a !important;
}

.bg-purpel {
  background: #9517c1 !important;
}

.bg-dark-blue {
  background: #251e71 !important;
}

/* .bg-blue-light{
	background:$color_pallate_8 !important;
}
.bg-green-light{
	background:$color_pallate_9 !important;
}
.bg-green{
	background:$color_pallate_10 !important;
} */
.bg-black {
  background: #000;
}

.text-black {
  color: #000 !important;
}

.dz-scroll {
  position: relative;
}

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block;
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block;
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block;
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block;
}

.scale-2 {
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: inline-block;
}

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

.height10 {
  height: 0.625rem;
}

.height20 {
  height: 1.25rem;
}

.height30 {
  height: 1.875rem;
}

.height40 {
  height: 2.5rem;
}

.height50 {
  height: 3.125rem;
}

.height60 {
  height: 3.75rem;
}

.height70 {
  height: 4.375rem;
}

.height80 {
  height: 5rem;
}

.height90 {
  height: 5.625rem;
}

.height100 {
  height: 6.25rem;
}

.height110 {
  height: 6.875rem;
}

.height120 {
  height: 7.5rem;
}

.height130 {
  height: 8.125rem;
}

.height140 {
  height: 8.75rem;
}

.height150 {
  height: 9.375rem;
}

.height160 {
  height: 10rem;
}

.height170 {
  height: 10.625rem;
}

.height180 {
  height: 11.25rem;
}

.height190 {
  height: 11.875rem;
}

.height200 {
  height: 12.5rem;
}

.height210 {
  height: 13.125rem;
}

.height220 {
  height: 13.75rem;
}

.height230 {
  height: 14.375rem;
}

.height240 {
  height: 15rem;
}

.height250 {
  height: 15.625rem;
}

.height260 {
  height: 16.25rem;
}

.height270 {
  height: 16.875rem;
}

.height280 {
  height: 17.5rem;
}

.height290 {
  height: 18.125rem;
}

.height300 {
  height: 18.75rem;
}

.height310 {
  height: 19.375rem;
}

.height320 {
  height: 20rem;
}

.height330 {
  height: 20.625rem;
}

.height340 {
  height: 21.25rem;
}

.height350 {
  height: 21.875rem;
}

.height360 {
  height: 22.5rem;
}

.height370 {
  height: 23.125rem;
}

.height380 {
  height: 23.75rem;
}

.height390 {
  height: 24.375rem;
}

.height400 {
  height: 25rem;
}

.height415 {
  height: 25.9375rem;
}

.height430 {
  height: 27.1000rem;
}

.height450 {
  height: 28.1000rem;
}

.height500 {
  height: 31.25rem;
}

.height550 {
  height: 34.375rem;
}

.height600 {
  height: 37.5rem;
}

.height630 {
  height: 39.375rem;
}

.height720 {
  height: 45rem;
}

.height750 {
  height: 46.875rem;
}

.height800 {
  height: 50rem;
}

.width10 {
  width: 0.625rem;
}

.width20 {
  width: 1.25rem;
}

.width30 {
  width: 1.875rem;
}

.width40 {
  width: 2.5rem;
}

.width50 {
  width: 3.125rem;
}

.width60 {
  width: 3.75rem;
}

.width70 {
  width: 4.375rem;
}

.width80 {
  width: 5rem;
}

.width90 {
  width: 5.625rem;
}

.width100 {
  width: 6.25rem;
}

.width110 {
  width: 6.875rem;
}

.width120 {
  width: 7.5rem;
}

.width130 {
  width: 8.125rem;
}

.width140 {
  width: 8.75rem;
}

.width150 {
  width: 9.375rem;
}

.width160 {
  width: 10rem;
}

.width170 {
  width: 10.625rem;
}

.width180 {
  width: 11.25rem;
}

.width190 {
  width: 11.875rem;
}

.width200 {
  width: 12.5rem;
}

.width210 {
  width: 13.125rem;
}

.width220 {
  width: 13.75rem;
}

.width230 {
  width: 14.375rem;
}

.width240 {
  width: 15rem;
}

.width250 {
  width: 15.625rem;
}

.width260 {
  width: 16.25rem;
}

.width270 {
  width: 16.875rem;
}

.width280 {
  width: 17.5rem;
}

.width290 {
  width: 18.125rem;
}

.width300 {
  width: 18.75rem;
}

.width310 {
  width: 19.375rem;
}

.width320 {
  width: 20rem;
}

.width330 {
  width: 20.625rem;
}

.width340 {
  width: 21.25rem;
}

.width350 {
  width: 21.875rem;
}

.width360 {
  width: 22.5rem;
}

.width370 {
  width: 23.125rem;
}

.width380 {
  width: 23.75rem;
}

.width390 {
  width: 24.375rem;
}

.width400 {
  width: 25rem;
}

.rounded {
  border-radius: 0.375rem !important;
}

label {
  margin-bottom: 0.5rem;
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

.body {
  font-size: 0.813rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}

/* Editable */
#preloader {
  background-color: #fff;
  padding: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  z-index: 99999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#preloader .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

#preloader .lds-ripple div {
  position: absolute;
  border: 4px solid var(--primary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

[data-theme-version="dark"] #preloader {
  background-color: #1E1E1E;
}

.footer {
  padding-left: 15rem;
  font-size: 0.813rem;
  font-weight: 400;
  margin-top: 1rem;
  border-top: 1px solid #E6E6E6;
}

.footer .copyright {
  padding: 0.9375rem;
}

.footer .copyright p {
  text-align: center;
  margin: 0;
  color: #918f8f;
}

.footer .copyright a {
  color: var(--primary) !important;
}

@media only screen and (max-width: 61.9375rem) {
  .footer {
    padding-left: 3.8rem;
  }
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: none;
}

[data-header-position="fixed"] .nav-header {
  position: fixed;
}

.nav-header {
  height: 4rem;
  width: 15rem;
  display: inline-block;
  text-align: left;
  position: absolute;
  top: 0;
  background-color: var(--nav-headbg);
  transition: all .2s ease;
  z-index: 5;
}

.nav-header .logo-compact {
  display: none;
}

.nav-header .brand-title-color {
  display: none;
}

.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 1.1rem;
  padding-right: 3rem;
  font-weight: 700;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .brand-logo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

[data-sidebar-style="compact"] .nav-header .brand-logo,
[data-sidebar-style="mini"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}

.nav-header .logo-abbr {
  /* height:45px;
		width:45px; */
}

.nav-header .logo-mini {
  display: none;
}

.nav-header .logo-color {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header .logo-color {
    display: block;
    /* height:45px;
			width:45px; */
  }
}

.nav-header .brand-title {
  margin-left: 0.6375rem;
  max-width: 10.15rem;
  margin-top: 0.2rem;
  font-size: 2.375rem;
  color: #000;
}

[data-theme-version="dark"] .nav-header .brand-title {
  background-position: 0 120%;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header {
    top: 0;
    background: transparent;
  }
}

.nav-header .rect-primary-rect {
  fill: var(--primary);
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
  }

  .nav-header .brand-title {
    display: none;
  }
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -3rem;
  text-align: center;
  top: 50.8%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 0.125rem 0.5rem 0;
  border-radius: 0.125rem;
  width: 3.063rem;
  height: 3.063rem;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-control {
    right: -3rem;
  }
}

.hamburger {
  display: inline-block;
  left: 0.1rem;
  position: relative;
  top: 0.5rem;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 1.625rem;
  z-index: 999;
}

.hamburger .line {
  background: #fff;
  display: block;
  height: 1px;
  border-radius: 0.1875rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  margin-right: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger svg {
  position: absolute;
  top: 9px;
  left: 0;
  z-index: 1;
}

.hamburger.is-active .line {
  opacity: 1;
}

.hamburger.is-active svg {
  opacity: 0;
}

.hamburger .line:nth-child(1) {
  width: 1.25rem;
}

.hamburger .line:nth-child(2) {
  width: 1.25rem;
}

.hamburger .line:nth-child(3) {
  width: 1.25rem;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 0.625rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 1.375rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) translateX(12px) rotate(45deg);
  transform: translateY(4px) translateX(12px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) translateX(12px) rotate(-45deg);
  transform: translateY(-4px) translateX(12px) rotate(-45deg);
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-control {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
  display: none;
}

[data-sidebar-style="full"][data-layout="horizontal"] .logo-compact {
  display: none;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
  max-width: 4.6875rem;
}

[data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
  width: 10.625rem;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
  display: none;
}

.header {
  height: 4rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  background: var(--headerbg);
  z-index: 3;
  padding-left: 15rem;
  padding-top: 0rem;
  transition: all .2s ease;
  /* &.is-fixed{
		background-color: #36246514;
		backdrop-filter: blur(6px);
	} */
}

.header .header-content {
  height: 100%;
  padding-left: 3.1rem;
  padding-right: 1.075rem;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 47.9375rem) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: 0.938rem;
  }

  .header .header-content:after {
    left: -5rem;
    width: calc(100% + 5rem);
  }
}

.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}

.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 47.9375rem) {
  .header {
    padding-top: 0;
  }
}

[data-headerbg="color_2"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_3"] .header {
  background: var(--headerbg);
}

.header {
  background: #2c2c2c;
}

[data-headerbg="color_5"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_6"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_7"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_8"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_9"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_10"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_11"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_12"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_13"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_14"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_15"] .header {
  background: var(--headerbg);
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}

svg.pulse-svg .first-circle,
svg.pulse-svg .second-circle,
svg.pulse-svg .third-circle {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
  fill: var(--primary);
}

svg.pulse-svg .second-circle {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

svg.pulse-svg .third-circle {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* pulse in CSS */
.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 1.25rem;
  position: absolute;
  background: #fe8630;
  right: 0.375rem;
  top: 0.3125rem;
  border: 0.25rem solid #fff;
  width: 1.25rem;
}

.pulse-css:after,
.pulse-css:before {
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -.2rem;
  background-color: #D8B9C3;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}

[direction="rtl"] .pulse-css:after,
[direction="rtl"] .pulse-css:before {
  left: auto;
  right: -.2rem;
}

@media only screen and (max-width: 87.5rem) {
  .pulse-css {
    height: 1rem;
    width: 1rem;
  }
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
  width: 100%;
}

@media only screen and (max-width: 87.5rem) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 17rem;
  }
}

@media only screen and (max-width: 63.9375rem) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 5rem;
  }
}

[data-sidebar-style="mini"] .header {
  width: 100%;
  padding-left: 7.5rem;
}

@media only screen and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"] .header {
    padding-left: 6.5rem;
  }
}

[data-sidebar-style="compact"] .header {
  width: 100%;
  padding-left: 12.5rem;
}

[data-sidebar-style="compact"] .header .header-content {
  padding-left: 2.5rem;
}

[data-header-position="fixed"] .header {
  position: fixed;
  top: 0;
  width: 100%;
}

[data-header-position="fixed"] .content-body {
  padding-top: 3.125rem;
  /* @include respond('tab-land'){
			padding-top: 5rem;
		} */
}

@media only screen and (max-width: 47.9375rem) {
  [data-header-position="fixed"] .content-body {
    padding-top: 3.1rem;
  }
}

[data-header-position="fixed"] .deznav {
  margin-top: 0;
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
  width: 74.9375rem;
}

[data-sidebar-style="modern"] .header {
  width: 100%;
  padding-left: 11.9rem;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .brand-logo {
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left .breadcrumb {
  margin-bottom: 0;
}

.header-left .agenda a {
  border-radius: 1.75rem;
}

.header-left .agenda a i {
  margin-right: 0.75rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-left .agenda a i {
    margin-right: 0rem;
  }
}

@media only screen and (max-width: 93.75rem) {
  .header-left .agenda a {
    font-size: 0;
    border-radius: 0.75rem;
    padding: 0.8125rem 0.9375rem;
  }

  .header-left .agenda a i {
    font-size: 1.25rem;
    margin-right: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-left .agenda {
    display: none;
  }
}

.header-left .dashboard_bar {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--secondary);
}

@media only screen and (max-width: 74.9375rem) {
  .header-left .dashboard_bar {
    font-size: 2.125rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-left .dashboard_bar {
    display: none;
  }
}

.header-left .dashboard_bar.sub-bar {
  font-size: 1.875rem;
}

.header-left .search-area {
  width: 15rem;
  /* background-color: var(--rgba-primary-1); */
  background-color: #fff;
  border-radius: 0;
}

@media only screen and (max-width: 100rem) {
  .header-left .search-area {
    width: 40.75rem;
    border-radius: 25px;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header-left .search-area {
    display: none;
  }
}

.header-left .search-area .form-control {
  border: 0;
  background: transparent;
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 4px;
}

@media only screen and (max-width: 87.5rem) {
  .header-left .search-area .form-control {
    height: 3rem;
  }
}

.header-left .search-area .input-group-text {
  background-color: transparent;
  border: 0;
  padding-right: 0;
}

.header-left .search-area .input-group-text a i {
  font-size: 1.5rem;
}

.header-left .search-area .input-group-text a svg path,
.header-left .search-area .input-group-text a svg circle {
  stroke: var(--primary);
}

@media only screen and (max-width: 87.5rem) {
  .header-left .search-area .input-group-text {
    height: 3rem;
  }
}

[data-headerbg="color_4"] .search-area {
  background-color: #202020 !important;
}

[data-sidebar-style="compact"] .header-left {
  margin-left: 0;
}

.header-right {
  height: 100%;
}

.header-right .header-profile {
  position: relative !important;
}

.header-right .header-profile .dropdown-menu:after {
  content: none;
}

.header-right .header-profile img {
  width: 45px;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .header-profile img {
    width: unset;
  }
}

.header-right .nav-item {
  display: flex;
  justify-content: center;
  position: relative;
}

.header-right .nav-item .nav-link {
  color: #fff;
  font-size: 1.125rem;
}

.header-right .right-sidebar {
  margin-right: -1.875rem;
}

.header-right .right-sidebar a {
  height: 5rem;
  width: 5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 0.0625rem solid #dfdfdf;
}

@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown {
    position: relative;
  }
}

.header-right .notification_dropdown .nav-link {
  position: relative;
  padding: 0.8375rem;
  line-height: 1;
}

.header-right .notification_dropdown .nav-link i {
  font-size: 1.5rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 1.125rem;
  }
}

.header-right .notification_dropdown .nav-link svg path,
.header-right .notification_dropdown .nav-link svg circle {
  stroke: #ffff;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.header-right .notification_dropdown .nav-link:hover {
  background: #ffff;
}

.header-right .notification_dropdown .nav-link:hover svg path,
.header-right .notification_dropdown .nav-link:hover svg circle {
  stroke: var(--primary);
}

.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
  color: #6e6e6e;
}

.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}

[data-theme-version="dark"] .header-right .dropdown-menu {
  box-shadow: none;
}

@media only screen and (max-width: 74.9375rem) {
  .header-right .dropdown-menu:after {
    content: none;
  }
}

.header-right .search-area {
  width: 25rem;
}

.header-right .search-area .form-control {
  background: #fff;
  border: 0;
  padding-left: 0;
  border-radius: 0.75rem;
}

.header-right .search-area ::placeholder {
  color: #A098AE;
  font-size: 16px;
  font-weight: 400;
}

.header-right .search-area .input-group-text {
  border-radius: 0.75rem;
  background: #fff;
  padding: 0px 5px;
  border: 0;
  padding-right: 0;
}

.header-right .search-area .input-group-text i {
  font-size: 1.5rem;
}

.header-right .search-area .input-group-text svg path {
  fill: var(--primary);
}

@media only screen and (max-width: 100rem) {
  .header-right .search-area {
    width: 15.625rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header-right .search-area {
    display: none;
  }
}

.header-right .header-media {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 7px;
}

.header-right .header-media img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .header-media {
    margin-right: 0;
  }
}

.header-right .header-info h6,
.header-right .header-info .h6 {
  font-size: 0.813rem;
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 1;
}

@media only screen and (max-width: 87.5rem) {

  .header-right .header-info h6,
  .header-right .header-info .h6 {
    margin-bottom: 4px;
  }
}

.header-right .header-info p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 4px;
}

.header-right .header-border {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  margin-left: 12px;
  padding-right: 24px;
}

.header-right .header-border:hover {
  background: transparent;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .header-border {
    margin-left: 1.875rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .header-border {
    display: none;
  }
}

.header-right .blur-img {
  position: absolute;
  left: 27%;
  top: 0;
  filter: blur(554px);
}

.dz-fullscreen #icon-minimize {
  display: none;
}

.dz-fullscreen.active #icon-full {
  display: none;
}

.dz-fullscreen.active #icon-minimize {
  display: inline-block;
}

.notification_dropdown .dropdown-menu-end {
  min-width: 18.5rem;
  padding: 1rem 0 0rem;
  top: 100%;
}

.notification_dropdown .dropdown-menu-end.style-1 {
  min-width: 18.5rem;
}

.notification_dropdown .dropdown-menu-end.show {
  overflow: unset;
}

.notification_dropdown .dropdown-menu-end .notification_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.notification_dropdown .dropdown-menu-end .notification_title h5,
.notification_dropdown .dropdown-menu-end .notification_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.notification_dropdown .dropdown-menu-end .media {
  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.125rem;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .media {
  border-color: #444444;
}

.notification_dropdown .dropdown-menu-end .media>span {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 3.125rem;
  display: inline-block;
  padding: 0.4375rem 0.5625rem;
  margin-right: 0.625rem;
}

[direction="rtl"].notification_dropdown .dropdown-menu-end .media>span {
  margin-right: 0;
  margin-left: 0.625rem;
}

.notification_dropdown .dropdown-menu-end .media>span.success {
  background: #daf5e6;
  color: #3AC977;
}

.notification_dropdown .dropdown-menu-end .media>span.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.notification_dropdown .dropdown-menu-end .media>span.danger {
  background: #ffdede;
  color: #FF5E5E;
}

.notification_dropdown .dropdown-menu-end .media .notify-time {
  width: 100%;
  margin-right: 0;
  color: #828690;
}

.notification_dropdown .dropdown-menu-end .media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12.5rem;
  margin-bottom: 0;
  margin-top: 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .notification_dropdown .dropdown-menu-end .media p {
    max-width: 6.25rem;
  }
}

.notification_dropdown .dropdown-menu-end .all-notification {
  display: block;
  padding: 0.9375rem 1.875rem 14px;
  color: var(--primary);
  text-align: center;
  border-top: 0.0625rem solid #f1f1f1;
  font-size: 0.813rem;
}

.notification_dropdown .dropdown-menu-end .all-notification i {
  margin-left: 0.625rem;
}

@media only screen and (max-width: 47.9375rem) {
  .notification_dropdown .dropdown-menu-end {
    right: 0;
    top: 45px;
    transform: translateX(0);
    padding-right: 0;
    width: 18rem;
    display: none !important;
    border-radius: 0.375rem;
  }

  .notification_dropdown .dropdown-menu-end.show {
    display: block !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .notification_dropdown .dropdown-menu-end {
    min-width: 18.5rem;
    right: -4rem;
  }
}

.header-profile>a.nav-link {
  padding: 0;
  display: flex;
  align-items: center;
}

.header-profile>a.nav-link i {
  font-weight: 700;
}

.header-profile>a.nav-link .header-info {
  padding-left: 0rem;
  text-align: left;
}

.header-profile>a.nav-link .header-info span {
  font-size: 1rem;
  color: #000;
  display: block;
  font-weight: 600;
}

.header-profile>a.nav-link .header-info strong {
  color: #6e6e6e;
}

.header-profile>a.nav-link .header-info small,
.header-profile>a.nav-link .header-info .small {
  display: block;
  font-size: 0.8125rem;
  color: #89879f;
  font-weight: 400;
  line-height: 1.2;
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile>a.nav-link .header-info {
    display: none;
  }
}

@media only screen and (max-width: 87.5rem) {
  .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
  }

  .header-profile>a.nav-link .header-info span {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
    border-left: 0;
  }
}

.header-profile .dropdown-menu {
  padding: 0.9375rem 0;
  min-width: 12.5rem;
  display: block;
  transform: scale(0.01);
  transform-origin: center top;
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
}

.header-profile .dropdown-menu a:hover,
.header-profile .dropdown-menu a:focus,
.header-profile .dropdown-menu a.active {
  color: var(--primary);
}

.header-profile .dropdown-menu.dropdown-menu-end.show {
  transform: scale(1);
  opacity: 1;
  box-shadow: 3px 3px 30px rgba(118, 96, 168, 0.15);
}

.header-profile img {
  border: 3px solid var(--primary);
  border-radius: 0.938rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile img {
    width: 2.4375rem;
    height: 2.4375rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-profile img {
    border: 2px solid var(--primary);
    border-radius: 0.625rem;
  }
}

.header-profile .dropdown-toggle i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile .dropdown-toggle span {
    display: none;
  }
}

.header-profile .profile_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.header-profile .profile_title h5,
.header-profile .profile_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.header-profile .dropdown-item {
  padding: 0.5rem 1.5rem;
}

[data-container="boxed"] .search-area {
  display: none !important;
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile2 .header-info {
    display: none;
  }
}

[data-headerbg="color_1"] .header .header-content {
  border-bottom: 1px solid #eee;
}

.profle-logout path {
  stroke: #ff7979 !important;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .navbar-nav .nav-item {
  float: right;
}

.rtl .navbar-nav .nav-item+.nav-item {
  margin-right: 1rem;
  margin-left: inherit;
}

.rtl th {
  text-align: right;
}

.rtl .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem;
}

.rtl .dropdown-menu {
  right: 0;
  text-align: right;
}

.rtl .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.375rem 0.375rem 0;
}

.rtl .btn-group>.btn:last-child:not(:first-child),
.rtl .btn-group>.dropdown-toggle:not(:first-child) {
  border-radius: 0.375rem 0 0 0.375rem;
}

.rtl .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-radius: 0.375rem 0 0 0.375rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
  right: 0;
  left: inherit;
}

.rtl .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  background: var(--bs-body-bg) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
  background-size: 8px 10px;
}

.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.rtl .input-group>.input-group-append:not(:last-child)>.btn,
.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-prepend>.btn,
.rtl .input-group>.input-group-prepend>.input-group-text {
  border-radius: 0 0.375rem 0.375rem 0;
}

.rtl .input-group>.input-group-append>.btn,
.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn,
.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-radius: 0.375rem 0 0 0.375rem;
}

.rtl .input-group>.custom-select:not(:first-child),
.rtl .input-group>.form-control:not(:first-child) {
  border-radius: 0.375rem 0 0 0.375rem;
}

.rtl .input-group>.custom-select:not(:last-child),
.rtl .input-group>.form-control:not(:last-child) {
  border-radius: 0 0.375rem 0.375rem 0;
}

.rtl .input-group>.custom-select:not(:last-child):not(:first-child),
.rtl .input-group>.form-control:not(:last-child):not(:first-child) {
  border-radius: 0;
}

.rtl .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem;
}

.rtl .custom-control-indicator {
  right: 0;
  left: inherit;
}

.rtl .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: .25rem 0 0 .25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .list-group {
  padding-right: 0;
  padding-left: 40px;
}

.rtl .close {
  float: left;
}

.rtl .modal-header .close {
  margin: -15px auto -15px -15px;
}

.rtl .modal-footer> :not(:first-child) {
  margin-right: .25rem;
}

.rtl .alert-dismissible .close {
  right: inherit;
  left: 0;
}

.rtl .dropdown-toggle::after {
  margin-right: .255em;
  margin-left: 0;
}

.rtl .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .offset-1 {
  margin-right: 8.33333%;
  margin-left: 0;
}

.rtl .offset-2 {
  margin-right: 16.66667%;
  margin-left: 0;
}

.rtl .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

.rtl .offset-4 {
  margin-right: 33.33333%;
  margin-left: 0;
}

.rtl .offset-5 {
  margin-right: 41.66667%;
  margin-left: 0;
}

.rtl .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

.rtl .offset-7 {
  margin-right: 58.33333%;
  margin-left: 0;
}

.rtl .offset-8 {
  margin-right: 66.66667%;
  margin-left: 0;
}

.rtl .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

.rtl .offset-10 {
  margin-right: 83.33333%;
  margin-left: 0;
}

.rtl .offset-11 {
  margin-right: 91.66667%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .rtl .offset-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-sm-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-sm-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-sm-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-sm-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .rtl .offset-md-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-md-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-md-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-md-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-md-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .rtl .offset-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-lg-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-lg-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-lg-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-lg-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .offset-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-xl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-xl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-xl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-xl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1440) {
  .rtl .offset-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-xxl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-xxl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-xxl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-xxl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

.rtl .mr-0,
.rtl .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {

  .rtl .mr-sm-0,
  .rtl .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-sm-0,
  .rtl .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-sm-1,
  .rtl .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-sm-1,
  .rtl .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-sm-3,
  .rtl .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-sm-3,
  .rtl .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-sm-4,
  .rtl .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-sm-4,
  .rtl .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-sm-5,
  .rtl .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-sm-5,
  .rtl .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-sm-0,
  .rtl .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-sm-0,
  .rtl .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-sm-1,
  .rtl .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-sm-1,
  .rtl .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-sm-2,
  .rtl .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-sm-2,
  .rtl .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-sm-3,
  .rtl .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-sm-3,
  .rtl .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-sm-4,
  .rtl .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-sm-4,
  .rtl .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-sm-5,
  .rtl .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-sm-5,
  .rtl .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {

  .rtl .mr-md-0,
  .rtl .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-md-0,
  .rtl .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-md-1,
  .rtl .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-md-1,
  .rtl .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-md-2,
  .rtl .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-md-2,
  .rtl .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-md-3,
  .rtl .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-md-3,
  .rtl .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-md-4,
  .rtl .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-md-4,
  .rtl .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-md-5,
  .rtl .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-md-5,
  .rtl .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-md-0,
  .rtl .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-md-0,
  .rtl .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-md-1,
  .rtl .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-md-1,
  .rtl .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-md-2,
  .rtl .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-md-2,
  .rtl .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-md-3,
  .rtl .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-md-3,
  .rtl .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-md-4,
  .rtl .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-md-4,
  .rtl .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-md-5,
  .rtl .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-md-5,
  .rtl .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {

  .rtl .mr-lg-0,
  .rtl .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-lg-0,
  .rtl .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-lg-1,
  .rtl .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-lg-1,
  .rtl .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-lg-2,
  .rtl .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-lg-2,
  .rtl .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-lg-3,
  .rtl .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-lg-3,
  .rtl .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-lg-4,
  .rtl .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-lg-4,
  .rtl .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-lg-5,
  .rtl .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-lg-5,
  .rtl .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-lg-0,
  .rtl .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-lg-0,
  .rtl .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-lg-1,
  .rtl .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-lg-1,
  .rtl .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-lg-2,
  .rtl .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-lg-2,
  .rtl .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-lg-3,
  .rtl .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-lg-3,
  .rtl .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-lg-4,
  .rtl .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-lg-4,
  .rtl .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-lg-5,
  .rtl .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-lg-5,
  .rtl .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {

  .rtl .mr-xl-0,
  .rtl .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-xl-0,
  .rtl .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-xl-1,
  .rtl .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-xl-1,
  .rtl .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-xl-2,
  .rtl .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-xl-2,
  .rtl .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-xl-3,
  .rtl .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-xl-3,
  .rtl .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-xl-4,
  .rtl .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-xl-4,
  .rtl .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-xl-5,
  .rtl .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-xl-5,
  .rtl .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-xl-0,
  .rtl .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-xl-0,
  .rtl .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-xl-1,
  .rtl .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-xl-1,
  .rtl .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-xl-2,
  .rtl .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-xl-2,
  .rtl .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-xl-3,
  .rtl .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-xl-3,
  .rtl .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-xl-4,
  .rtl .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-xl-4,
  .rtl .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-xl-5,
  .rtl .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-xl-5,
  .rtl .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {

  .rtl .mr-xxl-0,
  .rtl .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-xxl-0,
  .rtl .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-xxl-1,
  .rtl .mx-xxl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-xxl-1,
  .rtl .mx-xxl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-xxl-2,
  .rtl .mx-xxl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-xxl-2,
  .rtl .mx-xxl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-xxl-3,
  .rtl .mx-xxl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-xxl-3,
  .rtl .mx-xxl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-xxl-4,
  .rtl .mx-xxl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-xxl-4,
  .rtl .mx-xxl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-xxl-5,
  .rtl .mx-xxl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-xxl-5,
  .rtl .mx-xxl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-xxl-0,
  .rtl .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-xxl-0,
  .rtl .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-xxl-1,
  .rtl .px-xxl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-xxl-1,
  .rtl .px-xxl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-xxl-2,
  .rtl .px-xxl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-xxl-2,
  .rtl .px-xxl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-xxl-3,
  .rtl .px-xxl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-xxl-3,
  .rtl .px-xxl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-xxl-4,
  .rtl .px-xxl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-xxl-4,
  .rtl .px-xxl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-xxl-5,
  .rtl .px-xxl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-xxl-5,
  .rtl .px-xxl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .text-right {
  text-align: left !important;
}

.rtl .text-left {
  text-align: right !important;
}

@media (min-width: 576px) {
  .rtl .text-sm-right {
    text-align: left !important;
  }

  .rtl .text-sm-left {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .rtl .text-md-right {
    text-align: left !important;
  }

  .rtl .text-md-left {
    text-align: right !important;
  }
}

@media (min-width: 992px) {
  .rtl .text-lg-right {
    text-align: left !important;
  }

  .rtl .text-lg-left {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .rtl .text-xl-right {
    text-align: left !important;
  }

  .rtl .text-xl-left {
    text-align: right !important;
  }
}

@media (min-width: 1440) {
  .rtl .text-xxl-right {
    text-align: left !important;
  }

  .rtl .text-xxl-left {
    text-align: right !important;
  }
}

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important;
}

.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important;
}

.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important;
}

.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important;
}

.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important;
}

.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important;
}

.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important;
}

.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important;
}

.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important;
}

.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important;
}

.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important;
}

.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important;
}

.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important;
}

.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important;
}

.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important;
}

.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important;
}

.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important;
}

.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important;
}

.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important;
}

.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important;
}

.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important;
}

.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important;
}

.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important;
}

.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .float-left {
  float: right !important;
}

.rtl .float-right {
  float: left !important;
}

.rtl .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important;
  }

  .rtl .float-sm-right {
    float: left !important;
  }

  .rtl .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important;
  }

  .rtl .float-md-right {
    float: left !important;
  }

  .rtl .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important;
  }

  .rtl .float-lg-right {
    float: left !important;
  }

  .rtl .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important;
  }

  .rtl .float-xl-right {
    float: left !important;
  }

  .rtl .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important;
  }

  .rtl .float-xxl-right {
    float: left !important;
  }

  .rtl .float-xxl-none {
    float: none !important;
  }
}

[direction="rtl"][data-theme-version="dark"] .border,
[direction="rtl"][data-theme-version="dark"] .border-left,
[direction="rtl"][data-theme-version="dark"] .border-right {
  border-color: #444444 !important;
}

[direction="rtl"] .text-right {
  text-align: left !important;
}

[direction="rtl"] .text-left {
  text-align: right !important;
}

[direction="rtl"] .border-right {
  border-left: 0.0625rem solid #E6E6E6 !important;
  border-right: 0 !important;
}

[direction="rtl"] .border-left {
  border-right: 0.0625rem solid #E6E6E6 !important;
  border-left: 0 !important;
}

[direction="rtl"] .dropdown-menu {
  left: auto;
}

[direction="rtl"] .dropdown-menu-right {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .dropdown-menu-right {
    left: 0.9375rem;
  }
}

[direction="rtl"] .notification_dropdown .dropdown-menu-right .media>span {
  margin-left: 0.625rem;
  margin-right: 0;
}

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
  left: auto;
  right: 0;
}

[direction="rtl"][data-container="wide-boxed"] .nav-header {
  left: auto;
  right: auto;
}

[direction="rtl"] .nav-header {
  text-align: right;
  right: auto;
}

[direction="rtl"] .nav-header .brand-title {
  margin-left: 0;
  margin-right: 0.9375rem;
}

[direction="rtl"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 1.75rem;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
  padding-right: 1.875rem;
}

[data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 1.875rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .nav-header .brand-logo {
    padding-right: 0;
  }
}

[direction="rtl"] .nav-control {
  right: auto;
  left: -4.0625rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .nav-control {
    left: -4.0625rem;
  }
}

@media (max-width: 575.98px) {
  [direction="rtl"] .nav-control {
    left: -2.0625rem;
  }
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  right: 0;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-right: 2.5rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
  width: 16rem;
}

[direction="rtl"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .header {
    padding-right: 5rem;
    padding-left: 0;
  }
}

[direction="rtl"] .header .header-content {
  padding-left: 1.875rem;
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header .header-content {
    padding-right: 3.5rem;
    padding-left: 0.938rem;
  }
}

[data-sidebar-style="compact"][direction="rtl"] .header .header-content {
  padding-right: 1.875rem;
}

[data-sidebar-style="modern"][direction="rtl"] .header .header-content {
  padding-right: 5.3125rem;
}

[data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
    padding-right: 0.5rem;
  }
}

[direction="rtl"] .header .nav-control {
  right: 0.4375rem;
  left: auto;
}

[direction="rtl"] .header-right>li:not(:first-child) {
  padding-left: 0;
  padding-right: 1.25rem;
  margin-right: 0 !important;
}

@media only screen and (max-width: 47.9375rem) {
  [direction="rtl"] .header-right>li:not(:first-child) {
    padding-right: 0.5rem;
  }
}

[direction="rtl"] .header-right .search-area .input-group-append .input-group-text {
  padding-right: auto;
  padding-left: 1.25rem;
}

[direction="rtl"] .header-right .search-area .form-control {
  padding-left: auto;
  padding-right: 1.25rem;
}

[direction="rtl"] .header-right .header-profile>a.nav-link {
  margin-left: auto;
  padding-left: auto;
  margin-right: 0.9375rem;
  padding-right: 1.875rem;
  border-right: 0.0625rem solid #CCCCCC;
  border-left: 0;
}

[direction="rtl"] .header-right .header-profile>a.nav-link .header-info {
  padding-right: 1.25rem;
  padding-left: auto;
  text-align: right;
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link .header-info {
    padding-right: 0.625rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link {
    margin-right: 0.625rem;
    padding-right: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link {
    margin-right: 0rem;
    padding-right: 0rem;
    border-right: 0;
  }
}

[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
  right: 2.5rem !important;
}

@media only screen and (max-width: 35.9375rem) {

  [direction="rtl"] .header-left .search_bar .dropdown-menu,
  [direction="rtl"] .header-left .search_bar .dropdown-menu.show {
    right: -6.25rem !important;
  }
}

[direction="rtl"] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header-left .search_bar .search_icon {
    border-radius: 5rem;
  }
}

[direction="rtl"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

[direction="rtl"][data-layout="horizontal"] .header .header-content {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="full"] .nav-header .brand-logo {
  padding-right: 2.5rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
  padding-right: 7.75rem;
}

[direction="rtl"][data-sidebar-style="mini"] .header {
  padding-right: 6.25rem;
}

[direction="rtl"][data-sidebar-style="compact"] .header {
  padding: 0 0.9375rem;
  padding-right: 11.25rem;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

[direction="rtl"][data-sidebar-style="modern"] .header {
  padding: 0 0.9375rem;
  padding-right: 10.625rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem;
}

[direction="rtl"] {
  text-align: right;
}

[direction="rtl"] .deznav {
  text-align: right;
}

[direction="rtl"] .deznav .metismenu ul:after {
  left: auto;
  right: 1.5625rem;
}

[direction="rtl"] .deznav .metismenu ul a:before {
  left: auto;
  right: 2.625rem;
}

[data-sidebar-style="full"][direction="rtl"] .deznav .metismenu li>a i {
  padding: 0 0 0 0;
  margin-right: 0;
  margin-left: 0.625rem;
}

[direction="rtl"] .deznav .metismenu li>a svg {
  margin-left: 0.3125rem;
  margin-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .deznav .metismenu li>a svg {
  left: auto;
  margin-left: auto;
  margin-right: auto;
}

[data-sidebar-style="icon-hover"][direction="rtl"] .deznav .metismenu li>a svg {
  margin-left: 0;
}

[direction="rtl"] .deznav .metismenu li ul a {
  padding-right: 6rem;
  padding-left: 0.625rem;
}

[direction="rtl"] .deznav .metismenu li.active>.has-arrow:after {
  transform: rotate(45deg) translateY(-50%);
}

[direction="rtl"] .deznav .metismenu .has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[data-layout="horizontal"][direction="rtl"] .deznav .metismenu .has-arrow:after {
  left: 1.125rem;
}

[data-sidebar-style="modern"][direction="rtl"] .deznav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li .has-arrow:after {
  left: 1.5rem;
  right: auto;
}

[direction="rtl"][data-sidebar-style="mini"] .deznav .metismenu>li>a>i {
  padding: 0;
}

[direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul a.has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu li>a i {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

[direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu li ul a {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul {
  right: 11.8125rem;
  left: 0;
}

[direction="rtl"] {
  /* ===== Light gallery ===== */
}

[direction="rtl"] .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 15px;
  right: auto;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-radius: 0.75rem 0 0 0.75rem;
}

[direction="rtl"] .list-group {
  padding-left: 0;
}

[direction="rtl"] .form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit;
}

[direction="rtl"] .form-check-inline .form-check-input {
  margin-right: 0;
  margin-left: 10px;
}

[direction="rtl"] .radio input,
[direction="rtl"] .radio-inline,
[direction="rtl"] .checkbox input,
[direction="rtl"] .checkbox-inline input {
  margin-left: 0;
  margin-right: 0;
}

[direction="rtl"] .content-body {
  margin-right: 21.563rem;
  margin-left: auto;
}

[data-sidebar-style="modern"][direction="rtl"] .content-body {
  margin-right: 9.375rem;
}

[direction="rtl"] .content-body .page-titles {
  text-align: right;
}

[direction="rtl"] .doctor-info-details .media-body span i,
[direction="rtl"] .recovered-chart-deta .col [class*="bg-"] {
  margin-right: 0;
  margin-left: 10px;
}

[direction="rtl"] .patients-chart-deta .col,
[direction="rtl"] .patients-chart-deta .col [class*="bg-"],
[direction="rtl"] .recovered-chart-deta .col {
  margin-right: 0;
  margin-left: 15px;
}

[direction="rtl"] .best-doctor .timeline .timeline-panel .media .number {
  left: auto;
  right: -13px;
}

[direction="rtl"] .doctor-info-details .media i {
  right: 0;
  left: -15px;
}

[direction="rtl"] .review-table .disease {
  border-left: 0;
  border-right: 1px solid #eee;
  padding-left: 0;
  padding-right: 20px;
}

[direction="rtl"] .apexcharts-legend-text {
  margin: 4px;
}

[direction="rtl"] .doctor-info-details .media-body {
  padding-left: 0;
  padding-right: 40px;
}

[direction="rtl"] .custom-control {
  margin-left: 0;
}

[direction="rtl"] .review-tab.nav-pills li:first-child a.nav-link {
  border-radius: 0 0.75rem 0 0;
}

[direction="rtl"] .review-tab.nav-pills li:last-child a.nav-link {
  border-radius: 0.75rem 0 0 0;
}

[direction="rtl"] .form-head .btn i {
  margin-left: 5px;
  margin-right: 0;
}

[direction="rtl"] .iconbox {
  padding-left: 0;
  padding-right: 70px;
}

[direction="rtl"] .iconbox i {
  left: auto;
  right: 0;
}

[direction="rtl"] .table.tr-rounded tr td:first-child,
[direction="rtl"] .table.tr-rounded tr th:first-child {
  border-radius: 0 0.375rem 0.375rem 0;
}

[direction="rtl"] .table.tr-rounded tr td:last-child,
[direction="rtl"] .table.tr-rounded tr th:last-child {
  border-radius: 0.375rem 0 0 0.375rem;
}

[direction="rtl"] .custom-switch.toggle-switch.text-right {
  padding-left: 48px;
  padding-right: 0;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-label:before {
  right: auto !important;
  left: -47px;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-label:after {
  right: auto !important;
  left: -28px;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-input:checked~.custom-control-label:after {
  left: -62px;
  right: auto !important;
}

[direction="rtl"] .check-switch {
  padding-right: 40px;
}

[direction="rtl"] .check-switch .custom-control-label:after,
[direction="rtl"] .check-switch .custom-control-label:before {
  right: -35px !important;
}

[direction="rtl"] .bar-chart .apexcharts-yaxis {
  transform: translatex(101%);
}

[direction="rtl"] .detault-daterange .input-group-text {
  padding: 0.532rem 0.75rem;
  padding-right: auto;
  padding-left: 0;
}

[direction="rtl"] .form-wrapper .input-group .form-control {
  text-align: left;
}

[direction="rtl"] .timeline-chart .apexcharts-yaxis {
  transform: translateX(0);
}

[direction="rtl"] .card-table td:first-child {
  padding-right: 30px;
  padding-left: 10px;
}

[direction="rtl"] .card-table td:last-child {
  padding-left: 30px;
  padding-right: 10px;
}

[direction="rtl"] .chatbox .img_cont {
  margin-right: 0px;
  margin-left: 10px;
}

[direction="rtl"] .profile-tab .nav-item .nav-link {
  margin-right: 0;
  margin-left: 30px;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .profile-tab .nav-item .nav-link {
    margin-left: 0px;
  }
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel {
  margin-left: 0px;
  margin-right: 40px;
}

[direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  padding: 5px 15px 5px 10px;
  border-width: 0px 5px 0px 0px;
}

[direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  right: -9px;
  width: 7px;
  left: auto;
  height: 7px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 0px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel:after {
  left: auto;
  right: -5px;
}

[direction="rtl"] .chatbox .msg_cotainer {
  margin-left: 0;
  margin-right: 10px;
}

[direction="rtl"] .new-arrival-content .price {
  float: left;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[direction="rtl"] .breadcrumb-item+.breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}

[direction="rtl"] .breadcrumb-item+.breadcrumb-item::before {
  padding-right: 0;
  padding-left: 0.5rem;
}

[direction="rtl"] .chatbox .chatbox-close {
  left: 340px;
  right: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .chatbox .chatbox-close {
    left: 280px;
  }
}

[direction="rtl"] .separator {
  margin-right: 0;
  margin-left: 9px;
}

[direction="rtl"] .card-tabs .nav-tabs {
  padding-right: 5px;
}

[direction="rtl"] .chatbox .msg_cotainer {
  margin-left: 0;
  margin-right: 10px;
  border-radius: 1.375rem 0 1.375rem 1.375rem;
}

[direction="rtl"] .chatbox .msg_cotainer:after {
  left: auto;
  right: -10px;
  transform: rotate(-90deg);
}

[direction="rtl"] .chatbox .type_msg .input-group .input-group-append .btn {
  border-top-right-radius: 38px !important;
  border-bottom-right-radius: 38px !important;
}

[direction="rtl"] .chatbox .msg_cotainer_send {
  margin-right: 0px;
  margin-left: 10px;
  border-radius: 0 6px 6px 6px;
}

[direction="rtl"] .chatbox .msg_cotainer_send:after {
  right: auto;
  left: -10px;
  transform: rotate(90deg);
}

[direction="rtl"] .new-arrival-content .price {
  float: left;
}

[direction="rtl"] .trending-menus .tr-row .num {
  margin-right: 0;
  margin-left: 15px;
}

[direction="rtl"] .default-select.style-2 .btn:after {
  margin-left: 0;
  margin-right: 0.5em;
}

[direction="rtl"] .widget-timeline-icon li .icon {
  left: auto;
  right: -2px;
}

[direction="rtl"] .widget-timeline-icon li a {
  padding: 25px 0px 0px 50px;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .widget-timeline-icon .timeline {
    margin-left: 0;
    margin-right: 10px;
  }

  [direction="rtl"] .widget-timeline-icon li {
    border-left: 0;
    border-right: 6px solid var(--primary);
  }

  [direction="rtl"] .widget-timeline-icon li a {
    padding: 0 30px 30px 0;
  }

  [direction="rtl"] .widget-timeline-icon li .icon {
    right: -12px;
  }

  [direction="rtl"] .widget-timeline-icon li:last-child {
    border-color: transparent;
  }
}

[direction="rtl"] #revenueMap .apexcharts-yaxis,
[direction="rtl"] #customerMapkm .apexcharts-yaxis {
  transform: translateX(0px);
}

[direction="rtl"] .mail-list .list-group-item i {
  padding-right: 0;
  padding-left: 0.625rem;
}

[direction="rtl"] .dz-demo-panel {
  right: auto;
  left: -380px;
}

[direction="rtl"] .dz-demo-panel.show {
  right: unset;
  left: 0;
}

[direction="rtl"] .dz-demo-panel .dz-demo-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction="rtl"][data-layout="horizontal"] .content-body {
  margin-right: 0;
}

[direction="rtl"][data-layout="horizontal"] .deznav .metismenu li li .has-arrow:after {
  -webkit-transform: rotate(-4deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 6.25rem;
}

[direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 11.25rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .content-body {
  margin-right: 0;
}

[direction="rtl"] #external-events .external-event:before {
  margin-right: 0;
  margin-left: .9rem;
}

[direction="rtl"] .post-input a i {
  margin-left: 15px;
  margin-right: 0;
}

[direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"] .deznav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

[direction="rtl"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
[direction="rtl"] .deznav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg);
}

[direction="rtl"] .chatbox {
  left: -500px;
  right: auto;
}

[direction="rtl"] .chatbox.active {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 575px) {
  [direction="rtl"] .best-doctor .timeline .timeline-panel .media {
    float: right;
    margin-right: 0 !important;
    margin-left: 15px !important;
  }
}

[direction="rtl"] .default-select.style-1 .btn:after {
  margin-left: 0;
  margin-right: 0.5em;
}

[direction="rtl"] .pagination .page-indicator {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

[direction="rtl"] .lg-outer.lg-visible {
  direction: ltr;
}

[direction="rtl"] .chart-point .chart-point-list {
  margin: 0;
  padding-right: 20px;
}

[direction="rtl"] .noUi-target {
  direction: rtl;
}

[direction="rtl"] .noUi-vertical .noUi-pips-vertical {
  left: -20px;
}

[direction="rtl"] .noUi-vertical .noUi-value-vertical {
  padding-left: 0;
  padding-right: 25px;
}

[direction="rtl"] .sidebar-right .ps--active-x>.ps__rail-x {
  display: none;
}

[direction="rtl"] .form-wizard .nav-wizard li .nav-link:after {
  right: 50%;
  left: auto;
}

[direction="rtl"] .dtp>.dtp-content {
  right: 50%;
  left: auto;
}

[direction="rtl"] .modal-header .close {
  margin: 0;
  left: 0;
  top: 0px;
  right: auto;
}

[direction="rtl"] .input-group-prepend .btn+.btn {
  border-radius: 0 !important;
}

[direction="rtl"] .form-control+.input-group-append .btn:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

[direction="rtl"] .input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}

[direction="rtl"] .custom-file-label::after {
  border-radius: .75rem 0 0 .75rem;
}

[direction="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

[direction="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-radius: 0;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction="rtl"] .content-body {
    margin-right: 17rem;
  }
}

[direction="rtl"] .sidebar-right {
  right: auto;
  left: -50rem;
}

[direction="rtl"] .sidebar-right.show {
  left: 5.25rem;
  right: unset;
}

[direction="rtl"] .sidebar-right .sidebar-right-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction="rtl"] .sidebar-right .sidebar-close-trigger {
  right: auto;
  left: -48px;
}

[direction="rtl"] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right;
}

html[dir="rtl"] [direction="rtl"] .footer {
  padding-right: 17.1875rem;
  padding-left: 0;
}

@media (max-width: 767.98px) {
  html[dir="rtl"] [direction="rtl"] .footer {
    padding-right: 0;
  }
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
  padding-right: 0;
}

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
  padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
  padding-right: 0;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
  padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
  padding-right: 9.375rem;
}

.nav-label {
  margin: 0.625rem 1.875rem 0;
  padding: 1.5625rem 0 0.625rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 0.0625rem solid #eaeaea;
  color: #999999;
}

[data-theme-version="dark"] .nav-label {
  border-color: #444444;
}

.nav-label.first {
  border: 0rem;
  margin-top: 0rem;
}

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem;
}

.content-body {
  margin-left: 15rem;
  z-index: 0;
  transition: all .2s ease;
}

.content-body.hide {
  margin-left: 3.7rem;
  transition: all .2s ease;
}

@media only screen and (max-width: 47.9375rem) {
  .content-body {
    padding-right: 0rem;
  }
}

.bell img {
  -webkit-animation: ring 8s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 0.25rem;
  -moz-animation: ring 8s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 0.25rem;
  animation: ring 8s .7s ease-in-out infinite;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.deznav {
  width: 15rem;
  padding-bottom: 10px;
  height: calc(100% - 3.125rem);
  position: absolute;
  padding-top: 1rem;
  z-index: 6;
  background-color: var(--sidebar-bg);
  border-right: 1px solid #E6E6E6;
  transition: all .2s ease;
  box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02);

}

/* .main-wrapper-details{
  display: flex;
} */

.deznav .deznav-scroll {
  position: relative;
  height: 100%;
  overflow-y: scroll;
}

.deznav.showMenu .deznav-scroll {
  overflow: visible;
}

.deznav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.deznav .metismenu {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(13, 153, 255, 0.05); */
  flex: 1;
}

.deznav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.deznav .metismenu>li {
  display: flex;
  flex-direction: column;
}

.deznav .metismenu>li a>i {
  font-size: 1.375rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  height: auto;
  width: auto;
  text-align: center;
  margin-right: 0.65rem;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.375rem;
  font-family: 'Material Icons';
  font-style: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
}

[data-sidebar-style="compact"] .deznav .metismenu>li a>i {
  display: block;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.3);
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.625rem;
  line-height: 3.45rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.3125rem;
}

[data-sidebar-style="compact"] .deznav .metismenu>li a>i[data-theme-version="dark"] {
  color: #fff;
}

@media only screen and (max-width: 84.375rem) {
  .deznav .metismenu>li a>i {
    height: auto;
    line-height: 0.0625rem;
    width: auto;
    font-size: 1.5rem;
    padding: 0;
    color: #969BA0;
  }
}

.deznav .metismenu>li>a {
  font-weight: 800;
  display: inline-block;
  color: #2C2C2C;
}

.deznav .metismenu>li>a i {
  color: #96A0AF;
}

.deznav .metismenu>li>a svg {
  height: 100%;
  margin-right: 0.4125rem;
  margin-top: -0.0875rem;
}

.deznav .metismenu>li>a .menu-icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 1.2rem;
  height: auto;
  width: auto;
  text-align: center;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.125rem;
}

.deznav .metismenu>li>a g [fill] {
  fill: #8088a1;
}

/* .deznav .metismenu>li:hover>a,
.deznav .metismenu>li:focus>a {
   background-color: lighten($color: $primary, $amount: 40%);
  color: var(--primary);
} */

.deznav .metismenu>li:hover>a g [fill],
.deznav .metismenu>li:focus>a g [fill] {
  fill: var(--primary);
}

.deznav .metismenu>li.mm-active>a {

  font-weight: 400;
}

.deznav .metismenu>li.mm-active>a i {
  font-weight: 900;
}

.deznav .metismenu>li.mm-active>a .menu-icon svg path {
  stroke: var(--primary);
}

.deznav .metismenu>li.mm-active>a g [fill] {
  fill: var(--primary);
}

.deznav .metismenu>li.mm-active>a:after {
  border-top: 0.3125rem solid #888888;
  border-left: 0.3125rem solid #888888;
  top: 44%;
  border-width: 1px;
}

[data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a i {
  background: var(--primary);
  color: #fff;
}

.deznav .metismenu li {
  position: relative;
}

.deznav .metismenu .menu-title {
  font-size: 0.75rem;
  font-weight: 400;
  padding: 8px 0px 10px 20.6px;
  color: var(--primary);
}

.deznav .metismenu .menu-title:not(:first-child) {
  margin-top: 10px;
}

.deznav .metismenu ul {
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
}

.deznav .metismenu ul.mm-show {
  transition: all 0.3s ease;
  max-height: 2045px;
  padding: 0.5rem 0;
}

.deznav .metismenu ul a {
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: relative;
  font-size: 0.878rem;
  padding-left: 2.5rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 84.375rem) {
  .deznav .metismenu ul a {
    padding-left: 3.8rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .deznav .metismenu ul a {
    padding-left: 3.4rem;
    font-size: 0.875rem;
  }
}

[data-sidebar-style="full"] .deznav .metismenu ul a:before {
  content: "";
  height: 0.125rem;
  width: 0.375rem;
  background: var(--rgba-primary-5);
  position: absolute;
  left: 1.8rem;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="full"] .deznav .metismenu ul a:hover {
  padding-left: 3.8rem;
}

@media only screen and (max-width: 78.125rem) {
  [data-sidebar-style="full"] .deznav .metismenu ul a:hover {
    padding-left: 3.3rem;
  }
}

[data-sidebar-style="full"] .deznav .metismenu ul a:hover:before {
  width: 1.25rem;
}

.deznav .metismenu ul a:hover,
.deznav .metismenu ul a:focus,
.deznav .metismenu ul a.mm-active {
  text-decoration: none;
  color: #000;
}

.deznav .metismenu ul a:before {
  position: absolute;
  content: none;
  height: 0.125rem;
  width: 0.5rem;
  top: 50%;
  left: 1.75rem;
  border-radius: 3.75rem;
  transform: translateY(-50%);
}

@media only screen and (max-width: 84.375rem) {
  .deznav .metismenu ul a:before {
    left: 2.8125rem;
  }
}

.deznav .metismenu a {
  position: relative;
  display: block;
  padding: 0.825rem 1.875rem;
  outline-width: 0;
  color: #737B8B;
  text-decoration: none;
}

@media only screen and (max-width: 47.9375rem) {
  .deznav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}

.deznav .metismenu .has-arrow:after {
  /* width:  .5rem;
                height: .5rem;
                border-color: inherit; */
  border-top: 0.3125rem solid #C8C8C8;
  border-left: 0.3125rem solid #C8C8C8;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  right: 15px;
  top: 44%;
  border-width: 1px;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}

.deznav .metismenu .has-arrow[aria-expanded=true]:after,
.deznav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
  /* margin-right: 2rem; */
}

.deznav .main-menu {
  padding: 0.625rem 1.875rem;
}

.deznav .header-info2 {
  padding: 0 20px;
}

.deznav .header-info2 img {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 1.25rem;
  margin-right: 1.2rem;
}

.deznav .header-info2 span {
  font-size: 1.25rem;
  font-weight: 500;
  color: #393939;
}

@media only screen and (max-width: 87.5rem) {
  .deznav .header-info2 span {
    font-size: 0.9rem;
  }
}

.deznav .header-info2 i {
  margin-left: 1rem;
  font-size: 1.2rem;
}

@media only screen and (max-width: 87.5rem) {
  .deznav .header-info2 {
    padding: 0;
  }
}

.deznav .ps__rail-y {
  left: 0 !important;
  opacity: 0.1;
}

.deznav .ps__rail-y:hover {
  opacity: 0.1;
}

.copyright {
  padding: 0 1.875rem;
}

.copyright p {
  font-size: 0.875rem;
}

.copyright strong {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
  }
}

@media (max-width: 47.9375rem) {
  .brand-title {
    display: none;
  }

  .footer {
    padding-left: 0;
  }

  .deznav {
    left: 0;
    top: 3.2rem;
  }
}

.extra-menu-area+.show-more-btn .show-less {
  display: none;
}

.extra-menu-area.show+.show-more-btn .show-less {
  display: block;
}

.extra-menu-area.show+.show-more-btn .show-more {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu>ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu>ul.collapse:not(.in) {
  height: 15.75rem !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(70vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(70vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 12.5rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(55vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 12.5rem;
  width: 55vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>a {
  width: calc(45vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(30vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 30vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 25.625rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(50vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 16.25rem;
  width: 50vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>a {
  width: calc(40vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 40vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(22vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 22vw;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:not(:last-child) {
  position: static;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul {
  left: 0;
  right: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #444444;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #0b4c44;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #0060a6;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: black;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #001ecb;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #0057d8;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #1971a3;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #7b3581;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #56704e;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #0d1547;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li a {
  transition: all .4s ease-in-out;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li a:hover {
  border-radius: 0.25rem;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  content: "";
  height: 100%;
  width: 0.0625rem;
  position: absolute;
  background-color: #fff;
  right: 2.8125rem;
  top: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #262626;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #12786c;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #007ed9;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #131313;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0025fe;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0c6dff;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #208fcf;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #9e44a5;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #6d8f62;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #142173;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 13.125rem;
  width: 70vw;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 13.125rem;
  width: 43.75rem;
  height: 13.125rem !important;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
    width: 43.75rem;
  }
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 20rem;
  width: 54vw;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-md:hover>ul {
    width: 60vw;
  }
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 20rem;
  width: 25vw;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
    width: 35vw;
  }
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 21.875rem;
  width: 100%;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 21.875rem;
  width: 55vw;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 50vw;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a {
  font-size: 0.813rem;
  padding: 0.75rem 1.5rem;
  margin: 0px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
  font-weight: 300;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li .has-arrow:after {
  right: 1.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 3.75rem;
  z-index: 999;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo .color-title {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control {
  right: -3rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: var(--primary);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .copyright,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .plus-box,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .help-desk {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 7.5rem;
  width: 100%;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding: 0 0.9375rem;
  padding-right: 7.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .header-content {
  padding-left: 0rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav {
  width: 3.75rem;
  overflow: visible;
  position: absolute;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .nav-text {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .slimScrollDiv,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .deznav-scroll {
  overflow: visible !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .header-info2 {
  padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .sidebar-info {
  display: none !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .header-profile2 {
  margin: 0 0.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li {
  position: relative;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a {
  background: transparent;
  margin: 0.125rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a svg {
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-right: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a:before {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a i {
  margin: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
  position: absolute;
  left: 3.7rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 0.0625rem;
  height: auto !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  margin-left: 0;
  border: 0;
  border-radius: 0.375rem;
  background: #fff;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
  left: auto;
  right: 5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul {
  left: 11.8125rem;
  top: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul:after {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li:hover>ul {
  display: block;
  height: auto;
  overflow: visible;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li {
  transition: all 0.4s ease-in-out;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a {
  padding: 0.725rem 0.775rem;
  margin: 3px 0px;
  text-align: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a.has-arrow:after {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a {
  background-color: rgba(13, 153, 255, 0.05);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a i {
  color: #fff;
  padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover:nth-last-child(-n + 1)>ul {
  bottom: 0;
  top: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: rgba(13, 153, 255, 0.05);
  color: #fff;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background: #242424;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a i {
  color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul {
  height: auto !important;
  padding: 0.625rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul a {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul ul {
  padding: 0.625rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul ul a {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-label,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-badge {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .menu-title {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-left: 3.7rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-right: 5.7rem;
  margin-left: auto;
  border: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 5.7rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 0;
  padding-right: 5.7rem;
}

[data-sidebar-style="full"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

[data-nav-headerbg="color_1"] .menu-toggle .color-title {
  display: none !important;
}

.show-more-btn {
  padding: 0.75rem 1.5rem;
  font-size: 0.813rem !important;
  color: var(--primary) !important;
}

.show-more-btn:before {
  display: none;
}

.show-more-btn:active {
  border: 0 !important;
}

.show-more-btn.collapsed {
  border: 0;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] .nav-header {
    width: 3.71rem;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control {
    z-index: -1;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
    left: 6.25rem !important;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
    background-color: #6e6e6e !important;
  }

  [data-sidebar-style="mini"] .nav-header .brand-title {
    display: none;
  }

  [data-sidebar-style="mini"] .nav-header .hamburger {
    display: none;
  }

  [data-sidebar-style="mini"] .header .header-content {
    padding-left: 1.875rem;
  }

  [direction="rtl"][data-sidebar-style="mini"] .header .header-content {
    padding-right: 1.875rem;
  }

  [data-sidebar-style="mini"] .deznav {
    width: 3.75rem;
    overflow: visible;
    position: absolute;
    z-index: 2;
  }

  [data-sidebar-style="mini"] .deznav .copyright,
  [data-sidebar-style="mini"] .deznav .plus-box {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .nav-text {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .slimScrollDiv,
  [data-sidebar-style="mini"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="mini"] .deznav .nav-user {
    padding: 0.6875rem;
  }

  [data-sidebar-style="mini"] .deznav .nav-user .media-body {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .header-profile {
    margin-bottom: 0;
    margin-top: 0.75rem;
  }

  [data-sidebar-style="mini"] .deznav .header-profile:hover>a.nav-link {
    border-radius: 3rem;
  }

  [data-sidebar-style="mini"] .deznav .header-profile img {
    width: 3rem;
    height: 3rem;
  }

  [data-sidebar-style="mini"] .deznav .header-profile>a.nav-link {
    border-radius: 3rem;
    padding: 0.3125rem 0.3125rem;
  }

  [data-sidebar-style="mini"] .deznav .header-profile .header-info {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li a {
    padding: 0.813rem 0.875rem;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li a svg {
    margin-right: 0;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li a i {
    height: auto;
    width: auto;
    line-height: 1;
    margin: 0;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li>ul {
    position: absolute;
    left: 6.25rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 0.0625rem;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    height: auto !important;
    border-radius: 0.375rem;
    background: #fff;
  }

  [direction="rtl"]:not([data-layout="horizontal"])[data-sidebar-style="mini"] .deznav .metismenu li>ul {
    left: auto;
    right: 6.25rem;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li>a {
    padding: 0.725rem 0.775rem;
    text-align: center;
    margin: 3px 0px;
    line-height: 1;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li>a>i {
    padding: 0;
    font-size: 1.375rem;
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li>a.has-arrow:after {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .metismenu .menu-title {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .metismenu .nav-label,
  [data-sidebar-style="mini"] .deznav .metismenu .nav-badge {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .header-info2 {
    padding: 0;
  }

  [data-sidebar-style="mini"] .deznav .sidebar-info {
    display: none !important;
  }

  [data-sidebar-style="mini"] .deznav .header-profile2 {
    margin: 0 0.5rem;
  }

  [data-sidebar-style="mini"] .deznav .help-desk {
    display: none;
  }

  [data-sidebar-style="mini"] .content-body {
    margin-left: 3.8rem;
  }

  [data-sidebar-style="mini"][data-header-position="fixed"] .content-body {
    padding-top: 3.1rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"][data-header-position="fixed"] .content-body {
    padding-top: 3.1rem;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav {
    position: absolute !important;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu .mm-active>.has-arrow:after {
    -webkit-transform: rotate(-225deg) translateY(-50%) !important;
    transform: rotate(-225deg) translateY(-50%) !important;
    /* margin-right: 2rem; */
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    display: block;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul:after {
    top: auto;
    bottom: 1.25rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul {
    overflow: visible;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul:after {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul {
    padding: 0.625rem 0;
    width: 13rem;
    left: 13rem;
    top: -0.625rem;
    border: 0;
    margin: 0;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul:after {
    content: none;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul {
    left: auto;
    right: 13rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
    background: rgba(13, 153, 255, 0.05);
    color: #fff;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
    background-color: rgba(13, 153, 255, 0.05);
    color: var(--primary);
    /* box-shadow:0 12px 15px 0 var(--rgba-primary-1); */
    border-radius: 0;
    position: unset;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
    color: #fff;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a .nav-text {
    padding-left: auto;
    padding-right: 1.6875rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    height: auto !important;
    overflow: visible;
    border: 0;
    margin-left: 0;
    left: 3.75rem;
    width: 13rem;
    border: 0;
    padding: 0.625rem 0;
    top: 0px;
  }

  [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    left: auto;
    right: 6.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a {
    padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a.has-arrow:after {
    right: 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul ul a {
    padding: 0.375rem 1.25rem 0.375rem 1.25rem;
    margin-left: -0.1rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
    width: 74.9375rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li {
    padding: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 0.725rem 0.775rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a i {
    padding: 0;
    margin: 0;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 1.125rem;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a svg {
    margin-left: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a svg {
    margin-right: 0;
    margin-top: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>ul li a {
    padding: 1rem 1.25rem 1rem 1.25rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"] .deznav li.mm-active ul {
    height: auto !important;
  }

  [data-sidebar-style="mini"] .deznav li a.has-arrow::after {
    transform: rotate(-225deg) translateY(-50%);
  }
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

@media (min-width: 64rem) {
  [data-layout="horizontal"] .deznav .metismenu>li>ul .extra-menu-li:hover .extra-menu-links {
    display: block !important;
    position: relative !important;
    top: 12px !important;
    left: 0 !important;
    right: auto !important;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>ul .extra-menu-li .extra-menu-links {
    position: relative;
    top: 12px !important;
    left: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    right: auto !important;
    box-shadow: none;
    display: block !important;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>ul .extra-menu-li .extra-menu-links:not(.show) {
    height: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>ul .extra-menu-li .extra-menu-links li a {
    padding: 1rem 1.25rem 1rem 1.25rem !important;
  }

  [data-layout="horizontal"] .nav-header {
    height: 3.125rem;
    width: 18.563rem;
    top: 0;
    z-index: 7;
  }

  [data-layout="horizontal"] .nav-header .nav-control {
    display: none;
  }

  [data-layout="horizontal"] .nav-header .brand-logo {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  [data-layout="horizontal"] .header {
    width: 100%;
    height: 3.125rem;
    padding-left: 18.563rem;
    padding-top: 0;
    z-index: 6;
  }

  [data-layout="horizontal"] .deznav {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 0;
    top: 0;
    z-index: 5;
  }

  [data-layout="horizontal"] .deznav .slimScrollDiv {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .slimScrollDiv .deznav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .slimScrollBar {
    display: none !important;
  }

  [data-layout="horizontal"] .deznav .header-profile {
    margin-right: 0.9375rem;
    margin-bottom: 0;
    display: none;
  }

  [data-layout="horizontal"] .deznav .header-profile:hover>a.nav-link {
    border-radius: 3rem;
  }

  [data-layout="horizontal"] .deznav .header-profile img {
    height: 2.8125rem;
    width: 2.8125rem;
  }

  [data-layout="horizontal"] .deznav .header-profile>a.nav-link {
    border-radius: 3rem;
    padding: 0.3125rem 0.3125rem;
  }

  [data-layout="horizontal"] .deznav .header-profile .header-info {
    display: none;
  }

  [data-layout="horizontal"] .deznav .header-profile2 {
    display: none;
  }

  [data-layout="horizontal"] .deznav .nav-user,
  [data-layout="horizontal"] .deznav .nav-label {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu {
    flex-direction: row;
    padding: 0.8rem 1.25rem;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;
  }

  [data-layout="horizontal"] .deznav .metismenu .collapse.in {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu ul {
    border-left: 0;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-layout="horizontal"] .deznav .metismenu li {
    flex-direction: column;
    position: relative;
    margin-top: -12px;
  }

  [data-layout="horizontal"] .deznav .metismenu li:hover>ul {
    display: block;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul {
    position: absolute;
    top: 100%;
    width: 100%;
    min-width: 13.75rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 1.2rem 0 0.5rem;
    display: none;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    margin: 0;
    background: #fff;
    border-radius: 0.375rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li>ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    background: #242424;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li {
    padding: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a {
    transition: all .4s ease-in-out;
    padding: 1rem 1.25rem 1rem 1.25rem;
    margin-left: -.1rem;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    text-align: right;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a:hover {
    border-radius: .4rem;
    padding-left: 1.25rem;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a:before {
    content: none;
    left: 1.375rem;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul li a:before {
    left: auto;
    right: 0.375rem;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li svg {
    width: 0.938rem;
    height: 1rem;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul .menu-title {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul ul {
    left: 100%;
    top: 0;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul ul {
    left: auto;
    right: 100%;
  }

  [data-layout="horizontal"] .deznav .metismenu>li {
    flex: 0 0 auto;
    position: relative;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a i {
    margin-right: 0.3125rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li {
    border-color: rgba(255, 255, 255, 0.07);
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mm-active {
    border-color: transparent;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover,
  [data-layout="horizontal"] .deznav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover>a,
  [data-layout="horizontal"] .deznav .metismenu>li.mm-active>a {
    color: var(--primary);
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover>a i,
  [data-layout="horizontal"] .deznav .metismenu>li.mm-active>a i {
    color: #fff;
    background: transparent;
    box-shadow: none;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover ul li>a:after,
  [data-layout="horizontal"] .deznav .metismenu>li.mm-active ul li>a:after {
    transform: rotate(-225deg) translateY(-50%);
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:first-child {
    border-right: 0;
  }

  [data-theme-version="dark"][direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li {
    border-color: #444444;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 0.65rem 0.3375rem 0.65rem 0.3375rem;
    margin: 0.125rem 0.125rem;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 0.9375rem 0.9375rem 0.9375rem 2.5rem;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a i {
    padding: 0 0.4375rem 0 0;
    height: auto;
    width: auto;
    line-height: 1;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li>a i {
    padding: 0 0 0 0.4375rem;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a .nav-badge {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a:after {
    right: 1.25rem;
    transform: rotate(-135deg) translateY(-50%);
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover {
    border-color: transparent;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover>ul {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
    border-radius: 0.375rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li:hover>ul {
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(0, 0, 0, 0.1);
    background: #242424;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>ul>li:hover ul.collapse {
    display: block !important;
    position: absolute;
    left: auto !important;
    right: -100% !important;
    top: 0 !important;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul {
    left: auto;
    right: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul>li:hover ul.collapse {
    right: auto !important;
    left: -100% !important;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul.left {
    left: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul.left>li:hover ul.collapse {
    left: 100% !important;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul.left {
    left: auto;
    right: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:last-child>ul ul {
    left: -100%;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:last-child>ul.left ul {
    left: 100%;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:last-child>ul.left ul {
    left: auto;
    right: 100%;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 3)>ul {
    left: 0;
    right: auto;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 3)>ul>li:hover ul.collapse {
    right: -100% !important;
    left: auto !important;
  }

  [data-layout="horizontal"] .deznav .metismenu .menu-title {
    display: none;
  }

  [data-layout="horizontal"] .deznav .copyright,
  [data-layout="horizontal"] .deznav .plus-box,
  [data-layout="horizontal"] .deznav .help-desk {
    display: none;
  }

  [data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-layout="horizontal"] .content-body .container-fluid,
  [data-layout="horizontal"] .content-body .container-sm,
  [data-layout="horizontal"] .content-body .container-md,
  [data-layout="horizontal"] .content-body .container-lg,
  [data-layout="horizontal"] .content-body .container-xl,
  [data-layout="horizontal"] .content-body .container-xxl {
    padding-top: 1.875rem;
  }

  [data-layout="horizontal"] .content-body .page-titles {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0;
    margin-top: 1.5rem;
  }

  [data-layout="horizontal"] .footer {
    margin-left: 0;
    margin: 0 auto;
    padding-left: 0;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .deznav {
    top: 3.125rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .header {
    height: 3.125rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .content-body {
    padding-top: 3.125rem;
  }

  [data-sidebar-position="static"][data-layout="horizontal"] .content-body .page-titles {
    margin-top: 0;
  }
}

[data-header-position="fixed"][data-sidebar-position="fixed"] .deznav {
  position: fixed;
}

[data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] .content-body {
  padding-top: 6.525rem;
}

[data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="modern"] .content-body {
  padding-top: 8.025rem;
}

[data-layout="horizontal"][data-container="boxed"] .content-body {
  padding-top: 12rem !important;
}

[data-layout="horizontal"][data-container="boxed"] .footer {
  margin-left: 0;
  max-width: 74.9375rem;
  margin: 0 auto;
}

[data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile {
  margin-bottom: 0;
}

[data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile img {
  height: 3.75rem;
  width: 3.75rem;
  margin-bottom: 0 !important;
}

[data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile>a.nav-link {
  border: 0.0625rem solid #CCCCCC;
  padding: 0.25rem 0.25rem;
  border-radius: 3rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .page-titles {
  margin-top: 1rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile {
  margin-bottom: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile img {
  height: 3.75rem;
  width: 3.75rem;
  margin-bottom: 0 !important;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile>a.nav-link {
  border: 0.0625rem solid #CCCCCC;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>ul {
  top: 6rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a {
  padding: 1.125rem 1.25rem 0.625rem 1.25rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a::after {
  display: none;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a .nav-text {
  margin-top: 0.3125rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a>i {
  width: auto;
  margin: 0;
  height: auto;
  line-height: 1;
  padding: 0;
  background: transparent;
  border-radius: 0;
  margin-bottom: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li li {
  text-align: left;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active a {
  background: transparent !important;
}

[data-sidebar-style="mini"][data-layout="horizontal"] .nav-header {
  width: 7.75rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

[data-sidebar-style="mini"][data-layout="horizontal"] .nav-header .brand-logo {
  justify-content: start;
  padding-left: 0;
  padding-right: 0;
}

[data-sidebar-style="mini"][data-layout="horizontal"] .header {
  width: 100%;
  padding-left: 7.75rem;
}

[data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li a {
  width: auto;
}

[data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li:hover a .nav-text {
  display: none;
}

[data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body {
  padding-top: 11.125rem;
}

[data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 0;
  width: 100%;
}

[data-sidebar-position="fixed"][data-header-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  position: fixed;
  left: 0;
  top: 80px;
  border-radius: 0;
  width: 100%;
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem;
  }

  [data-sidebar-style="compact"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
  [data-sidebar-style="compact"] .deznav .metismenu .mm-active>.has-arrow:after {
    margin-right: 0;
  }

  [data-sidebar-style="compact"] .deznav .header-profile {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link {
    display: block;
    text-align: center;
    border: 0;
  }

  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link img {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link .header-info {
    margin-left: 0 !important;
    text-align: center;
    display: none;
  }

  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link .header-info small,
  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link .header-info .small {
    text-align: center !important;
  }

  [data-sidebar-style="compact"] .deznav .header-profile .dropdown-menu {
    min-width: 11rem;
  }

  [data-sidebar-style="compact"] .deznav .header-profile a svg {
    display: unset !important;
  }

  [data-sidebar-style="compact"] .deznav .nav-user {
    display: none;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li {
    text-align: center;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a {
    padding: 0.7rem 0.5rem;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a svg {
    margin-right: 0.0125rem;
    margin-top: -1.3875rem;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a .menu-icon {
    display: block;
    padding: 0;
    background-color: rgba(13, 153, 255, 0.05);
    color: rgba(0, 0, 0, 0.3);
    width: 3.125rem;
    height: 3.125rem;
    line-height: 3.125rem;
    border-radius: 0.375rem;
    line-height: 4.25rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a i {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li>a {
    box-shadow: none;
    font-size: 0.813rem;
  }

  [data-sidebar-style="compact"] .deznav .plus-box,
  [data-sidebar-style="compact"] .deznav .copyright {
    display: none;
  }

  [data-sidebar-style="compact"] .deznav .copyright {
    padding: 0 1.25rem;
    margin-top: 1.25rem;
  }

  [data-sidebar-style="compact"] .deznav .header-info2 {
    padding: 0;
  }

  [data-sidebar-style="compact"] .deznav .header-info2 img {
    margin-left: -1rem;
  }

  [data-sidebar-style="compact"] .deznav .sidebar-info {
    display: none !important;
  }

  [data-sidebar-style="compact"] .deznav .header-profile2 {
    margin: 0 3rem;
  }

  [data-sidebar-style="compact"] .deznav .feature-box,
  [data-sidebar-style="compact"] .deznav .support-box,
  [data-sidebar-style="compact"] .deznav .help-desk {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-text {
    display: inline-block;
    margin-top: 0.3125rem;
    font-size: 0.813rem;
  }

  [data-sidebar-style="compact"] .nav-label.first {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-badge {
    display: none;
  }

  [data-sidebar-style="compact"] .footer {
    padding-left: 12.5rem;
  }

  [data-sidebar-style="compact"] .content-body {
    margin-left: 10.5rem;
  }

  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li>a i {
    color: #fff;
  }

  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu li.mm-active>a i {
    background: var(--primary);
    color: #fff;
  }

  [data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu li:hover>a i {
    background: var(--rgba-primary-1);
    color: var(--primary);
  }
}

[data-layout="horizontal"][data-sidebar-style="compact"] .footer {
  padding-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .content-body {
  margin-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav {
  margin-bottom: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .header {
  padding-left: 18.563rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header {
  width: 18.563rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header .brand-logo {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav {
  width: 11.25rem;
  padding-top: 1rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
  margin-left: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.02);
  padding: 0.625rem 0;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
  background: 255, 255, 255, 0.05;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul a:before {
  content: none;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul ul a {
  padding: 0.625rem 0.9375rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a {
  padding: 0.9rem 0.5rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a.has-arrow::after {
  top: 0.0625rem;
  display: inline-block;
  right: auto;
  margin-left: 0.3125rem;
  position: relative;
  width: 0.4375rem;
  height: 0.4375rem;
  border-width: 0.125rem 0rem 0rem 0.125rem;
}

[direction="rtl"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a.has-arrow::after {
  left: auto;
  margin-left: 0;
  margin-right: 0.3125rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  color: var(--primary);
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .header .header-content {
    padding-left: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu li.mm-active>ul {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-info2 {
    justify-content: space-between;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-info2 img {
    order: 1;
    margin-right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .metismenu li.mm-active>ul {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
    width: 7rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 1.875rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-left: 7rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-right: 7rem;
    padding-left: 0.9375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-left: 6rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-right: 1.375rem;
    padding-left: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    overflow: visible;
    position: absolute;
    left: -9.72rem;
    z-index: 4;
  }
}

@media only screen and (min-width: 47.9375rem) and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    left: -10rem;
  }
}

@media (min-width: 47.9375rem) {
  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    left: auto;
    right: -14.463rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-profile img {
    order: 1;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-profile .header-info {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-right: 0.625rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    margin: 0px;
    border-radius: 0.375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>svg,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>i,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
    order: 1;
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
    height: auto;
    width: auto;
    line-height: 1;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>svg,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>i,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
    padding-left: 0;
    padding-right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
    background: rgba(13, 153, 255, 0.05);
    color: var(--primary);
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    border-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    padding-right: 0;
    padding-left: 0;
  }

  [data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    border-color: #444444;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
    left: auto;
    right: 1.75rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
    left: 1.75rem;
    right: auto;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
    position: relative;
    padding-left: 1.3rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
    padding-right: 1.25rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
    left: 1.25rem;
    right: auto;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
    right: auto;
    left: -0.3125rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
    right: 4.4rem;
    opacity: 0;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
    right: auto;
    left: 5rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .menu-title {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .copyright,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .plus-box {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav.mm-show {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 18.75rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo {
    padding-left: 1.6rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo .brand-title {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo .color-title {
    display: none;
  }
}

@media only screen and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 17rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle.mm-show {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .header {
    padding-left: 3.38rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav {
    left: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .metismenu .has-arrow:after {
    opacity: 1;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav {
    left: auto;
    right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .menu-title {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 5.2rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 0;
    margin-right: 7rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    padding-left: 7rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    margin-left: 0;
    margin-right: 7rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="modern"] .nav-header {
    width: 10.625rem;
  }

  [data-sidebar-style="modern"] .nav-header .brand-logo {
    justify-content: center;
  }

  [data-sidebar-style="modern"] .deznav .header-profile {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link {
    display: block;
    text-align: center;
    margin: 0 -0.625rem 0.9375rem;
    padding: 0.9375rem 0.625rem;
    border-radius: 0.375rem;
  }

  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link img {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link .header-info {
    margin-left: 0 !important;
    text-align: center;
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link .header-info small,
  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link .header-info .small {
    text-align: center !important;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li {
    text-align: center;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a {
    padding: 1.25rem 0.9375rem 1.25rem 0.9375rem;
    margin: 0.125rem 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a::after {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: var(--primary-dark);
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li:hover,
  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a {
    background: rgba(13, 153, 255, 0.05);
    color: black;
    border-radius: 0.375rem;
    box-shadow: none;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li:hover>a i,
  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li:hover>a .nav-text,
  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a .nav-text {
    color: var(--primary);
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li:hover ul li a,
  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active ul li a {
    padding: 0.5rem 1rem;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li li {
    text-align: left;
  }

  [direction="rtl"][data-sidebar-style="modern"] .deznav .metismenu>li li {
    text-align: right;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    font-size: 0.813rem;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li>ul {
    height: auto !important;
  }

  [data-sidebar-style="modern"] .deznav .metismenu .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .nav-text {
    display: block;
    margin-top: 0.3125rem;
  }

  [data-sidebar-style="modern"] .deznav .plus-box,
  [data-sidebar-style="modern"] .deznav .copyright,
  [data-sidebar-style="modern"] .deznav .help-desk {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .sidebar-info {
    display: none !important;
  }

  [data-sidebar-style="modern"] .deznav .menu-title {
    display: none;
  }

  [data-sidebar-style="modern"] .footer {
    padding-left: 11.9rem;
  }

  [data-sidebar-style="modern"] .content-body {
    margin-left: 10.6rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu li>a i {
    padding: 0;
    margin: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav {
    width: 10.625rem;
    left: 0;
    padding-top: 1rem;
    z-index: 3;
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav {
    left: auto;
    right: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .slimScrollDiv,
  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu {
    padding: 0.625rem 0.875rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>a {
    padding: 1.375rem 0.6em 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>a i {
    padding: 0;
    height: auto;
    width: auto;
    margin: 0;
    line-height: 1;
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>ul {
    display: none;
    padding: 1.875rem 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li {
    position: relative;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a {
    padding: 1rem 1rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a:before {
    content: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    position: absolute;
    left: 105%;
    top: 0;
    bottom: auto;
    background-color: #fff;
    border: 0.0625rem solid #E6E6E6;
    width: 12.5rem;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    background: #242424;
    box-shadow: 0rem 0rem 0.8125rem 0rem rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    left: auto;
    right: 105%;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    display: block;
    left: 100%;
    padding: 1rem 0;
    margin-left: 0;
    border: 0;
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
    border-radius: 0.375rem;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    left: auto;
    right: 100%;
    box-shadow: -0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-text {
    display: block;
    margin-top: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .header-profile2 {
    padding: 0 2.5rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .nav-control {
    right: -4.25rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .header .header-content {
    padding-left: 6rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav,
  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .nav-header {
    left: -10.625rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .header {
    padding-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
    width: 18.563rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .header {
    padding-left: 18.563rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .footer,
  [data-sidebar-style="modern"][data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 0.7375rem 1.0625rem 0.75rem 1.0625rem;
    margin: 0 0.125rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu>li>ul {
    top: 4.8rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li>a {
    padding: 0.8125rem 1.25rem;
  }
}

[data-nav-headerbg="color_1"][data-headerbg="color_1"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .color-title {
  display: block !important;
}

[data-sidebar-style="overlay"][data-layout="vertical"] .deznav {
  border-radius: 0 0 0 0 !important;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"][data-layout="vertical"] .deznav {
    border-radius: 0 0 0 0 !important;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .nav-header {
  border-radius: 0;
}

[data-sidebar-style="overlay"] .deznav {
  left: -100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="overlay"] .deznav .metismenu>li>a {
  font-size: 0.813rem;
  padding: 0.75rem 1.5rem;
  color: #7f7184;
  border-radius: 0.375rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="overlay"] .deznav .metismenu>li>a i {
  height: auto;
  width: auto;
  line-height: 1;
}

[data-sidebar-style="overlay"] .deznav .metismenu>li:hover>a {
  color: var(--primary);
}

[data-sidebar-style="overlay"] .deznav .metismenu>li:hover>a i {
  color: var(--primary);
}

[data-sidebar-style="overlay"] .deznav .metismenu>li.mm-active>a {
  /* background: var(--rgba-primary-1); */
  color: var(--primary);
}

[data-sidebar-style="overlay"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"] .deznav .metismenu>li {
    padding: 0rem 0.9375rem;
  }
}

[data-sidebar-style="overlay"] .deznav .metismenu ul a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  font-size: 0.813rem;
  padding-left: 3rem;
}

[data-sidebar-style="overlay"] .deznav .metismenu ul a:before {
  left: 1.5625rem;
  content: "";
  height: 0.125rem;
  width: 0.375rem;
  background: var(--rgba-primary-5);
  position: absolute;
  left: 1.8rem;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[direction="rtl"][data-sidebar-style="overlay"] .deznav {
  left: auto;
  right: -100%;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"] .deznav .metismenu>li>a {
    font-size: 0.875rem;
    padding: 0.75rem 0.875rem;
  }

  [data-sidebar-style="overlay"] .deznav .metismenu>li>a i {
    font-size: 1.125rem;
  }

  [data-sidebar-style="overlay"] .deznav .metismenu ul li a {
    padding-left: 42px;
  }
}

[data-sidebar-style="overlay"] .content-body {
  margin-left: 0;
}

[data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  left: 0;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: var(--primary);
}

[data-sidebar-style="overlay"] .menu-toggle .nav-header {
  position: absolute;
  left: auto;
}

[data-sidebar-style="overlay"] .menu-toggle .deznav {
  left: 0;
  z-index: 3;
}

[direction="rtl"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  left: auto;
  right: 0;
}

[data-sidebar-style="overlay"] .footer {
  padding-left: 0;
}

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-style="overlay"][data-nav-headerbg="color_1"] .menu-toggle .nav-header .color-title {
  display: block !important;
}

[data-sidebar-style="overlay"] .out-footer {
  width: calc(100% - 9.125rem);
  margin-right: 7.375rem;
  margin-left: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"] .out-footer {
    width: calc(100% - 2.125rem);
    margin-right: 0.975rem;
  }
}

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  position: fixed;
  border-top-left-radius: 0;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
  position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav .deznav-scroll {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
  position: fixed;
}

[data-layout="vertical"] .nav-header {
  border-top-left-radius: 0;
}

[data-layout="vertical"] .deznav {
  border-bottom-left-radius: 0;
}

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav {
  position: absolute;
}

.sidebar-right-trigger {
  position: absolute;
  z-index: 9;
  top: 7.75rem;
  right: 100%;
  background-color: var(--primary);
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
}

[data-theme-version="dark"] .sidebar-right-trigger {
  color: #fff;
}

@media only screen and (max-width: 64rem) {
  .sidebar-right-trigger {
    display: none;
  }
}

.sidebar-right-trigger:hover {
  color: #fff;
}

.sidebar-right {
  right: -50rem;
  position: fixed;
  top: 0;
  width: 50rem;
  background-color: #fff;
  margin-top: 3.5rem;
  transition: all .5s ease-in-out;
  border-radius: 0.375rem;
  z-index: 9999;
}

.sidebar-right .bg-label-pattern {
  background: transparent;
  /* background-image: url("../images/pattern/pattern5.png"); */
  background-size: 130%;
}

.sidebar-right .bootstrap-select {
  height: 3rem;
  border-radius: 0.375rem;
}

.sidebar-right .bootstrap-select .btn {
  padding: 0.75rem 0.9375rem;
  font-size: 0.9375rem;
  border-color: #d1d1d1;
  border-radius: 0.375rem;
  color: #000 !important;
}

[direction="rtl"] .sidebar-right .slimScrollDiv {
  overflow: visible !important;
}

.sidebar-right .note-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  opacity: 0.5;
  font-size: 12px;
}

.sidebar-right .sidebar-close-trigger {
  position: absolute;
  z-index: 2;
  font-size: 1.75rem;
  top: 0;
  right: -3rem;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  background: var(--primary);
  color: #fff;
}

.sidebar-right.show {
  right: 5.25rem;
  box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

@media only screen and (max-width: 47.9375rem) {
  .sidebar-right.show {
    display: none;
  }
}

.sidebar-right.show .bg-overlay {
  position: fixed;
  width: 100%;
  cursor: pointer;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.sidebar-right .card-tabs .nav-tabs {
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  border-bottom: 4px solid var(--rgba-primary-1);
}

.sidebar-right .card-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
  flex: 1;
}

.sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  border: 0;
  font-size: 1.125rem;
  position: relative;
  text-align: center;
  background-color: #fff;
  border-radius: 0;
  margin: 0;
}

.sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--rgba-primary-1);
  color: #000;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  border-right: none;
  border-left: none;
  border-top: none;
}

.sidebar-right .sidebar-right-inner>h4,
.sidebar-right .sidebar-right-inner>.h4 {
  padding: 0.625rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  background: #fff;
  margin: 0rem 0rem 0rem 0rem;
}

.sidebar-right .tab-content {
  padding: 1.25rem 1.25rem 0;
  min-height: 480px;
  background: #fff;
}

.sidebar-right .tab-content .tab-pane .admin-settings .row>div {
  margin-bottom: 1.25rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings p {
  color: #353535;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
  display: none;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]+label {
  display: inline-block;
  width: 2.1875rem;
  height: 2.1875rem;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 0.25rem;
  margin-right: 0.3125rem;
  margin-bottom: 0.1875rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label {
  position: relative;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label::after {
  height: 2.0625rem;
  width: 2.0625rem;
  left: -0.25rem;
  top: -0.25rem;
  content: "";
  position: absolute;
  background-color: inherit;
  border-radius: 0.375rem;
  opacity: 0.4;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #secondary_color_1+label,
.sidebar-right #primary_color_1+label {
  background-color: #fff;
}

.sidebar-right #nav_header_color_2+label,
.sidebar-right #header_color_2+label,
.sidebar-right #sidebar_color_2+label,
.sidebar-right #secondary_color_2+label,
.sidebar-right #primary_color_2+label {
  background-color: #18A594;
}

.sidebar-right #nav_header_color_3+label,
.sidebar-right #header_color_3+label,
.sidebar-right #sidebar_color_3+label,
.sidebar-right #secondary_color_3+label,
.sidebar-right #primary_color_3+label {
  background-color: #0D99FF;
}

.sidebar-right #nav_header_color_4+label,
.sidebar-right #header_color_4+label,
.sidebar-right #sidebar_color_4+label,
.sidebar-right #secondary_color_4+label,
.sidebar-right #primary_color_4+label {
  background-color: #2C2C2C;
}

.sidebar-right #nav_header_color_5+label,
.sidebar-right #header_color_5+label,
.sidebar-right #sidebar_color_5+label,
.sidebar-right #secondary_color_5+label,
.sidebar-right #primary_color_5+label {
  background-color: #3250ff;
}

.sidebar-right #nav_header_color_6+label,
.sidebar-right #header_color_6+label,
.sidebar-right #sidebar_color_6+label,
.sidebar-right #secondary_color_6+label,
.sidebar-right #primary_color_6+label {
  background-color: #3f8cff;
}

.sidebar-right #nav_header_color_7+label,
.sidebar-right #header_color_7+label,
.sidebar-right #sidebar_color_7+label,
.sidebar-right #secondary_color_7+label,
.sidebar-right #primary_color_7+label {
  background-color: #40a7e2;
}

.sidebar-right #nav_header_color_8+label,
.sidebar-right #header_color_8+label,
.sidebar-right #sidebar_color_8+label,
.sidebar-right #secondary_color_8+label,
.sidebar-right #primary_color_8+label {
  background-color: #B65FBD;
}

.sidebar-right #nav_header_color_9+label,
.sidebar-right #header_color_9+label,
.sidebar-right #sidebar_color_9+label,
.sidebar-right #secondary_color_9+label,
.sidebar-right #primary_color_9+label {
  background-color: #88a67e;
}

.sidebar-right #nav_header_color_10+label,
.sidebar-right #header_color_10+label,
.sidebar-right #sidebar_color_10+label,
.sidebar-right #secondary_color_10+label,
.sidebar-right #primary_color_10+label {
  background-color: #1C2E9E;
}

.sidebar-right #nav_header_color_11+label,
.sidebar-right #header_color_11+label,
.sidebar-right #sidebar_color_11+label,
.sidebar-right #secondary_color_11+label,
.sidebar-right #primary_color_11+label {
  background-color: #c6164f;
}

.sidebar-right #nav_header_color_12+label,
.sidebar-right #header_color_12+label,
.sidebar-right #sidebar_color_12+label,
.sidebar-right #secondary_color_12+label,
.sidebar-right #primary_color_12+label {
  background-color: #343a40;
}

.sidebar-right #nav_header_color_13+label,
.sidebar-right #header_color_13+label,
.sidebar-right #sidebar_color_13+label,
.sidebar-right #secondary_color_13+label,
.sidebar-right #primary_color_13+label {
  background-color: #f58706;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #secondary_color_1+label,
.sidebar-right #primary_color_1+label {
  border: 0.0625rem solid #c4c4c4;
}

.sidebar-right.style-1 {
  height: 100vh;
  width: 15.625rem;
  margin-top: 0;
  right: -15.625rem;
}

.sidebar-right.style-1 .sidebar-right-inner {
  background: #fff;
}

.sidebar-right.style-1 .sidebar-right-trigger {
  top: 12.4rem;
}

.sidebar-right.style-1.show {
  right: 0;
}

.sidebar-right.style-1.show .sidebar-right-trigger {
  display: block;
}

.nice-select.wide {
  line-height: 2.1125rem;
}

.nice-select.wide.form-control-sm {
  height: 35px;
  line-height: 1rem;
}

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
  }
}

@media only screen and (max-width: 87.5rem) {
  .nice-select.wide {
    line-height: 2rem;
  }
}

.nav-user {
  background: var(--primary);
  margin-bottom: 0.625rem;
  padding: 1.25rem 1.5625rem 0.9375rem;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user {
    padding: 1.25rem 0.9375rem 0.9375rem;
  }
}

.nav-user img {
  width: 2.1875rem;
  height: 2.1875rem;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user img {
    width: 2.1875rem;
    height: 2.1875rem;
    margin-bottom: 0.625rem;
  }
}

.nav-user h5,
.nav-user .h5 {
  margin-left: 0.625rem;
  margin-bottom: 0.1875rem;
  color: #fff;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {

  .nav-user h5,
  .nav-user .h5 {
    display: none;
  }
}

[data-sibebarbg="color_2"] .nav-user h5,
[data-sibebarbg="color_2"] .nav-user .h5 {
  color: #fff;
}

.nav-user p {
  margin-left: 0.625rem;
  margin-bottom: 0.5rem;
  color: #afcff7;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user p {
    display: none;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user i {
    margin-top: 0.9375rem;
    display: block;
  }
}

.menu-toggle .nav-user {
  padding: 1.25rem 0.9375rem 0.9375rem;
}

.menu-toggle .nav-user img {
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.625rem;
}

.menu-toggle .nav-user h5,
.menu-toggle .nav-user .h5,
.menu-toggle .nav-user p {
  display: none;
}

.menu-toggle .nav-user i {
  margin-top: 0.9375rem;
  display: block;
}

.menu-toggle .nav-user .dropdown-menu {
  left: 2.8125rem !important;
  top: 1.375rem !important;
}

.chatbox {
  width: 21.25rem;
  height: 100vh;
  position: fixed;
  right: -31.25rem;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0rem 0rem 1.875rem 0rem rgba(82, 63, 105, 0.15);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

[data-theme-version="dark"] .chatbox {
  background: #242424;
}

.chatbox .chatbox-close {
  position: absolute;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  width: 0;
  height: 100%;
  right: 21.25rem;
  background: #000;
  z-index: 1;
  opacity: 0.1;
  cursor: pointer;
}

.chatbox .card-fotter {
  padding: 0.75rem 1rem;
}

.chatbox .card-body {
  padding: 1rem;
}

.chatbox.active {
  right: 0;
}

.chatbox.active .chatbox-close {
  width: 100vw;
}

.chatbox .type_msg {
  padding-top: 0.625rem;
}

.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: var(--rgba-primary-1);
  border: 0;
  justify-content: space-between;
}

.chatbox .nav .nav-link {
  color: var(--primary);
  opacity: 0.7;
  text-transform: uppercase;
}

.chatbox .nav .nav-link:hover,
.chatbox .nav .nav-link.active {
  background: transparent;
  color: var(--primary);
  opacity: 1;
  border-color: var(--primary);
}

.chatbox .img_cont {
  width: 2.5rem;
  border-radius: 2.5rem;
  margin-right: 0.625rem;
  position: relative;
  height: 2.5rem;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.875rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
}

.chatbox .img_cont .icon {
  color: #fff;
}

.chatbox .img_cont.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .chatbox .img_cont.primary {
  background: var(--rgba-primary-1);
}

.chatbox .img_cont.warning {
  background: #ffeccc;
  color: #FF9F00;
}

[data-theme-version="dark"] .chatbox .img_cont.warning {
  background: rgba(255, 159, 0, 0.1);
}

.chatbox .img_cont.success {
  background: #daf5e6;
  color: #3AC977;
}

[data-theme-version="dark"] .chatbox .img_cont.success {
  background: rgba(58, 201, 119, 0.1);
}

.chatbox .img_cont.info {
  background: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .chatbox .img_cont.info {
  background: rgba(88, 186, 215, 0.1);
}

.chatbox .img_cont img {
  width: 100%;
}

.chatbox .img_cont .online_icon {
  background: #3AC977;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.9375rem;
  right: -0.0625rem;
  bottom: 0rem;
  border: 0.125rem solid #fff;
}

.chatbox .img_cont .online_icon.offline {
  background: #FF5E5E;
}

.chatbox .card {
  box-shadow: none;
  border-radius: 0;
}

.chatbox .search {
  height: 2.5rem;
}

.chatbox .user_info span {
  font-size: 0.9375rem;
  color: #000;
  display: block;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.3125rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 10.625rem;
}

[data-theme-version="dark"] .chatbox .user_info span {
  color: #fff;
}

.chatbox .user_info p {
  font-size: 0.8125rem;
  margin-bottom: 0;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 10.625rem;
}

.chatbox .contacts li {
  padding: 0.75rem 1rem;
  border-bottom: 0.0625rem solid #eee;
  cursor: pointer;
}

[data-theme-version="dark"] .chatbox .contacts li {
  border-color: #444444;
}

.chatbox .contacts li>div {
  display: flex;
  align-items: center;
}

.chatbox .contacts li:hover {
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .chatbox .contacts li:hover {
  background-color: #1E1E1E;
}

.chatbox .contacts .name-first-letter {
  background: var(--rgba-primary-1);
  padding: 0.25rem 1rem;
  font-weight: 700;
  color: #000;
  position: sticky;
  top: 0;
  z-index: 1;
}

[data-theme-version="dark"] .chatbox .contacts .name-first-letter {
  color: #fff;
  background: #1E1E1E;
}

.chatbox .contacts_body {
  height: calc(100vh - 168px);
}

.chatbox .card-header {
  background: #f4f7ff;
  padding: 0.9375rem 1.25rem;
  justify-content: center;
}

.chatbox .card-header h6,
.chatbox .card-header .h6 {
  font-size: 0.9375rem;
}

.chatbox .card-header p {
  line-height: 1.2;
  font-size: 0.75rem;
  color: #888888;
}

.chatbox .chat-list-header {
  justify-content: space-between;
  background: #fff;
}

[data-theme-version="dark"] .chatbox .chat-list-header {
  background: #242424;
}

.chatbox .chat-list-header a {
  text-align: center;
  width: 1.875rem;
  height: 1.875rem;
  background: var(--rgba-primary-1);
  border-radius: 0.375rem;
  line-height: 1.675rem;
  display: block;
}

[data-theme-version="dark"] .chatbox .chat-list-header a {
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .chatbox .chat-list-header a svg g [fill] {
  fill: #fff;
}

.chatbox .img_cont_msg {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  max-width: 1.875rem;
  min-width: 1.875rem;
}

.chatbox .img_cont_msg img {
  width: 100%;
}

.chatbox .msg_cotainer {
  background: var(--primary);
  margin-left: 0.625rem;
  border-radius: 0 0.375rem 0.375rem 0.375rem;
  padding: 0.625rem 0.9375rem;
  color: #fff;
  position: relative;
}

.chatbox .msg_cotainer .msg_time {
  display: block;
  font-size: 0.6875rem;
  color: #fff;
  margin-top: 0.3125rem;
  opacity: 0.5;
}

.chatbox .msg_cotainer:after {
  content: "";
  position: absolute;
  left: -0.625rem;
  border-right: 0.625rem solid var(--primary);
  border-bottom: 0.625rem solid transparent;
  border-top: 0rem solid;
  top: 0;
}

.chatbox .msg_cotainer_send {
  background: #f5f5f5;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.375rem 0rem 0.375rem 0.375rem;
  margin-right: 0.625rem;
  color: #222;
  position: relative;
  text-align: right;
}

[data-theme-version="dark"] .chatbox .msg_cotainer_send {
  background: #1E1E1E;
  color: #fff;
}

.chatbox .msg_cotainer_send .msg_time_send {
  display: block;
  font-size: 0.6875rem;
  text-align: right;
  margin-top: 0.3125rem;
  opacity: 0.6;
}

.chatbox .msg_cotainer_send:after {
  content: "";
  position: absolute;
  right: -0.625rem;
  border-left: 0.625rem solid #fff;
  border-bottom: 0.625rem solid transparent;
  border-top: 0rem solid;
  top: 0;
}

[data-theme-version="dark"] .chatbox .msg_cotainer_send:after {
  border-left: 0.625rem solid #1E1E1E;
}

.chatbox .type_msg .form-control {
  padding: 0.625rem 0;
  height: 3.125rem;
  border: 0;
  resize: none;
}

.chatbox .type_msg .form-control:focus {
  z-index: 0;
}

.chatbox .type_msg .btn {
  font-size: 1.125rem;
  border-radius: 2.375rem !important;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0;
  margin-top: 0.375rem;
}

.chatbox .video_cam {
  margin-left: 0.9375rem;
}

.chatbox .video_cam span {
  width: 2.1875rem;
  height: 2.1875rem;
  background: #10ca93;
  text-align: center;
  line-height: 2.1875rem;
  border-radius: 2.1875rem;
  color: #fff;
  margin-right: 0.3125rem;
  align-self: center;
  font-size: 1rem;
  padding: 0 0.1875rem;
  display: inline-block;
}

.chatbox .note_card .contacts li {
  padding: 0.75rem 1rem;
}

@media only screen and (max-width: 36rem) {
  .chatbox {
    width: 17.5rem;
  }

  .chatbox .chatbox-close {
    right: 17.5rem;
  }
}

.dz-demo-panel {
  right: -100%;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  transition: all .5s ease-in-out;
  z-index: 9999;
}

.dz-demo-panel .note-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  font-size: 12px;
  background-color: transprent;
  color: #9b9a9a;
}

.dz-demo-panel .dz-demo-trigger {
  position: absolute;
  z-index: 9;
  top: 7.75rem;
  right: 100%;
  background-color: #627EEA;
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
  display: none;
}

@media only screen and (max-width: 63.9375rem) {
  .dz-demo-panel .dz-demo-trigger {
    display: none;
  }
}

.dz-demo-panel .dz-demo-close {
  height: 1.875rem;
  color: #000 !important;
  width: 1.875rem;
  border-radius: 0.5rem;
  background: #fff;
  line-height: 1.875rem;
  text-align: center;
}

.dz-demo-panel:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.85);
  display: block;
  backdrop-filter: blur(4px);
}

.dz-demo-panel.show {
  right: 0;
  box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
  z-index: 99999;
  overflow: hidden;
}

.dz-demo-panel.show .sidebar-right-trigger {
  display: none;
}

.dz-demo-panel.show .bg-close {
  position: fixed;
  z-index: -2;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
}

.dz-demo-panel .dz-demo-inner {
  padding: 1.875rem 1.875rem;
}

.dz-demo-panel .dz-demo-content {
  height: calc(100vh - 200px);
  padding: 0 15px;
  margin: 0 -15px;
  display: flex;
}

.dz-demo-panel .dz-demo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.5rem;
}

.dz-demo-panel .dz-demo-header h4,
.dz-demo-panel .dz-demo-header .h4 {
  margin-bottom: 0;
  color: #fff;
}

.dz-demo-panel .dz-demo-bx {
  overflow: hidden;
  border: 3px solid #efefef;
  box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0.625rem;
}

.dz-demo-panel .dz-demo-bx.demo-active {
  border-color: #2C2C2C;
}

.dz-demo-panel .dz-demo-bx.demo-active .overlay-layer {
  opacity: 1;
}

.dz-demo-panel .overlay-bx {
  position: relative;
}

.dz-demo-panel .overlay-bx .overlay-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 0;
  border: 0;
}

.dz-demo-panel .overlay-bx:hover .overlay-layer {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 1;
}

:root {
  --nav-headbg: #FFFFFF;
  --sidebar-bg: #FFFFFF;
  --headerbg: #FFFFFF;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"],
[data-nav-headerbg="color_2"] {
  --nav-headbg: #18A594;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_2"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_2"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_2"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_2"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"],
[data-nav-headerbg="color_3"] {
  --nav-headbg: #0D99FF;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_3"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_3"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_3"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_3"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"],
[data-nav-headerbg="color_4"] {
  --nav-headbg: #2C2C2C;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_4"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_4"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_4"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_4"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"],
[data-nav-headerbg="color_5"] {
  --nav-headbg: #3250ff;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_5"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_5"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_5"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_5"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"],
[data-nav-headerbg="color_6"] {
  --nav-headbg: #3f8cff;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_6"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_6"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_6"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_6"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"],
[data-nav-headerbg="color_7"] {
  --nav-headbg: #40a7e2;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_7"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_7"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_7"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_7"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"],
[data-nav-headerbg="color_8"] {
  --nav-headbg: #B65FBD;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_8"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_8"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_8"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_8"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"],
[data-nav-headerbg="color_9"] {
  --nav-headbg: #88a67e;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_9"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_9"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_9"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_9"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"],
[data-nav-headerbg="color_10"] {
  --nav-headbg: #1C2E9E;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_10"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_10"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_10"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_10"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"],
[data-nav-headerbg="color_11"] {
  --nav-headbg: #c6164f;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_11"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_11"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_11"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_11"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"],
[data-nav-headerbg="color_12"] {
  --nav-headbg: #343a40;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_12"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_12"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_12"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_12"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"],
[data-nav-headerbg="color_13"] {
  --nav-headbg: #f58706;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
[data-nav-headerbg="color_13"] .nav-header .brand-logo .logo-abbr path.react-w,
[data-nav-headerbg="color_13"] .nav-header .brand-logo .logo-abbr path.w3 {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
[data-nav-headerbg="color_13"] .nav-header .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_13"] .nav-header .hamburger .line {
  background: #000;
}

[data-sidebarbg="color_2"][data-theme-version="dark"],
[data-sidebarbg="color_2"] {
  --sidebar-bg: #18A594;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_2"] .menu-toggle .deznav .metismenu li>ul {
  background: #18A594 !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_2"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_2"] .deznav .menu-icon svg path,
[data-sidebarbg="color_2"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_2"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_2"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_2"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_2"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_2"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #1ed2bc;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #1ab2a0;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #1ed2bc;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #1ed2bc;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_2"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_2"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_2"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_2"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_2"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_2"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_2"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_2"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_2"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_2"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_2"] .deznav .help-desk .btn {
  background-color: #1ed2bc;
  border-color: #1ed2bc;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_2"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_2"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_2"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_2"] .plus-box {
  background-color: #1ed2bc;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_2"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"],
[data-sidebarbg="color_3"] {
  --sidebar-bg: #0D99FF;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_3"] .menu-toggle .deznav .metismenu li>ul {
  background: #0D99FF !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_3"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_3"] .deznav .menu-icon svg path,
[data-sidebarbg="color_3"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_3"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_3"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_3"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_3"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_3"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #40aeff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #1c9fff;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #40aeff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #40aeff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_3"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_3"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_3"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_3"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_3"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_3"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_3"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_3"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_3"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_3"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_3"] .deznav .help-desk .btn {
  background-color: #40aeff;
  border-color: #40aeff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_3"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_3"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_3"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_3"] .plus-box {
  background-color: #40aeff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_3"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"],
[data-sidebarbg="color_4"] {
  --sidebar-bg: #2C2C2C;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_4"] .menu-toggle .deznav .metismenu li>ul {
  background: #2C2C2C !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_4"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_4"] .deznav .menu-icon svg path,
[data-sidebarbg="color_4"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_4"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_4"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_4"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_4"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_4"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #464646;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #343434;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #464646;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #464646;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_4"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_4"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_4"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_4"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_4"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_4"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_4"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_4"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_4"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_4"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_4"] .deznav .help-desk .btn {
  background-color: #464646;
  border-color: #464646;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_4"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_4"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_4"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_4"] .plus-box {
  background-color: #464646;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_4"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"],
[data-sidebarbg="color_5"] {
  --sidebar-bg: #3250ff;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_5"] .menu-toggle .deznav .metismenu li>ul {
  background: #3250ff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_5"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_5"] .deznav .menu-icon svg path,
[data-sidebarbg="color_5"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_5"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_5"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_5"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_5"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_5"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #415dff;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_5"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_5"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_5"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_5"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_5"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_5"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_5"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_5"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_5"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_5"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_5"] .deznav .help-desk .btn {
  background-color: #657cff;
  border-color: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_5"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_5"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_5"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_5"] .plus-box {
  background-color: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_5"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"],
[data-sidebarbg="color_6"] {
  --sidebar-bg: #3f8cff;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_6"] .menu-toggle .deznav .metismenu li>ul {
  background: #3f8cff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_6"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_6"] .deznav .menu-icon svg path,
[data-sidebarbg="color_6"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_6"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_6"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_6"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_6"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_6"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #4e95ff;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_6"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_6"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_6"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_6"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_6"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_6"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_6"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_6"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_6"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_6"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_6"] .deznav .help-desk .btn {
  background-color: #72abff;
  border-color: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_6"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_6"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_6"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_6"] .plus-box {
  background-color: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_6"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"],
[data-sidebarbg="color_7"] {
  --sidebar-bg: #40a7e2;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_7"] .menu-toggle .deznav .metismenu li>ul {
  background: #40a7e2 !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_7"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_7"] .deznav .menu-icon svg path,
[data-sidebarbg="color_7"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_7"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_7"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_7"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_7"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_7"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #4dade4;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_7"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_7"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_7"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_7"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_7"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_7"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_7"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_7"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_7"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_7"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_7"] .deznav .help-desk .btn {
  background-color: #6cbbe9;
  border-color: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_7"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_7"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_7"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_7"] .plus-box {
  background-color: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_7"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"],
[data-sidebarbg="color_8"] {
  --sidebar-bg: #B65FBD;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_8"] .menu-toggle .deznav .metismenu li>ul {
  background: #B65FBD !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_8"] .deznav .menu-icon svg path,
[data-sidebarbg="color_8"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_8"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_8"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_8"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_8"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_8"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #bb6ac1;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_8"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_8"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_8"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_8"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_8"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_8"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_8"] .deznav .help-desk .btn {
  background-color: #c683cc;
  border-color: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_8"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_8"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_8"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_8"] .plus-box {
  background-color: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_8"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"],
[data-sidebarbg="color_9"] {
  --sidebar-bg: #88a67e;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_9"] .menu-toggle .deznav .metismenu li>ul {
  background: #88a67e !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_9"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_9"] .deznav .menu-icon svg path,
[data-sidebarbg="color_9"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_9"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_9"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_9"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_9"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_9"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #90ac87;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_9"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_9"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_9"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_9"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_9"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_9"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_9"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_9"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_9"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_9"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_9"] .deznav .help-desk .btn {
  background-color: #a4bb9c;
  border-color: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_9"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_9"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_9"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_9"] .plus-box {
  background-color: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_9"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"],
[data-sidebarbg="color_10"] {
  --sidebar-bg: #1C2E9E;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_10"] .menu-toggle .deznav .metismenu li>ul {
  background: #1C2E9E !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_10"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_10"] .deznav .menu-icon svg path,
[data-sidebarbg="color_10"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_10"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_10"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_10"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_10"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_10"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #1e32ab;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_10"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_10"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_10"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_10"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_10"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_10"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_10"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_10"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_10"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_10"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_10"] .deznav .help-desk .btn {
  background-color: #243bc9;
  border-color: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_10"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_10"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_10"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_10"] .plus-box {
  background-color: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_10"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"],
[data-sidebarbg="color_11"] {
  --sidebar-bg: #c6164f;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_11"] .menu-toggle .deznav .metismenu li>ul {
  background: #c6164f !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_11"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_11"] .deznav .menu-icon svg path,
[data-sidebarbg="color_11"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_11"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_11"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_11"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_11"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_11"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #d41854;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_11"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_11"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_11"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_11"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_11"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_11"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_11"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_11"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_11"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_11"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_11"] .deznav .help-desk .btn {
  background-color: #e72866;
  border-color: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_11"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_11"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_11"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_11"] .plus-box {
  background-color: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_11"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"],
[data-sidebarbg="color_12"] {
  --sidebar-bg: #343a40;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_12"] .menu-toggle .deznav .metismenu li>ul {
  background: #343a40 !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_12"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_12"] .deznav .menu-icon svg path,
[data-sidebarbg="color_12"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_12"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_12"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_12"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_12"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_12"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #3b4248;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_12"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_12"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_12"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_12"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_12"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_12"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_12"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_12"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_12"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_12"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_12"] .deznav .help-desk .btn {
  background-color: #4b545c;
  border-color: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_12"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_12"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_12"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_12"] .plus-box {
  background-color: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_12"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"],
[data-sidebarbg="color_13"] {
  --sidebar-bg: #f58706;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sidebarbg="color_13"] .menu-toggle .deznav .metismenu li>ul {
  background: #f58706 !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sidebarbg="color_13"] .deznav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .menu-icon svg path,
[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
[data-sidebarbg="color_13"] .deznav .menu-icon svg path,
[data-sidebarbg="color_13"] .deznav .menu-icon svg ellipse {
  stroke: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
[data-sidebarbg="color_13"][data-layout="vertical"] .deznav .menu-title {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_13"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
[data-sidebarbg="color_13"][data-layout="vertical"] .deznav .plus-box h5,
[data-sidebarbg="color_13"][data-layout="vertical"] .deznav .plus-box .h5,
[data-sidebarbg="color_13"][data-layout="vertical"] .deznav .plus-box p {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #fa9f34;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #f98e11;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a .menu-icon {
  background-color: #fa9f34;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: var(--primary);
  color: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li>a .menu-icon {
  background-color: #fa9f34;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a,
[data-sidebarbg="color_13"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sidebarbg="color_13"] .deznav .metismenu li ul a:hover,
[data-sidebarbg="color_13"] .deznav .metismenu li ul a:focus,
[data-sidebarbg="color_13"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sidebarbg="color_13"] .deznav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sidebarbg="color_13"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sidebarbg="color_13"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"] .deznav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_13"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_13"] .deznav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sidebarbg="color_13"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .help-desk .btn,
[data-sidebarbg="color_13"] .deznav .help-desk .btn {
  background-color: #fa9f34;
  border-color: #fa9f34;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .header-info2 span,
[data-sidebarbg="color_13"] .deznav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .sidebar-info,
[data-sidebarbg="color_13"] .deznav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .sidebar-info i,
[data-sidebarbg="color_13"] .deznav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_13"] .plus-box {
  background-color: #fa9f34;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_13"] .copyright {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"],
[data-headerbg="color_2"] {
  --headerbg: #18A594;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_2"] .header-left .search-area .form-control,
[data-headerbg="color_2"] .header-left .search-area .input-group-text {
  background-color: #1ed2bc;
}

[data-headerbg="color_2"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_2"] .nav-control {
  background-color: #1ed2bc;
}

[data-headerbg="color_2"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_2"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_2"] .search-area .input-group-text,
[data-headerbg="color_2"] .search-area .form-control,
[data-headerbg="color_2"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_2"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_2"] .header-left .search-area {
  background-color: #1ed2bc;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_2"] .header-right .search-area .form-control,
[data-headerbg="color_2"] .header-right .search-area .input-group-text {
  background-color: #1ed2bc;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_2"] .header-right .search-area .form-control i,
[data-headerbg="color_2"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_2"] .header-right .search-area .form-control svg path,
[data-headerbg="color_2"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_2"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_2"] .header-right .nav-item svg path,
[data-headerbg="color_2"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_2"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_2"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_2"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_2"] .header-right .header-border .btn {
  background-color: #1ed2bc;
  border-color: #1ed2bc;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_2"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_2"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_2"] .header-right .header-info h6,
[data-headerbg="color_2"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_2"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_2"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_2"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_2"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_2"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_2"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_2"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_2"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_2"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_2"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_2"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_3"][data-theme-version="dark"],
[data-headerbg="color_3"] {
  --headerbg: #0D99FF;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_3"] .header-left .search-area .form-control,
[data-headerbg="color_3"] .header-left .search-area .input-group-text {
  background-color: #40aeff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_3"] .nav-control {
  background-color: #40aeff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_3"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_3"] .search-area .input-group-text,
[data-headerbg="color_3"] .search-area .form-control,
[data-headerbg="color_3"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_3"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_3"] .header-left .search-area {
  background-color: #40aeff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_3"] .header-right .search-area .form-control,
[data-headerbg="color_3"] .header-right .search-area .input-group-text {
  background-color: #40aeff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_3"] .header-right .search-area .form-control i,
[data-headerbg="color_3"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_3"] .header-right .search-area .form-control svg path,
[data-headerbg="color_3"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_3"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_3"] .header-right .nav-item svg path,
[data-headerbg="color_3"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_3"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_3"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_3"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_3"] .header-right .header-border .btn {
  background-color: #40aeff;
  border-color: #40aeff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_3"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_3"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_3"] .header-right .header-info h6,
[data-headerbg="color_3"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_3"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_3"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_3"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_3"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_3"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_3"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_3"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_3"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_3"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_3"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_3"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_4"][data-theme-version="dark"],
[data-headerbg="color_4"] {
  --headerbg: #2C2C2C;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_4"] .header-left .search-area .form-control,
[data-headerbg="color_4"] .header-left .search-area .input-group-text {
  background-color: #464646;
}

[data-headerbg="color_4"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_4"] .nav-control {
  background-color: #464646;
}

[data-headerbg="color_4"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_4"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_4"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_4"] .header-left .search-area {
  background-color: #464646;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_4"] .header-right .search-area .form-control,
[data-headerbg="color_4"] .header-right .search-area .input-group-text {
  background-color: #464646;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_4"] .header-right .search-area .form-control i,
[data-headerbg="color_4"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_4"] .header-right .search-area .form-control svg path,
[data-headerbg="color_4"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_4"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_4"] .header-right .nav-item svg path,
[data-headerbg="color_4"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_4"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_4"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_4"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_4"] .header-right .header-border .btn {
  background-color: #464646;
  border-color: #464646;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_4"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_4"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_4"] .header-right .header-info h6,
[data-headerbg="color_4"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_4"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_4"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_4"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_4"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_4"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_4"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_4"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_4"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_4"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_4"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_4"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_5"][data-theme-version="dark"],
[data-headerbg="color_5"] {
  --headerbg: #3250ff;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_5"] .header-left .search-area .form-control,
[data-headerbg="color_5"] .header-left .search-area .input-group-text {
  background-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_5"] .nav-control {
  background-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_5"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_5"] .search-area .input-group-text,
[data-headerbg="color_5"] .search-area .form-control,
[data-headerbg="color_5"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_5"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_5"] .header-left .search-area {
  background-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_5"] .header-right .search-area .form-control,
[data-headerbg="color_5"] .header-right .search-area .input-group-text {
  background-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_5"] .header-right .search-area .form-control i,
[data-headerbg="color_5"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_5"] .header-right .search-area .form-control svg path,
[data-headerbg="color_5"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_5"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_5"] .header-right .nav-item svg path,
[data-headerbg="color_5"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_5"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_5"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_5"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_5"] .header-right .header-border .btn {
  background-color: #657cff;
  border-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_5"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_5"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_5"] .header-right .header-info h6,
[data-headerbg="color_5"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_5"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_5"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_5"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_5"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_5"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_5"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_5"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_5"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_5"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_5"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_5"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_6"][data-theme-version="dark"],
[data-headerbg="color_6"] {
  --headerbg: #3f8cff;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_6"] .header-left .search-area .form-control,
[data-headerbg="color_6"] .header-left .search-area .input-group-text {
  background-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_6"] .nav-control {
  background-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_6"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_6"] .search-area .input-group-text,
[data-headerbg="color_6"] .search-area .form-control,
[data-headerbg="color_6"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_6"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_6"] .header-left .search-area {
  background-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_6"] .header-right .search-area .form-control,
[data-headerbg="color_6"] .header-right .search-area .input-group-text {
  background-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_6"] .header-right .search-area .form-control i,
[data-headerbg="color_6"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_6"] .header-right .search-area .form-control svg path,
[data-headerbg="color_6"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_6"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_6"] .header-right .nav-item svg path,
[data-headerbg="color_6"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_6"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_6"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_6"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_6"] .header-right .header-border .btn {
  background-color: #72abff;
  border-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_6"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_6"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_6"] .header-right .header-info h6,
[data-headerbg="color_6"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_6"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_6"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_6"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_6"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_6"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_6"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_6"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_6"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_6"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_6"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_6"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_7"][data-theme-version="dark"],
[data-headerbg="color_7"] {
  --headerbg: #40a7e2;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_7"] .header-left .search-area .form-control,
[data-headerbg="color_7"] .header-left .search-area .input-group-text {
  background-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_7"] .nav-control {
  background-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_7"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_7"] .search-area .input-group-text,
[data-headerbg="color_7"] .search-area .form-control,
[data-headerbg="color_7"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_7"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_7"] .header-left .search-area {
  background-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_7"] .header-right .search-area .form-control,
[data-headerbg="color_7"] .header-right .search-area .input-group-text {
  background-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_7"] .header-right .search-area .form-control i,
[data-headerbg="color_7"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_7"] .header-right .search-area .form-control svg path,
[data-headerbg="color_7"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_7"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_7"] .header-right .nav-item svg path,
[data-headerbg="color_7"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_7"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_7"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_7"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_7"] .header-right .header-border .btn {
  background-color: #6cbbe9;
  border-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_7"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_7"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_7"] .header-right .header-info h6,
[data-headerbg="color_7"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_7"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_7"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_7"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_7"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_7"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_7"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_7"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_7"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_7"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_7"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_7"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_8"][data-theme-version="dark"],
[data-headerbg="color_8"] {
  --headerbg: #B65FBD;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_8"] .header-left .search-area .form-control,
[data-headerbg="color_8"] .header-left .search-area .input-group-text {
  background-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_8"] .nav-control {
  background-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_8"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_8"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_8"] .header-left .search-area {
  background-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_8"] .header-right .search-area .form-control svg path,
[data-headerbg="color_8"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_8"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_8"] .header-right .nav-item svg path,
[data-headerbg="color_8"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_8"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_8"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_8"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_8"] .header-right .header-border .btn {
  background-color: #c683cc;
  border-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_8"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_8"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_8"] .header-right .header-info h6,
[data-headerbg="color_8"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_8"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_8"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_8"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_8"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_8"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_8"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_8"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_8"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_8"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_8"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_9"][data-theme-version="dark"],
[data-headerbg="color_9"] {
  --headerbg: #88a67e;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_9"] .header-left .search-area .form-control,
[data-headerbg="color_9"] .header-left .search-area .input-group-text {
  background-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_9"] .nav-control {
  background-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_9"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_9"] .search-area .input-group-text,
[data-headerbg="color_9"] .search-area .form-control,
[data-headerbg="color_9"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_9"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_9"] .header-left .search-area {
  background-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_9"] .header-right .search-area .form-control,
[data-headerbg="color_9"] .header-right .search-area .input-group-text {
  background-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_9"] .header-right .search-area .form-control i,
[data-headerbg="color_9"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_9"] .header-right .search-area .form-control svg path,
[data-headerbg="color_9"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_9"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_9"] .header-right .nav-item svg path,
[data-headerbg="color_9"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_9"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_9"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_9"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_9"] .header-right .header-border .btn {
  background-color: #a4bb9c;
  border-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_9"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_9"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_9"] .header-right .header-info h6,
[data-headerbg="color_9"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_9"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_9"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_9"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_9"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_9"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_9"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_9"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_9"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_9"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_9"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_9"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_10"][data-theme-version="dark"],
[data-headerbg="color_10"] {
  --headerbg: #1C2E9E;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_10"] .header-left .search-area .form-control,
[data-headerbg="color_10"] .header-left .search-area .input-group-text {
  background-color: #243bc9;
}

[data-headerbg="color_10"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_10"] .nav-control {
  background-color: #243bc9;
}

[data-headerbg="color_10"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_10"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_10"] .search-area .input-group-text,
[data-headerbg="color_10"] .search-area .form-control,
[data-headerbg="color_10"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_10"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_10"] .header-left .search-area {
  background-color: #243bc9;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_10"] .header-right .search-area .form-control,
[data-headerbg="color_10"] .header-right .search-area .input-group-text {
  background-color: #243bc9;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_10"] .header-right .search-area .form-control i,
[data-headerbg="color_10"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_10"] .header-right .search-area .form-control svg path,
[data-headerbg="color_10"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_10"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_10"] .header-right .nav-item svg path,
[data-headerbg="color_10"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_10"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_10"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_10"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_10"] .header-right .header-border .btn {
  background-color: #243bc9;
  border-color: #243bc9;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_10"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_10"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_10"] .header-right .header-info h6,
[data-headerbg="color_10"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_10"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_10"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_10"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_10"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_10"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_10"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_10"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_10"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_10"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_10"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_10"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_11"][data-theme-version="dark"],
[data-headerbg="color_11"] {
  --headerbg: #c6164f;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_11"] .header-left .search-area .form-control,
[data-headerbg="color_11"] .header-left .search-area .input-group-text {
  background-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_11"] .nav-control {
  background-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_11"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_11"] .search-area .input-group-text,
[data-headerbg="color_11"] .search-area .form-control,
[data-headerbg="color_11"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_11"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_11"] .header-left .search-area {
  background-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_11"] .header-right .search-area .form-control,
[data-headerbg="color_11"] .header-right .search-area .input-group-text {
  background-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_11"] .header-right .search-area .form-control i,
[data-headerbg="color_11"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_11"] .header-right .search-area .form-control svg path,
[data-headerbg="color_11"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_11"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_11"] .header-right .nav-item svg path,
[data-headerbg="color_11"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_11"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_11"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_11"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_11"] .header-right .header-border .btn {
  background-color: #e72866;
  border-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_11"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_11"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_11"] .header-right .header-info h6,
[data-headerbg="color_11"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_11"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_11"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_11"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_11"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_11"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_11"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_11"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_11"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_11"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_11"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_11"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_12"][data-theme-version="dark"],
[data-headerbg="color_12"] {
  --headerbg: #343a40;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_12"] .header-left .search-area .form-control,
[data-headerbg="color_12"] .header-left .search-area .input-group-text {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_12"] .nav-control {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_12"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_12"] .search-area .input-group-text,
[data-headerbg="color_12"] .search-area .form-control,
[data-headerbg="color_12"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_12"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_12"] .header-left .search-area {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_12"] .header-right .search-area .form-control,
[data-headerbg="color_12"] .header-right .search-area .input-group-text {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_12"] .header-right .search-area .form-control i,
[data-headerbg="color_12"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_12"] .header-right .search-area .form-control svg path,
[data-headerbg="color_12"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_12"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_12"] .header-right .nav-item svg path,
[data-headerbg="color_12"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_12"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_12"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_12"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_12"] .header-right .header-border .btn {
  background-color: #4b545c;
  border-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_12"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_12"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_12"] .header-right .header-info h6,
[data-headerbg="color_12"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_12"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_12"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_12"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_12"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_12"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_12"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_12"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_12"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_12"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_12"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_12"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-headerbg="color_13"][data-theme-version="dark"],
[data-headerbg="color_13"] {
  --headerbg: #f58706;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-headerbg="color_13"] .header-left .search-area .form-control,
[data-headerbg="color_13"] .header-left .search-area .input-group-text {
  background-color: #fa9f34;
}

[data-headerbg="color_13"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_13"] .nav-control {
  background-color: #fa9f34;
}

[data-headerbg="color_13"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_13"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_13"] .search-area .input-group-text,
[data-headerbg="color_13"] .search-area .form-control,
[data-headerbg="color_13"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_13"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area,
[data-headerbg="color_13"] .header-left .search-area {
  background-color: #fa9f34;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_13"] .header-right .search-area .form-control,
[data-headerbg="color_13"] .header-right .search-area .input-group-text {
  background-color: #fa9f34;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_13"] .header-right .search-area .form-control i,
[data-headerbg="color_13"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
[data-headerbg="color_13"] .header-right .search-area .form-control svg path,
[data-headerbg="color_13"] .header-right .search-area .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
[data-headerbg="color_13"] .header-right .notification_dropdown .nav-link.show {
  background-color: #202020;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item svg path,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item svg circle,
[data-headerbg="color_13"] .header-right .nav-item svg path,
[data-headerbg="color_13"] .header-right .nav-item svg circle {
  stroke: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
[data-headerbg="color_13"] .header-right .nav-item.ps-3:hover {
  background: transparent;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
[data-headerbg="color_13"] .header-right .nav-item .ai-icon svg path,
[data-headerbg="color_13"] .header-right .nav-item .ai-icon svg circle {
  stroke: var(--primary);
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-border .btn,
[data-headerbg="color_13"] .header-right .header-border .btn {
  background-color: #fa9f34;
  border-color: #fa9f34;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-border:hover,
[data-headerbg="color_13"] .header-right .header-border:hover {
  background: transparent;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-info p,
[data-headerbg="color_13"] .header-right .header-info p {
  color: #E1E1E1;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-info h6,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-info .h6,
[data-headerbg="color_13"] .header-right .header-info h6,
[data-headerbg="color_13"] .header-right .header-info .h6 {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_13"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
[data-headerbg="color_13"] .header-left .search-area .input-group-text a svg path,
[data-headerbg="color_13"] .header-left .search-area .input-group-text a svg circle {
  stroke: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_13"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_13"][data-theme-version="dark"] .hamburger .line svg rect,
[data-headerbg="color_13"] .hamburger .line svg rect {
  fill: #fff !important;
}

[data-headerbg="color_13"][data-theme-version="dark"] .hamburger svg rect,
[data-headerbg="color_13"] .hamburger svg rect {
  fill: #fff !important;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
[data-headerbg="color_13"][data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_13"][data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_13"][data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-layout="horizontal"] .header .header-content,
[data-nav-headerbg="color_1"][data-layout="horizontal"] .header .header-content {
  padding-left: 0;
}

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="mini"] .color-title,
[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="modern"] .color-title,
[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="compact"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="mini"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="modern"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="compact"] .color-title {
  display: none !important;
}

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="icon-hover"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="icon-hover"] .color-title {
  display: none !important;
}

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="icon-hover"] .iconhover-toggle .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="icon-hover"] .iconhover-toggle .color-title {
  display: block !important;
}

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .color-title {
  display: block !important;
}

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="morden"][data-layout="horizontal"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="morden"][data-layout="horizontal"] .color-title {
  display: block !important;
}

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="compact"][data-layout="horizontal"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="compact"][data-layout="horizontal"] .color-title {
  display: block !important;
}

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="compact"][data-layout="vertical"] .menu-toggle .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="compact"][data-layout="vertical"] .menu-toggle .color-title {
  display: block !important;
}

@media (min-width: 62rem) {
  [data-container="boxed"] #main-wrapper {
    max-width: 74.9375rem;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-container="boxed"] #main-wrapper {
    text-align: right;
  }

  [data-container="boxed"] .content-body {
    padding-right: 0rem;
  }

  [data-container="boxed"] .invoice-num {
    font-size: 1.375rem;
  }

  [data-container="boxed"] .email-susb {
    display: none;
  }

  [data-container="boxed"] .market-previews,
  [data-container="boxed"] .assets-al {
    width: 50%;
  }

  [data-container="boxed"] .buy-coin .coin-img img {
    width: 332px;
    bottom: -11px;
  }

  [data-container="boxed"] .dz-side-menu {
    display: none;
  }

  [data-container="boxed"] .out-footer {
    margin-right: 5.375rem;
  }
}

@media only screen and (min-width: 84.375rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 74.9375rem;
  }
}

@media only screen and (min-width: 75rem) and (max-width: 84.3125rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 74.9375rem;
  }
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] .deznav {
  max-width: 74.9375rem;
}

[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"] .header,
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] .header {
  width: 74.9375rem;
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 74.9375rem;
}

[data-container="boxed"] .metismenu.fixed {
  left: auto;
  max-width: 74.9375rem;
}

[data-container="boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 0.9375rem 0.9375rem;
}

[data-container="boxed"] .content-body .container-fluid,
[data-container="boxed"] .content-body .container-sm,
[data-container="boxed"] .content-body .container-md,
[data-container="boxed"] .content-body .container-lg,
[data-container="boxed"] .content-body .container-xl,
[data-container="boxed"] .content-body .container-xxl {
  padding: 2.5rem;
}

[data-container="boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  position: absolute;
}

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  left: auto;
  max-width: 74.9375rem;
}

@media (min-width: 62rem) {
  [data-container="wide-boxed"] #main-wrapper {
    max-width: 92.5rem;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-container="wide-boxed"] #main-wrapper {
    text-align: right;
  }

  [data-container="wide-boxed"] .content-body {
    padding-right: 0rem;
  }

  [data-container="wide-boxed"] .email-susb {
    display: none;
  }

  [data-container="wide-boxed"] .market-previews,
  [data-container="wide-boxed"] .assets-al {
    width: 50%;
  }

  [data-container="wide-boxed"] .buy-coin .coin-img img {
    width: 332px;
    bottom: -11px;
  }
}

@media only screen and (min-width: 84.375rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] .header {
    width: 92.5rem;
  }
}

@media only screen and (min-width: 75rem) and (max-width: 100rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] .header {
    width: 92.5rem;
  }
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="wide-boxed"][data-layout="vertical"] .header {
  width: 92.5rem;
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] .deznav {
  max-width: 92.5rem;
}

[data-layout="vertical"][data-container="wide-boxed"][data-header-position="fixed"] .header,
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"] .header {
  width: 92.5rem;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 92.5rem;
}

[data-container="wide-boxed"] .metismenu.fixed {
  left: auto;
  max-width: 92.5rem;
}

[data-container="wide-boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 0.9375rem 0.9375rem;
}

[data-container="wide-boxed"] .content-body .container-fluid,
[data-container="wide-boxed"] .content-body .container-sm,
[data-container="wide-boxed"] .content-body .container-md,
[data-container="wide-boxed"] .content-body .container-lg,
[data-container="wide-boxed"] .content-body .container-xl,
[data-container="wide-boxed"] .content-body .container-xxl {
  padding: 2.5rem;
}

[data-container="wide-boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0;
}

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  position: absolute;
}

[data-container="wide-boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  left: auto;
  max-width: 92.5rem;
}

[data-primary="color_2"] {
  --primary: #18A594;
  --primary-hover: #12786c;
  --primary-dark: #051f1c;
  --rgba-primary-1: rgba(24, 165, 148, 0.1);
  --rgba-primary-2: rgba(24, 165, 148, 0.2);
  --rgba-primary-3: rgba(24, 165, 148, 0.3);
  --rgba-primary-4: rgba(24, 165, 148, 0.4);
  --rgba-primary-5: rgba(24, 165, 148, 0.5);
  --rgba-primary-6: rgba(24, 165, 148, 0.6);
  --rgba-primary-7: rgba(24, 165, 148, 0.7);
  --rgba-primary-8: rgba(24, 165, 148, 0.8);
  --rgba-primary-9: rgba(24, 165, 148, 0.9);
}

[data-primary="color_3"] {
  --primary: #0D99FF;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
}

[data-primary="color_4"] {
  --primary: #2C2C2C;
  --primary-hover: #131313;
  --primary-dark: black;
  --rgba-primary-1: rgba(44, 44, 44, 0.1);
  --rgba-primary-2: rgba(44, 44, 44, 0.2);
  --rgba-primary-3: rgba(44, 44, 44, 0.3);
  --rgba-primary-4: rgba(44, 44, 44, 0.4);
  --rgba-primary-5: rgba(44, 44, 44, 0.5);
  --rgba-primary-6: rgba(44, 44, 44, 0.6);
  --rgba-primary-7: rgba(44, 44, 44, 0.7);
  --rgba-primary-8: rgba(44, 44, 44, 0.8);
  --rgba-primary-9: rgba(44, 44, 44, 0.9);
}

[data-primary="color_5"] {
  --primary: #3250ff;
  --primary-hover: #0025fe;
  --primary-dark: #001698;
  --rgba-primary-1: rgba(50, 80, 255, 0.1);
  --rgba-primary-2: rgba(50, 80, 255, 0.2);
  --rgba-primary-3: rgba(50, 80, 255, 0.3);
  --rgba-primary-4: rgba(50, 80, 255, 0.4);
  --rgba-primary-5: rgba(50, 80, 255, 0.5);
  --rgba-primary-6: rgba(50, 80, 255, 0.6);
  --rgba-primary-7: rgba(50, 80, 255, 0.7);
  --rgba-primary-8: rgba(50, 80, 255, 0.8);
  --rgba-primary-9: rgba(50, 80, 255, 0.9);
}

[data-primary="color_6"] {
  --primary: #3f8cff;
  --primary-hover: #0c6dff;
  --primary-dark: #0042a5;
  --rgba-primary-1: rgba(63, 140, 255, 0.1);
  --rgba-primary-2: rgba(63, 140, 255, 0.2);
  --rgba-primary-3: rgba(63, 140, 255, 0.3);
  --rgba-primary-4: rgba(63, 140, 255, 0.4);
  --rgba-primary-5: rgba(63, 140, 255, 0.5);
  --rgba-primary-6: rgba(63, 140, 255, 0.6);
  --rgba-primary-7: rgba(63, 140, 255, 0.7);
  --rgba-primary-8: rgba(63, 140, 255, 0.8);
  --rgba-primary-9: rgba(63, 140, 255, 0.9);
}

[data-primary="color_7"] {
  --primary: #40a7e2;
  --primary-hover: #208fcf;
  --primary-dark: #125277;
  --rgba-primary-1: rgba(64, 167, 226, 0.1);
  --rgba-primary-2: rgba(64, 167, 226, 0.2);
  --rgba-primary-3: rgba(64, 167, 226, 0.3);
  --rgba-primary-4: rgba(64, 167, 226, 0.4);
  --rgba-primary-5: rgba(64, 167, 226, 0.5);
  --rgba-primary-6: rgba(64, 167, 226, 0.6);
  --rgba-primary-7: rgba(64, 167, 226, 0.7);
  --rgba-primary-8: rgba(64, 167, 226, 0.8);
  --rgba-primary-9: rgba(64, 167, 226, 0.9);
}

[data-primary="color_8"] {
  --primary: #B65FBD;
  --primary-hover: #9e44a5;
  --primary-dark: #59265d;
  --rgba-primary-1: rgba(182, 95, 189, 0.1);
  --rgba-primary-2: rgba(182, 95, 189, 0.2);
  --rgba-primary-3: rgba(182, 95, 189, 0.3);
  --rgba-primary-4: rgba(182, 95, 189, 0.4);
  --rgba-primary-5: rgba(182, 95, 189, 0.5);
  --rgba-primary-6: rgba(182, 95, 189, 0.6);
  --rgba-primary-7: rgba(182, 95, 189, 0.7);
  --rgba-primary-8: rgba(182, 95, 189, 0.8);
  --rgba-primary-9: rgba(182, 95, 189, 0.9);
}

[data-primary="color_9"] {
  --primary: #88a67e;
  --primary-hover: #6d8f62;
  --primary-dark: #3f5239;
  --rgba-primary-1: rgba(136, 166, 126, 0.1);
  --rgba-primary-2: rgba(136, 166, 126, 0.2);
  --rgba-primary-3: rgba(136, 166, 126, 0.3);
  --rgba-primary-4: rgba(136, 166, 126, 0.4);
  --rgba-primary-5: rgba(136, 166, 126, 0.5);
  --rgba-primary-6: rgba(136, 166, 126, 0.6);
  --rgba-primary-7: rgba(136, 166, 126, 0.7);
  --rgba-primary-8: rgba(136, 166, 126, 0.8);
  --rgba-primary-9: rgba(136, 166, 126, 0.9);
}

[data-primary="color_10"] {
  --primary: #1C2E9E;
  --primary-hover: #142173;
  --primary-dark: #05081c;
  --rgba-primary-1: rgba(28, 46, 158, 0.1);
  --rgba-primary-2: rgba(28, 46, 158, 0.2);
  --rgba-primary-3: rgba(28, 46, 158, 0.3);
  --rgba-primary-4: rgba(28, 46, 158, 0.4);
  --rgba-primary-5: rgba(28, 46, 158, 0.5);
  --rgba-primary-6: rgba(28, 46, 158, 0.6);
  --rgba-primary-7: rgba(28, 46, 158, 0.7);
  --rgba-primary-8: rgba(28, 46, 158, 0.8);
  --rgba-primary-9: rgba(28, 46, 158, 0.9);
}

[data-primary="color_11"] {
  --primary: #c6164f;
  --primary-hover: #98113d;
  --primary-dark: #3c0718;
  --rgba-primary-1: rgba(198, 22, 79, 0.1);
  --rgba-primary-2: rgba(198, 22, 79, 0.2);
  --rgba-primary-3: rgba(198, 22, 79, 0.3);
  --rgba-primary-4: rgba(198, 22, 79, 0.4);
  --rgba-primary-5: rgba(198, 22, 79, 0.5);
  --rgba-primary-6: rgba(198, 22, 79, 0.6);
  --rgba-primary-7: rgba(198, 22, 79, 0.7);
  --rgba-primary-8: rgba(198, 22, 79, 0.8);
  --rgba-primary-9: rgba(198, 22, 79, 0.9);
}

[data-primary="color_12"] {
  --primary: #343a40;
  --primary-hover: #1d2124;
  --primary-dark: black;
  --rgba-primary-1: rgba(52, 58, 64, 0.1);
  --rgba-primary-2: rgba(52, 58, 64, 0.2);
  --rgba-primary-3: rgba(52, 58, 64, 0.3);
  --rgba-primary-4: rgba(52, 58, 64, 0.4);
  --rgba-primary-5: rgba(52, 58, 64, 0.5);
  --rgba-primary-6: rgba(52, 58, 64, 0.6);
  --rgba-primary-7: rgba(52, 58, 64, 0.7);
  --rgba-primary-8: rgba(52, 58, 64, 0.8);
  --rgba-primary-9: rgba(52, 58, 64, 0.9);
}

[data-primary="color_13"] {
  --primary: #f58706;
  --primary-hover: #c36c05;
  --primary-dark: #603502;
  --rgba-primary-1: rgba(245, 135, 6, 0.1);
  --rgba-primary-2: rgba(245, 135, 6, 0.2);
  --rgba-primary-3: rgba(245, 135, 6, 0.3);
  --rgba-primary-4: rgba(245, 135, 6, 0.4);
  --rgba-primary-5: rgba(245, 135, 6, 0.5);
  --rgba-primary-6: rgba(245, 135, 6, 0.6);
  --rgba-primary-7: rgba(245, 135, 6, 0.7);
  --rgba-primary-8: rgba(245, 135, 6, 0.8);
  --rgba-primary-9: rgba(245, 135, 6, 0.9);
}

[data-secondary="color_2"] {
  --secondary: #18A594;
  --secondary-dark: #12786c;
}

[data-secondary="color_3"] {
  --secondary: #0D99FF;
  --secondary-dark: #007ed9;
}

[data-secondary="color_4"] {
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
}

[data-secondary="color_5"] {
  --secondary: #3250ff;
  --secondary-dark: #0025fe;
}

[data-secondary="color_6"] {
  --secondary: #3f8cff;
  --secondary-dark: #0c6dff;
}

[data-secondary="color_7"] {
  --secondary: #40a7e2;
  --secondary-dark: #208fcf;
}

[data-secondary="color_8"] {
  --secondary: #B65FBD;
  --secondary-dark: #9e44a5;
}

[data-secondary="color_9"] {
  --secondary: #88a67e;
  --secondary-dark: #6d8f62;
}

[data-secondary="color_10"] {
  --secondary: #1C2E9E;
  --secondary-dark: #142173;
}

[data-secondary="color_11"] {
  --secondary: #c6164f;
  --secondary-dark: #98113d;
}

[data-secondary="color_12"] {
  --secondary: #343a40;
  --secondary-dark: #1d2124;
}

[data-secondary="color_13"] {
  --secondary: #f58706;
  --secondary-dark: #c36c05;
}

[data-typography="opensans"] {
  font-family: 'Open Sans', sans-serif;
}

[data-typography="poppins"] {
  font-family: 'poppins', sans-serif;
}

[data-typography="cairo"] {
  font-family: 'Cairo', sans-serif;
}

[data-typography="roboto"] {
  font-family: 'Roboto', sans-serif;
}

[data-typography="helvetica"] {
  font-family: 'HelveticaNeue';
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

[data-theme-version="transparent"] {
  /* background: url("../page-error-404.html"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
}

[data-theme-version="transparent"] h1,
[data-theme-version="transparent"] .h1,
[data-theme-version="transparent"] h2,
[data-theme-version="transparent"] .h2,
[data-theme-version="transparent"] h3,
[data-theme-version="transparent"] .h3,
[data-theme-version="transparent"] h4,
[data-theme-version="transparent"] .h4,
[data-theme-version="transparent"] h5,
[data-theme-version="transparent"] .h5,
[data-theme-version="transparent"] h6,
[data-theme-version="transparent"] .h6 {
  color: #fff !important;
}

[data-theme-version="transparent"] a.link {
  color: #ddd;
}

[data-theme-version="transparent"] a.link:focus,
[data-theme-version="transparent"] a.link:hover {
  color: #58bad7;
}

[data-theme-version="transparent"] a {
  color: #fff;
}

[data-theme-version="transparent"] a:hover {
  color: #828690;
}

[data-theme-version="transparent"] .border-right {
  border-right: 1px solid #444444 !important;
}

[data-theme-version="transparent"] .border-left {
  border-left: 1px solid #444444 !important;
}

[data-theme-version="transparent"] .border-top {
  border-top: 1px solid #444444 !important;
}

[data-theme-version="transparent"] .border-bottom {
  border-bottom: 1px solid #444444 !important;
}

[data-theme-version="transparent"] .border {
  border: 1px solid #444444 !important;
}

[data-theme-version="transparent"] .dropdown-menu {
  background-color: #242424;
}

[data-theme-version="transparent"] .dropdown-item:hover,
[data-theme-version="transparent"] .dropdown-item:focus {
  background-color: #1E1E1E;
  color: #fff;
}

[data-theme-version="transparent"] .form-control {
  background-color: #1E1E1E;
  border-color: #444444;
  color: #fff;
}

[data-theme-version="transparent"] .card {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .header {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .header-left input {
  border-color: #444444;
  color: #fff;
}

[data-theme-version="transparent"] .header-left input:focus {
  box-shadow: none;
  border-color: var(--primary);
}

[data-theme-version="transparent"] .header-left input::placeholder {
  color: #fff;
}

[data-theme-version="transparent"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="transparent"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff;
}

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .nav-control {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .nav-control {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="transparent"] .deznav {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .deznav .metismenu>li:hover>a,
[data-theme-version="transparent"] .deznav .metismenu>li:focus>a,
[data-theme-version="transparent"] .deznav .metismenu>li.mm-active>a {
  background-color: rgba(0, 0, 0, 0.15) !important;
  color: #fff;
}

[data-theme-version="transparent"] .deznav .metismenu>li:hover>a::after,
[data-theme-version="transparent"] .deznav .metismenu>li:focus>a::after,
[data-theme-version="transparent"] .deznav .metismenu>li.mm-active>a::after {
  border-color: transparent transparent #fff transparent;
}

[data-theme-version="transparent"] .deznav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="transparent"] .deznav .metismenu ul {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .deznav .metismenu ul a:hover,
[data-theme-version="transparent"] .deznav .metismenu ul a:focus,
[data-theme-version="transparent"] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version="transparent"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .deznav .metismenu ul {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .deznav .metismenu .has-arrow:after {
  border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

[data-theme-version="dark"] {
  background: #1E1E1E;
  color: #828690;
  --nav-headbg: #242424;
  --sidebar-bg: #242424;
  --headerbg: #242424;
  --bs-body-bg: #242424;
}

[data-theme-version="dark"] h1,
[data-theme-version="dark"] .h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] .h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] .h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] .h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] .h5,
[data-theme-version="dark"] h6,
[data-theme-version="dark"] .h6 {
  color: #fff !important;
}

[data-theme-version="dark"] a.link {
  color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
  color: #58bad7;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .border-right {
  border-right: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] .border-left {
  border-left: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] .border-top {
  border-top: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] .border-bottom {
  border-bottom: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] .border {
  border: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] {
  --secondary: $white;
}

[data-theme-version="dark"] .card {
  background-color: #242424;
  box-shadow: none;
  border-color: #444444;
}

[data-theme-version="dark"] .dropdown-menu {
  background-color: #242424;
  box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item {
  color: #777777;
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:hover,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
  background-color: #242424;
  color: #fff;
}

[data-theme-version="dark"] a {
  color: #fff !important;
}

[data-theme-version="dark"] .btn-link g [fill] {
  fill: #fff;
}

[data-theme-version="dark"] .btn-light:active,
[data-theme-version="dark"] .btn-light:focus,
[data-theme-version="dark"] .btn-light:hover {
  color: #000;
}

[data-theme-version="dark"] .form-control {
  background-color: #1E1E1E;
  border-color: #444444;
  color: #fff;
}

[data-theme-version="dark"] .form-select {
  background-color: #1E1E1E;
  border-color: #444444;
  color: #fff;
}

[data-theme-version="dark"] .toggle-switch.form-check .form-check-input {
  background: #1E1E1E;
}

[data-theme-version="dark"] .toggle-switch.form-check .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .modal-content {
  background: #242424;
}

[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #444444;
}

[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .close:hover {
  color: #fff;
}

[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #444444;
}

[data-theme-version="dark"] .widget-stat .media>span {
  background: #444444;
  border-color: #444444;
  color: #fff;
}

[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #444444;
}

[data-theme-version="dark"] .custom-select {
  border-color: #444444;
  color: #828690;
  background: #1E1E1E;
}

[data-theme-version="dark"] .nav-tabs {
  border-color: var(--primary);
}

[data-theme-version="dark"] .nav-tabs .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version="dark"] hr {
  border-color: #444444;
}

[data-theme-version="dark"] .grid-col {
  background: #1E1E1E;
}

[data-theme-version="dark"] .noUi-target {
  border-color: #444444;
  border-radius: 0.5rem;
  box-shadow: none;
}

[data-theme-version="dark"] .noUi-target .noUi-connects {
  background: #444444;
}

[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #444444;
}

[data-theme-version="dark"] .input-group-text {
  background: #1E1E1E;
  color: #fff;
  border-color: #444444;
}

[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #444444;
}

[data-theme-version="dark"] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #444444;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #444444;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #444444;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link.active {
  background: #1E1E1E;
  color: #fff;
}

[data-theme-version="dark"] .list-group-item-action {
  color: #888888;
}

[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #1E1E1E;
  border-color: #1E1E1E;
}

[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item.active:focus,
[data-theme-version="dark"] .list-group-item.active:hover,
[data-theme-version="dark"] .list-group-item.active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .swal2-popup {
  background: #242424;
}

[data-theme-version="dark"] .form-head .btn-outline-primary {
  border-color: #444444;
}

[data-theme-version="dark"] .form-head .btn-outline-primary:hover {
  border-color: var(--primary);
}

[data-theme-version="dark"] .review-tab.nav-pills li a.nav-link.active {
  background: transparent;
}

[data-theme-version="dark"] .new-arrival-content h4 a,
[data-theme-version="dark"] .new-arrival-content .h4 a {
  color: #fff;
}

[data-theme-version="dark"] .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .abilities-chart .ct-chart .ct-label {
  fill: #fff;
}

[data-theme-version="dark"] .morris_chart_height text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .btn-link {
  color: #fff;
}

[data-theme-version="dark"] .order-bg {
  background: #1E1E1E;
}

[data-theme-version="dark"] .detault-daterange {
  background: #1E1E1E;
  color: #fff;
}

[data-theme-version="dark"] .detault-daterange .input-group-text {
  background: #242424;
  border: 0;
}

[data-theme-version="dark"] .dataTablesCard {
  background-color: #242424;
}

[data-theme-version="dark"] .compose-content .dropzone {
  background: #1E1E1E !important;
}

[data-theme-version="dark"] .compose-content .dropzone .dz-message .dz-button {
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker {
  background: #1E1E1E;
  border-color: var(--primary);
}

[data-theme-version="dark"] .daterangepicker .calendar-table {
  border-color: var(--primary);
  background: #1E1E1E;
}

[data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker:after {
  border-bottom: 0.375rem solid #1E1E1E;
}

[data-theme-version="dark"] .daterangepicker select.hourselect,
[data-theme-version="dark"] .daterangepicker select.minuteselect,
[data-theme-version="dark"] .daterangepicker select.secondselect,
[data-theme-version="dark"] .daterangepicker select.ampmselect {
  background: #1E1E1E;
  border: 0.0625rem solid #444444;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker td.off,
[data-theme-version="dark"] .daterangepicker td.off.in-range,
[data-theme-version="dark"] .daterangepicker td.off.start-date,
[data-theme-version="dark"] .daterangepicker td.off.end-date {
  background-color: #242424;
}

[data-theme-version="dark"] .daterangepicker td.off:hover,
[data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
[data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
[data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button {
  background-color: #1E1E1E;
  border-color: var(--primary);
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button:hover,
[data-theme-version="dark"] .app-fullcalendar .fc-button.fc-stat-hover {
  background-color: var(--primary);
}

[data-theme-version="dark"] .swal2-popup .swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem #444444, 0 0 0 0.25rem var(--rgba-primary-1);
}

[data-theme-version="dark"] .dd-handle {
  border-color: #444444;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul {
  background: #242424 !important;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  border-color: #444444;
}

[data-theme-version="dark"] .clockpicker-popover .popover-content {
  background-color: #242424;
}

[data-theme-version="dark"] .clockpicker-plate {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .clockpicker-popover .popover-title {
  background-color: #1E1E1E;
  color: #fff;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link:after {
  background: #1E1E1E;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active:after,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done:after {
  background: var(--primary);
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active span,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done span {
  background: var(--primary);
}

[data-theme-version="dark"] .check-switch .custom-control-label:after,
[data-theme-version="dark"] .check-switch .custom-control-label:before {
  border-color: var(--primary);
}

[data-theme-version="dark"] .fc-unthemed .fc-today {
  background: #1E1E1E;
}

[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-popover .fc-header {
  background: #444444;
}

[data-theme-version="dark"] .picker__box {
  background: #1E1E1E;
}

[data-theme-version="dark"] .picker__box .picker__button--clear,
[data-theme-version="dark"] .picker__box .picker__button--close,
[data-theme-version="dark"] .picker__box .picker__button--today {
  background: #242424;
  color: #fff;
}

[data-theme-version="dark"] .picker__box .picker__button--clear:hover:before,
[data-theme-version="dark"] .picker__box .picker__button--close:hover:before,
[data-theme-version="dark"] .picker__box .picker__button--today:hover:before {
  color: #fff;
}

[data-theme-version="dark"] .picker {
  color: #999;
}

[data-theme-version="dark"] .dtp>.dtp-content {
  background: #1E1E1E;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a {
  color: #68686a;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a.selected {
  color: #fff;
}

[data-theme-version="dark"] .order-request tbody tr {
  border-color: #444444;
}

[data-theme-version="dark"] .card-list li {
  color: #fff;
}

[data-theme-version="dark"] .card-bx .change-btn:hover {
  color: var(--primary);
}

[data-theme-version="dark"] .invoice-card.bg-warning {
  background-color: #5b3c1f !important;
}

[data-theme-version="dark"] .invoice-card.bg-success {
  background-color: #2a6729 !important;
}

[data-theme-version="dark"] .invoice-card.bg-info {
  background-color: #4c276a !important;
}

[data-theme-version="dark"] .invoice-card.bg-secondary {
  background-color: #1c3e52 !important;
}

[data-theme-version="dark"] .user-list li {
  border-color: #242424;
}

[data-theme-version="dark"] .toggle-switch {
  color: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-text tspan {
  fill: #969ba0;
}

[data-theme-version="dark"] .bar-chart line {
  stroke: #444444;
}

[data-theme-version="dark"] .accordion.style-1 .accordion-item,
[data-theme-version="dark"] .invoice-list {
  border-color: #444444;
}

[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info a,
[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info,
[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed>span {
  color: #fff;
}

[data-theme-version="dark"] .ic-card>a {
  background: #25479f;
}

[data-theme-version="dark"] .ic-card>a:first-child {
  border-color: #25479f;
}

[data-theme-version="dark"] .ic-card span {
  color: #fff;
}

[data-theme-version="dark"] table.dataTable thead th,
[data-theme-version="dark"] table.dataTable thead td {
  border-color: #444444 !important;
}

[data-theme-version="dark"] .paging_simple_numbers.dataTables_paginate {
  background: #242424;
}

[data-theme-version="dark"] .dataTables_info {
  color: #fff;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  background: var(--primary);
  color: #fff !important;
}

[data-theme-version="dark"] .dashboard-select {
  background: var(--rgba-primary-1);
  color: white;
  border-color: #444444;
}

[data-theme-version="dark"] .dashboard-select .list {
  background: #242424;
}

[data-theme-version="dark"] .dashboard-select .option:hover,
[data-theme-version="dark"] .dashboard-select .option.focus,
[data-theme-version="dark"] .dashboard-select .option.selected.focus {
  background: #1E1E1E;
}

[data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
  background: #242424;
}

[data-theme-version="dark"] .transaction-details {
  border-color: #444444;
}

[data-theme-version="dark"] .description {
  color: #fff;
}

[data-theme-version="dark"] .transaction-details .amount-bx {
  background: #3f250d;
}

[data-theme-version="dark"] .transaction-details .amount-bx i {
  background: #8d3b0c;
}

[data-theme-version="dark"] .nice-select .option {
  background: #242424;
}

[data-theme-version="dark"] .nice-select .option:hover,
[data-theme-version="dark"] .nice-select .option.focus,
[data-theme-version="dark"] .nice-select .option.selected.focus {
  background: #242424;
}

[data-theme-version="dark"] .card-tabs.style-1 {
  border-color: #444444;
}

[data-theme-version="dark"] .nice-select .list {
  background: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .table-search .input-group .input-group-text {
  background: #242424;
}

[data-theme-version="dark"] .dark-btn svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-legend-text {
  color: #fff !important;
}

[data-theme-version="dark"] .Sales tspan {
  fill: #fff;
}

[data-theme-version="dark"] .timeline-chart rect {
  fill: #1E1E1E;
}

[data-theme-version="dark"] .header-info span {
  color: #fff !important;
}

[data-theme-version="dark"] .smallipop-instance.blue {
  color: #fff;
}

[data-theme-version="dark"] .property-map .smallipop.smallimap-mapicon .circle-marker {
  border-color: #444444;
}

[data-theme-version="dark"] .property-features li {
  color: #fff;
}

[data-theme-version="dark"] .review-tabs-1 {
  background: #242424;
}

[data-theme-version="dark"] .coin-tabs {
  background: #2E2E40;
}

[data-theme-version="dark"] .coin-tabs .nav-link.active {
  background: var(--primary) !important;
  color: #fff;
}

[data-theme-version="dark"] .separate-row>div {
  border-color: #444444;
}

[data-theme-version="dark"] .bg-white {
  background: #1E1E1E !important;
}

[data-theme-version="dark"] .deznav .header-info2 span {
  color: #fff;
}

[data-theme-version="dark"] .brand-title path {
  fill: #fff;
}

[data-theme-version="dark"] .brand-title tspan {
  fill: #fff;
}

[data-theme-version="dark"] .shapreter-row>div {
  border-color: #444444;
}

[data-theme-version="dark"] .chartBar line {
  stroke: #444444;
}

[data-theme-version="dark"] .search-job .search-dropdown:after {
  background: #444444;
}

[data-theme-version="dark"] .chartjs line {
  stroke: #444444;
}

[data-theme-version="dark"] .form-label {
  color: #fff;
}

[data-theme-version="dark"] .datepicker-switch {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown td.day,
[data-theme-version="dark"] .datepicker.datepicker-dropdown th.next,
[data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown th.dow {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown th.datepicker-switch,
[data-theme-version="dark"] .datepicker.datepicker-dropdown th.next,
[data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
  color: #fff;
}

[data-theme-version="dark"] .btn-close {
  color: #fff !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23099'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

[data-theme-version="dark"] .bgl-secondary {
  color: #fff;
}

[data-theme-version="dark"] .application tr td .btn {
  color: #fff;
}

[data-theme-version="dark"] .btn-link svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .alert.alert-outline-secondary,
[data-theme-version="dark"] .alert.alert-outline-dark {
  color: #fff;
}

[data-theme-version="dark"] .form-control-plaintext {
  color: #fff;
}

[data-theme-version="dark"] .timeline-panel .media-body small,
[data-theme-version="dark"] .timeline-panel .media-body .small {
  color: #fff;
}

[data-theme-version="dark"] .jobs {
  border-color: #444444;
}

[data-theme-version="dark"] .apexcharts-gridlines-vertical line {
  stroke: #444444;
}

[data-theme-version="dark"] .apexcharts-text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .widget-courses .schedule-icon {
  color: #fff;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule,
[data-theme-version="dark"] .upcomming-task-widget {
  background: #242424;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 {
  background: #1E1E1E;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 .date-box {
  background: #242424;
  color: #fff;
}

[data-theme-version="dark"] .instructors-box .instructors-media .custome-badge .badge {
  color: #fff;
}

[data-theme-version="dark"] .instructors-box .instructors-media .info-box {
  background: #1E1E1E;
}

[data-theme-version="dark"] .course-details-tab .nav-tabs .nav-link {
  color: #cbc3c3;
}

[data-theme-version="dark"] .message-box,
[data-theme-version="dark"] .chat-icon,
[data-theme-version="dark"] .chat-people,
[data-theme-version="dark"] .chat-tabs .chat-bx,
[data-theme-version="dark"] .share-files,
[data-theme-version="dark"] .type-massage,
[data-theme-version="dark"] .work-ic {
  border-color: #444444;
}

[data-theme-version="dark"] .timeline-active .modulel {
  background: #242424;
}

[data-theme-version="dark"] .timeline-active li .time {
  background: #242424;
}

[data-theme-version="dark"] .achievements .achievements-content span,
[data-theme-version="dark"] .bio .bio-content {
  background: #1E1E1E;
}

[data-theme-version="dark"] .score-active.style-1 .dashboard-select {
  background: #1E1E1E;
}

[data-theme-version="dark"] #activity line {
  stroke: #444444;
}

[data-theme-version="dark"] .pagination-down ul li a {
  background: #1E1E1E;
}

[data-theme-version="dark"] .pagination-down ul li a.active {
  background: var(--primary);
}

[data-theme-version="dark"] .custome-accordion .accordion-button {
  background: #242424;
}

[data-theme-version="dark"] .custome-accordion .acc-courses {
  background: #1E1E1E;
}

[data-theme-version="dark"] .widget-timeline .side-border h4,
[data-theme-version="dark"] .widget-timeline .side-border .h4,
[data-theme-version="dark"] .widget-timeline .side-border .h4,
[data-theme-version="dark"] .widget-timeline .side-border .custom-dropdown {
  background: #242424;
}

[data-theme-version="dark"] .widget-timeline .side-border:after {
  border-color: #444444;
}

[data-theme-version="dark"] #columnChart line {
  stroke: #444444;
}

[data-theme-version="dark"] #sellingActivity svg path {
  stroke: #242424;
}

[data-theme-version="dark"] #sellingActivity line {
  stroke: #444444;
}

[data-theme-version="dark"] .review-box {
  background: #242424;
}

[data-theme-version="dark"] #NewCustomers line {
  stroke: #fff;
}

[data-theme-version="dark"] .students-list .search-area .form-control,
[data-theme-version="dark"] .students-list .search-area .input-group-text {
  background: #1E1E1E;
  border: #444444;
}

[data-theme-version="dark"] .custome-accordion.style-1 .acc-courses {
  background: #242424;
  border-color: #444444;
}

[data-theme-version="dark"] .miac,
[data-theme-version="dark"] .profile-img img,
[data-theme-version="dark"] .profile-img span,
[data-theme-version="dark"] .compose-content .dropzone,
[data-theme-version="dark"] .fc-theme-standard td,
[data-theme-version="dark"] .fc-theme-standard th,
[data-theme-version="dark"] .fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
[data-theme-version="dark"] .fc-scrollgrid,
[data-theme-version="dark"] table {
  border-color: #444444;
}

[data-theme-version="dark"] .btn-icon-end {
  border-color: #444444;
}

[data-theme-version="dark"] .raiting li {
  border-color: #444444;
}

[data-theme-version="dark"] .miac svg path {
  fill: #fff;
}

[data-theme-version="dark"] .type-massage.style-1 {
  background: #1E1E1E;
}

[data-theme-version="dark"] .header .header-content:after {
  background: #444444;
}

[data-theme-version="dark"] .search-coundry .dashboard-select {
  color: #fff;
  background: transparent;
}

[data-theme-version="dark"] .search-coundry .dashboard-select .option {
  color: #fff;
}

[data-theme-version="dark"] .search-coundry .dashboard-select:after {
  border-bottom: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option.selected {
  color: #000;
}

[data-theme-version="dark"] #redial .apexcharts-datalabels-group text {
  fill: #fff;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification {
  border-color: #444444;
}

[data-theme-version="dark"] .app-fullcalendar1 .fc .fc-button-group>.fc-button {
  border-color: #444444;
}

[data-theme-version="dark"] .app-fullcalendar1 .fc-direction-ltr .fc-daygrid-event.fc-event-end,
[data-theme-version="dark"] .app-fullcalendar1 .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background: #242424 !important;
  border-color: #444444;
}

[data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day {
  color: #ffffff !important;
}

[data-theme-version="dark"] .dz-side-menu {
  background: #242424;
}

[data-theme-version="dark"] .nice-select .option.selected {
  color: #fff;
}

[data-theme-version="dark"] .dz-cource,
[data-theme-version="dark"] .message-box,
[data-theme-version="dark"] .type-massage {
  background-color: #242424;
}

[data-theme-version="dark"] .dz-cource .form-control,
[data-theme-version="dark"] .message-box .form-control,
[data-theme-version="dark"] .type-massage .form-control {
  background-color: transparent;
}

[data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch {
  color: #fff;
}

[data-theme-version="dark"] .fc .fc-button-group>.fc-button {
  border-color: #444444;
}

[data-theme-version="dark"] .fc-daygrid-dot-event {
  background-color: #1E1E1E;
  border-color: #1E1E1E;
}

[data-theme-version="dark"] #redial .apexcharts-radialbar-hollow {
  fill: #242424;
}

[data-theme-version="dark"] .chat-tabs .chat-bx.active:after {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.day:hover,
[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.hour:hover,
[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.minute:hover,
[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.second:hover {
  background: #1E1E1E;
}

[data-theme-version="dark"] .chat-box-area .message-received p,
[data-theme-version="dark"] .message-send,
[data-theme-version="dark"] .type-massage .form-control {
  color: #fff;
  background-color: #171717;
}

[data-theme-version="dark"] .chat-icon ul li a,
[data-theme-version="dark"] .file {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .chat-icon ul li a svg path,
[data-theme-version="dark"] .file svg path {
  fill: #fff;
}

[data-theme-version="dark"] .course-dedails-bx .description .user-pic span,
[data-theme-version="dark"] .course-learn ul li {
  color: #fff;
}

[data-theme-version="dark"] .course-details-tab .user-pic2 p {
  color: #828690;
}

[data-theme-version="dark"] #columnChart .apexcharts-bar-series path {
  stroke: #242424;
}

[data-theme-version="dark"] .custome-donut tspan {
  fill: #fff;
}

[data-theme-version="dark"] #morris_donught.custome-donut svg path {
  stroke-width: 0px;
}

[data-theme-version="dark"] .card.bg-pink {
  background-color: #EB62D0;
}

[data-theme-version="dark"] #coin-ponent>div {
  background-color: #1E1E1E !important;
  color: #fff !important;
  border-color: #444444 !important;
}

[data-theme-version="dark"] #coin-ponent>div div {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-ponent div {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-ponent div div {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-ponent .cp-select-menu-item {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-ponent .cp-select-menu-item:hover {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-chart text,
[data-theme-version="dark"] .revenueMap text {
  fill: white;
}

[data-theme-version="dark"] #coin-chart line,
[data-theme-version="dark"] .revenueMap line {
  stroke: #444444;
}

[data-theme-version="dark"] .previews-info-list:hover {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .sell-element .sell-blance .form-control,
[data-theme-version="dark"] .limit-sell .nav-tabs {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .text-start span {
  color: #fff !important;
}

[data-theme-version="dark"] .text-secondary {
  color: #fff !important;
}

[data-theme-version="dark"] .card-wiget .reward-earn span {
  color: #fff;
}

[data-theme-version="dark"] .market_chart line,
[data-theme-version="dark"] #btcStock line {
  stroke: #444444;
}

[data-theme-version="dark"] .apexcharts-datalabels-group {
  stroke: #fff;
}

[data-theme-version="dark"] .btn.tp-btn svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .coin-warpper .nav .nav-item {
  border-color: #444444;
}

[data-theme-version="dark"] .form-wrapper.trade-form .input-group .input-group-text {
  color: #fff;
}

[data-theme-version="dark"] .progress-bar.bg-secondary {
  background-color: #fff !important;
}

[data-theme-version="dark"] .profile-card {
  border-color: #444444;
}

[data-theme-version="dark"] .profile-card .card-footer,
[data-theme-version="dark"] .author-profile .info-list li {
  border-color: #444444;
}

[data-theme-version="dark"] .email-left-box {
  border-color: #444444;
}

[data-theme-version="dark"] .email-left-box .mail-list .list-group-item i {
  color: #fff;
}

[data-theme-version="dark"] .dropzone .dlab-message {
  margin: 5em 0;
}

[data-theme-version="dark"] .email-list {
  background: #242424;
}

[data-theme-version="dark"] .email-list .message {
  border-color: #444444;
}

[data-theme-version="dark"] .email-list .message .col-mail-2 .subject {
  color: #fff;
}

[data-theme-version="dark"] .email-list .message:hover {
  background: #1E1E1E;
}

[data-theme-version="dark"] .email-right-box .table-pagenation {
  background: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .email-right-box .pagination {
  background: #1E1E1E;
}

[data-theme-version="dark"] .btn.tp-btn-light.btn-secondary,
[data-theme-version="dark"] .btn.tp-btn.btn-secondary {
  color: white;
}

[data-theme-version="dark"] .list-group-item-secondary {
  color: #fff;
}

[data-theme-version="dark"] .students span {
  color: #fff;
}

[data-theme-version="dark"] .progress .bg-white {
  background-color: #fff !important;
}

[data-theme-version="dark"] .border-end {
  border-color: #444444 !important;
}

[data-theme-version="dark"] .form-check-input {
  border-color: #444444;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .menu-toggle .logo-color {
  display: none !important;
}

@media only screen and (max-width: 47.9375rem) {
  [data-theme-version="dark"] .menu-toggle .logo-color {
    display: block !important;
  }
}

[data-theme-version="dark"] #overiewChart line {
  stroke: #444444;
}

[data-theme-version="dark"] #NewCustomers #SvgjsSvg1006 {
  margin-top: 16px;
}

[data-theme-version="dark"] .chart-grd:after {
  background: transparent;
}

[data-theme-version="dark"] .chat-box-area .message-sent p {
  background: #383838;
  color: white;
}

[data-theme-version="dark"] .page-titles,
[data-theme-version="dark"] .to-dodroup .sub-card,
[data-theme-version="dark"] .ttl-project,
[data-theme-version="dark"] .active-country .country-list,
[data-theme-version="dark"] .active-projects.selling-product tbody tr td,
[data-theme-version="dark"] .events .event-media,
[data-theme-version="dark"] .active-projects.style-1 tbody tr td,
[data-theme-version="dark"] .footer,
[data-theme-version="dark"] .active-projects.manage-client tbody tr td,
[data-theme-version="dark"] .task .task-summary,
[data-theme-version="dark"] .active-projects.task-table tbody td,
[data-theme-version="dark"] .kanbanPreview-bx .card-body .sub-card {
  border-color: #444444;
}

[data-theme-version="dark"] .light.badge-primary {
  background-color: #1E1E1E !important;
}

[data-theme-version="dark"] .avatar-list.avatar-list-stacked .avatar,
[data-theme-version="dark"] .author-profile .card-footer .form-control,
[data-theme-version="dark"] .profile-card .card-header,
[data-theme-version="dark"] .author-profile .upload-link {
  border-color: #444444;
}

[data-theme-version="dark"] .email-left-box .mail-list .list-group-item {
  background: rgba(255, 255, 255, 0.1);
  margin: 13px 0px;
}

[data-theme-version="dark"] .inovice-logo .logo-abbr .react-w {
  fill: #fff;
  stroke: #fff;
}

[data-theme-version="dark"] .demo-right-inner {
  background-color: #242424;
}

[data-theme-version="dark"] .border-start {
  body-color: #444444;
}

[data-theme-version="dark"] .ttl-project .pr-data:after,
[data-theme-version="dark"] .active-country:after {
  background: #444444;
}

[data-theme-version="dark"] .to-dodroup .sub-card .d-items,
[data-theme-version="dark"] .kanbanPreview-bx .card-body .sub-card {
  background-color: #242424;
}

[data-theme-version="dark"] .to-dodroup .sub-card .d-items .d-items-2 label,
[data-theme-version="dark"] .earning-tab .nav-item .nav-link.active {
  color: #fff;
}

[data-theme-version="dark"] .active-projects thead tr th,
[data-theme-version="dark"] .schedules-cal .datepicker-days .dow {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .bootstrap-select .btn {
  background: #242424 !important;
}

[data-theme-version="dark"] .sidebar-right-inner .bootstrap-select .btn {
  background: transparent !important;
  border-color: #E6E6E6 !important;
}

[data-theme-version="dark"] .sidebar-right-inner .dropdown-menu.show {
  background-color: #fff;
}

[data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item.active,
[data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item:active {
  color: var(--primary) !important;
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item {
  color: #777777 !important;
}

[data-theme-version="dark"] .sidebar-right-inner .dropdown-item:hover,
[data-theme-version="dark"] .sidebar-right-inner .dropdown-item:focus {
  background-color: var(--bs-dropdown-link-hover-bg);
}

[data-theme-version="dark"] .badge-outline-dark,
[data-theme-version="dark"] .badge-outline-secondary {
  border-color: #444444;
}

[data-theme-version="dark"] .light.badge-info {
  border-color: #444444;
}

[data-theme-version="dark"] .email-left-box .btn.text-white.btn-block {
  background-color: var(--primary);
}

[data-theme-version="dark"] .btn-secondary .text-secondary {
  color: #000 !important;
}

[data-theme-version="dark"] .list-group-item {
  color: #fff;
}

[data-theme-version="dark"] #TabWithIcon .nav-link.active {
  background-color: #1E1E1E;
  border-color: #444444;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-theme-version="dark"] .ck.ck-editor .ck.ck-button {
  border-color: #444444;
  color: #fff;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .dark-logo {
  display: block;
}

[data-theme-version="dark"] .light-logo {
  display: none;
}

[data-theme-version="dark"] .deznav,
[data-theme-version="dark"] .read-content-attachment .attachment>div:not(:last-child) {
  border-color: #444444;
}

[data-theme-version="dark"] #AllProject .apexcharts-series path {
  stroke: #444444;
}

[data-theme-version="dark"] .offcanvas {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .offcanvas .btn-close {
  background: transparent;
}

[data-theme-version="dark"] .events h6,
[data-theme-version="dark"] .events .h6 {
  border-color: #444444;
}

[data-theme-version="dark"] .task-tab {
  background-color: #242424;
}

[data-theme-version="dark"] .kanbanPreview-bx .card-body {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .custom-tab-1 .nav.nav-tabs {
  border-color: #444444;
}

[data-theme-version="dark"] .login-form {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .accordion-primary .accordion-header.collapsed {
  color: var(--primary) !important;
}

[data-theme-version="dark"] .accordion-item {
  background-color: transparent;
}

[data-theme-version="dark"] .mix-chart-tab .nav-link {
  background: #444444;
  color: #fff;
}

[data-theme-version="dark"] .mix-chart-tab .nav-link.active {
  background: var(--primary);
}

[data-theme-version="dark"] .active-map-main:after {
  background: #444444;
}

[data-theme-version="dark"] .upload-img .dropzone {
  border-color: #444444;
}

[data-theme-version="dark"] .upload-img .dropzone .dlab-message {
  margin: 1em 0;
}

[data-theme-version="dark"] .navbar-nav .active .scroll {
  color: var(--primary) !important;
}

[data-theme-version="dark"] .accordion-primary .accordion-header-text,
[data-theme-version="dark"] .accordion-danger-solid .accordion-header-text {
  color: #fff;
}

[data-theme-version="dark"] .header-left .search-area {
  background-color: transparent;
}

[data-theme-version="dark"] [data-headerbg="color_4"] .search-area {
  background-color: transparent;
}

[data-theme-version="dark"] .third-post.style-2,
[data-theme-version="dark"] .c-details ul li {
  border-color: #444444;
}

[data-theme-version="dark"] .dt-filter .dataTables_filter input[type="search"] {
  background: #1E1E1E;
}

[data-theme-version="dark"] .dt-filter .dataTables_filter label {
  background: #1E1E1E;
}

[data-theme-version="dark"] .card-profile .admin-user {
  background-color: rgba(0, 0, 0, 0.9);
}

[data-theme-version="dark"] .card-profile .admin-user .img-wrraper a {
  background-color: black;
  box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .card-profile .follow-list .follow-num {
  color: #fff;
}

[data-theme-version="dark"] .profile-accordion .accordion-item .accordion-header .accordion-button {
  color: #fff;
}

[data-theme-version="dark"] .c-list .search-area .form-control {
  border: 0;
}

[data-theme-version="dark"] .chat-left-area.active {
  background: #242424;
}

[data-theme-version="dark"] .logo-light {
  display: none;
}

[data-theme-version="dark"] .logo-dark {
  display: inline-block;
}

[data-theme-version="dark"] .error-page .error-head {
  color: #fff;
}

[data-theme-version="dark"] .ai-header-alert {
  background-color: #242424;
}

[data-theme-version="dark"] .ai-header-alert .btn-close {
  background: none;
}

[data-theme-version="dark"] .setting-list svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .check-ai .form-check-input+.form-check-label {
  background-color: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .nav-tabs.style-1 li a.nav-link {
  background-color: #242424;
  border-color: #444444;
}

[data-theme-version="dark"] .nav-tabs.style-1 li a.nav-link.active {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .nav-tabs.style-1 {
  border-color: #444444;
}

[data-theme-version="dark"] .cm-content-box {
  background-color: #242424;
}

[data-theme-version="dark"] .cm-content-box .cm-content-body {
  border-color: #444444;
}

[data-theme-version="dark"] .dd-handle.move-media {
  background-color: var(--primary);
}

[data-theme-version="dark"] .dd-handle.move-media+.accordion-button {
  background-color: #242424;
  color: #fff;
}

[data-theme-version="dark"] .menu-tabs,
[data-theme-version="dark"] .cm-content-box.style-1 .content-title,
[data-theme-version="dark"] .dd .accordion-body,
[data-theme-version="dark"] .dz-terms {
  border-color: #444444;
}

[data-theme-version="dark"] .login-form .login-title:before,
[data-theme-version="dark"] .login-form .login-title:after {
  background-color: #444444;
}

[data-theme-version="dark"] .new-scroll {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .tab-my.nav-tabs .nav-link {
  color: #000;
}

[data-theme-version="dark"] .svg-icons-ov .svg-icon-popup .btn {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .svg-area .card-header,
[data-theme-version="dark"] .login-content p {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown {
  border-color: #444444;
}

[data-theme-version="dark"] .accordion-button:not(.collapsed)::after {
  filter: brightness(2.5);
}

[data-theme-version="dark"] .pages-left {
  background-color: #242424;
}

[data-theme-version="dark"] .logo-dark {
  display: none;
}

[data-theme-version="dark"] .logo-light {
  display: inline-block;
}

[data-theme-version="dark"] .upload-img.dropzone {
  background-color: #242424;
}

[data-theme-version="dark"] .ai-check,
[data-theme-version="dark"] .upload-img.dropzone {
  border-color: #444444;
}

[data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-theme-version="dark"] .header-left .search-area .form-control {
  border-radius: 0;
}

[data-theme-version="dark"] .email-left-body.active {
  background-color: #242424;
}

[data-theme-version="dark"] .email-tools-box.active:before {
  background-color: #fff;
  opactiy: 0.1;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .header {
  border-color: #444444;
}

[data-theme-version="dark"] .header {
  border-color: #444444;
}

[data-theme-version="dark"] .header-left .dashboard_bar {
  color: #fff;
}

[data-theme-version="dark"] .search-area .form-control {
  background: #2c2c2c;
}

[data-theme-version="dark"] .search-area .input-group-text {
  border: 0;
  background: #2c2c2c;
}

[data-theme-version="dark"] .search-area .input-group-text a {
  color: #4f7086;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  /* background: $d-bg!important; */
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #242424;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="dark"] .nav-header .hamburger .line {
  background: #fff !important;
}

[data-theme-version="dark"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff !important;
}

[data-theme-version="dark"] .header-left .search-area .input-group-text {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .header-left .search-area .form-control {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .nav-header {
  border-color: #444444;
}

[data-theme-version="dark"] .nav-control svg rect {
  fill: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: #fff;
}

[data-theme-version="dark"] .nav-header {
  border-color: #444444;
}

[data-theme-version="dark"] .nav-control svg rect {
  fill: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: #fff;
}

[data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li>ul {
  background-color: #242424;
}

[data-theme-version="dark"] .fixed-content-box {
  background-color: #242424;
}

[data-theme-version="dark"] .fixed-content-box .head-name {
  background: #242424;
  color: #fff;
  border-color: #444444;
}

[data-theme-version="dark"] .fixed-content-box+.header+.deznav {
  background-color: #1E1E1E;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .deznav {
  border-color: #444444;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a {
  background: transparent;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a i,
[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a i {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li a>i {
  color: rgba(255, 255, 255, 0.3);
}

[data-theme-version="dark"] .deznav .header-profile>a.nav-link {
  border-color: #444444;
}

[data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li>a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a {
  color: #fff !important;
  /* background:var(--rgba-primary-1); */
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a i,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="dark"] .deznav .metismenu ul:after {
  background-color: #444444;
}

[data-theme-version="dark"] .deznav .metismenu ul a:hover,
[data-theme-version="dark"] .deznav .metismenu ul a:focus,
[data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
  color: #fff !important;
}

[data-theme-version="dark"] .deznav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu a {
  color: #b3b3b3 !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  border-color: var(--rgba-primary-1) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  color: #000 !important;
}

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #CCCCCC;
}

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff;
}

[data-theme-version="dark"] .sidebar-right .default-select .list .option.selected,
[data-theme-version="dark"] .sidebar-right .default-select .list .option.focus,
[data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>h4,
[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>.h4 {
  color: #000 !important;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option {
  background: #fff;
}

[data-theme-version="dark"] .footer {
  background-color: #242424;
}

[data-theme-version="dark"] .footer .copyright {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright p {
  color: rgba(255, 255, 255, 0.7);
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  -moz-tab-size: 16;
  tab-size: 2;
}

pre code.hljs {
  display: block;
  overflow-x: auto;
  color: #fff;
  padding: 0;
}

.hljs-tag .hljs-attr,
.hljs-tag .hljs-name {
  color: #78c6ff;
}

.hljs-punctuation,
.hljs-tag {
  color: var(--primary);
}

.hljs-deletion,
.hljs-number,
.hljs-quote,
.hljs-selector-class,
.hljs-selector-id,
.hljs-string,
.hljs-template-tag,
.hljs-type {
  color: #ffa8a8;
}

/* =============
   Calendar
============= */
.app-fullcalender button {
  border-radius: 0rem;
  color: #6e6e6e;
}

.app-fullcalender td {
  border-color: #E6E6E6;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 1.875rem;
}

.fc-toolbar {
  margin-bottom: 0.3125rem;
  margin-top: 0.9375rem;
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-left {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    float: none;
  }
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-right {
    display: flex;
    justify-content: center;
    float: none;
    margin-bottom: 0.3125rem;
  }
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-center {
    display: flex;
    justify-content: center;
  }

  .fc-toolbar .fc-center * {
    float: none;
  }
}

.fc-toolbar h2,
.fc-toolbar .h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
  box-shadow: none;
}

.fc-widget-header {
  border: 0.0625rem solid #E6E6E6;
  border-bottom: 0 !important;
}

.fc th.fc-widget-header {
  background: #E6E6E6 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase;
}

.fc-button {
  border: 0.0625rem solid #E6E6E6;
  text-transform: capitalize;
}

.fc-button.active {
  box-shadow: none !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-event,
.external-event {
  border-radius: 0.125rem;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 0.3125rem 0.4375rem;
  padding: 0.3125rem;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 0.625rem 0;
  padding: 0.125rem 0;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 0.3125rem;
}

.fc-basic-view td.fc-day-number {
  padding-right: 0.3125rem;
}

#drop-remove {
  margin-right: 0.5rem;
  top: 0.1875rem;
}

#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 37.5rem;
}

.fc-content {
  color: #fff;
}

.fc th.fc-widget-header {
  background: transparent !important;
}

.fc-button {
  background: #fff;
}

.fc-state-hover {
  background: #fff !important;
}

.fc-state-highlight {
  background: #f2f4fa !important;
}

[data-theme-version="dark"] .fc-state-highlight {
  color: #fff !important;
}

.fc-cell-overlay {
  background: #fff !important;
}

.fc-unthemed .fc-today {
  background: #f2f4fa !important;
}

.fc-day-top {
  color: #6e6e6e !important;
}

[data-theme-version="dark"] .fc-day-top {
  color: #fff !important;
}

.external-event {
  color: #fff;
}

[data-theme-version="dark"] .external-event {
  color: #fff !important;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 1rem;
}

.fc-scroller.fc-day-grid-container {
  height: 30.625rem !important;
}

.fc-row.fc-week.fc-widget-content.fc-rigid {
  height: 5.0625rem !important;
}

@media only screen and (max-width: 90rem) {
  .email_left_pane {
    display: none;
  }
}

#external-events .external-event:before {
  content: "";
  display: block;
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  margin-right: .9rem;
  position: relative;
  top: 0.125rem;
}

[data-theme-version="dark"] #external-events [data-class="bg-primary"] {
  color: #fff !important;
}

#external-events [data-class="bg-primary"]::before {
  background: var(--primary);
}

#external-events [data-class="bg-success"]::before {
  background: #3AC977;
}

#external-events [data-class="bg-warning"]::before {
  background: #FF9F00;
}

#external-events [data-class="bg-dark"]::before {
  background: #6e6e6e;
}

#external-events [data-class="bg-danger"]::before {
  background: #FF5E5E;
}

#external-events [data-class="bg-info"]::before {
  background: #58bad7;
}

#external-events [data-class="bg-pink"]::before {
  background: #e83e8c;
}

#external-events [data-class="bg-secondary"]::before {
  background: #2C2C2C;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  border-color: #E6E6E6;
}

[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton table,
[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton td,
[data-theme-version="dark"] .fc .fc-row .fc-helper-skeleton td {
  border-color: #444444;
}

[data-theme-version="dark"] .fc-unthemed .fc-content,
[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-list-view,
[data-theme-version="dark"] .fc-unthemed .fc-popover,
[data-theme-version="dark"] .fc-unthemed .fc-row,
[data-theme-version="dark"] .fc-unthemed tbody,
[data-theme-version="dark"] .fc-unthemed td,
[data-theme-version="dark"] .fc-unthemed th,
[data-theme-version="dark"] .fc-unthemed thead {
  border-color: #444444;
}

.email-left-box {
  border-right: 1px solid #fff;
  height: calc(100vh - 120px);
  padding: 1.875rem 1.25rem;
  overflow-y: auto;
  border-right: 1px solid #E6E6E6;
}

@media only screen and (max-width: 35.9375rem) {
  .email-left-box {
    padding-left: 20px !important;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .email-left-box {
    border-right: 0;
    height: calc(100vh - 204.2px);
  }
}

.email-left-box .btn.text-white.btn-block {
  background-color: var(--secondary);
  font-weight: 500;
  font-size: 0.938rem;
  white-space: nowrap;
}

@media only screen and (max-width: 47.9375rem) {
  .email-left-box {
    width: 100%;
    float: none;
    border: none;
    padding-bottom: 1.875rem !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .email-left-box {
    padding-bottom: 0rem !important;
  }
}

.email-left-box .intro-title {
  padding: 1rem;
  margin: 1.875rem 0 1.25rem 0;
}

.email-left-box .intro-title h5,
.email-left-box .intro-title .h5 {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
}

.email-left-box .intro-title h5 i,
.email-left-box .intro-title .h5 i {
  font-size: 0.75rem;
  position: relative;
  bottom: 0.0625rem;
}

.email-left-box .intro-title i {
  color: var(--primary);
}

.email-left-box .mail-list {
  margin-top: 2.188rem;
}

.email-left-box .mail-list a {
  border: 0;
  vertical-align: middle;
  padding: 0.625rem 0.9375rem;
  display: block;
  background: transparent;
  color: #000;
  font-weight: 600;
  z-index: 1;
}

.email-left-box .mail-list .change {
  color: #666666;
  font-weight: 500;
}

.email-left-box .mail-list .list-group-item {
  border-radius: 10px;
  padding: 0.75rem 1.0625rem;
  font-size: 0.938rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
  overflow: hidden;
}

.email-left-box .mail-list .list-group-item i {
  font-size: 1.125rem;
  padding-right: 1.125rem;
  color: #000;
}

.email-left-box .mail-list .list-group-item svg {
  margin-right: 1.125rem;
}

.email-left-box .mail-list .list-group-item.active {
  color: #2C2C2C;
  background-color: var(--rgba-secondary-1);
}

.email-left-box .mail-list .list-group-item.active span.badge {
  background-color: var(--secondary);
}

.email-left-box .mail-list .list-group-item.active i {
  color: var(--secondary);
}

.email-left-box .mail-list .list-group-item.change {
  margin-bottom: 0;
}

.email-left-box .mail-list .list-group-item::before {
  content: '';
  display: block;
  background-color: var(--rgba-secondary-1);
  color: var(--secondary);
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.email-left-box .mail-list .list-group-item:hover {
  color: var(--secondary);
}

.email-left-box .mail-list .list-group-item:hover::before {
  width: 100%;
}

.email-left-box .mail-list .list-group-item:hover i {
  color: var(--secondary);
}

.email-left-box .mail-list .list-group-item:hover svg path {
  fill: var(--secondary);
  stroke: var(--secondary);
}

@media only screen and (max-width: 35.9375rem) {
  .email-left-box {
    height: calc(100vh - 160px);
  }
}

input#checkAll.form-check-input {
  background-color: #fff;
}

[data-theme-version="dark"] input#checkAll.form-check-input {
  background-color: #1E1E1E;
}

input#checkAll.form-check-input:checked {
  background-color: var(--primary);
}

.email-right-box .pagination {
  padding: 0.4rem 0;
  margin-bottom: 0rem;
  background-color: #E6EBEE;
  padding-left: 16px;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .email-right-box {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0 !important;
    clear: both;
  }
}

@media (max-width: 575.98px) {
  .email-right-box {
    padding-left: 0;
    padding-right: 0;
  }
}

.email-right-box .right-box-border {
  border-right: 0.125rem solid var(--rgba-primary-1);
}

@media screen and (min-width: 40.5625rem) and (max-width: 75rem) {
  .email-right-box .right-box-padding {
    padding-left: 1.25rem;
  }
}

@media (min-width: 106.25rem) {
  .email-right-box .right-box-padding {
    padding-left: 0.9375rem;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .email-right-box .right-box-padding {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .email-right-box .right-box-padding {
    padding: 1rem;
  }
}

.email-right-box .table-pagenation {
  background-color: #e6ebee;
  margin-top: 0;
  border: 1px solid #fff;
  border-width: 1px 0w;
  margin-left: -4px;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbar {
  height: 3.563rem;
}

@media only screen and (max-width: 74.9375rem) {
  .toolbar {
    height: auto;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .toolbar {
    border-top: 1px solid #fff;
    padding-top: 10px;
  }
}

.toolbar .btn-group .btn.nav-link.active {
  border: 0;
  background-color: transparent;
  font-size: 14px;
  padding: 0.5rem 0rem;
  color: var(--primary);
  box-shadow: unset;
}

@media only screen and (max-width: 74.9375rem) {
  .toolbar .btn-group .btn.nav-link.active {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .toolbar .btn-group .btn.nav-link.active {
    padding: 0.3rem 0.3rem;
  }

  .toolbar .btn-group .btn.nav-link.active .btn {
    font-size: 0.613rem;
  }
}

.toolbar .btn-group .btn.nav-link.active::before {
  content: '';
  display: block;
  background-color: var(--primary);
  position: absolute;
  bottom: -0.325rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 3px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 87.5rem) {
  .toolbar .btn-group .btn.nav-link.active::before {
    display: none;
  }
}

.toolbar .btn-group .btn.nav-link.active:focus {
  box-shadow: none;
}

.toolbar .btn-group .btn.nav-link.active svg path {
  fill: var(--primary);
}

.toolbar .btn-group .btn.nav-link::before {
  content: '';
  display: block;
  background-color: var(--primary);
  position: absolute;
  bottom: -0.325rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0%;
  height: 3px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.toolbar .btn-group .btn.nav-link:hover::before {
  width: 100%;
}

.toolbar .btn-group .btn.nav-link:hover svg path {
  fill: var(--primary);
}

.toolbar .btn-group .btn.nav-link svg {
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}

.toolbar .btn-group input {
  position: relative;
  top: 0.125rem;
}

.saprat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
}

.saprat .btn-group {
  margin-left: 1.125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .saprat .btn-group {
    margin-left: 0;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .saprat {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .saprat {
    display: inline-block;
    width: 97%;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .saprat .mail-tools {
    margin-left: 3.725rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .saprat .mail-tools {
    margin-left: 2.025rem;
  }
}

.saprat .mail-tools a:hover svg {
  background: var(--rgba-primary-1);
  border-radius: 0.375rem;
}

.read-content textarea {
  height: 9.375rem;
  padding: 0.9375rem 1.25rem;
  border-radius: 0.375rem;
}

.read-content-email {
  font-size: 0.875rem;
}

.read-content h5,
.read-content .h5 {
  color: #6a707e;
}

.read-content p strong {
  color: #6a707e;
}

.read-content-body p {
  margin-bottom: 1.875rem;
}

.read-content-attachment h6,
.read-content-attachment .h6 {
  font-size: 1.125rem;
  color: #6a707e;
}

.read-content-attachment h6 i,
.read-content-attachment .h6 i {
  padding-right: 0.3125rem;
}

.read-content-attachment .attachment a {
  color: var(--primary) !important;
}

.read-content-attachment .attachment>div:not(:last-child) {
  border-right: 0.0625rem solid #DDDFE1;
}

.compose-wrapper {
  height: calc(100vh - 180px);
  overflow-y: scroll;
  padding: 0 1rem;
}

@media only screen and (max-width: 61.9375rem) {
  .compose-wrapper {
    height: calc(100vh - 294px);
  }
}

.read-wapper {
  height: calc(100vh - 180px);
  padding: 0 1.25rem;
}

.compose-content .wysihtml5-toolbar {
  border-color: #fff;
}

.compose-content .dropzone {
  background: #fff !important;
}

.compose-content h5,
.compose-content .h5 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.compose-content h5 i,
.compose-content .h5 i {
  font-size: 1.125rem;
  transform: rotate(90deg);
}

.compose-content .dropzone {
  border: 0.0625rem dashed #DDDFE1;
  min-height: 13.125rem;
  position: relative;
  border-radius: 0.375rem;
}

.compose-content .dropzone .dz-message {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.compose-content #email-compose-editor {
  height: unset;
}

.email-list {
  display: block;
  padding-left: 0;
  background: #fff;
  height: calc(100vh - 212px);
  overflow-y: scroll;
}

.email-list label {
  margin-bottom: 0px;
}

.email-list .message {
  position: relative;
  display: block;
  height: 3.438rem;
  padding: 0 15px;
  line-height: 3.438rem;
  cursor: default;
  transition-duration: 0.3s;
  border-bottom: 1px solid #fff;
}

.email-list .message a {
  color: var(--primary);
}

.email-list .message-single .custom-checkbox {
  margin-top: 0.125rem;
}

.email-list .message-single i {
  color: #89879f;
  font-size: 1.125rem;
  padding-left: .4rem;
}

.email-list .message .icon {
  position: absolute;
  top: 16px;
  right: 10px;
  opacity: 0;
  transition-duration: 0.3s;
}

@media only screen and (max-width: 35.9375rem) {
  .email-list .message .icon {
    display: none;
  }
}

.email-list .message:hover {
  transition-duration: 0.05s;
  background: #F3F5F7;
}

.email-list .message:hover .icon {
  opacity: 1;
}

.email-list .message:hover .col-mail-2 .date {
  opacity: 0;
}

.email-list .message:hover .message-single .form-check-input {
  opacity: 1;
}

.email-list .message .col-mail {
  float: left;
  position: relative;
}

.email-list .message .col-mail-1 {
  width: 5.625rem;
}

.email-list .message .col-mail-1 .star-toggle {
  display: block;
  float: left;
  margin-top: 1.125rem;
  font-size: 1rem;
  margin-left: 0.3125rem;
}

.email-list .message .col-mail-1 .email-checkbox {
  display: block;
  float: left;
  margin: 0.9375rem 0.625rem 0 1.25rem;
}

.email-list .message .col-mail-1 .dot {
  display: block;
  float: left;
  border: .4rem solid transparent;
  border-radius: 6.25rem;
  margin: 1.375rem 1.625rem 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.email-list .message .col-mail-2 {
  position: absolute;
  top: 0;
  left: 5.625rem;
  right: 0;
  bottom: 0;
}

.email-list .message .col-mail-2 .subject {
  position: absolute;
  top: 0;
  left: 0;
  right: 5.5rem;
  font-weight: 400;
  color: var(--secondary);
  margin: 0 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.email-list .message .col-mail-2 .subject span {
  color: #666666;
}

.email-list .message .col-mail-2 .date {
  position: absolute;
  top: 0;
  right: 15px;
  opacity: 1;
  transition-duration: 0.3s;
  font-weight: 500;
}

.email-list .message .message-single .bookmark-btn input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  display: none;
}

.email-list .message .message-single .bookmark-btn .checkmark {
  font-weight: 900;
  font-family: 'font awesome 5 free';
}

.email-list .message .message-single .bookmark-btn .checkmark:before {
  content: "\f005";
  font-size: 1.25rem;
  border-color: #666666;
  font-weight: 100;
  color: #C4C4C4;
}

.email-list .message .message-single .bookmark-btn input:checked~.checkmark:before {
  color: #ff9838;
  font-weight: 900;
}

.email-list .message .message-single .form-check-input {
  margin-top: 0;
}

.email-list .message .hader {
  font-size: 0.938rem;
  font-weight: 400;
  padding: 0 1.25rem;
}

.email-checkbox {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  position: relative;
  display: inline-block;
  border-radius: .1rem;
  position: relative;
  top: 0.3125rem;
}

.email-checkbox input {
  opacity: 0;
  cursor: pointer;
}

.email-checkbox input:checked label {
  opacity: 1;
}

.email-checkbox label {
  position: absolute;
  top: .3rem;
  left: .3rem;
  right: .3rem;
  bottom: .3rem;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0 !important;
  transition-duration: 0.05s;
}

.chat-wrap {
  padding: 1.0625rem 1.875rem;
}

.chat-wrap .media .media-body h6,
.chat-wrap .media .media-body .h6 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.chat-wrap .media .media-body p {
  font-size: 0.875rem;
}

@media (min-width: 40.5rem) {
  .email-filter {
    padding-left: 1.25rem;
  }
}

@media (min-width: 106.25rem) {
  .email-filter {
    padding-left: 1.875rem;
  }
}

.email-filter .input-group-prepend i {
  font-size: 0.875rem;
}

.email-filter .input-group-prepend .input-group-text {
  border: 0;
  border-bottom: 0.0625rem solid #DDDFE1 !important;
  background: transparent;
}

.email-filter .input-group .form-control {
  padding: 0 0 0 0.3125rem;
  border: 0;
  font-size: 0.875rem;
  height: 1.875rem;
  border-bottom: 0.0625rem solid #DDDFE1;
}

.email-filter .input-group .form-control::placeholder {
  font-size: 0.875rem;
}

.email-filter .input-group>.form-control {
  min-height: 1.875rem;
}

.single-mail {
  display: block;
  padding: 1.5625rem 0;
}

.single-mail .media {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 106.25rem) {
  .single-mail .media {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.single-mail .media img {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 50%;
  margin-right: 0.9375rem;
}

@media (min-width: 106.25rem) {
  .single-mail .media img {
    margin-right: 1.875rem;
  }
}

.single-mail .media-body h6,
.single-mail .media-body .h6 {
  color: #abafb3;
}

.single-mail .media-body h4,
.single-mail .media-body .h4 {
  font-size: 1rem;
  color: #6a707e;
}

.single-mail .media-body h4 button i,
.single-mail .media-body .h4 button i {
  font-size: 1.125rem;
  color: #abafb3;
  font-weight: bold;
  transform: rotate(90deg);
}

.single-mail .media-body p {
  font-size: 0.875rem;
  color: #abafb3;
}

.single-mail.active {
  background: var(--primary);
}

.single-mail.active h6,
.single-mail.active .h6,
.single-mail.active h4,
.single-mail.active .h4,
.single-mail.active p,
.single-mail.active i {
  color: #fff !important;
}

[direction="rtl"] .email-right-box {
  padding-left: 1rem;
  padding-right: 15rem;
}

@media only screen and (max-width: 61.9375rem) {
  [direction="rtl"] .email-right-box {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .email-right-box {
    padding-left: 0;
    padding-right: 0;
  }
}

[direction="rtl"] .email-left-box {
  float: right;
}

[direction="rtl"] .email-list .message .col-mail-2 {
  right: 5.625rem;
  left: 0;
  float: right;
}

[direction="rtl"] .email-list .message .col-mail-2 .date {
  right: auto;
  left: 0;
}

[direction="rtl"] .email-list .message .col-mail-2 .subject {
  right: 0;
  left: 5.5rem;
}

.inbox-height {
  min-height: 800px !important;
}

@media only screen and (max-width: 35.9375rem) {
  .effect.nav-link {
    font-size: 0.75rem !important;
    padding: 0.3rem 0.3rem !important;
  }
}

.tagify__tag {
  background-color: #e5e5e5;
}

@media only screen and (max-width: 35.9375rem) {
  .email-left-body {
    width: 18rem;
    position: fixed;
    height: 100vh;
    z-index: 2;
    transition: all 0.5s;
    left: -22rem;
    top: 0px;
    z-index: 999;
  }

  .email-left-body.active {
    left: 0;
    background: #fff;
    transition: all 0.5s;
    overflow-y: scroll;
  }
}

.email-tools-box {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: var(--rgba-primary-1);
  border-radius: 0.375rem;
  color: var(--primary);
  display: none !important;
}

.email-tools-box.active {
  background-color: #0D99FF;
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  .email-tools-box {
    display: inline-block !important;
    margin-left: auto;
  }
}

.email-tools-box.active:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  background-color: #000;
  opacity: 0.2;
  border-radius: 0.75rem;
  z-index: 4;
}

.email-left-box {
  margin-bottom: 20px;
}

@media only screen and (max-width: 74.9375rem) {
  .email-left-box {
    margin-right: -10px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .email-left-box {
    margin: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .email-left-box {
    margin-top: 20px;
  }
}

.photo-content {
  position: relative;
}

.photo-content .cover-photo {
  /* background: url(../images/profile/cover.jpg); */
  background-size: cover;
  background-position: center;
  min-height: 15.625rem;
  width: 100%;
}

.profile .profile-photo {
  max-width: 6.25rem;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  margin-right: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .profile .profile-photo {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.25rem;
  }
}

[direction="rtl"] .profile .profile-photo {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 0.9375rem;
}

@media only screen and (max-width: 74.9375rem) {
  [direction="rtl"] .profile .profile-photo {
    right: 0.9375rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .profile .profile-photo {
    width: 5rem;
    right: calc(50% - 2.5rem);
    top: -6.25rem;
  }
}

.profile-info {
  padding: 0.9375rem 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info {
    padding: 0 0 1.25rem;
    text-align: center;
  }
}

.profile-info h4,
.profile-info .h4 {
  color: #464a53 !important;
}

.profile-info h4.text-primary,
.profile-info .text-primary.h4 {
  color: var(--primary) !important;
}

.profile-info p {
  color: #828690;
}

.profile-info .prf-col {
  min-width: 15.625rem;
  padding: 0.625rem 3.125rem 0;
}

.profile-interest .row {
  margin: 0 -0.0437rem;
}

.profile-interest .row .int-col {
  padding: 0 0.0437rem;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 0.0875rem;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.3125rem;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 0.075rem;
}

.profile-tab .nav-item .nav-link {
  font-size: 1rem;
  margin-right: 1.875rem;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.0125rem solid transparent;
  color: #828690;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border: 0;
  background: transparent;
  background: transparent;
  border-bottom: 0.0125rem solid var(--primary);
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .profile-tab .nav-item .nav-link {
    margin-right: 0rem;
  }
}

.profile-info {
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info {
    display: block;
  }
}

.profile-info .profile-details {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info .profile-details {
    display: block;
  }

  .profile-info .profile-details .dropdown {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;
  }
}

.post-input {
  margin-bottom: 1.875rem;
}

.post-input .form-control {
  height: 4.6875rem;
  font-weight: 400;
  margin: 0.9375rem 0;
}

.post-input .btn-social {
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  border-radius: 0.375rem;
  color: #fff;
  width: 3.4375rem;
  line-height: 3.375rem;
}

.post-input .btn-social.facebook {
  background-color: #3B5998;
}

.post-input .btn-social.google-plus {
  background-color: #DE4E43;
}

.post-input .btn-social.linkedin {
  background-color: #007BB6;
}

.post-input .btn-social.instagram {
  background-color: #8A5A4E;
}

.post-input .btn-social.twitter {
  background-color: #1EA1F3;
}

.post-input .btn-social.youtube {
  background-color: #CE201F;
}

.post-input .btn-social.whatsapp {
  background-color: #01C854;
}

.post-input .btn-social i {
  margin: 0 !important;
}

.profile-uoloaded-post img {
  margin-bottom: 1.25rem;
}

.profile-uoloaded-post a h4,
.profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53;
}

.profile-page {
  height: 60px;
}

.card-profile {
  /* background-image: url("../images/crm-profile.jpg"); */
  background-size: cover;
  background-position: center center;
  height: 420px;
  padding: 30px;
}

.card-profile .admin-user {
  background-color: #fff;
  padding: 30px;
  max-width: 380px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  border-radius: 0.375rem;
}

.card-profile .admin-user .img-wrraper {
  position: relative;
  margin-bottom: 10px;
}

.card-profile .admin-user .img-wrraper img {
  height: 80px;
  width: 80px;
  border: 7px solid rgba(62, 95, 206, 0.08);
}

.card-profile .admin-user .img-wrraper a {
  position: absolute;
  bottom: 0;
  margin: 0 24px;
  height: 30px;
  width: 30px;
  background: #fff;
  color: var(--primary);
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
}

.card-profile .user-details .title h4,
.card-profile .user-details .title .h4 {
  font-size: 1rem;
}

.card-profile .user-details .title h6,
.card-profile .user-details .title .h6 {
  color: #888888;
  margin-bottom: 20px;
}

.card-profile .user-social-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-profile .user-social-links a {
  color: var(--primary);
  display: block;
  height: 30px;
  width: 30px;
  background-color: var(--rgba-primary-1);
  text-align: center;
  line-height: 30px;
  margin: 0 4px;
  border-radius: 0.375rem;
}

.card-profile .user-social-links a:hover {
  background: var(--primary);
  color: #fff;
}

.card-profile .follow-list {
  display: flex;
  justify-content: center;
}

.card-profile .follow-list li {
  padding: 14px 20px;
  border-radius: 0.375rem;
  margin: 0 9px;
  margin-top: 44px;
  background: var(--rgba-primary-1);
}

@media only screen and (max-width: 35.9375rem) {
  .card-profile .follow-list li {
    padding: 5px 10px;
  }
}

.card-profile .follow-list .follow-num {
  font-size: 1rem;
  font-weight: 500;
  color: black;
}

.profile-accordion .accordion-item {
  background: transparent;
}

.profile-accordion .accordion-item .accordion-header {
  padding: 0;
  border: 0;
}

.profile-accordion .accordion-item .accordion-header .accordion-button {
  padding: 0;
  background: transparent;
  box-shadow: none;
  font-size: 1rem;
  font-weight: 500;
  color: black;
}

.profile-accordion .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.about-me ul li {
  display: flex;
  align-items: center;
  margin: 15px 0px;
  margin-top: 0;
}

.about-me ul li h6,
.about-me ul li .h6 {
  margin-bottom: 0;
}

.about-me ul li i {
  height: 35px;
  width: 35px;
  background-color: var(--rgba-primary-1);
  color: var(--primary);
  text-align: center;
  line-height: 35px;
  border-radius: 0.375rem;
  margin-right: 15px;
}

.second-head {
  font-size: 1rem;
  font-weight: 500;
  color: black;
}

.bg-facebook {
  background: #4867aa;
  color: white;
}

.bg-facebook:hover {
  background: #4867aa;
  color: white;
}

.bg-whatsapp {
  background: #1ad03f;
  color: white;
}

.bg-whatsapp:hover {
  background: #1ad03f;
  color: white;
}

.bg-skype {
  background: #0194d7;
  color: white;
}

.bg-skype:hover {
  background: #0194d7;
  color: white;
}

.bg-linkedin {
  background: #0a66c2;
  color: white;
}

.bg-linkedin:hover {
  background: #0a66c2;
  color: white;
}

.profile-interest img {
  border-radius: 0.375rem;
}

.post-img img {
  border-radius: 0.375rem;
  width: 100%;
}

@media only screen and (max-width: 87.5rem) {
  .post-img img {
    margin-bottom: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.friend-list .avatar {
  margin-left: 9px;
  margin-bottom: 16px;
}

.chat-border {
  border-right: 1px solid rgba(136, 136, 136, 0.2);
}

.chat-p {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
}

.chat-p h6,
.chat-p .h6 {
  font-size: 14px;
}

.chat-p span {
  font-size: 12px;
  white-space: nowrap;
}

.chat-p.shaprate {
  border-bottom: 1px solid rgba(136, 136, 136, 0.2);
}

.chat-p.style-1 {
  padding-bottom: 0;
}

.chat-p.style-1 .active {
  position: relative;
}

.chat-p.style-1 .active:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background: #3AC977;
  bottom: 0;
  left: 33px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.c-list {
  padding: 1.25rem;
}

.c-list .search-area {
  background-color: transparent !important;
}

.c-list .search-area .form-control {
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.c-list .search-area .input-group-text {
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.people-list {
  height: calc(100vh - 260px);
  margin-bottom: 25px;
}

.chat-height {
  min-height: 1000px !important;
}

.chat-admin {
  height: 0;
}

.chat-admin .sharp {
  line-height: 1.5;
}

@media only screen and (max-width: 35.9375rem) {
  .chat-admin .icon-box {
    height: 2rem;
    width: 2rem;
    border-radius: 5px;
    line-height: 1.9rem;
  }

  .chat-admin .icon-box svg {
    width: 14px;
    line-height: 1.8rem;
  }

  .chat-admin .sharp {
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    padding: 0.2375rem;
  }
}

.chat-box-area.style-2 {
  padding: 1.25rem;
  height: calc(100vh - 260px);
  margin-top: 20px;
}

@media only screen and (max-width: 35.9375rem) {
  .chat-box-area.style-2 {
    height: calc(100vh - 230px);
  }
}

.message-send.style-2 {
  margin: 1.25rem;
}

.chat-meadia {
  padding: 1.25rem;
}

@media only screen and (max-width: 74.9375rem) {
  .chat-meadia {
    width: 50%;
    float: left;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .chat-meadia {
    width: 100%;
  }
}

.image-list {
  margin-top: 10px;
}

.image-list img {
  height: 75px;
  width: 75px;
  object-fit: cover;
  border-radius: 6px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.file-list h5,
.file-list .h5 {
  margin-top: 10px;
}

.file-list .filie-l-icon {
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  max-width: 164px;
}

@media only screen and (max-width: 74.9375rem) {
  .file-list .filie-l-icon {
    max-width: 100%;
  }
}

.file-list .filie-l-icon:hover {
  background: var(--rgba-primary-1);
}

.file-list .filie-l-icon img {
  width: 46px;
  height: 45px;
}

@media only screen and (max-width: 87.5rem) {
  .file-list {
    height: 300px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .chat-left-area {
    width: 22rem;
    position: absolute;
    z-index: 2;
    transition: all 0.2s;
    left: -22rem;
  }

  .chat-left-area.active {
    left: 0;
    background: white;
  }
}

.chat-toggle {
  display: none !important;
}

@media only screen and (max-width: 35.9375rem) {
  .chat-toggle {
    display: inline-block !important;
  }
}

.chat-toggle:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -50vw;
  width: 100vw;
  height: 100vh;
}

.chat-toggle.active:before {
  z-index: 1;
}

.event-chat-ryt .chat-area .chat-reciver,
.event-chat-ryt .chat-area .chat-sender {
  margin-bottom: 1.875rem;
  padding: 0;
}

.event-chat-ryt .chat-area .chat-reciver img,
.event-chat-ryt .chat-area .chat-sender img {
  border-radius: 1.875rem;
}

.event-chat-ryt .chat-area .media {
  position: relative;
}

.event-chat-ryt .chat-area .media-body p {
  margin: 0;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.event-chat-ryt .chat-area .media-body p span {
  padding: 1rem;
  display: inline-block;
  top: 103%;
  position: relative;
  border: 0.0625rem solid #E6E6E6;
}

.event-chat-ryt .chat-reciver {
  padding: .5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.event-chat-ryt .chat-reciver .media {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-chat-ryt .chat-reciver .media .media-body {
  margin-right: 2rem;
  text-align: right;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body {
  text-align: left;
  margin-left: 2rem;
  margin-right: auto;
}

.event-chat-ryt .chat-reciver .media .media-body p {
  background: #fff;
  margin-bottom: 0;
  border-radius: 0.3125rem 0.3125rem 0 0.3125rem;
}

.event-chat-ryt .chat-reciver .media .media-body p span {
  text-align: left;
  border: 0.0625rem solid #E6E6E6;
}

.event-chat-ryt .chat-reciver .media .media-body p span::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-bottom: 0.0625rem solid #E6E6E6;
  border-right: 0.0625rem solid #E6E6E6;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(-45deg) translateX(15px);
  transform: rotate(-45deg) translateX(15px);
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p span::after {
  left: 0;
  right: auto;
  -webkit-transform: rotate(135deg) translateY(15px);
  transform: rotate(135deg) translateY(15px);
}

.event-chat-ryt .chat-reciver .media .media-body p .time {
  position: absolute;
  font-size: 0.75rem;
  color: #888888;
  font-weight: 400;
  bottom: 0;
  left: -5rem;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p .time {
  right: -5rem;
  left: auto;
}

.event-chat-ryt .chat-sender {
  text-align: left;
  padding: .5rem 1rem;
}

.event-chat-ryt .chat-sender .media .media-body {
  margin-left: 2rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body {
  text-align: right;
  margin-right: 2rem;
  margin-left: auto;
}

.event-chat-ryt .chat-sender .media .media-body p {
  background-color: #fff;
  margin-bottom: 0;
}

.event-chat-ryt .chat-sender .media .media-body p span::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-bottom: 0.0625rem solid #E6E6E6;
  border-left: 0.0625rem solid #E6E6E6;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(45deg) translateX(-15px);
  transform: rotate(45deg) translateX(-15px);
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p span::after {
  left: auto;
  right: 0;
  -webkit-transform: rotate(-135deg) translateY(15px);
  transform: rotate(-135deg) translateY(15px);
}

.event-chat-ryt .chat-sender .media .media-body p .time {
  position: absolute;
  font-size: 0.625rem;
  color: #888888;
  font-weight: 400;
  bottom: 0;
  right: -5rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p .time {
  left: -5rem;
  right: auto;
}

.char-type {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.char-type form .form-control {
  height: 2.8125rem;
  padding-left: 1.125rem;
  background: #fff;
  border-right: 0;
}

.char-type form .input-group-append i {
  color: #898989;
  font-size: 1.125rem;
}

.char-type form .input-group-append .input-group-text {
  padding-left: .7rem;
  padding-right: .7rem;
  background: #fff;
  border-color: #E6E6E6;
  border-left: 0;
}

.char-type form .input-group-append .input-group-text:last-child {
  padding-right: 1.8rem;
}

.media-avatar {
  padding: 1.5625rem 0;
  border-bottom: 0.0625rem solid #E6E6E6;
}

.media-avatar:last-child {
  border-bottom: 0rem;
}

.media-avatar p {
  margin-bottom: 0;
}

.media-avatar .avatar-status {
  position: relative;
}

.media-avatar .avatar-status i {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 15.7rem;
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 0.75rem;
  line-height: 1;
}

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 0.625rem;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: var(--primary);
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #3AC977;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #FF9F00;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #FF5E5E;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #58bad7;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #6e6e6e;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #2C2C2C;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #00A2FF;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #ff9800;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 0.625rem;
  padding: 0.125rem 0.625rem;
  border-radius: 0.125rem;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3FC6D4;
}

#donught_graph .ct-series.ct-series-b .ct-slice-donut {
  stroke: #333333;
}

#donught_graph .ct-series.ct-series-c .ct-slice-donut {
  stroke: #F63465;
}

@media only screen and (max-width: 47.9375rem) {
  #pie-chart .ct-label {
    color: #fff;
    fill: #fff;
  }
}

#visitor_graph {
  height: 15.8125rem !important;
}

#user_rating_graph {
  height: 17.5rem !important;
}

#activity {
  height: 20.575rem !important;
}

#visitorOnline {
  height: 4.5rem !important;
}

#trendMeter {
  height: 4.5rem !important;
}

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
  height: 7.3125rem !important;
}

#widget-profit1,
#widget-profit2,
#widget-profit3 {
  height: 10rem !important;
}

#comparison-rate {
  height: 14.375rem;
}

#session_day {
  height: 10.9375rem;
  width: auto !important;
  margin: 0 auto;
}

#walet-status {
  height: 8.75rem;
}

#bar1 {
  height: 9.375rem;
}

#sold-product {
  height: 14.375rem;
}

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
  height: 9.375rem !important;
}

#areaChart_3 {
  height: 18.4375rem !important;
}

.chart-point {
  display: flex;
  align-items: center;
}

.chart-point .check-point-area {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
}

.chart-point .chart-point-list {
  margin: 0;
  padding-left: 0.3125rem;
}

.chart-point .chart-point-list li {
  list-style: none;
  font-size: 0.8125rem;
  padding: 0.125rem 0;
}

.chart-point .chart-point-list li i {
  margin-right: 0.3125rem;
  font-size: 0.6875rem;
  position: relative;
  top: -0.0625rem;
}

.c3 {
  height: 15.625rem;
}

.c3-legend-item {
  fill: #9fabb1;
}

.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line,
.tick text {
  stroke: #fff;
}

.flot-chart {
  height: 15.7rem;
}

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: .5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: .2rem;
}

.legendColorBox>div {
  border: 0 !important;
  padding: 0 !important;
}

.legendLabel {
  font-size: 0.825rem;
  padding-left: .5rem;
  color: var(--primary);
}

[data-theme-version="dark"] .legendLabel {
  color: #fff;
}

.flot-chart .legend table tbody tr:last-child .legendLabel {
  color: #ffaa2b;
}

.flotTip {
  background: #000;
  border: 0.0625rem solid #000;
  color: #fff;
}

.legend>div {
  background: transparent !important;
}

#balance_graph {
  height: 16.25rem;
}

.morris-hover {
  position: absolute;
  z-index: 1;
  background: var(--primary);
  color: #fff;
}

.morris-hover .morris-hover-point {
  color: #fff !important;
  margin: 0.1875rem 0;
  text-align: center;
  padding: 0 1.5625rem;
  color: #fff;
}

.morris-hover .morris-hover-row-label {
  background-color: #6e6e6e;
  text-align: center;
  padding: 0.3125rem;
  color: #fff;
  margin-bottom: 0.3125rem;
}

.morris-hover.morris-default-style {
  border-radius: 0.3125rem;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
}

/* svg text {
    font-weight: 600 !important;
} */
#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
  height: 15rem !important;
}

#morris_line {
  height: 17.375rem !important;
}

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
  height: 9.375rem;
}

#daily-sales,
#comparison-rate,
#usage-chart,
#walet-status {
  width: 100%;
  display: block;
}

#daily-sales canvas,
#comparison-rate canvas,
#usage-chart canvas,
#walet-status canvas {
  max-width: 100% !important;
  width: 100% !important;
}

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
  height: 6.25rem !important;
}

#sparkline11 canvas {
  width: 6.25rem !important;
}

.easy-pie-chart {
  position: relative;
  text-align: center;
}

.easy-pie-chart .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.easy-pie-chart img {
  width: 4.6875rem;
  height: 4.6875rem;
  border-radius: 50%;
}

.easy-pie-chart canvas {
  display: block;
  margin: 0 auto;
}

#revenue-chart {
  height: 27.7rem;
}

#duration-value-axis {
  height: 27.6rem;
  width: 100%;
}

#combined-bullet {
  height: 28.125rem;
  width: 100%;
}

#zoomable-chart {
  height: 28.125rem;
  width: 100%;
}

#chartMap {
  height: 28.125rem;
  width: 100%;
}

#professional-candlesticks {
  width: 100%;
  height: 28.125rem;
}

#comparing-stock-indices {
  width: 100%;
  height: 28.125rem;
}

#multiple-panel-data {
  width: 100%;
  height: 28.125rem;
}

#depth-chart {
  width: 100%;
  height: 28.125rem;
}

.amcharts-export-menu {
  display: none;
}

.amcharts-data-set-selector-div {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 16.875rem;
  margin: 0 auto;
}

.amcharts-data-set-selector-div select {
  border: 0;
  margin-left: 0.625rem;
  background: #ddd;
  color: #000;
}

.amChartsInputField {
  border: 0;
  background: var(--primary);
  color: #fff;
  padding: 0.3125rem 0.9375rem;
  margin: 0 0.9375rem;
}

.amcharts-data-set-select {
  border: 0;
  background: #ddd;
  color: #000;
}

.amcharts-period-input,
.amcharts-period-input-selected {
  border: 0;
  margin-left: 0.625rem;
  background: var(--primary);
  color: #fff;
  padding: 0.3125rem 0.9375rem;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 0.1875rem 0.1875rem;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem;
  }
}

.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem;
  }
}

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem;
  }
}

.amcharts-graph-column-front {
  -webkit-transition: all .3s .3s ease-out;
  transition: all .3s .3s ease-out;
}

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .amChartsPeriodSelector>fieldset>div {
    float: none !important;
    display: block !important;
    margin-bottom: 0.625rem;
  }
}

.highcharts-root text {
  font-weight: 300 !important;
}

.highcharts-credits {
  display: none;
}

#chart_employee_gender,
#chart_employee_status {
  width: auto;
  height: 21.875rem;
}

.form-control {
  background: #fff;
  color: #6e6e6e;
  line-height: 1.5;
  font-size: 13px;
  height: 3rem;
  border-radius: 5px;
  border-color: #CCCCCC;
}

#search-inp {
  box-shadow: none;
}

.form-control:hover,
.form-control:focus,
.form-control.active {
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11);
  background: #fff;
  color: #6e6e6e;
}

/* .form-control:focus {
  border-color: var(--primary);
} */

.form-control.solid {
  background: #fff;
  border-color: #ececec;
  color: #000;
}

.form-control.solid[type="file"] {
  padding: 6px 10px;
}

.form-control[type="file"] {
  padding: 0.175rem 0.75rem;
  line-height: 2.7;
}

.input-rounded {
  border-radius: 6.25rem;
}

[data-theme-version="light"] .input-primary .form-control,
[data-theme-version="dark"] .input-primary .form-control {
  border-color: var(--primary);
}

[data-theme-version="light"] .input-primary .input-group-text,
[data-theme-version="dark"] .input-primary .input-group-text {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="light"] .input-danger .form-control,
[data-theme-version="dark"] .input-danger .form-control {
  border-color: #FF5E5E;
}

[data-theme-version="light"] .input-danger .input-group-text,
[data-theme-version="dark"] .input-danger .input-group-text {
  background-color: #FF5E5E;
  color: #fff;
}

[data-theme-version="light"] .input-info .form-control,
[data-theme-version="dark"] .input-info .form-control {
  border-color: #58bad7;
}

[data-theme-version="light"] .input-info .input-group-text,
[data-theme-version="dark"] .input-info .input-group-text {
  background-color: #58bad7;
  color: #fff;
}

[data-theme-version="light"] .input-success .form-control,
[data-theme-version="dark"] .input-success .form-control {
  border-color: #3AC977;
}

[data-theme-version="light"] .input-success .input-group-text,
[data-theme-version="dark"] .input-success .input-group-text {
  background-color: #3AC977;
  color: #fff;
}

[data-theme-version="light"] .input-warning .form-control,
[data-theme-version="dark"] .input-warning .form-control {
  border-color: #FF9F00;
}

[data-theme-version="light"] .input-warning .input-group-text,
[data-theme-version="dark"] .input-warning .input-group-text {
  background-color: #FF9F00;
  color: #fff;
}

[data-theme-version="light"] .input-primary-o .form-control,
[data-theme-version="dark"] .input-primary-o .form-control {
  border-color: var(--primary);
}

[data-theme-version="light"] .input-primary-o .input-group-text,
[data-theme-version="dark"] .input-primary-o .input-group-text {
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

[data-theme-version="light"] .input-danger-o .form-control,
[data-theme-version="dark"] .input-danger-o .form-control {
  border-color: #FF5E5E;
}

[data-theme-version="light"] .input-danger-o .input-group-text,
[data-theme-version="dark"] .input-danger-o .input-group-text {
  background-color: transparent;
  border-color: #FF5E5E;
  color: #FF5E5E;
}

[data-theme-version="light"] .input-info-o .form-control,
[data-theme-version="dark"] .input-info-o .form-control {
  border-color: #58bad7;
}

[data-theme-version="light"] .input-info-o .input-group-text,
[data-theme-version="dark"] .input-info-o .input-group-text {
  background-color: transparent;
  border-color: #58bad7;
  color: #58bad7;
}

[data-theme-version="light"] .input-success-o .form-control,
[data-theme-version="dark"] .input-success-o .form-control {
  border-color: #3AC977;
}

[data-theme-version="light"] .input-success-o .input-group-text,
[data-theme-version="dark"] .input-success-o .input-group-text {
  background-color: transparent;
  border-color: #3AC977;
  color: #3AC977;
}

[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="dark"] .input-warning-o .form-control {
  border-color: #FF9F00;
}

[data-theme-version="light"] .input-warning-o .input-group-text,
[data-theme-version="dark"] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: #FF9F00;
  color: #FF9F00;
}

.input-group-text {
  min-width: 3.125rem;
  justify-content: center;
  border-radius: 0.375rem;
  border-color: #CCCCCC;
}

.form-file-label {
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}

.custom-select {
  background: none;
  border-color: #E6E6E6;
  color: #6e6e6e;
}

.custom-select:focus {
  box-shadow: none;
  border-color: var(--primary);
  color: var(--primary);
}

.form-file-label {
  background: #656C73;
  white-space: nowrap;
  color: #fff;
}

[data-theme-version="dark"] .form-file-label {
  background: #444444;
  border-color: #444444;
  color: #888888;
}

.custom_file_input .form-file-label::after {
  height: 100%;
}

.form-control:disabled,
.form-control[readonly] {
  background: #fff;
  opacity: 1;
}

.form-file {
  border: 0.0625rem solid #E6E6E6;
  background: #fff;
}

[data-theme-version="dark"] .form-file {
  background: #1E1E1E;
  border-color: #444444;
}

.input-group {
  /* &> .form-file {
		display: flex;
		align-items: center;

		&:not(:last-child) .form-file-label,
		&:not(:last-child) .form-file-label::after { @include border-right-radius(0); }
		&:not(:first-child) .form-file-label { @include border-left-radius(0); }
	  } */
}

.input-group>.form-control-plaintext,
.input-group>.form-select,
.input-group>.form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0.375rem;
}

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto;
}

#validationCustomUsername {
  border-radius: 0 0.5rem 0.5rem 0;
}

.show-pass {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto;
}

.input-group .input-group-text+.form-file {
  border-radius: 0 0.5rem 0.5rem 0;
}

.input-group .input-group-text+.form-file .form-control {
  border-radius: inherit;
}

.custom_file_input .form-file {
  border-radius: 0.5rem;
}

.custom_file_input .form-file .form-control {
  margin: 0;
  border: 0;
  line-height: 2.625rem;
  border-radius: inherit;
  font-size: 16px;
  font-weight: 600;
}

.custom_file_input .form-control {
  padding-left: 10px;
  line-height: 45px;
}

#username {
  border-radius: 0 0.5rem 0.5rem 0;
}

.form-control-lg {
  min-height: 3.75rem;
  padding: 0.5rem 0.75rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
  height: calc(1.5em + 1rem + 2px);
}

.form-control-sm {
  min-height: 2.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.5rem;
  height: calc(1.5em + 0.5rem + 2px);
}

.form-control-sm[type="file"] {
  line-height: 2.5;
  padding: 0.257rem 0.5rem;
}

.ck.ck-editor__main>.ck-editor__editable {
  background: #fafafa !important;
}

.modal-grid {
  background: #fff;
}

.nice-select.wide.form-control-lg {
  line-height: 2.7125rem;
}

.nice-select.wide.form-control-sm {
  line-height: 1.8125rem;
}

.inline-check {
  line-height: 1.8rem !important;
}

.form-check {
  font-size: 14px;
  font-weight: 600;
}

.input-full {
  border: 0;
}

.border-1 {
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.border3 {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.custome-input .input-group-text {
  border-color: transparent !important;
}

.p2p-select .default-select {
  height: 3rem;
}

.input-group-lg .input-group-text {
  border-radius: 0.375rem;
}

.input-group-sm .input-group-text {
  border-radius: 0.375rem;
}

textarea.form-control {
  min-height: auto;
  height: auto !important;
  padding: 0.75rem;
}

.form-control[type="file"] {
  line-height: 2.375rem;
  padding-left: 0.75rem;
}

.form-control-sm[type="file"] {
  line-height: 2rem;
  padding: 0.25rem;
  padding-left: 8px;
}

.form-control-lg[type="file"] {
  line-height: 3.25rem;
  padding-left: 0.625rem;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

/* input[type="checkbox"] {
    &:after{
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-top: 0px;
        margin-left: -1px;
        border: 1px solid transparent;
        border-radius: 3px;
        background: #d4d7da;
        line-height: 1.3;
    }

    &:checked:after{
        width: 1rem;
        height: 1rem;
        display: block;
        content: "\f00c";
        font-family: 'FontAwesome';
        color: $white;
        font-weight: 100;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        background: $primary;
    }

} */
.form-check-label {
  margin-left: 0.3125rem;
  margin-top: 0.1875rem;
}

.form-check-inline .form-check-input {
  margin-right: 0.625rem;
}

.form-check-input {
  top: 0.125rem;
  border-width: 1px;
  width: 1.25rem;
  height: 1.25rem;
  border-color: #e7e7e7;
}

.rtl .form-check-label:before,
.rtl .form-check-label:after {
  right: -1.5rem !important;
  left: inherit;
}

.form-check {
  line-height: normal;
}

.toggle-switch {
  padding-left: 3.125rem;
  line-height: 1.25;
  display: inline-block;
  color: #000;
  font-weight: 600;
}

.toggle-switch .form-check-input {
  border: 0;
  cursor: pointer;
  background: #d8d8d8;
  width: 2.3125rem;
  border-radius: 1.25rem !important;
  height: 0.875rem;
  position: relative;
  left: -0.3125rem;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  background-image: none;
}

.toggle-switch .form-check-input:focus {
  background-image: none !important;
}

.toggle-switch .form-check-input:checked {
  background: var(--rgba-primary-2);
  background-image: none !important;
}

.toggle-switch .form-check-input:checked:after {
  left: 1.25rem;
  background: var(--primary);
}

.toggle-switch .form-check-input:focus {
  box-shadow: none;
}

.toggle-switch .form-check-input:after {
  width: 1.25rem;
  background: #909090;
  height: 1.25rem;
  content: "";
  position: absolute;
  border-radius: 1.5rem;
  top: -0.1875rem;
  left: 0;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.toggle-switch.text-end {
  padding-right: 0rem;
  padding-left: 0;
}

.toggle-switch.text-end .form-check-input {
  left: auto;
  margin-left: 0;
  float: right;
  right: 0rem;
}

.toggle-switch.text-end .form-check-label {
  margin-right: 0.9375rem;
  margin-left: 0;
}

.toggle-switch .form-check-label {
  cursor: pointer;
}

.form-check-input:focus~.form-check-label::before {
  box-shadow: none !important;
}

.form-check-label::before {
  background-color: transparent;
  border-color: #dfdfdf;
  border-width: 0.125rem;
  border-radius: 0.125rem !important;
}

[data-theme-version="dark"] .form-check-label::before {
  background-color: transparent;
  border-color: #444444;
}

.check-xs .form-check-input {
  width: 1.125rem;
  height: 1.125rem;
}

.check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.check-xl .form-check-input {
  width: 1.75rem;
  height: 1.75rem;
}

.checkbox-info .form-check-input:focus {
  border-color: #58bad7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(88, 186, 215, 0.25);
}

.checkbox-info .form-check-input:checked {
  background-color: #58bad7;
  border-color: #58bad7;
}

.checkbox-danger .form-check-input:focus {
  border-color: #FF5E5E;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 94, 94, 0.25);
}

.checkbox-danger .form-check-input:checked {
  background-color: #FF5E5E;
  border-color: #FF5E5E;
}

.checkbox-success .form-check-input:focus {
  border-color: #3AC977;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(58, 201, 119, 0.25);
}

.checkbox-success .form-check-input:checked {
  background-color: #3AC977;
  border-color: #3AC977;
}

.checkbox-warning .form-check-input:focus {
  border-color: #FF9F00;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 159, 0, 0.25);
}

.checkbox-warning .form-check-input:checked {
  background-color: #FF9F00;
  border-color: #FF9F00;
}

.checkbox-secondary .form-check-input:focus {
  border-color: #2C2C2C;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(44, 44, 44, 0.25);
}

.checkbox-secondary .form-check-input:checked {
  background-color: #2C2C2C;
  border-color: #2C2C2C;
}

.check-switch {
  padding-left: 2.5rem;
}

.check-switch .form-check-label {
  line-height: 1.875rem;
  font-weight: 500;
}

.check-switch .form-check-label span {
  line-height: 1;
}

.check-switch .form-check-label:after,
.check-switch .form-check-label:before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 3rem !important;
  border-color: var(--rgba-primary-3);
}

.check-switch .form-check-input:checked~.form-check-label::after {
  /* background-image: url("../page-error-404.html"); */
}

.check-switch .form-check-input:checked~.form-check-label::before {
  background: #fff;
}

.form-switch .form-check-input {
  width: 2.5rem;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input:focus {
  border-color: var(--primary);
  box-shadow: var(--rgba-primary-1);
}

.js-switch+.switchery {
  border-radius: 3.125rem;
  margin-right: 4rem;
}

@media (max-width: 767.98px) {
  .js-switch+.switchery {
    margin-right: 1rem;
  }
}

.js-switch+.switchery>small,
.js-switch+.switchery>.small {
  top: 0.125rem;
}

.js-switch.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch.js-switch-lg+.switchery>small,
.js-switch.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch.js-switch-md+.switchery>small,
.js-switch.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch.js-switch-sm+.switchery>small,
.js-switch.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 0.0625rem;
}

.js-switch-square+.switchery {
  border-radius: 0;
}

.js-switch-square+.switchery>small,
.js-switch-square+.switchery>.small {
  border-radius: 0;
  top: 0.125rem;
}

.js-switch-square.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch-square.js-switch-lg+.switchery>small,
.js-switch-square.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch-square.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch-square.js-switch-md+.switchery>small,
.js-switch-square.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch-square.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch-square.js-switch-sm+.switchery>small,
.js-switch-square.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 0.0625rem;
}

.form-control.is-valid {
  border-color: #3AC977 !important;
  border-right: 0rem !important;
}

.form-control.is-valid:focus {
  box-shadow: none;
}

.form-control.is-warning {
  border-color: #FF9F00 !important;
  border-right: 0rem !important;
}

.form-control.is-warning:focus {
  box-shadow: none;
}

.form-control.is-invalid {
  border-color: #FF5E5E !important;
  border-right: 0rem !important;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.is-valid .input-group-prepend .input-group-text i {
  color: #3AC977;
}

.is-invalid .input-group-prepend .input-group-text i {
  color: var(--rgba-primary-2);
}

.show-pass {
  cursor: pointer;
}

.show-pass .fa-eye {
  display: none;
}

.show-pass.active .fa-eye-slash {
  display: none;
}

.show-pass.active .fa-eye {
  display: inline-block;
}

.bleft {
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
}

.asColorPicker-dropdown {
  max-width: 26rem;
}

.asColorPicker-trigger {
  background: none;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1875rem;
}

[direction="rtl"] .asColorPicker-trigger {
  left: 0;
  right: auto;
}

.asColorPicker-trigger span {
  border-radius: 0 0.425rem 0.425rem 0;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: .5rem;
}

.daterangepicker td.active {
  background-color: var(--primary);
}

.daterangepicker td.active:hover {
  background-color: var(--primary);
}

.daterangepicker button.applyBtn {
  background-color: var(--primary);
  border-color: var(--primary);
}

.datepicker.datepicker-dropdown {
  background: #fff;
  border-radius: 0.0625rem;
  border: 0.0625rem solid #EEEEEE;
}

.datepicker.datepicker-dropdown td.day,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  height: 1.875rem;
  width: 1.875rem !important;
  padding: 0;
  text-align: center;
  font-weight: 500;
  border-radius: 0.375rem;
}

.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover {
  box-shadow: unset;
  /*  color: $main-color; */
  background-color: var(--rgba-primary-1);
}

.datepicker.datepicker-dropdown th.datepicker-switch,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  font-weight: 600;
  color: #333;
}

.datepicker.datepicker-dropdown th.dow {
  font-weight: 600;
}

.datepicker table tr td.selected,
.datepicker table tr td.active.active {
  background-color: var(--primary);
  border-radius: 0.375rem;
  border: 0;
  color: #fff;
  background-image: none;
  position: relative;
}

.datepicker table tr td.today {
  border-radius: 0.375rem;
  /* color : $main-color; */
}

.datepicker table tr td.today:hover {
  background-color: var(--primary);
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table thead tr:first-child th:hover {
  background: var(--rgba-primary-1);
}

.datepicker table thead tr th:hover {
  background: var(--rgba-primary-1);
}

.datepicker table td.day:hover {
  background: var(--rgba-primary-1);
  border-radius: 0.375rem;
}

.picker__select--month,
.picker__select--year {
  height: 2.5em;
}

.picker__input {
  background-color: transparent !important;
}

[data-theme-version="dark"] .picker__input {
  background-color: transparent !important;
  border: 0.0625rem solid #444444;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  min-height: 3.75rem;
  padding: 0.5rem 1.563rem;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
  min-height: 2.5rem;
  padding: 0.25rem 1.563rem;
}

.basic-form .form-check-input[type="radio"]+.form-check-label {
  margin-top: 5px;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  min-height: 2.5rem;
  padding: 0.25rem 1.1rem;
  border-radius: 0.375rem;
}

.input-group.input-group-lg {
  min-height: 3.75rem;
  border-radius: 0.375rem;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  border-radius: 0.375rem;
}

.clockpicker-popover .popover-title {
  padding: 10px 0px;
}

.asColorPicker-trigger span {
  border-radius: 0 0.375rem 0.375rem 0;
}

.daterangepicker .calendar-table td {
  width: 33px;
  height: 33px;
  border-radius: 0.5rem;
  color: #B5B5C3;
}

.daterangepicker td.in-range {
  background-color: transparent;
  color: #5E6278 !important;
}

.daterangepicker td.active {
  background-color: #0D99FF;
  color: #fff !important;
}

.daterangepicker td.active:hover {
  color: #fff !important;
}

button.cancelBtn.btn.btn-sm.btn-inverse,
.btn-group-sm>button.cancelBtn.btn.btn-inverse {
  background-color: #f9f9f9;
}

#image {
  max-width: 100%;
}

.docs-options .dropdown-menu {
  padding: 1.5rem;
}

.docs-preview {
  margin-bottom: 3rem;
}

.docs-preview .img-preview {
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  overflow: hidden;
  max-width: 100% !important;
}

.docs-preview .img-preview>img {
  max-width: 100% !important;
}

.docs-preview .img-preview.preview-lg {
  width: 16rem;
  height: 9rem;
}

.docs-preview .img-preview.preview-md {
  width: 8rem;
  height: 4.5rem;
}

.docs-preview .img-preview.preview-sm {
  width: 4rem;
  height: 2.25rem;
}

.docs-preview .img-preview.preview-xs {
  width: 2rem;
  height: 1.125rem;
  margin-right: 0;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 0.375rem;
  border: 0.0625rem solid #dfdfdf;
  height: 2.5rem;
  background: #fff;
}

[data-theme-version="dark"] .select2-container--default .select2-selection--single {
  background: #1E1E1E;
  border-color: #444444;
}

.select2-container--default .select2-selection--single:hover,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single.active {
  box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.313rem;
  color: #888888;
  padding-left: 0.9375rem;
  min-height: 2.813rem;
}

.select2-container--default .select2-selection--multiple {
  border-color: #E6E6E6;
  border-radius: 0;
}

.select2-dropdown {
  border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary);
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #c8c8c8;
  background: #fff;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 0.375rem;
  right: 0.9375rem;
}

.select2-container .select2-selection--multiple {
  min-height: 2.5rem;
  color: #888888;
  border-radius: 0.375rem;
  border: 0.0625rem solid #dfdfdf;
}

[data-theme-version="dark"] .select2-container .select2-selection--multiple {
  background: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .select2-search--dropdown .select2-search__field {
  background: #242424;
  border-color: #444444;
}

.select2-dropdown {
  border-color: #dfdfdf;
}

[data-theme-version="dark"] .select2-dropdown {
  background: #1E1E1E;
  border-color: #444444;
}

.swal2-popup .swal2-content {
  color: #888888;
}

.select2-selection__clear {
  display: none;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
.form-wizard {
  border: 0;
}

.form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 2rem;
}

.form-wizard .nav-wizard li .nav-link {
  position: relative;
}

.form-wizard .nav-wizard li .nav-link span {
  border-radius: 3.125rem;
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid var(--primary);
  display: block;
  line-height: 3rem;
  color: var(--primary);
  font-size: 1.125rem;
  margin: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.form-wizard .nav-wizard li .nav-link:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0.1875rem;
  transform: translateY(-50%);
  background: #eeeeee !important;
  z-index: 0;
  width: 100%;
}

.form-wizard .nav-wizard li .nav-link.active:after {
  background: var(--primary) !important;
}

.form-wizard .nav-wizard li .nav-link.active span {
  background: var(--primary);
  color: #fff;
}

.form-wizard .nav-wizard li .nav-link.done:after {
  background: var(--primary) !important;
}

.form-wizard .nav-wizard li .nav-link.done span {
  background-color: var(--primary);
  color: #fff;
}

.form-wizard .nav-wizard li:last-child .nav-link:after {
  content: none;
}

.form-wizard .toolbar-bottom .btn {
  background-color: var(--primary);
  border: 0;
  padding: 0.75rem 1.125rem;
}

.form-wizard .tab-content .tab-pane {
  padding: 0;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #EEF5F9;
  text-align: center;
  margin: auto;
}

[data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
  background-color: #1E1E1E;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: .5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.form-wizard .emial-setup label.mailclinet input[type="radio"] {
  display: none;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #EEF5F9;
  text-align: center;
  margin: auto;
}

[data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
  background-color: #1E1E1E;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: .5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 0.7rem;
    line-height: 0.95rem;
  }
}

.form-wizard .emial-setup label.mailclinet input[type="radio"] {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  .form-wizard .nav-wizard {
    flex-direction: unset !important;
  }

  .form-wizard .tab-content {
    height: 100% !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .nav-wizard li .nav-link {
    padding: 0;
  }
}

.custom-ekeditor ul {
  padding-left: 1.25rem;
}

.custom-ekeditor ul li {
  list-style: unset;
}

.custom-ekeditor ol li {
  list-style: decimal;
}

.ql-container {
  height: 25rem;
}

#world-datamap {
  padding-bottom: 46% !important;
}

.datamaps-hoverover {
  background: #fff;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: 'Roboto' !important;
  color: var(--primary);
  border: 0.0625rem solid var(--rgba-primary-3);
}

@media only screen and (max-width: 90rem) {
  .world_map_card ul.list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2.1875rem;
  }
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 1.25rem;
  width: 1.25rem;
  line-height: 0.875rem;
  background-color: var(--primary);
  color: #fff;
}

.jqvmap-zoomout {
  top: 2.1875rem;
}

#world-map,
#usa {
  height: 25rem;
}

@media only screen and (max-width: 61.9375rem) {

  #world-map,
  #usa {
    height: 21.875rem;
  }
}

@media only screen and (max-width: 35.9375rem) {

  #world-map,
  #usa {
    height: 14.375rem;
  }
}

.blockUI.blockMsg.blockPage {
  border: 0 !important;
}

#loginForm {
  cursor: auto;
}

.blockMsg {
  border: 0rem;
  width: 20% !important;
}

.blockMsg h1,
.blockMsg .h1 {
  font-size: 1rem;
  padding: 0.5rem 0;
  margin-bottom: 0;
}

.bootstrap-select {
  margin-bottom: 0;
}

.bootstrap-select .btn {
  border: 1px solid #E6E6E6 !important;
  background-color: transparent !important;
  font-weight: 400;
  color: #888888 !important;
}

[data-theme-version="dark"] .bootstrap-select .btn {
  border-color: #444444 !important;
  background: #1E1E1E !important;
}

.bootstrap-select .btn:active,
.bootstrap-select .btn:focus,
.bootstrap-select .btn:hover {
  outline: none !important;
  outline-offset: 0;
}

[data-theme-version="dark"] .bootstrap-select .btn:active,
[data-theme-version="dark"] .bootstrap-select .btn:focus,
[data-theme-version="dark"] .bootstrap-select .btn:hover {
  color: #888888 !important;
}

.bootstrap-select .dropdown-menu {
  border-color: #E6E6E6 !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
}

.bootstrap-select .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

[data-theme-version="dark"] .bootstrap-select .dropdown-menu {
  border-color: #E6E6E6 !important;
}

.input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

.clipboard-btn {
  transition: all .1s ease-in-out;
}

.clipboard-btn:hover {
  background-color: var(--primary);
  color: #fff;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

.bootstrap-tagsinput {
  flex: 1;
  padding: 0;
  padding-left: .5rem;
  border-radius: 0;
  border-color: #E6E6E6;
  background-color: #fff;
  width: 100%;
}

.bootstrap-tagsinput .tag {
  font-size: 0.875rem;
  background-color: var(--primary);
  border-radius: 0;
  align-items: center;
  border: 0.0625rem solid #E6E6E6;
}

.bootstrap-tagsinput input[type="text"] {
  flex: 1;
  height: 2.1rem;
  border-radius: 0;
  border-color: #E6E6E6;
}

.bootstrap-tagsinput label {
  margin-bottom: .5rem;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-dataset.tt-dataset-states {
  border: 1px solid #E6E6E6;
}

.twitter-typeahead .tt-menu {
  width: 100%;
  background-color: #fff;
}

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: 0.625rem;
  cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: var(--primary);
  color: #fff;
}

.weather-one i {
  font-size: 8rem;
  position: relative;
  top: .5rem;
}

.weather-one h2,
.weather-one .h2 {
  display: inline-block;
  float: right;
  font-size: 4.8rem;
}

.weather-one .city {
  position: relative;
  text-align: right;
  top: -2.5rem;
}

.weather-one .currently {
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  top: 2.5rem;
}

.weather-one .celcious {
  text-align: right;
  font-size: 2rem;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

.noUi-target {
  border-color: transparent;
  border-radius: 0;
}

.noUi-connect {
  background-color: var(--primary);
}

.noUi-connects {
  background-color: #D2D6DE;
}

.noUi-connect.c-1-color {
  background-color: #3AC977;
}

.noUi-connect.c-2-color {
  background-color: #58bad7;
}

.noUi-connect.c-3-color {
  background-color: var(--primary);
}

.noUi-connect.c-4-color {
  background-color: #FF9F00;
}

.noUi-vertical {
  width: 0.375rem;
}

.noUi-horizontal {
  height: 0.125rem;
  border: 0;
  margin-bottom: 0.625rem;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  height: 0.9375rem;
  width: 0.9375rem;
  border-radius: 3.125rem;
  box-shadow: none;
  border: none;
  background-color: var(--primary);
}

.noUi-horizontal .noUi-handle::after,
.noUi-horizontal .noUi-handle::before,
.noUi-vertical .noUi-handle::after,
.noUi-vertical .noUi-handle::before {
  display: none;
}

.noUi-vertical .noUi-handle {
  left: -0.35rem;
  top: -0.375rem;
}

.noUi-horizontal .noUi-handle {
  top: -0.4375rem;
  cursor: pointer;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -0.375rem;
}

#slider-toggle {
  height: 3.125rem;
}

#slider-toggle.off .noUi-handle {
  border-color: var(--primary);
}

.colorpicker-slider .sliders.noUi-target#red,
.colorpicker-slider .sliders.noUi-target#green,
.colorpicker-slider .sliders.noUi-target#blue {
  margin: 0.625rem;
  display: inline-block;
  height: 12.5rem;
}

.colorpicker-slider .sliders.noUi-target#red .noUi-connect {
  background: #c0392b;
}

.colorpicker-slider .sliders.noUi-target#green .noUi-connect {
  background: #27ae60;
}

.colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
  background: #2980b9;
}

.colorpicker-slider #result {
  margin: 3.75rem 1.625rem;
  height: 6.25rem;
  width: 6.25rem;
  display: inline-block;
  vertical-align: top;
  color: #7f7f7f;
  background: #7f7f7f;
  border: 0.0625rem solid #fff;
  box-shadow: 0 0 0.625rem;
}

.slider-vertical {
  height: 18rem;
}

.nestable-cart {
  overflow: hidden;
}

.dd-handle {
  color: var(--secondary);
  background: #fff;
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: 1px solid #CCCCCC;
  cursor: all-scroll;
}

.dd-handle:hover {
  color: var(--secondary);
  background: #fff;
}

.dd3-content:hover {
  color: var(--secondary);
  background: #dfdfdf;
}

.dd3-content {
  color: #fff;
}

.dd-item>button {
  line-height: 28px;
  color: #fff;
}

.dd-item>button:before {
  color: var(--secondary);
}

.pignose-calendar {
  box-shadow: none;
  width: 100%;
  max-width: none;
  border-color: var(--primary);
}

.pignose-calendar .pignose-calendar-top-date {
  background-color: var(--primary);
}

.pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
  color: #fff;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: var(--primary);
  box-shadow: none;
}

.pignose-calendar .pignose-calendar-top {
  box-shadow: none;
  border-bottom: 0;
}

.pignose-calendar.pignose-calendar-blue {
  background-color: rgba(0, 0, 0, 0.15);
}

.pignose-calendar .pignose-calendar-unit {
  height: 4.8em;
}

.cd-h-timeline {
  opacity: 0;
  transition: opacity 0.2s;
}

.cd-h-timeline--loaded {
  opacity: 1;
}

.cd-h-timeline__container {
  position: relative;
  height: 6.25rem;
  max-width: 50rem;
}

.cd-h-timeline__dates {
  position: relative;
  height: 100%;
  margin: 0 2.5rem;
  overflow: hidden;
}

.cd-h-timeline__dates::after,
.cd-h-timeline__dates::before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 1.25rem;
}

.cd-h-timeline__dates::before {
  left: 0;
  background: var(--primary);
}

.cd-h-timeline__dates::after {
  right: 0;
  background: var(--primary);
}

.cd-h-timeline__line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 3.0625rem;
  height: 0.125rem;
  background-color: var(--primary);
  transition: transform 0.4s;
}

.cd-h-timeline__filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #3AC977;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s;
}

.cd-h-timeline__date {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 0.8em;
  padding-bottom: var(--space-sm);
  color: var(--cd-color-1);
  user-select: none;
  text-decoration: none;
}

.cd-h-timeline__date::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.3125rem;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  background-color: var(--primary);
  transition: background-color 0.3s, border-color .3s;
}

.cd-h-timeline__date:hover::after {
  background-color: #3AC977;
  border-color: #3AC977;
}

.cd-h-timeline__date--selected {
  pointer-events: none;
}

.cd-h-timeline__date--selected::after {
  background-color: #3AC977;
  border-color: #3AC977;
}

.cd-h-timeline__date--older-event::after {
  border-color: #3AC977;
}

.cd-h-timeline__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  transition: border-color 0.3s;
}

.cd-h-timeline__navigation::after {
  content: '';
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* background: url(../page-error-404.html) no-repeat 0 0; */
}

.cd-h-timeline__navigation:hover {
  border-color: #3AC977;
}

.cd-h-timeline__navigation--prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

.cd-h-timeline__navigation--next {
  right: 0;
}

.cd-h-timeline__navigation--inactive {
  cursor: not-allowed;
}

.cd-h-timeline__navigation--inactive::after {
  background-position: 0 -1rem;
}

.cd-h-timeline__navigation--inactive:hover {
  border-color: var(--rgba-primary-6);
}

.cd-h-timeline__events {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height .4s;
}

.cd-h-timeline__event {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  padding: 0.0625rem 5%;
  opacity: 0;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}

.cd-h-timeline__event--selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0);
}

.cd-h-timeline__event--enter-right,
.cd-h-timeline__event--leave-right {
  animation-name: cd-enter-right;
}

.cd-h-timeline__event--enter-left,
.cd-h-timeline__event--leave-left {
  animation-name: cd-enter-left;
}

.cd-h-timeline__event--leave-right,
.cd-h-timeline__event--leave-left {
  animation-direction: reverse;
}

.cd-h-timeline__event-content {
  max-width: 50rem;
}

.cd-h-timeline__event-title {
  color: var(--cd-color-1);
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: var(--text-xxxl);
}

.cd-h-timeline__event-date {
  display: block;
  font-style: italic;
  margin: var(--space-xs) auto;
}

.cd-h-timeline__event-date::before {
  content: '- ';
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.toast-success {
  background-color: var(--primary);
}

.toast-info {
  background-color: #58bad7;
}

.toast-warning {
  background-color: #FF9F00;
}

.toast-error {
  background-color: #FF5E5E;
}

#toast-container>div {
  box-shadow: none;
  border-radius: 0;
  width: auto;
  max-width: 15.625rem;
  opacity: 1;
}

[direction="rtl"] #toast-container>div {
  padding: 0.9375rem 3.125rem 0.9375rem 0.9375rem;
  background-position: calc(100% - 0.9375rem);
  text-align: right;
}

#toast-container>div:hover {
  box-shadow: none;
}

#toast-container .toast-title {
  margin-bottom: 0.3125rem;
  font-weight: 600;
}

#toast-container .toast-message {
  font-size: 0.75rem;
}

#toast-container .toast-close-button {
  opacity: 1;
  font-size: 1.25rem;
  font-weight: normal;
  text-shadow: none;
}

[direction="rtl"] .toast-top-right.demo_rtl_class {
  left: 0.75rem;
  right: auto;
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-thumb-outer.lg-grab,
.lg-toogle-thumb.lg-icon {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lightimg {
  cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 0.625rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  border-radius: 0.1875rem;
  background: #000000;
  padding: 0.1875rem;
  color: white;
  width: 1.0625rem;
  height: 1.0625rem;
  cursor: pointer;
  line-height: 0.625rem;
  text-align: center;
}

.jqvmap-zoomin {
  top: 0.625rem;
}

.jqvmap-zoomout {
  top: 1.875rem;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #DAE2F3;
  width: 0.25rem;
}

.ps__thumb-y {
  background-color: #DAE2F3;
  width: 0.25rem;
}

.total-average {
  position: relative;
  height: 18.75rem;
}

.widget-chat {
  position: relative;
  height: 15.625rem;
}

.widget-todo {
  position: relative;
  height: 13.125rem;
}

.widget-team {
  position: relative;
  height: 17.8125rem;
}

.widget-timeline {
  position: relative;
}

.widget-comments {
  position: relative;
  height: 25rem;
}

.sidebar-right-inner {
  position: relative;
  height: 100%;
}

.widget-team .ps .ps__rail-x:hover,
.widget-team .ps .ps__rail-y:hover,
.widget-team .ps .ps__rail-x:focus,
.widget-team .ps .ps__rail-y:focus,
.widget-team .ps .ps__rail-x.ps--clicking,
.widget-team .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}

.fc-h-event,
.fc-v-event {
  background: var(--primary);
  border-radius: .42rem;
}

.fc-h-event .fc-event-title {
  color: #fff;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #ebedf3;
}

.fc-unthemed .fc-h-event,
.fc-unthemed .fc-event-dot {
  padding: 0;
  border-radius: .42rem;
}

.fc-theme-standard th {
  padding: .75rem .5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #b5b5c3;
}

@media only screen and (max-width: 35.9375rem) {
  .fc-theme-standard th {
    font-size: 14px;
    font-weight: 400;
    padding: 3px 0px;
  }
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
.fc-scrollgrid,
table {
  border-color: #ebedf3;
}

.fc-daygrid-dot-event {
  background: #fff;
  border: 1px solid #ebedf3;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}

.fc-daygrid-dot-event .fc-daygrid-event-dot {
  border-color: var(--primary);
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500;
}

.fc-event.bg-primary,
.fc-event.bg-success,
.fc-event.bg-warning,
.fc-event.bg-secondary,
.fc-event.bg-dark,
.fc-event.bg-info {
  color: #fff !important;
  border-radius: 8px;
}

.fc-event.bg-primary .fc-daygrid-event-dot,
.fc-event.bg-success .fc-daygrid-event-dot,
.fc-event.bg-warning .fc-daygrid-event-dot,
.fc-event.bg-secondary .fc-daygrid-event-dot,
.fc-event.bg-dark .fc-daygrid-event-dot,
.fc-event.bg-info .fc-daygrid-event-dot {
  border-color: #fff;
}

.fc .fc-scroller-liquid-absolute,
.fc-scroller {
  position: relative;
  overflow: visible !important;
}

.fc .fc-button-group>.fc-button {
  color: #b5b5c3;
  background: 0 0;
  border: 1px solid #ebedf3;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.fc .fc-button-group>.fc-button:focus,
.fc .fc-button-group>.fc-button:active,
.fc .fc-button-group>.fc-button:hover,
.fc .fc-button-group>.fc-button.fc-button-active {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.fc-button.fc-button-primary.fc-today-button {
  background: var(--primary);
  color: #fff;
  border: 0;
  opacity: 1;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  background: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}

.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.3em;
}

.external-event {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.external-event:hover:before {
  background: #fff !important;
}

.fc-event {
  overflow: hidden;
}

.fc .fc-view-harness {
  height: 800px !important;
  overflow-y: auto;
}

@media only screen and (max-width: 35.9375rem) {
  .fc .fc-view-harness {
    height: 450px !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    display: flex;
    justify-content: center;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
    justify-content: space-between;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
    margin-bottom: 8px;
  }
}

#external-events .external-event {
  text-align: left;
  font-size: 16px;
}

@media only screen and (max-width: 35.9375rem) {
  .fc.app-fullcalendar .fc-timegrid-slot-label {
    width: 40px !important;
    font-size: 10px;
  }

  .fc.app-fullcalendar .fc-event,
  .fc.app-fullcalendar .external-event {
    font-size: 10px;
    margin: 0;
    padding: 2px 0;
    text-align: center;
    line-height: 1.3;
  }

  .fc.app-fullcalendar .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .fc-daygrid-dot-event {
    flex-wrap: wrap;
  }

  .fc-daygrid-event {
    white-space: unset;
  }
}

.ck.ck-editor .ck.ck-toolbar__items .ck.ck-toolbar__separator {
  display: none;
}

.ck.ck-editor .ck.ck-button {
  padding: 0.375rem 0.5rem;
  background-color: #fff;
  color: #374557;
  font-weight: 900;
  border: 0.0625rem solid #F0F0F1;
  cursor: pointer;
}

.ck.ck-editor .ck.ck-toolbar {
  background-color: #fff;
  border: 0;
  padding: 0.3125rem 0.625rem;
}

[data-theme-version="dark"] .ck.ck-editor .ck.ck-toolbar {
  background: #1E1E1E !important;
}

.ck.ck-editor .ck.ck-toolbar__separator {
  display: none;
}

.ck.ck-editor .ck-content {
  background-color: #f7f7f7;
  border-width: 0.0625rem 0 0;
  border-color: rgba(0, 0, 0, 0.1);
  min-height: 12.5rem;
  border: 0.0625rem solid var(--rgba-primary-1) !important;
}

[data-theme-version="dark"] .ck.ck-editor .ck-content {
  border: 0.0625rem solid #444444 !important;
}

.ck-editor__editable {
  background-color: #f7f7f7 !important;
}

[data-theme-version="dark"] .ck-editor__editable {
  background: #444444 !important;
}

.ck.ck-reset.ck-editor {
  border: 0.0625rem solid var(--rgba-primary-1);
}

.ck.ck-editor__main>.ck-editor__editable {
  background: #fff !important;
}

[data-theme-version="dark"] .ck.ck-editor__main>.ck-editor__editable {
  background: #1E1E1E !important;
}

/*  */
.accordion-primary .accordion-header {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
}

.accordion-primary .accordion-header.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
}

[data-theme-version="dark"] .accordion-primary .accordion-header.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #888888;
}

.accordion-primary-solid .accordion-header {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-primary-solid .accordion-header.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

[data-theme-version="dark"] .accordion-primary-solid .accordion-header.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #888888;
}

.accordion-primary-solid .accordion__body {
  border: 0.125rem solid var(--primary);
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-danger .accordion-header {
  background: #FF5E5E;
  border-color: #FF5E5E;
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(255, 94, 94, 0.15);
}

.accordion-danger .accordion-header.collapsed {
  background: #ffdede;
  border-color: #ffdede;
  color: #211c37;
  box-shadow: none;
}

.accordion-danger-solid .accordion-header {
  background: #FF5E5E;
  border-color: #FF5E5E;
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 rgba(255, 94, 94, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-danger-solid .accordion-header.collapsed {
  background: #ffdede;
  border-color: #ffdede;
  color: #211c37;
  box-shadow: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

[data-theme-version="dark"] .accordion-danger-solid .accordion-header.collapsed {
  background: rgba(255, 94, 94, 0.15);
  border-color: rgba(255, 94, 94, 0.15);
  color: #888888;
}

.accordion-danger-solid .accordion__body {
  border: 0.125rem solid #FF5E5E;
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(255, 94, 94, 0.15);
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-item {
  margin-bottom: 1.25rem;
  border: 0;
}

.accordion-header {
  padding: 0.7rem 0.75rem;
  border: 0.0625rem solid #E6E6E6;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  border-radius: 0.375rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-theme-version="dark"] .accordion-header {
  color: var(--primary);
  border-color: #444444;
}

.accordion-header-indicator {
  font-family: 'themify';
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  transform: translateY(-50%);
}

[direction="rtl"] .accordion-header-indicator {
  right: auto;
  left: 1.5625rem;
}

.accordion-header-indicator.indicator_bordered {
  display: inline-block;
  width: 1.5625rem;
  text-align: center;
  height: 1.5625rem;
  border: 0.0625rem solid #E6E6E6;
  border-radius: 50%;
  line-height: 1.5625rem;
}

.accordion-header:not(.collapsed) .accordion-header-indicator::before {
  content: "\e622";
}

.accordion-header:not(.collapsed) .accordion-header-indicator.style_two::before {
  content: "\e648";
}

.accordion-header.collapsed .accordion-header-indicator::before {
  content: "\e61a";
}

.accordion-header.collapsed .accordion-header-indicator.style_two::before {
  content: "\e64b";
}

.accordion-body-text {
  padding: 0.875rem 0.75rem;
}

.accordion-bordered .accordion__body {
  border: 0.0625rem solid #E6E6E6;
  border-top: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

[data-theme-version="dark"] .accordion-bordered .accordion__body {
  border-color: #444444;
}

.accordion-bordered .accordion-header.collapsed {
  border-radius: 0.375rem;
}

.accordion-bordered .accordion-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-no-gutter .accordion-item {
  margin-bottom: 0;
}

.accordion-no-gutter .accordion-item .accordion-header.collapsed {
  border-bottom: none;
}

.accordion-no-gutter .accordion-item:last-child .accordion-header {
  border-bottom: 0.0625rem solid #E6E6E6;
}

[data-theme-version="dark"] .accordion-no-gutter .accordion-item:last-child .accordion-header {
  border-color: #444444;
}

.accordion-no-gutter.accordion__bordered .accordion-item:not(:last-child) .accordion__body {
  border-bottom: none;
}

.accordion-left-indicator .accordion-header-text {
  padding-left: 2.5rem;
}

.accordion-left-indicator .accordion-header-indicator {
  right: auto;
  left: 1.5625rem;
}

.accordion-with-icon .accordion-header-text {
  padding-left: 2.5rem;
}

[direction="rtl"] .accordion-with-icon .accordion-header-text {
  padding-left: 0;
  padding-right: 2.5rem;
}

.accordion-with-icon .accordion-header-icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: 'themify';
}

[direction="rtl"] .accordion-with-icon .accordion-header-icon {
  left: auto;
  right: 1.5625rem;
}

.accordion-with-icon .accordion-header-icon::before {
  content: "\e645";
}

.accordion-header-bg .accordion-header {
  background-color: #dfdfdf;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header {
  background-color: #1E1E1E;
  color: #fff;
}

.accordion-header-bg .accordion-header-primary {
  background-color: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-primary {
  background-color: var(--primary);
}

.accordion-header-bg .accordion-header-info {
  background-color: #58bad7;
  color: #fff;
  border-color: #58bad7;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-info {
  background-color: #58bad7;
}

.accordion-header-bg .accordion-header-success {
  background-color: #3AC977;
  color: #fff;
  border-color: #3AC977;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-success {
  background-color: #3AC977;
}

.accordion-header-bg.accordion-no-gutter .accordion-header {
  border-color: transparent;
  border-radius: 0;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:first-child .accordion-header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:last-child .accordion-header {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion.accordion-no-gutter .accordion-header {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-header.collapsed {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__body {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-item:first-child .accordion-header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion-header.collapsed {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion__body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-solid-bg .accordion-header {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion-header {
  background-color: #1E1E1E;
}

.accordion-solid-bg .accordion-header.collapsed {
  border-radius: 0.375rem;
}

.accordion-solid-bg .accordion__body {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion__body {
  background-color: #1E1E1E;
}

.accordion-active-header .accordion-header:not(.collapsed) {
  background-color: #58bad7;
  border-color: #58bad7;
  color: #fff;
}

.accordion-header-shadow .accordion-header {
  border: none;
  box-shadow: 0 0 0.9375rem -0.1875rem rgba(0, 0, 0, 0.3);
}

.accordion-rounded-stylish .accordion-header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.accordion-rounded-stylish .accordion__body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-rounded .accordion-header {
  border-radius: 0.3125rem;
}

.accordion-gradient .accordion-header {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-gradient .accordion-header.collapsed {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-gradient .accordion__body {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
}

.dzm-tabs {
  padding: 4px;
  border-radius: 0.375rem;
  background-color: var(--rgba-primary-1);
  border: none;
  flex-wrap: nowrap;
}

.dzm-tabs .nav-item .nav-link {
  border-radius: 0.375rem;
}

.dzm-tabs .nav-item .nav-link.active {
  background: var(--primary) !important;
  color: #fff;
  border: 1px solid transparent;
}

.dzm-tabs .nav-item .nav-link:hover {
  border-color: transparent;
}

.dzm-tabs.style-2 {
  background-color: #f5f5f5;
}

.dz-card .card-body {
  padding: 1.125rem !important;
  border-radius: 0 0 0.375rem 0.375rem;
}

@media only screen and (max-width: 35.9375rem) {
  .dz-card .card-body {
    padding: 1rem;
  }
}

.dz-card .card-body.code-area {
  background: var(--secondary);
}

.dz-card .card-body.code-area .language-html {
  background: transparent;
  color: white;
}

.dz-card .card-footer {
  color: #888888;
}

.dz-card .badge-box {
  position: relative;
  background: black;
  opacity: 0.6;
  border-radius: 0 0 0.625rem 0.625rem;
}

.unorder-list li {
  list-style: unset;
}

@media only screen and (max-width: 61.9375rem) {
  .unorder-list {
    padding-left: 2rem;
  }
}

.order-list li {
  list-style-type: unset;
}

.text-justify {
  text-align: justify;
}

.accordion-item {
  background-color: transparent;
}

.alert {
  border-radius: 0.375rem;
  padding: 0.75rem 1.5rem;
}

.alert p {
  line-height: 1.5;
}

.alert-square {
  border-radius: 0;
}

.alert-rounded {
  border-radius: 1.875rem;
}

.alert-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .alert-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

.alert-secondary {
  background: #c5c5c5;
  border-color: #c5c5c5;
  color: #2C2C2C;
}

[data-theme-version="dark"] .alert-secondary {
  background: rgba(44, 44, 44, 0.5);
  border-color: rgba(44, 44, 44, 0.5);
  color: #fff;
}

.alert-success {
  background: #daf5e6;
  border-color: #daf5e6;
  color: #3AC977;
}

[data-theme-version="dark"] .alert-success {
  background: rgba(58, 201, 119, 0.1);
  border-color: rgba(58, 201, 119, 0.1);
}

.alert-warning {
  background: #ffeccc;
  border-color: #ffeccc;
  color: #FF9F00;
}

[data-theme-version="dark"] .alert-warning {
  background: rgba(255, 159, 0, 0.1);
  border-color: rgba(255, 159, 0, 0.1);
}

.alert-danger {
  background: #ffdede;
  border-color: #ffdede;
  color: #FF5E5E;
}

[data-theme-version="dark"] .alert-danger {
  background: rgba(255, 94, 94, 0.15);
  border-color: rgba(255, 94, 94, 0.15);
}

.alert-info {
  background: #e8f6f9;
  border-color: #e8f6f9;
  color: #58bad7;
}

[data-theme-version="dark"] .alert-info {
  background: rgba(88, 186, 215, 0.1);
  border-color: rgba(88, 186, 215, 0.1);
}

.alert-dark {
  background: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e;
}

[data-theme-version="dark"] .alert-dark {
  background: rgba(110, 110, 110, 0.35);
  border-color: rgba(110, 110, 110, 0.35);
  color: #fff;
}

.alert-light {
  background: #dfdfdf;
  border-color: #dfdfdf;
  color: #6e6e6e;
}

.alert .btn-close {
  background: none;
  text-shadow: 0 1px 0 #fff;
  top: -5px;
  opacity: 0.5;
  color: inherit;
}

.alert .btn-close i {
  transform: scale(1.4);
}

.alert-alt.alert-primary {
  border-left: 0.25rem solid var(--primary);
}

.alert-alt.alert-secondary {
  border-left: 0.25rem solid #2C2C2C;
}

.alert-alt.alert-success {
  border-left: 0.25rem solid #3AC977;
}

.alert-alt.alert-warning {
  border-left: 0.25rem solid #FF9F00;
}

.alert-alt.alert-danger {
  border-left: 0.25rem solid #FF5E5E;
}

.alert-alt.alert-info {
  border-left: 0.25rem solid #58bad7;
}

.alert-alt.alert-dark {
  border-left: 0.25rem solid #6e6e6e;
}

.alert-alt.alert-light {
  border-left: 0.25rem solid #b9b9b9;
}

.alert-alt.alert-primary.solid {
  border-left: 0.25rem solid var(--primary-dark) !important;
}

.alert-alt.alert-secondary.solid {
  border-left: 0.25rem solid black !important;
}

.alert-alt.alert-success.solid {
  border-left: 0.25rem solid #1c673c !important;
}

.alert-alt.alert-warning.solid {
  border-left: 0.25rem solid #805000 !important;
}

.alert-alt.alert-danger.solid {
  border-left: 0.25rem solid #de0000 !important;
}

.alert-alt.alert-info.solid {
  border-left: 0.25rem solid #22758e !important;
}

.alert-alt.alert-dark.solid {
  border-left: 0.25rem solid #2e2e2e !important;
}

.alert-alt.alert-light.solid {
  border-left: 0.25rem solid #9f9f9f !important;
}

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1;
}

.alert.alert-primary.solid {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.alert.alert-secondary.solid {
  background: #2C2C2C;
  color: #fff;
  border-color: #2C2C2C;
}

.alert.alert-success.solid {
  background: #3AC977;
  color: #fff;
  border-color: #3AC977;
}

.alert.alert-warning.solid {
  background: #FF9F00;
  color: #fff;
  border-color: #FF9F00;
}

.alert.alert-danger.solid {
  background: #FF5E5E;
  color: #fff;
  border-color: #FF5E5E;
}

.alert.alert-info.solid {
  background: #58bad7;
  color: #fff;
  border-color: #58bad7;
}

.alert.alert-dark.solid {
  background: #6e6e6e;
  color: #fff;
  border-color: #6e6e6e;
}

.alert.alert-light.solid {
  background: #dfdfdf;
  color: #6e6e6e;
  border-color: #dfdfdf;
}

.alert-right-icon>span i {
  font-size: 1.125rem;
  margin-right: 0.3125rem;
}

.alert-right-icon .close i {
  font-size: 1rem;
}

.alert.alert-outline-primary {
  background: transparent;
  color: var(--primary);
  border-color: var(--primary);
}

.alert.alert-outline-secondary {
  background: transparent;
  color: #888888;
  border-color: #2C2C2C;
}

.alert.alert-outline-success {
  background: transparent;
  color: #3AC977;
  border-color: #3AC977;
}

.alert.alert-outline-info {
  background: transparent;
  color: #58bad7;
  border-color: #58bad7;
}

.alert.alert-outline-warning {
  background: transparent;
  color: #FF9F00;
  border-color: #FF9F00;
}

.alert.alert-outline-danger {
  background: transparent;
  color: #FF5E5E;
  border-color: #FF5E5E;
}

.alert.alert-outline-dark {
  background: transparent;
  color: #888888;
  border-color: #6e6e6e;
}

.alert.alert-outline-light {
  background: transparent;
  color: #6e6e6e;
  border-color: #dfdfdf;
}

.alert-social {
  color: #fff;
}

.alert-social .alert-social-icon {
  align-self: center;
  margin-right: 0.9375rem;
}

.alert-social .alert-social-icon i {
  font-size: 2.625rem;
}

.alert-social.facebook {
  background-color: #3b5998;
}

.alert-social.twitter {
  background-color: #1da1f2;
}

.alert-social.linkedin {
  background-color: #007bb6;
}

.alert-social.google-plus {
  background-color: #db4439;
}

.alert-social .close:hover {
  opacity: 1 !important;
  color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
  font-size: 2.1875rem;
  line-height: 1;
}

[direction="rtl"] .left-icon-big .alert-left-icon-big,
[direction="rtl"] .alert-social .alert-social-icon {
  margin-right: 0;
  margin-left: 0.9375rem;
}

.dz-scroll {
  overflow-y: scroll;
}

body>* {
  scrollbar-width: thin;
  scrollbar-color: rgba(111, 133, 147, 0.1) #fff0;
}

::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}

.badge {
  line-height: 1.5;
  border-radius: 0.25rem;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 12px;
  border: 0.0625rem solid transparent;
}

.badge-rounded {
  border-radius: 1.25rem;
  padding: 0.1875rem 0.8125rem;
}

.badge-circle {
  border-radius: 6.25rem;
  padding: 0.1875rem 0.575rem;
}

.light.badge-default {
  background: #ADB6C7;
}

.light.badge-primary {
  background-color: #e8f5ff !important;
  border-color: #a6d9ff;
  color: #fff;
}

.light.badge-success {
  background-color: #daf5e6;
  border-color: #b2eaca;
  color: #3AC977;
}

.light.badge-info {
  background-color: #d3edf5;
  border-color: #d3edf5;
  color: #58bad7;
}

.light.badge-secondary {
  background-color: #c5c5c5;
  border-color: #acacac;
  color: #2C2C2C;
}

.light.badge-warning {
  background-color: #ffeccc;
  border-color: white;
  color: #FF9F00;
}

.light.badge-danger {
  background-color: #ffdede;
  border-color: #fff7f7;
  color: #FF5E5E;
}

.light.badge-dark {
  background-color: #eeeeee;
  border-color: #bbbbbb;
  color: #6e6e6e;
}

.light.badge-light {
  background-color: #e7e7e7;
  border-color: #dfdfdf;
  color: #6e6e6e;
}

.badge-outline-primary {
  border: 0.0625rem solid var(--primary);
  color: var(--primary);
}

.badge-outline-secondary {
  border: 0.0625rem solid #2C2C2C;
  color: #2C2C2C;
}

[data-theme-version="dark"] .badge-outline-secondary {
  color: #888888;
}

.badge-outline-success {
  border: 0.0625rem solid #3AC977;
  color: #3AC977;
}

.badge-outline-info {
  border: 0.0625rem solid #58bad7;
  color: #58bad7;
}

.badge-outline-warning {
  border: 0.0625rem solid #FF9F00;
  color: #FF9F00;
}

.badge-outline-danger {
  border: 0.0625rem solid #FF5E5E;
  color: #FF5E5E;
}

.badge-outline-light {
  border: 0.0625rem solid #E6E6E6;
  color: #6e6e6e;
}

[data-theme-version="dark"] .badge-outline-light {
  color: #888888;
}

.badge-outline-dark {
  border: 0.0625rem solid #6e6e6e;
  color: #6e6e6e;
}

[data-theme-version="dark"] .badge-outline-dark {
  color: #888888;
}

.badge-xs {
  font-size: 0.5rem;
  padding: 0rem 0.3125rem;
  line-height: 1rem;
  height: 16px;
}

.badge-sm {
  font-size: 0.6875rem;
  padding: 0.3125rem 0.5rem;
  line-height: 0.6875rem;
}

.badge-lg {
  font-size: 0.875rem;
  padding: 0rem 0.625rem;
  line-height: 1.875rem;
}

.badge-xl {
  font-size: 16px;
  padding: 0.1rem 0.9375rem;
  line-height: 2.1875rem;
  font-weight: 400;
}

.badge-default {
  background: #ADB6C7;
}

.badge-success {
  background-color: #3AC977;
}

.badge-success:hover {
  color: #fff;
}

.badge-secondary {
  background-color: #2C2C2C;
}

.badge-secondary:hover {
  color: #fff;
}

.badge-info {
  background-color: #58bad7;
}

.badge-info:hover {
  color: #fff;
}

.badge-primary {
  background-color: var(--primary);
}

.badge-primary:hover {
  color: #fff;
}

.badge-warning {
  background-color: #FF9F00;
}

.badge-warning:hover {
  color: #fff;
}

.badge-danger {
  background-color: #FF5E5E;
}

.badge-danger:hover {
  color: #fff;
}

.badge-dark {
  background-color: #6e6e6e;
}

.badge-dark:hover {
  color: #fff;
}

.badge-light {
  background-color: #dfdfdf;
  color: #000;
}

.badge-light:hover {
  color: #000;
}

.light.badge-default {
  background: #ADB6C7;
}

.light.badge-success {
  background-color: #daf5e6;
  color: #3AC977;
}

[data-theme-version="dark"] .light.badge-success {
  background-color: rgba(58, 201, 119, 0.1);
  border-color: transparent;
}

.light.badge-info {
  background-color: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .light.badge-info {
  background-color: rgba(88, 186, 215, 0.1);
}

.light.badge-primary {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .light.badge-primary {
  background-color: var(--rgba-primary-1);
  border-color: transparent;
}

.light.badge-secondary {
  background-color: #c5c5c5;
  color: #2C2C2C;
}

[data-theme-version="dark"] .light.badge-secondary {
  background-color: rgba(44, 44, 44, 0.5);
  color: #fff;
  border-color: transparent;
}

.light.badge-warning {
  background-color: #ffeccc;
  color: #FF9F00;
}

[data-theme-version="dark"] .light.badge-warning {
  background-color: rgba(255, 159, 0, 0.1);
  border-color: transparent;
}

.light.badge-danger {
  background-color: #ffdede;
  color: #FF5E5E;
}

[data-theme-version="dark"] .light.badge-danger {
  background-color: rgba(255, 94, 94, 0.15);
  border-color: transparent;
}

.light.badge-dark {
  background-color: #eeeeee;
  color: #6e6e6e;
}

[data-theme-version="dark"] .light.badge-dark {
  background-color: rgba(110, 110, 110, 0.35);
  color: #888888;
  border-color: transparent;
}

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem;
}

.bootstrap-label .label:last-child {
  margin-right: 0;
}

.badge-demo .badge {
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.badge-demo .badge:last-child {
  margin-right: 0;
}

.bootstrap-badge-buttons button {
  margin-right: .2rem;
  margin-bottom: 1rem;
}

.bootstrap-badge-buttons button:last-child {
  margin-right: 0;
}

.breadcrumb {
  font-size: 13px;
}

.breadcrumb .breadcrumb-item a svg {
  margin-bottom: 3px;
  margin-right: 4px;
}

.breadcrumb .breadcrumb-item.active a {
  color: #000;
}

.page-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1.95rem 0.6rem;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #E6E6E6;
  flex-flow: wrap;
}

[data-theme-version="dark"] .page-titles {
  background: #242424;
}

@media only screen and (max-width: 74.9375rem) {
  .page-titles {
    margin-top: 0;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .page-titles {
    margin-bottom: 0rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles {
    padding: 0.6rem 1.55rem 0.6rem;
  }
}

.page-titles .justify-content-sm-end {
  align-items: center;
}

.page-titles h4,
.page-titles .h4 {
  margin-bottom: 0;
  margin-top: 0;
  color: var(--primary);
  font-size: 1.25rem;
}

.page-titles h4 span,
.page-titles .h4 span {
  font-size: 0.875rem;
  font-weight: 400;
}

.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
  align-items: center;
}

.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
}

.page-titles .breadcrumb li a {
  color: #828690;
  font-size: 13px;
}

.page-titles .breadcrumb li.active {
  color: var(--primary);
  font-weight: 500;
}

.page-titles .breadcrumb li.active a {
  color: var(--primary) !important;
}

.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  /* font-family: 'simple-line-icons';
                font-weight: 400;
				position:absolute;
                font-size: 10px; */
  color: var(--primary);
}

.page-titles .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}

.page-titles .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}

.page-titles .bc-title {
  margin: 0;
  font-size: 17px;
  margin-right: 2rem;
  color: var(--secondary);
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles .bc-title {
    font-size: 15px;
  }
}

.select2-selection__clear span {
  display: none;
}

.select2-container .select2-search--inline .select2-search__field {
  padding: 2px 5px !important;
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
  box-shadow: none;
}

.btn {
  margin: 0.119rem 0.763rem;
  border-radius: 0.375rem;
  line-height: 20px;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 87.5rem) {
  .btn {
    padding: 0.625rem 1rem;
    font-size: 0.813rem;
  }
}

.btn.btn-success,
.btn.btn-secondary,
.btn.btn-warning,
.btn.btn-primary,
.btn.btn-danger,
.btn.btn-info {
  color: #fff;
}

.btn-transparent {
  background-color: transparent;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none;
}

.btn-primary {
  border-color: var(--primary);
  background-color: var(--primary);
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
}

.btn-link svg circle {
  fill: var(--primary);
}

.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.sharp {
  min-width: 2.4rem;
  padding: 0.4375rem;
  height: 2.4rem;
  line-height: 1.7;
  min-height: 2.5rem;
}

.sharp.btn-xs {
  padding: 0.1175rem;
  width: 1.625rem;
  height: 1.625rem;
  min-width: 1.625rem;
  min-height: 1.625rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.light.tp-btn {
  background-color: transparent;
}

.light.btn-default {
  background: #ADB6C7;
}

.light.btn-success {
  background-color: #daf5e6;
  border-color: #daf5e6;
  color: #3AC977;
}

.light.btn-success g [fill] {
  fill: #3AC977;
}

[data-theme-version="dark"] .light.btn-success {
  background-color: rgba(58, 201, 119, 0.1);
  border-color: transparent;
}

.light.btn-success:hover {
  background-color: #3AC977;
  border-color: #3AC977;
  color: #fff;
}

.light.btn-success:hover g [fill] {
  fill: #fff;
}

.light.btn-info {
  background-color: #d3edf5;
  border-color: #d3edf5;
  color: #58bad7;
}

.light.btn-info g [fill] {
  fill: #58bad7;
}

[data-theme-version="dark"] .light.btn-info {
  background-color: rgba(88, 186, 215, 0.1);
  border-color: transparent;
}

.light.btn-info:hover {
  background-color: #58bad7;
  border-color: #58bad7;
  color: #fff;
}

.light.btn-info:hover g [fill] {
  fill: #fff;
}

.light.btn-primary {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.light.btn-primary g [fill] {
  fill: var(--primary);
}

[data-theme-version="dark"] .light.btn-primary {
  background-color: var(--rgba-primary-1);
  border-color: transparent;
  color: #fff;
}

.light.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.light.btn-primary:hover g [fill] {
  fill: #fff;
}

.light.btn-primary:hover svg path {
  stroke: #fff;
}

.light.btn-secondary {
  background-color: #c5c5c5;
  border-color: #c5c5c5;
  color: #2C2C2C;
}

.light.btn-secondary g [fill] {
  fill: #2C2C2C;
}

[data-theme-version="dark"] .light.btn-secondary {
  background-color: rgba(44, 44, 44, 0.5);
  border-color: transparent;
  color: #fff;
}

.light.btn-secondary:hover {
  background-color: #2C2C2C;
  border-color: #2C2C2C;
  color: #fff;
}

.light.btn-secondary:hover g [fill] {
  fill: #fff;
}

.light.btn-warning {
  background-color: #ffeccc;
  border-color: #ffeccc;
  color: #FF9F00;
}

.light.btn-warning g [fill] {
  fill: #FF9F00;
}

[data-theme-version="dark"] .light.btn-warning {
  background-color: rgba(255, 159, 0, 0.1);
  border-color: transparent;
}

.light.btn-warning:hover {
  background-color: #FF9F00;
  border-color: #FF9F00;
  color: #fff;
}

.light.btn-warning:hover g [fill] {
  fill: #fff;
}

.light.btn-danger {
  background-color: #ffdede;
  border-color: #ffdede;
  color: #FF5E5E;
}

.light.btn-danger g [fill] {
  fill: #FF5E5E;
}

[data-theme-version="dark"] .light.btn-danger {
  background-color: rgba(255, 94, 94, 0.15);
  border-color: transparent;
}

.light.btn-danger:hover {
  background-color: #FF5E5E;
  border-color: #FF5E5E;
  color: #fff;
}

.light.btn-danger:hover g [fill] {
  fill: #fff;
}

.light.btn-danger:hover svg path {
  stroke: #fff;
}

.light.btn-dark {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e;
}

.light.btn-dark g [fill] {
  fill: #6e6e6e;
}

[data-theme-version="dark"] .light.btn-dark {
  background-color: rgba(110, 110, 110, 0.35);
  border-color: transparent;
  color: #fff;
}

.light.btn-dark:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.light.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn.btn-default {
  background: #ADB6C7;
}

.btn.tp-btn.btn-success {
  color: #3AC977;
}

.btn.tp-btn.btn-success g [fill] {
  fill: #3AC977;
}

.btn.tp-btn.btn-success:hover {
  background-color: #3AC977;
  border-color: #3AC977;
  color: #fff;
}

.btn.tp-btn.btn-success:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-info {
  color: #58bad7;
}

.btn.tp-btn.btn-info g [fill] {
  fill: #58bad7;
}

.btn.tp-btn.btn-info:hover {
  background-color: #58bad7;
  border-color: #58bad7;
  color: #fff;
}

.btn.tp-btn.btn-info:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-primary {
  color: var(--primary);
}

.btn.tp-btn.btn-primary g [fill] {
  fill: var(--primary);
}

.btn.tp-btn.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn.tp-btn.btn-primary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-primary:hover svg path {
  fill: #fff;
}

.btn.tp-btn.btn-secondary {
  color: #2C2C2C;
}

.btn.tp-btn.btn-secondary g [fill] {
  fill: #2C2C2C;
}

.btn.tp-btn.btn-secondary:hover {
  background-color: #2C2C2C;
  border-color: #2C2C2C;
  color: #fff;
}

.btn.tp-btn.btn-secondary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-warning {
  color: #FF9F00;
}

.btn.tp-btn.btn-warning g [fill] {
  fill: #FF9F00;
}

.btn.tp-btn.btn-warning:hover {
  background-color: #FF9F00;
  border-color: #FF9F00;
  color: #fff;
}

.btn.tp-btn.btn-warning:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-danger {
  color: #FF5E5E;
}

.btn.tp-btn.btn-danger g [fill] {
  fill: #FF5E5E;
}

.btn.tp-btn.btn-danger:hover {
  background-color: #FF5E5E;
  border-color: #FF5E5E;
  color: #fff;
}

.btn.tp-btn.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-light {
  color: #000;
}

.btn.tp-btn.btn-light g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn.btn-light:hover {
  background-color: #dfdfdf;
  border-color: #dfdfdf;
  color: #000;
}

.btn.tp-btn.btn-light:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-dark {
  color: #6e6e6e;
}

.btn.tp-btn.btn-dark g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn.btn-dark:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.btn.tp-btn.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn-light.btn-success {
  color: #3AC977;
}

.btn.tp-btn-light.btn-success g [fill] {
  fill: #3AC977;
}

.btn.tp-btn-light.btn-success:hover {
  background-color: #daf5e6;
  border-color: #daf5e6;
  color: #3AC977;
}

.btn.tp-btn-light.btn-success:hover g [fill] {
  fill: #3AC977;
}

.btn.tp-btn-light.btn-info {
  color: #58bad7;
}

.btn.tp-btn-light.btn-info g [fill] {
  fill: #58bad7;
}

.btn.tp-btn-light.btn-info:hover {
  background-color: #d3edf5;
  border-color: #d3edf5;
  color: #58bad7;
}

.btn.tp-btn-light.btn-info:hover g [fill] {
  fill: #58bad7;
}

.btn.tp-btn-light.btn-primary {
  color: var(--primary);
}

.btn.tp-btn-light.btn-primary g [fill] {
  fill: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover svg path {
  stroke: #fff;
}

.btn.tp-btn-light.btn-secondary {
  color: #2C2C2C;
}

.btn.tp-btn-light.btn-secondary g [fill] {
  fill: #2C2C2C;
}

.btn.tp-btn-light.btn-secondary:hover {
  background-color: #c5c5c5;
  border-color: #c5c5c5;
  color: #2C2C2C;
}

.btn.tp-btn-light.btn-secondary:hover g [fill] {
  fill: #2C2C2C;
}

.btn.tp-btn-light.btn-warning {
  color: #FF9F00;
}

.btn.tp-btn-light.btn-warning g [fill] {
  fill: #FF9F00;
}

.btn.tp-btn-light.btn-warning:hover {
  background-color: #ffeccc;
  border-color: #ffeccc;
  color: #FF9F00;
}

.btn.tp-btn-light.btn-warning:hover g [fill] {
  fill: #FF9F00;
}

.btn.tp-btn-light.btn-danger {
  color: #FF5E5E;
}

.btn.tp-btn-light.btn-danger g [fill] {
  fill: #FF5E5E;
}

.btn.tp-btn-light.btn-danger:hover {
  background-color: #ffdede;
  border-color: #ffdede;
  color: #FF5E5E;
}

.btn.tp-btn-light.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light.btn-dark {
  color: #6e6e6e;
}

.btn.tp-btn-light.btn-dark g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn-light.btn-dark:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e;
}

.btn.tp-btn-light.btn-dark:hover g [fill] {
  fill: #fff;
}

.shadow.btn-primary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
}

.shadow.btn-secondary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(44, 44, 44, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(44, 44, 44, 0.2) !important;
}

.shadow.btn-warning {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 159, 0, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 159, 0, 0.2) !important;
}

.shadow.btn-danger {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 94, 94, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 94, 94, 0.2) !important;
}

.shadow.btn-info {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(88, 186, 215, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(88, 186, 215, 0.2) !important;
}

.shadow.btn-success {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(58, 201, 119, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(58, 201, 119, 0.2) !important;
}

.btn-xxs {
  padding: 0.375rem 0.9375rem;
  font-size: 0.6875rem;
  line-height: 1.3;
}

.btn-xs {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600;
}

.btn-sm,
.btn-group-sm>.btn {
  font-size: 0.813rem !important;
  padding: 5px 12px;
  font-weight: 400;
  border-radius: 0.25rem;
  line-height: 18px;
  border-radius: 0.25rem;
}

.btn-md {
  font-size: 0.875rem !important;
  padding: 0.875rem 1.25rem;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 1rem 2rem;
  font-size: 1.125rem !important;
}

@media only screen and (max-width: 35.9375rem) {

  .btn-lg,
  .btn-group-lg>.btn {
    padding: 0.75rem 1.25rem;
  }
}

.btn-xl {
  padding: 0.6rem 1rem;
}

.btn-xl.btn-default {
  font-weight: 600;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 2.5rem !important;
}

.btn-icon-end {
  border-left: 0.0625rem solid #dfdfdf;
  display: inline-block;
  margin: -.8rem 0 -.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 0.8rem 0 0.8rem 1.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .btn-icon-end {
    padding: 0.7rem 0 0.7rem 1.25rem;
  }
}

.btn-icon-start {
  background: #fff;
  border-radius: 0.425rem;
  display: inline-block;
  margin: -0.3rem 0.35rem -0.2rem -0.888rem;
  padding: 0.3rem 0.6rem 0.2rem;
  float: left;
}

@media only screen and (max-width: 87.5rem) {
  .btn-icon-start {
    margin: -0.3rem 0.75rem -0.5rem -0.5rem;
  }
}

[direction="rtl"] .btn-icon-start {
  margin: -.5rem 0.5rem -0.5rem -1rem;
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .btn-icon-start {
    margin: -0.5rem 0.75rem -0.5rem -0.880rem;
  }
}

[direction="rtl"] .btn-icon-end {
  border-left: 0rem solid white;
  display: inline-block;
  margin: -.8rem 1rem -.8rem 0;
  padding: 0.4375rem 1rem 0.4375rem 0;
  border-right: 0.0625rem solid white;
}

.toggle-dropdown::after {
  margin-left: 0.755em;
}

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem;
}

.social-icon .btn {
  padding: .7rem 1.4rem;
}

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important;
}

.btn-circle-sm {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 1.8rem;
}

.btn-circle-md {
  width: 6rem;
  height: 6rem;
  font-size: 2.5rem;
}

.btn-circle-md i {
  font-size: 2.4rem;
}

.btn-circle-lg {
  width: 8rem;
  height: 8rem;
  font-size: 3.2rem;
}

.btn-circle-lg i {
  font-size: 3.1rem;
}

.btn-page .btn {
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
}

.size-1 {
  min-width: 10rem !important;
  font-size: 1.5rem;
  padding: 0.68rem 0.75rem;
}

.size-2 {
  font-size: 1.25rem;
  min-width: 8.125rem !important;
  padding: 0.57rem 0.75rem;
}

.size-3 {
  font-size: 0.875rem;
  min-width: 6.875rem !important;
  padding: 0.536rem 0.75rem;
}

.size-4 {
  font-size: 0.875rem;
  min-width: 6.25rem !important;
}

.size-5 {
  font-size: 0.875rem;
  min-width: 5.625rem !important;
  padding: .22rem 0.75rem;
}

.size-6 {
  font-size: 0.8125rem;
  min-width: 5rem !important;
  padding: 0.097rem 0.75rem;
}

.size-7 {
  font-size: 0.75rem;
  min-width: 3.75rem !important;
  padding: 0.001rem 0.75rem;
}

.btn-light {
  background: #dfdfdf;
  border-color: #dfdfdf;
  color: #000 !important;
}

.btn-light:active,
.btn-light:focus,
.btn-light:hover {
  background: #f9f9f9;
  color: #6e6e6e;
  border-color: #f9f9f9;
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-outline-primary:hover svg path {
  stroke: #fff;
}

.btn-outline-warning:hover {
  color: #fff;
}

.btn-outline-light {
  color: #6e6e6e;
}

[data-theme-version="dark"] .btn-outline-light {
  color: #888888;
}

[data-theme-version="dark"] .btn-outline-secondary {
  color: #888888;
}

[data-theme-version="dark"] .btn-outline-dark {
  color: #888888;
}

.btn-dark {
  background: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.btn-dark:active,
.btn-dark:hover {
  background: #888888;
  color: #fff;
  border-color: #555555;
}

.btn-dark:focus {
  background: #888888;
}

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 1.875rem;
  border-bottom-left-radius: 1.875rem;
}

.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
}

.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-info:hover {
  color: #fff;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
  background: #2d4373;
  color: #fff;
  border-color: #2d4373;
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
  background: #0c85d0;
  color: #fff;
  border-color: #0c85d0;
}

.btn-youtube {
  background: #FF0000;
  border-color: #FF0000;
  color: #fff;
}

.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
  background: #cc0000;
  color: #fff;
  border-color: #cc0000;
}

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff;
}

.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
  background: #992180;
  color: #fff;
  border-color: #992180;
}

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover {
  background: #8c0615;
  color: #fff;
  border-color: #8c0615;
}

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff;
}

.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
  background: #005983;
  color: #fff;
  border-color: #005983;
}

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff;
}

.btn-google-plus:active,
.btn-google-plus:focus,
.btn-google-plus:hover {
  background: #be2d23;
  color: #fff;
  border-color: #be2d23;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff;
}

.btn-google:active,
.btn-google:focus,
.btn-google:hover {
  background: #1266f1;
  color: #fff;
  border-color: #1266f1;
}

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000;
}

.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
  background: #ccca00;
  color: #000;
  border-color: #ccca00;
}

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff;
}

.btn-whatsapp:active,
.btn-whatsapp:focus,
.btn-whatsapp:hover {
  background: #1da851;
  color: #fff;
  border-color: #1da851;
}

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff;
}

.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover {
  background: #222e3d;
  color: #fff;
  border-color: #222e3d;
}

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}

.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover {
  background: #cc3700;
  color: #fff;
  border-color: #cc3700;
}

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff;
}

.btn-spotify:active,
.btn-spotify:focus,
.btn-spotify:hover {
  background: #18aa4c;
  color: #fff;
  border-color: #18aa4c;
}

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff;
}

.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover {
  background: #2d0165;
  color: #fff;
  border-color: #2d0165;
}

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-dribbble:active,
.btn-dribbble:focus,
.btn-dribbble:hover {
  background: #e51e6b;
  color: #fff;
  border-color: #e51e6b;
}

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff;
}

.btn-skype:active,
.btn-skype:focus,
.btn-skype:hover {
  background: #008abd;
  color: #fff;
  border-color: #008abd;
}

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff;
}

.btn-quora:active,
.btn-quora:focus,
.btn-quora:hover {
  background: #771800;
  color: #fff;
  border-color: #771800;
}

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
  background: #1295bf;
  color: #fff;
  border-color: #1295bf;
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-close:focus {
  box-shadow: none;
}

.asColorPicker-trigger {
  background: none;
}

.dz-card .nav-link {
  padding: 5px 12px;
  border-radius: 0.375rem;
}

.btn.btn-icon-lg {
  padding: 1rem 1.2rem;
}

.btn.btn-icon-md {
  padding: 0.719rem 0.919rem;
}

.btn.btn-icon-sm {
  padding: 0.438rem 0.6rem;
}

.btn.btn-icon-xs {
  padding: 0.375rem 0.5375rem;
}

.btn.btn-icon-xxs {
  padding: 0.275rem 0.4375rem;
}

.btn.btn-sharp {
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  padding: 0;
}

.btn.btn-success:hover,
.btn.btn-secondary:hover,
.btn.btn-warning:hover,
.btn.btn-warning:hover,
.btn.btn-primary:hover,
.btn.btn-danger:hover,
.btn.btn-info:hover {
  color: #fff;
}

.card {
  margin-bottom: 1.563rem;
  background-color: #fff;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.375rem;
  height: calc(100% - 25px);
  border: 1px solid #E6E6E6;
}

@media only screen and (max-width: 35.9375rem) {
  .card {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }
}

.card-body {
  padding: 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .card-body {
    padding: 1rem;
  }
}

.card-title {
  font-size: 1rem;
  font-weight: 500;
  color: var(--secondary);
  text-transform: capitalize;
}

.card-title--large {
  font-size: 1.5rem;
}

.card-title--medium {
  font-size: 1rem;
}

.card-title--small {
  font-size: 0.875rem;
}

.card-header {
  border-color: #E6E6E6;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.25rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 35.9375rem) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}

[data-theme-version="dark"] .card-header {
  border-color: #444444;
}

.card-header .card-title {
  margin-bottom: 0rem;
}

.card-header .subtitle {
  padding-top: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.card-footer {
  border-color: #E6E6E6;
  background: transparent;
  padding: 1rem 1.25rem 1rem;
}

[data-theme-version="dark"] .card-footer {
  border-color: #444444;
}

@media only screen and (max-width: 35.9375rem) {
  .card-footer {
    padding: 1.25rem 1rem 1.25rem;
  }
}

.transparent-card.card {
  background: transparent;
  border: 0.0625rem solid transparent;
  box-shadow: none;
}

.card-action>a {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  background: var(--primary-dark);
  color: #fff;
  margin-right: 0.5rem;
}

[data-theme-version="dark"] .card-action>a {
  background: #1E1E1E;
}

.card-action>a:last-child {
  margin-right: 0;
}

.card-action>a:hover,
.card-action>a:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action>a:hover,
[data-theme-version="dark"] .card-action>a:focus {
  background: #1E1E1E;
}

.card-action>a i,
.card-action>a span {
  font-size: 1rem;
}

.card-action .dropdown {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  margin-right: 0.5rem;
  top: -0.125rem;
  position: relative;
  display: inline-block;
  background: var(--primary-dark);
  color: var(--primary);
}

[data-theme-version="dark"] .card-action .dropdown {
  background: #1E1E1E;
}

.card-action .dropdown:hover,
.card-action .dropdown:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action .dropdown:hover,
[data-theme-version="dark"] .card-action .dropdown:focus {
  background: #1E1E1E;
}

.card-action .dropdown .btn {
  padding: 0;
  line-height: 1.6875rem;
  color: #fff;
}

.card-action .dropdown .btn:focus {
  box-shadow: none;
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: var(--primary-dark);
  padding: 0.625rem;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header .date_picker {
  display: inline-block;
  padding: 0.5rem;
  border: 0.0625rem solid #E6E6E6;
  cursor: pointer;
  border-radius: .375rem;
}

.card-header .border-0 {
  padding-bottom: 0;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.carousel-custom-prev,
.carousel-custom-next {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center;
}

.carousel-custom-prev i,
.carousel-custom-next i {
  font-size: 1rem;
}

.carousel-custom-prev:hover,
.carousel-custom-next:hover {
  background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
  color: #fff;
}

.carousel-custom-next {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.carousel-custom-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.carousel-caption {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.carousel-caption h5,
.carousel-caption .h5 {
  color: #fff;
  font-size: 1.8rem;
}

.carousel-caption p {
  margin-bottom: 0;
}

.carousel-indicators {
  z-index: 1;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-outline {
  border: 0.1rem solid var(--primary);
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  z-index: 4;
  overflow: hidden;
  border-radius: 0.375rem;
  box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15);
  margin-top: 0;
  z-index: 9;
}

.dropdown-menu .dropdown-item {
  font-size: 0.813rem;
  color: #888888;
  padding: 0.5rem 1.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .dropdown-menu .dropdown-item {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
  }
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  color: var(--primary);
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

[direction="rtl"] .dropdown-menu {
  right: auto !important;
}

.dropdown-toggle-split {
  padding: 0 0.625rem;
  opacity: 0.85;
}

.dropdown-toggle-split:after {
  margin-left: 0 !important;
}

.dropdown-toggle-split:active,
.dropdown-toggle-split:focus,
.dropdown-toggle-split:hover {
  opacity: 1;
}

/* .dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
	content:"\f107";
	font-family:FontAwesome;
	border: 0;
    vertical-align: middle;
    margin-left: 0.25em;
	line-height: 1;
} */
/* .dropup .dropdown-toggle:after{
	content:"\f106";
} */
/* .dropleft .dropdown-toggle::before {
	content:"\f104";
}
.dropright .dropdown-toggle::before {
	content:"\f105";
} */
.dropright .dropdown-toggle::after {
  content: none;
}

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
}

.custom-dropdown .dropdown-menu {
  border: 0rem;
  min-width: 10rem;
}

.card-action .custom-dropdown {
  margin: 0rem;
  background: var(--rgba-primary-1);
}

.card-action .custom-dropdown.show,
.card-action .custom-dropdown:focus,
.card-action .custom-dropdown:hover {
  background: var(--primary);
  color: #fff;
}

.card-action .custom-dropdown i {
  display: inline-block;
  padding-top: 0.5625rem;
}

.dropdown .dropdown-dots {
  position: relative;
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(110, 110, 110, 0.4);
  border-radius: 0.3125rem;
  display: block;
}

.dropdown .dropdown-dots:after,
.dropdown .dropdown-dots:before {
  content: "";
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(110, 110, 110, 0.4);
  position: absolute;
  border-radius: 0.3125rem;
}

.dropdown .dropdown-dots:after {
  right: -0.5rem;
}

.dropdown .dropdown-dots:before {
  left: -0.5rem;
}

.dropdown .dropdown-dots.text-white {
  background: rgba(255, 255, 255, 0.7);
}

.dropdown .dropdown-dots.text-white:after,
.dropdown .dropdown-dots.text-white:before {
  background: rgba(255, 255, 255, 0.7);
}

.header-profile2 .dropdown-menu {
  /* width: 275px; */
  border-radius: 0px 0px 0.375rem 0.375rem;
  margin: 0;
}

.header-profile2 .dropdown-menu .dropdown-item {
  padding: 0.6rem 1.25rem;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 3px;
  padding: 6px 5px;
}

.grid-col {
  padding: 0.5rem !important;
  background: #f2f4fa;
}

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center;
}

.row.grid .grid-col:first-child {
  text-align: left;
}

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: .2rem .8rem;
  border-radius: 0.5rem;
}

.label-fixed {
  width: 7.5rem;
  padding: .6rem 0;
}

.label-fixed-lg {
  width: 9.5rem;
  padding: .6rem 0;
}

.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}

.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}

.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}

.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}

.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #ADB6C7;
}

.label-primary {
  background: var(--primary);
  color: #fff;
}

.label-secondary {
  background: #2C2C2C;
  color: #fff;
}

.label-info {
  background: #58bad7;
  color: #fff;
}

.label-success {
  background: #3AC977;
  color: #fff;
}

.label-warning {
  background: #FF9F00;
  color: #fff;
}

.label-danger {
  background: #FF5E5E;
  color: #fff;
}

.label-light {
  background: #dfdfdf;
  color: #000;
}

.label-dark {
  background: #6e6e6e;
  color: #fff;
}

code {
  word-break: break-word;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  background: white;
  color: #FF5E5E;
}

[data-theme-version="dark"] code {
  background: rgba(255, 94, 94, 0.1);
}

.heading-labels {
  color: #333333;
}

.heading-labels>* {
  margin-bottom: .8rem;
}

.heading-labels h1 .label,
.heading-labels .h1 .label {
  font-size: 1.125rem;
  font-weight: normal;
  padding: .4rem .9rem;
}

.heading-labels h2 .label,
.heading-labels .h2 .label {
  font-size: 1rem;
  font-weight: normal;
  padding: 0.3rem .9rem;
}

.heading-labels h3 .label,
.heading-labels .h3 .label {
  font-size: 0.875rem;
  font-weight: normal;
}

.heading-labels h4 .label,
.heading-labels .h4 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h5 .label,
.heading-labels .h5 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h6 .label,
.heading-labels .h6 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #CCCCCC;
  padding: 1rem 1.5rem;
}

.list-group-item.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item {
  border-color: #444444;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #fff;
  background-color: #2C2C2C;
  border-color: #2C2C2C;
}

[class*="bg-"] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05);
}

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05);
}

.bg-primary .list-group-flush .list-group-item,
.bg-warning .list-group-flush .list-group-item {
  color: #fff;
}

/*  */
.vertical-card__menu:hover {
  box-shadow: none;
}

.vertical-card__menu--image {
  text-align: center;
}

.vertical-card__menu--image img {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
  background: #FF5E5E;
}

.vertical-card__menu--status.closed:after {
  border-top: 0.625rem solid #FF5E5E;
}

.vertical-card__menu--status.closed .ribbon-curve {
  border-top: 0.6875rem solid #FF5E5E;
  border-bottom: 0.625rem solid #FF5E5E;
}

.vertical-card__menu--desc p {
  margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700;
}

.vertical-card__menu--rating {
  font-size: 0.75rem;
}

.vertical-card__menu--title {
  font-weight: 700;
}

.vertical-card__menu--button button {
  font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
  padding: 0.9375rem 1.25rem;
  background-color: #E6E6E6;
  border-top: none;
}

@media only screen and (min-width: 75rem) and (max-width: 90rem) {
  .vertical-card__menu .card-footer {
    padding: 0.9375rem;
  }
}

.vertical-card__menu .card-footer span {
  color: #6e6e6e;
  margin-right: 0.3125rem;
}

.horizontal-card__menu {
  box-shadow: 0 0 0.4375rem rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;
}

@media (max-width: 575.98px) {
  .horizontal-card__menu {
    display: block;
  }
}

.horizontal-card__menu .card-body {
  padding: 0;
  padding: 1.25rem 1.875rem;
}

.horizontal-card__menu--image {
  flex-basis: 30%;
  max-width: 30%;
}

.horizontal-card__menu--image img {
  height: 100%;
  width: 100%;
}

@media (max-width: 575.98px) {
  .horizontal-card__menu--image {
    max-width: unset;
    flex-basis: 100%;
  }
}

.horizontal-card__menu--title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
  margin-right: 0.5rem;
}

.horizontal-card__menu--price {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}

.horizontal-card__menu--rating {
  font-size: 0.625rem;
}

.horizontal-card__menu--footer {
  margin-top: 0.625rem;
}

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5;
}

.modal-header {
  padding: 1rem 1.875rem;
}

.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 1.875rem;
  font-weight: 100;
}

.modal-body {
  padding: 1.875rem;
}

.modal-footer {
  padding: 1rem 1.875rem;
}

.modal-content {
  border-radius: 0.375rem;
}

.modal-backdrop {
  z-index: 10 !important;
}

.modal-dialog-center {
  min-height: calc(100% - 3.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination {
  margin-bottom: 1.25rem;
}

.pagination .page-item.page-indicator .page-link {
  padding: .69rem .8rem;
  font-size: 1.2rem;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  border-radius: 0.375rem;
}

.pagination .page-item.page-indicator:hover .page-link {
  color: #6e6e6e;
}

.pagination .page-item .page-link {
  z-index: 1;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  color: #6e6e6e;
  margin-left: 7px;
  border: 0.0625rem solid #E6E6E6;
}

[data-theme-version="dark"] .pagination .page-item .page-link {
  border-color: #444444;
  color: #828690;
  background: rgba(255, 255, 255, 0);
}

.pagination .page-item .page-link:hover i,
.pagination .page-item .page-link span {
  color: #fff;
}

.pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.pagination .page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .pagination .page-item.active .page-link {
  color: #fff;
}

.pagination .page-item .page-link {
  color: #6e6e6e;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.pagination .page-item:last-child .page-link {
  margin-right: 0;
}

[direction="rtl"] .pagination .page-item:first-child .page-link {
  margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: #fff;
  border-color: transparent;
}

[data-theme-version="dark"] .pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: #242424;
}

.pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

[data-theme-version="dark"] .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

.pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #FF5E5E;
  border-color: #FF5E5E;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #FF5E5E;
  border-color: #FF5E5E;
}

.pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #58bad7;
  border-color: #58bad7;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #58bad7;
  border-color: #58bad7;
}

.pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #FF9F00;
  border-color: #FF9F00;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #FF9F00;
  border-color: #FF9F00;
}

.pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #888888;
}

[data-theme-version="dark"] .pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: transparent;
  color: var(--primary);
}

.pagination-primary .page-item:hover .page-link,
.pagination-primary .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

[data-theme-version="dark"] .pagination-primary .page-item:hover .page-link,
[data-theme-version="dark"] .pagination-primary .page-item.active .page-link {
  color: #fff;
}

.pagination-danger .page-item .page-link {
  background: #ffdede;
  border-color: #ffdede;
  color: #FF5E5E;
}

[data-theme-version="dark"] .pagination-danger .page-item .page-link {
  background: rgba(255, 94, 94, 0.15);
  border-color: transparent;
  color: #FF5E5E;
}

.pagination-danger .page-item:hover .page-link,
.pagination-danger .page-item.active .page-link {
  background: #FF5E5E;
  border-color: #FF5E5E;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 94, 94, 0.2);
}

[data-theme-version="dark"] .pagination-danger .page-item:hover .page-link,
[data-theme-version="dark"] .pagination-danger .page-item.active .page-link {
  color: #fff;
}

.pagination-info .page-item .page-link {
  background: #d3edf5;
  border-color: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .pagination-info .page-item .page-link {
  background: rgba(88, 186, 215, 0.1);
  border-color: transparent;
  color: #58bad7;
}

.pagination-info .page-item:hover .page-link,
.pagination-info .page-item.active .page-link {
  background: #58bad7;
  border-color: #58bad7;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(88, 186, 215, 0.2);
}

.pagination-warning .page-item .page-link {
  background: #ffeccc;
  border-color: #ffeccc;
  color: #FF9F00;
}

[data-theme-version="dark"] .pagination-warning .page-item .page-link {
  background: rgba(255, 159, 0, 0.1);
  border-color: transparent;
  color: #FF9F00;
}

.pagination-warning .page-item:hover .page-link,
.pagination-warning .page-item.active .page-link {
  background: #FF9F00;
  border-color: #FF9F00;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 159, 0, 0.2);
}

.pagination-gutter .page-item {
  margin-right: 0.4375rem;
}

.pagination-gutter .page-item .page-link {
  border-radius: 0.375rem !important;
}

.pagination-circle .page-item {
  margin-right: 0.4375rem;
}

.pagination-circle .page-item .page-link,
.pagination-circle .page-item .page-link {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 50% !important;
  padding: 0;
}

.pagination-circle .page-item.page-indicator .page-link {
  width: 2.5rem;
  border: 1px solid var(--primary);
  border-radius: 22px !important;
  line-height: 1.3rem;
  height: 2.5rem;
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.pagination-circle .page-item.page-indicator .page-link:hover {
  color: #fff;
  background: var(--primary);
}

.pagination.pagination-md .page-item .page-link {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
  font-size: 0.75rem;
}

.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 1rem;
  border-radius: 0.375rem;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
  font-size: 0.625rem;
}

.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  border-radius: 0.375rem;
}

.popover {
  border: 0.125rem solid #2C2C2C;
  min-width: 13.125rem;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
}

[data-theme-version="dark"] .popover {
  background-color: #1E1E1E;
}

.popover-header {
  background: #2C2C2C;
  color: #fff;
  font-weight: 300;
}

.popover-header::before {
  border-bottom: 0 !important;
}

.popover-body {
  font-size: 0.75rem;
}

[data-theme-version="dark"] .popover .popover-header {
  border-color: #242424;
}

@media only screen and (max-width: 47.9375rem) {
  .popover {
    z-index: 1;
  }
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 0.5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: .5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover,
.bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: var(--primary);
  color: #fff;
  box-shadow: none;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[data-popper-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 0rem;
  border-top-color: #2C2C2C;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 0rem;
  border-left-color: #2C2C2C;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 0rem;
  border-right-color: #2C2C2C;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0rem;
  border-bottom-color: #2C2C2C;
}

.note-popover {
  display: none;
}

.progress-bar {
  background-color: var(--primary);
}

.progress-bar-primary {
  background-color: var(--primary);
}

.progress-bar-secondary {
  background-color: var(--secondary);
}

.progress-bar-success {
  background-color: #3AC977;
}

.progress-bar-info {
  background-color: #58bad7;
}

.progress-bar-danger {
  background-color: #FF5E5E;
}

.progress-bar-warning {
  background-color: #FF9F00;
}

.progress-bar-pink {
  background-color: #e83e8c;
}

.progress {
  height: 5px;
  background-color: #f6f6f6;
  overflow: hidden;
}

[data-theme-version="dark"] .progress {
  background-color: #1E1E1E;
}

.progress-bar {
  border-radius: 0.375rem;
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
}

@media (max-width: 991.98px) {
  .progress-vertical {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);
}

@media (max-width: 991.98px) {
  .progress-vertical-bottom {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
}

.ribbon__one {
  top: 0.9375rem;
  left: -0.6875rem;
  min-height: 1.25rem;
  min-width: 3.25rem;
  text-align: center;
  padding: 0.1875rem 0.625rem;
  background: #3AB54B;
  color: #fff;
  font-size: 0.625rem;
}

.ribbon__one::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0.625rem solid #239132;
  border-left: 0.6875rem solid transparent;
  left: 0;
  content: "";
  bottom: -0.625rem;
}

.ribbon__two {
  width: 3.125rem;
  height: 3.125rem;
  display: inline-block;
  background: #FF9F00;
  line-height: 3.125rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  right: 0.9375rem;
  top: 0.9375rem;
  border-radius: 0.1875rem;
}

.ribbon__three {
  left: -1.875rem;
  top: 0.875rem;
  width: 6.875rem;
  height: 1.5625rem;
  background-color: #FF5E5E;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}

.ribbon__four {
  left: 0.5rem;
  top: -0.5rem;
  width: 6.875rem;
  height: 3.125rem;
  background-color: var(--primary);
  z-index: auto;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ribbon__four:after {
  right: -0.3125rem;
}

.ribbon__four:before {
  left: -0.3125rem;
}

.ribbon__four:after,
.ribbon__four:before {
  z-index: -1;
  background-color: var(--rgba-primary-1);
  top: 0.1875rem;
  transform: rotate(45deg);
  content: "";
  height: 0.625rem;
  width: 0.625rem;
  position: absolute;
}

.ribbon__five {
  left: -1.875rem;
  top: 0.625rem;
  width: 6.875rem;
  height: 1.875rem;
  background-color: var(--primary);
  transform: rotate(-45deg);
  font-size: 0.75rem;
  color: #fff;
  padding-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.ribbon__five::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.125rem 3.125rem 3.125rem;
  border-color: transparent transparent var(--primary) transparent;
  left: 0.25rem;
  top: -2.8125rem;
}

.ribbon__six {
  left: 0;
  top: 1.125rem;
  width: 6.875rem;
  height: 2.375rem;
  background-color: var(--primary);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ribbon-curve {
  position: absolute;
  top: 0;
  right: -0.375rem;
  width: 0.625rem;
  height: 0.6875rem;
  border-top: 0.6875rem solid #3AB54B;
  border-bottom: 0.625rem solid #3AB54B;
  border-right: 0.3125rem solid transparent;
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 0.5rem;
  height: 0.5rem;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  background-color: #f5f5f5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
}

.multi-steps>li.is-active:before,
.multi-steps>li.is-active~li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400;
}

.multi-steps>li.is-active:after,
.multi-steps>li.is-active~li:after {
  background-color: #E6E6E6;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.multi-steps>li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: var(--primary);
}

@media (max-width: 575.98px) {
  .multi-steps>li {
    font-size: 0.75rem;
  }
}

.multi-steps>li:before {
  content: '\2713';
  display: block;
  margin: 0 auto 0.25rem;
  background-color: #fff;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--primary);
  border-radius: 0.3125rem;
}

@media (max-width: 575.98px) {
  .multi-steps>li:before {
    width: 1.5625rem;
    height: 1.5625rem;
    line-height: 1.3125rem;
  }
}

.multi-steps>li:after {
  content: '';
  height: 0.125rem;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  top: 0.75rem;
  left: 50%;
}

[direction="rtl"] .multi-steps>li:after {
  left: auto;
  right: 50%;
}

@media (max-width: 575.98px) {
  .multi-steps>li:after {
    top: 0.75rem;
  }
}

.multi-steps>li:last-child:after {
  display: none;
}

.multi-steps>li.is-active:before {
  background-color: #fff;
  border-color: var(--primary);
}

.multi-steps>li.is-active~li {
  color: #888888;
}

.multi-steps>li.is-active~li:before {
  background-color: #E6E6E6;
  border-color: #E6E6E6;
}

.nav-pills .nav-link {
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 13px;
}

.default-tab .nav-link {
  background: transparent;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  font-weight: 500;
}

.default-tab .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}

.default-tab .nav-link:focus,
.default-tab .nav-link:hover,
.default-tab .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff #ebeef6;
  border-radius: 0.375rem 0.375rem 0 0;
  color: var(--primary);
}

[data-theme-version="dark"] .default-tab .nav-link:focus,
[data-theme-version="dark"] .default-tab .nav-link:hover,
[data-theme-version="dark"] .default-tab .nav-link.active {
  background-color: var(--rgba-primary-1);
  border-color: transparent transparent #444444 transparent;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
}

.custom-tab-1 .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}

.custom-tab-1 .nav-link:focus,
.custom-tab-1 .nav-link:hover,
.custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary);
  border-radius: 0;
  color: var(--primary);
  border-width: 0 0rem 0.1875rem 0;
}

[data-theme-version="dark"] .custom-tab-1 .nav-link:focus,
[data-theme-version="dark"] .custom-tab-1 .nav-link:hover,
[data-theme-version="dark"] .custom-tab-1 .nav-link.active {
  background-color: var(--rgba-primary-1);
}

.nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
}

[data-theme-version="dark"] .nav-pills.light .nav-link.active,
[data-theme-version="dark"] .nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--primary);
  box-shadow: 0 0 1.25rem 0 var(--rgba-primary-2);
}

.tooltip-wrapper button:not(:last-child) {
  margin-right: 0.5rem;
}

.tooltip-wrapper button:hover {
  background: var(--primary);
  color: #fff;
}

.tooltip-wrapper button {
  margin-bottom: .5rem;
}

.tooltip-wrapper button:focus {
  box-shadow: none;
}

.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #333333;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #333333;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}

.widget-timeline .timeline:before {
  top: 1.25rem;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 0.188rem;
  left: 0.5625rem;
  margin-right: -0.0938rem;
  background: #E6E6E6;
}

[data-theme-version="dark"] .widget-timeline .timeline:before {
  background: #444444;
}

[direction="rtl"] .widget-timeline .timeline:before {
  left: auto;
  right: 0.5625rem;
  margin-right: auto;
  margin-left: -0.0938rem;
}

.widget-timeline .timeline>li {
  margin-bottom: 0.9375rem;
  position: relative;
}

.widget-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.widget-timeline .timeline>li:after {
  content: " ";
  display: table;
  clear: both;
}

.widget-timeline .timeline>li>.timeline-panel {
  border-radius: 0.375rem;
  padding: 0.9375rem 1.25rem;
  position: relative;
  display: block;
  margin-left: 2.5rem;
  border-width: 0.0625rem;
  border-style: solid;
}

.widget-timeline .timeline>li>.timeline-panel span {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0.3125rem;
  opacity: 0.8;
  letter-spacing: 0.0625rem;
}

.widget-timeline .timeline>li>.timeline-panel p {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.widget-timeline .timeline>li>.timeline-panel h6,
.widget-timeline .timeline>li>.timeline-panel .h6 {
  font-size: 13px;
}

.widget-timeline .timeline>li>.timeline-panel:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  background: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0rem 0rem 0.0625rem 0.0625rem;
  display: block;
  position: absolute;
  left: -0.3125rem;
  transform: rotate(45deg);
  top: 0.9375rem;
}

.widget-timeline .timeline>li>.timeline-badge {
  border-radius: 50%;
  height: 1.275rem;
  left: 0;
  position: absolute;
  top: 0.625rem;
  width: 1.275rem;
  border-width: 0.125rem;
  border-style: solid;
  background: #fff;
  padding: 0.25rem;
}

[data-theme-version="dark"] .widget-timeline .timeline>li>.timeline-badge {
  background-color: #242424;
}

.widget-timeline .timeline>li>.timeline-badge:after {
  content: "";
  width: 0.525rem;
  height: 0.525rem;
  border-radius: 100%;
  display: block;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 1.1875rem;
}

.widget-timeline .timeline-body>p {
  font-size: 0.75rem;
}

.widget-timeline .timeline-badge.primary {
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.primary {
  border-color: var(--rgba-primary-1);
}

.widget-timeline .timeline-badge.primary:after {
  background-color: var(--primary);
  box-shadow: 0 0.3125rem 0.625rem 0 var(--rgba-primary-2);
}

.widget-timeline .timeline-badge.primary+.timeline-panel {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.primary+.timeline-panel {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
}

.widget-timeline .timeline-badge.success {
  border-color: #daf5e6;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.success {
  border-color: rgba(58, 201, 119, 0.1);
}

.widget-timeline .timeline-badge.success:after {
  background-color: #3AC977 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(58, 201, 119, 0.2);
}

.widget-timeline .timeline-badge.success+.timeline-panel {
  background: #daf5e6;
  border-color: #daf5e6;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.success+.timeline-panel {
  background-color: rgba(58, 201, 119, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.warning {
  border-color: #ffeccc;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.warning {
  border-color: rgba(255, 159, 0, 0.1);
}

.widget-timeline .timeline-badge.warning:after {
  background-color: #FF9F00 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(255, 159, 0, 0.2);
}

.widget-timeline .timeline-badge.warning+.timeline-panel {
  background: #ffeccc;
  border-color: #ffeccc;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.warning+.timeline-panel {
  background-color: rgba(255, 159, 0, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.danger {
  border-color: #ffdede;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.danger {
  border-color: rgba(255, 94, 94, 0.15);
}

.widget-timeline .timeline-badge.danger:after {
  background-color: #FF5E5E !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(255, 94, 94, 0.2);
}

.widget-timeline .timeline-badge.danger+.timeline-panel {
  background: #ffdede;
  border-color: #ffdede;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.danger+.timeline-panel {
  background-color: rgba(255, 94, 94, 0.15);
  border-color: transparent;
}

.widget-timeline .timeline-badge.info {
  border-color: #d3edf5;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.info {
  border-color: rgba(88, 186, 215, 0.1);
}

.widget-timeline .timeline-badge.info:after {
  background-color: #58bad7 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(88, 186, 215, 0.2);
}

.widget-timeline .timeline-badge.info+.timeline-panel {
  background: #d3edf5;
  border-color: #d3edf5;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.info+.timeline-panel {
  background-color: rgba(88, 186, 215, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.dark {
  border-color: #eeeeee;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.dark {
  border-color: rgba(110, 110, 110, 0.35);
}

.widget-timeline .timeline-badge.dark:after {
  background-color: #6e6e6e !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(110, 110, 110, 0.2);
}

.widget-timeline .timeline-badge.dark+.timeline-panel {
  background: #eeeeee;
  border-color: #eeeeee;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.dark+.timeline-panel {
  background-color: rgba(110, 110, 110, 0.35);
  border-color: transparent;
}

.widget-timeline.style-1 .timeline-panel {
  background: transparent;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 0.2125rem;
  border-radius: 0;
  padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  left: -0.3625rem;
  width: 0.3375rem;
  height: 0.3375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel {
  border-color: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel:after {
  background: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel {
  border-color: #3AC977;
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel:after {
  background: #3AC977;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel {
  border-color: #FF9F00;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel:after {
  background: #FF9F00;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel {
  border-color: #FF5E5E;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel:after {
  background: #FF5E5E;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel {
  border-color: #58bad7;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel:after {
  background: #58bad7;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel {
  border-color: #6e6e6e;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel:after {
  background: #6e6e6e;
}

#chart_widget_4 {
  height: 15.9375rem !important;
}

#chart_widget_1 {
  margin-top: -5px;
}

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: #46ffc8;
}

#chart_widget_5 .ct-line {
  stroke-width: 0.0625rem;
}

#chart_widget_5 .ct-point {
  stroke-width: 0.125rem;
}

#chart_widget_5 .ct-series-a .ct-area {
  fill: #20dea6;
}

#chart_widget_5 .ct-area {
  fill-opacity: 1;
}

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #58bad7;
}

#chart_widget_6 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_6 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_6 .ct-series-a .ct-area {
  fill: #58bad7;
}

#chart_widget_6 .ct-area {
  fill-opacity: .5;
}

#chart_widget_8 {
  height: 15.9375rem;
}

#chart_widget_8 .ct-series-a .ct-line,
#chart_widget_8 .ct-series-a .ct-point {
  stroke: #58bad7;
}

#chart_widget_8 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_8 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_8 .ct-series-a .ct-area {
  fill: #58bad7;
}

#chart_widget_8 .ct-area {
  fill-opacity: .5;
}

#chart_widget_9,
#chart_widget_10 {
  height: 15.625rem !important;
}

#chart_widget_11 .ct-slice-donut,
#chart_widget_12 .ct-slice-donut,
#chart_widget_13 .ct-slice-donut {
  stroke-width: 1.5625rem !important;
}

#chart_widget_11 {
  height: 16.875rem !important;
}

#chart_widget_17 {
  height: 9.375rem !important;
}

.chart_widget_tab_one .nav-link {
  border: 0.0625rem solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
  background-color: var(--primary);
  border: 0.0625rem solid var(--primary);
  color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
  border: 0.0625rem solid var(--primary);
}

.chart_widget_tab_one .nav-link:hover {
  border: 0.0625rem solid #ddd;
}

[data-theme-version="dark"] .ccc-widget>div {
  background: #242424 !important;
  border-color: #444444 !important;
}

.list-group-flush .list-group-item {
  border: 0;
}

.social-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  text-align: center;
  background: #fff;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.social-icon i {
  color: #fff;
}

.social-icon.youtube {
  background: #FF0000;
}

.social-icon.facebook {
  background: #3b5998;
}

.social-icon.twitter {
  background: #1da1f2;
}

.social-graph-wrapper {
  text-align: center;
  padding: 1.25rem;
  position: relative;
  color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.social-graph-wrapper.widget-facebook {
  background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
  background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
  background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
  background-color: #db4439;
}

.social-graph-wrapper .s-icon {
  font-size: 1.5rem;
  position: relative;
  padding: 0 0.625rem;
}

.social-icon2 a {
  border: 0.1rem solid var(--primary);
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  border-radius: .3rem;
  font-size: 1.6rem;
}

.social-icon2 i {
  font-size: 0.75rem;
  color: var(--primary);
}

.social-icon3 ul li {
  display: inline-block;
}

.social-icon3 ul li a {
  display: block;
}

.social-icon3 ul li a i {
  font-size: 1.4rem;
  padding: 1rem .7rem;
  color: #464a53;
}

.social-icon3 ul li a:hover i {
  color: var(--primary);
}

.social-icons-muted ul li a i {
  color: #89879f;
}

.social-links a {
  padding: .5rem;
}

.widget-stat .media {
  padding: 0rem 0;
  align-items: center;
}

.widget-stat .media>span {
  height: 5.3125rem;
  width: 5.3125rem;
  border-radius: 3.125rem;
  padding: 0.625rem 0.75rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
  min-width: 5.3125rem;
}

.widget-stat .media .media-body p {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
}

[data-theme-version="dark"] .widget-stat .media .media-body p {
  color: #fff;
}

.widget-stat .media .media-body h3,
.widget-stat .media .media-body .h3 {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.widget-stat .media .media-body h4,
.widget-stat .media .media-body .h4 {
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
}

.widget-stat .media .media-body span {
  margin-left: 0.3125rem;
}

.widget-stat[class*="bg-"] .media>span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.widget-stat[class*="bg-"] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

[direction="rtl"] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 0.625rem;
}

.dez-widget-1 .card {
  background: #FFE7DB;
}

.dez-widget-1 .card .card-body p {
  color: #f87533;
}

.dez-widget-1 .icon {
  float: right;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 1.75rem;
}

.bgl-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .bgl-primary {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

.bgl-secondary {
  background: #c5c5c5;
  border-color: #c5c5c5;
}

[data-theme-version="dark"] .bgl-secondary {
  background-color: rgba(44, 44, 44, 0.5);
  border-color: rgba(44, 44, 44, 0.5);
}

.bgl-success {
  background: #daf5e6;
  border-color: #daf5e6;
}

[data-theme-version="dark"] .bgl-success {
  background-color: rgba(58, 201, 119, 0.1);
  border-color: rgba(58, 201, 119, 0.1);
}

.bgl-warning {
  background: #ffeccc;
  border-color: #ffeccc;
}

[data-theme-version="dark"] .bgl-warning {
  background-color: rgba(255, 159, 0, 0.1);
  border-color: rgba(255, 159, 0, 0.1);
}

.bgl-danger {
  background: #ffdede;
  border-color: #ffdede;
}

[data-theme-version="dark"] .bgl-danger {
  background-color: rgba(255, 94, 94, 0.15);
  border-color: rgba(255, 94, 94, 0.15);
}

.bgl-info {
  background: #d3edf5;
  border-color: #d3edf5;
}

[data-theme-version="dark"] .bgl-info {
  background-color: rgba(88, 186, 215, 0.1);
  border-color: rgba(88, 186, 215, 0.1);
}

.bg-primary-light {
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .bg-primary-light {
  background-color: var(--rgba-primary-1);
}

.bg-secondary-light {
  background: rgba(197, 197, 197, 0.5);
}

[data-theme-version="dark"] .bg-secondary-light {
  background-color: rgba(44, 44, 44, 0.05);
}

.bg-success-light {
  background: rgba(218, 245, 230, 0.5);
}

[data-theme-version="dark"] .bg-success-light {
  background-color: rgba(58, 201, 119, 0.05);
}

.bg-warning-light {
  background: rgba(255, 236, 204, 0.5);
}

[data-theme-version="dark"] .bg-warning-light {
  background-color: rgba(255, 159, 0, 0.05);
}

.bg-danger-light {
  background: rgba(255, 222, 222, 0.4);
}

[data-theme-version="dark"] .bg-danger-light {
  background-color: rgba(255, 94, 94, 0.05);
}

.bg-info-light {
  background: rgba(211, 237, 245, 0.5);
}

[data-theme-version="dark"] .bg-info-light {
  background-color: rgba(88, 186, 215, 0.05);
}

.bgl-dark {
  background: #eeeeee;
  border-color: #eeeeee;
}

.bgl-light {
  background: #dfdfdf;
  border-color: #dfdfdf;
}

.overlay-box {
  position: relative;
  z-index: 1;
}

.overlay-box:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: var(--primary);
  z-index: -1;
}

.rating-bar {
  font-size: 0.8125rem;
}

.tdl-holder {
  margin: 0 auto;
}

.tdl-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tdl-holder li {
  background-color: #fff;
  border-bottom: 0.0625rem solid #E6E6E6;
  list-style: none none;
  margin: 0;
  padding: 0;
}

.tdl-holder li span {
  margin-left: 2.1875rem;
  font-size: 1rem;
  vertical-align: middle;
  transition: all 0.2s linear;
}

[direction="rtl"] .tdl-holder li span {
  margin-left: auto;
  margin-right: 2.1875rem;
}

.tdl-holder label {
  cursor: pointer;
  display: block;
  line-height: 3.125rem;
  padding-left: 1.5rem;
  position: relative;
  margin: 0 !important;
}

[direction="rtl"] .tdl-holder label {
  padding-left: 0;
  padding-right: 1.5rem;
}

.tdl-holder label:hover {
  background-color: #eef1f6;
  color: #6e6e6e;
}

.tdl-holder label:hover a {
  color: #FF5E5E;
}

.tdl-holder label a {
  color: #fff;
  display: inline-block;
  line-height: normal;
  height: 100%;
  text-align: center;
  text-decoration: none;
  width: 3.125rem;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all 0.2s linear;
  padding: 1.125rem 0rem;
  font-size: 1.125rem;
  position: absolute;
  right: 0;
}

[direction="rtl"] .tdl-holder label a {
  right: auto;
  left: 0;
}

.tdl-holder input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.tdl-holder input[type=checkbox]+i {
  background-color: #fff;
  border: 0.0625rem solid #e7e7e7;
  display: block;
  height: 1.25rem;
  position: absolute;
  top: 0.9375rem;
  width: 1.25rem;
  z-index: 1;
  border-radius: 0rem;
  font-size: 0.6875rem;
  border-radius: 0.25rem;
}

.tdl-holder input[type=checkbox]:checked+i {
  background: var(--primary);
  border-color: transparent;
}

.tdl-holder input[type=checkbox]:checked+i::after {
  content: "\f00c";
  font-family: 'fontAwesome';
  display: block;
  left: 0.125rem;
  position: absolute;
  top: -0.875rem;
  z-index: 2;
  color: #fff;
}

.tdl-holder input[type=checkbox]:checked~span {
  text-decoration: line-through;
  position: relative;
}

.tdl-holder input[type=text] {
  background-color: #fff;
  height: 3.125rem;
  margin-top: 1.8125rem;
  border-radius: 0.1875rem;
}

.tdl-holder input[type=text]::placeholder {
  color: #6e6e6e;
}

.widget-message p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.picker .picker__frame {
  min-width: 28.125rem;
  max-width: 28.125rem;
}

.picker .picker__frame .picker__box {
  padding: 1.25rem;
  border: 0;
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1);
}

.picker .picker__frame .picker__box .picker__header {
  margin-top: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__year {
  color: var(--primary);
  font-style: inherit;
  font-size: 1.25rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev,
.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 0.125rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  font-family: fontawesome;
  border: 0;
  margin: 0 auto;
  font-size: 1.375rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
  background-color: var(--primary);
  color: #fff;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev {
  left: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
  content: "\f0d9";
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  right: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  content: "\f0da";
}

.picker .picker__frame .picker__box .picker__table .picker__weekday {
  padding: 0.625rem 0;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__table .picker__day {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3.125rem;
  padding: 0 !important;
  font-size: 1rem;
  line-height: 2.5rem;
  margin: auto;
  border: 0 !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
  border: 0 !important;
  padding: 0;
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day:hover {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.picker .picker__frame .picker__box .picker__footer .picker__button--close,
.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  border: 0;
  border-radius: 0.125rem;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
  background-color: var(--primary);
  color: #fff !important;
}

@media only screen and (max-width: 35.9375rem) {
  .picker .picker__frame {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .picker .picker__frame .picker__box {
    padding: 0.9375rem;
    margin: 0 0.625rem;
  }
}

.card-list {
  overflow: unset;
  height: calc(100% - 50px);
}

.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}

.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 0.3125rem;
  width: 100%;
}

@media only screen and (max-width: 74.9375rem) {
  .card-list {
    height: calc(100% - 40px);
  }
}

.card-list.list-left {
  margin-top: 0.9375rem;
  margin-left: 0.9375rem;
}

.card-list.list-left .card-header {
  padding-left: 0;
}

.card-list.list-left .card-header .photo {
  margin: -0.9375rem 0.9375rem 0 -0.9375rem;
}

.card-list.list-right {
  margin-top: 0.9375rem;
  margin-right: 0.9375rem;
  text-align: right;
}

.card-list.list-right .card-header {
  padding-right: 0;
}

.card-list.list-right .card-header .photo {
  margin: -0.9375rem -0.9375rem 0 0.9375rem;
}

.card-list.list-right .card-header .photo img {
  width: 100%;
}

.card-list.list-center {
  margin-top: 0.9375rem;
}

.card-list.list-center .card-header .photo {
  margin: -0.9375rem 0 0 0;
}

.card-list .photo img {
  width: 100%;
}

.card-profile .profile-photo {
  margin-top: -1.25rem;
}

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0.8rem 0.75rem;
  margin: 0 -5px;
  border-radius: 0.375rem;
  position: relative;
  transition: all 0.5s;
  /* &:after{
				position:absolute;
				content:"";
				background-color:var(--primary);
				width:0.5rem;
				height:0.5rem;
				border-radius:100%;
				top:0.875rem;
				right:0.875rem;
			} */
}

.widget-media .timeline .timeline-panel:hover {
  background-color: rgba(13, 153, 255, 0.05);
  cursor: pointer;
}

.widget-media .timeline .timeline-panel .media {
  width: 2.188rem;
  height: 2.188rem;
  background: #eee;
  border-radius: 100%;
  overflow: hidden;
  font-size: 0.813rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}

.widget-media .timeline .timeline-panel .media-primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-primary {
  background-color: var(--rgba-primary-1);
}

.widget-media .timeline .timeline-panel .media-info {
  background: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-info {
  background-color: rgba(88, 186, 215, 0.1);
}

.widget-media .timeline .timeline-panel .media-warning {
  background: #ffeccc;
  color: #FF9F00;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-warning {
  background-color: rgba(255, 159, 0, 0.1);
}

.widget-media .timeline .timeline-panel .media-danger {
  background: #ffdede;
  color: #FF5E5E;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-danger {
  background-color: rgba(255, 94, 94, 0.15);
}

.widget-media .timeline .timeline-panel .media-success {
  background: #daf5e6;
  color: #3AC977;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-success {
  background-color: rgba(58, 201, 119, 0.1);
}

.widget-media .timeline .timeline-panel .media-body span {
  border: 1px solid #E6E6E6;
  font-size: 0.75rem;
  border-radius: 0.375rem;
  padding: 0.313rem 0.625rem;
}

.widget-media .timeline .timeline-panel .media-body h6,
.widget-media .timeline .timeline-panel .media-body .h6 {
  font-size: 0.813rem;
  line-height: 1rem;
}

.widget-media .timeline .timeline-panel .media-body p {
  font-size: 0.75rem;
}

.widget-media .timeline .timeline-panel .media-body .attc {
  display: flex;
  align-items: flex-start;
  border: 1px solid #f1f1f1;
  border-radius: 0.375rem;
  padding: 0.313rem 0.625rem;
}

.widget-media .timeline .timeline-panel .media-body .attc .media.style-1 {
  border-radius: 0.375rem;
  border: 1px solid #E6E6E6;
}

.widget-media .timeline .timeline-panel .dropdown {
  align-self: self-end;
  margin-top: 0.3125rem;
}

.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.card[class*="bg-"] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.donut-chart-sale small,
.donut-chart-sale .small,
.donut-chart-sale .small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.125rem;
  font-weight: 600;
}

.students h4,
.students .h4,
.students h5,
.students .h5,
.students span {
  color: #fff;
}

.table {
  color: strong;
  color-color: #6e6e6e;
}

.table th,
.table td {
  border-color: #E6E6E6;
  padding: 0.9375rem 0.625rem;
}

[data-theme-version="dark"] .table th,
[data-theme-version="dark"] .table td {
  border-color: #444444;
  color: #fff !important;
}

.table.table-striped tbody tr:nth-of-type(odd),
.table.table-hover tr:hover {
  background-color: #fff;
}

[data-theme-version="dark"] .table.table-striped tbody tr:nth-of-type(odd),
[data-theme-version="dark"] .table.table-hover tr:hover {
  background-color: #1E1E1E;
}

.table.shadow-hover tbody tr:hover {
  background-color: #fff;
  box-shadow: 0 0rem 1.875rem var(--rgba-primary-2);
}

[data-theme-version="dark"] .table.shadow-hover tbody tr:hover {
  background-color: #1E1E1E;
}

.table.tr-rounded tr td:first-child,
.table.tr-rounded tr th:first-child {
  border-radius: 2.8125rem 0 0 2.8125rem;
}

.table.tr-rounded tr td:last-child,
.table.tr-rounded tr th:last-child {
  border-radius: 0 2.8125rem 2.8125rem 0;
}

.table.border-hover tr td {
  border-width: 0.0625rem 0 0.0625rem 0;
  border-bottom: 0.0625rem solid transparent;
  border-color: transparent;
}

.table.border-hover tr td:first-child {
  border-width: 0.0625rem 0 0.0625rem 0.0625rem;
}

.table.border-hover tr td:last-child {
  border-width: 0.0625rem 0.0625rem 0.0625rem 0;
}

.table.border-hover tr:hover td {
  border-color: #CCCCCC;
}

.table.bg-primary-hover th,
.table.bg-primary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-primary-hover td {
  color: #000;
}

[data-theme-version="dark"] .table.bg-primary-hover td {
  color: #fff;
}

.table.bg-primary-hover th {
  color: #6C6C6C;
}

.table.bg-primary-hover tr:hover td,
.table.bg-primary-hover tr:hover th {
  background: var(--primary);
  color: #fff;
}

.table.bg-secondary-hover th,
.table.bg-secondary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-secondary-hover td {
  color: #000;
}

[data-theme-version="dark"] .table.bg-secondary-hover td {
  color: #fff;
}

.table.bg-secondary-hover th {
  color: #6C6C6C;
}

.table.bg-secondary-hover tr:hover td,
.table.bg-secondary-hover tr:hover th {
  background: #2C2C2C;
  color: #fff !important;
}

.table.bg-info-hover th,
.table.bg-info-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-info-hover td {
  color: #000;
}

.table.bg-info-hover th {
  color: #6C6C6C;
}

.table.bg-info-hover tr:hover td,
.table.bg-info-hover tr:hover th {
  background: #58bad7;
  color: #fff !important;
}

.table.bg-warning-hover th,
.table.bg-warning-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-warning-hover td {
  color: #000;
}

[data-theme-version="dark"] .table.bg-warning-hover td {
  color: #fff;
}

.table.bg-warning-hover th {
  color: #6C6C6C;
}

.table.bg-warning-hover tr:hover td,
.table.bg-warning-hover tr:hover th {
  background: #FF9F00;
  color: #fff !important;
}

.table.border-no td {
  border: 0;
}

.table.short-one tr td:first-child,
.table.short-one tr th:first-child {
  width: 3.75rem;
}

.table thead th {
  border-bottom: 0.0625rem solid #E6E6E6;
  text-transform: capitalize;
  color: #000;
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 500;
  border-color: #E6E6E6 !important;
}

[data-theme-version="dark"] .table thead th {
  border-color: #444444 !important;
}

.table tbody tr td {
  vertical-align: middle;
  border-color: #E6E6E6;
  white-space: nowrap;
}

.table:not(.table-bordered) thead th {
  border-top: none;
}

.table .thead-primary th {
  background-color: var(--primary);
  color: #fff;
}

.table .thead-info th {
  background-color: #58bad7;
  color: #fff;
}

.table .thead-warning th {
  background-color: #FF9F00;
  color: #fff;
}

.table .thead-danger th {
  background-color: #FF5E5E;
  color: #fff;
}

.table .thead-success th {
  background-color: #3AC977;
  color: #fff;
}

.table.primary-table-bordered {
  border: 0.0625rem solid #E6E6E6;
}

[data-theme-version="dark"] .table.primary-table-bordered {
  border-color: #444444;
}

.table.primary-table-bg-hover thead th {
  background-color: var(--primary-dark);
  color: #fff;
  border-bottom: none;
}

.table.primary-table-bg-hover tbody tr {
  background-color: var(--primary);
  color: #fff;
  transition: all .1s ease;
}

.table.primary-table-bg-hover tbody tr:hover {
  background-color: var(--rgba-primary-8);
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td,
.table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 0.0625rem solid var(--primary-dark);
}

.table-responsive-tiny {
  min-width: 18.75rem;
}

.table-responsive-sm {
  min-width: 30rem !important;
}

.table-responsive-md {
  min-width: 36rem !important;
}

.table-responsive-lg {
  min-width: 60.9375rem !important;
}

.table-responsive-xl {
  min-width: 70.9375rem !important;
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .table-primary,
[data-theme-version="dark"] .table-primary>th,
[data-theme-version="dark"] .table-primary>td {
  background-color: var(--rgba-primary-1);
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #daf5e6;
  color: #3AC977;
}

[data-theme-version="dark"] .table-success,
[data-theme-version="dark"] .table-success>th,
[data-theme-version="dark"] .table-success>td {
  background-color: rgba(58, 201, 119, 0.1);
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .table-info,
[data-theme-version="dark"] .table-info>th,
[data-theme-version="dark"] .table-info>td {
  background-color: rgba(88, 186, 215, 0.1);
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffeccc;
  color: #FF9F00;
}

[data-theme-version="dark"] .table-warning,
[data-theme-version="dark"] .table-warning>th,
[data-theme-version="dark"] .table-warning>td {
  background-color: rgba(255, 159, 0, 0.1);
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #ffdede;
  color: #FF5E5E;
}

[data-theme-version="dark"] .table-danger,
[data-theme-version="dark"] .table-danger>th,
[data-theme-version="dark"] .table-danger>td {
  background-color: rgba(255, 94, 94, 0.15);
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: #f2f4fa;
}

[data-theme-version="dark"] .table-active,
[data-theme-version="dark"] .table-active>th,
[data-theme-version="dark"] .table-active>td {
  background-color: #1E1E1E;
}

.card-table th:first-child,
.card-table td:first-child {
  padding-left: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {

  .card-table th:first-child,
  .card-table td:first-child {
    padding-left: 0.9375rem;
  }
}

.card-table th:last-child,
.card-table td:last-child {
  padding-right: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {

  .card-table th:last-child,
  .card-table td:last-child {
    padding-right: 0.9375rem;
  }
}

.table-sell tbody tr td {
  border-color: #ffffff66;
}

.table-sell thead th {
  border-color: #ffffff66 !important;
}

.table-sell tr td {
  padding: 0.670rem 0.625rem;
}

.table-sell tr th {
  padding: 0.670rem 0.625rem;
  font-size: 1rem;
}

.order-tbl tbody tr:hover {
  border-radius: 0.375rem;
}

.dataTablemarket tbody tr td {
  text-align: center;
}

[data-theme-version="dark"] .table-sell tbody tr td {
  border-color: #ffffff66 !important;
}

[data-theme-version="dark"] .table-sell thead th {
  border-color: #ffffff66 !important;
}

.dark-logo {
  display: none;
}

.light-logo {
  display: block;
}

#horizontal-bar-chart .ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  width: 64px !important;
}

.table.primary-table-bg-hover tbody tr td,
.table.primary-table-bg-hover tbody tr th {
  background-color: var(--primary);
  color: #fff !important;
}

.bootgrid-header {
  padding: 0 !important;
  margin: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .bootgrid-header .actionBar {
    padding: 0;
  }

  .bootgrid-header .search {
    margin: 0 0 0.625rem 0;
  }
}

table#example {
  padding: 0rem 0 2rem 0;
}

table.dataTable {
  font-size: 0.875rem;
}

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important;
}

#employees,
#custommers {
  padding: .5rem 0 1rem 0;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 0.125rem solid #CCCCCC;
  border-top: 0;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody tr,
table.dataTable tbody td {
  background: transparent !important;
}

table.dataTable thead th {
  color: #374557;
  white-space: nowrap;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500;
  padding: 1.25rem 0.9375rem;
}

[data-theme-version="dark"] table.dataTable thead th {
  color: #fff;
}

@media only screen and (max-width: 87.5rem) {
  table.dataTable thead th {
    font-size: 1rem;
  }
}

table.dataTable tbody td {
  padding: 1.25rem 0.9375rem;
  font-size: 15px;
  font-weight: 400;
  border-bottom: 0;
}

@media only screen and (max-width: 35.9375rem) {
  table.dataTable tbody td {
    padding: 0.5rem 0.3125rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  table.dataTable tbody td {
    font-size: 0.875rem;
    padding: 0.5rem 0.9375rem;
  }
}

table.dataTable tfoot th {
  color: #6e6e6e;
  font-weight: 600;
}

[data-theme-version="dark"] table.dataTable tfoot th {
  color: #fff;
}

td.text-ov {
  white-space: nowrap;
}

.dataTables_wrapper .dataTables_paginate {
  align-items: center;
  display: flex;
  flex-flow: wrap;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-size: 13px;
  height: 24px;
  width: 24px;
  background: transparent;
  border-radius: 0.375rem;
  padding: 0 0.45rem;
  line-height: 25px;
  margin: 0 0.225rem;
  display: inline-block;
  color: var(--primary) !important;
  box-shadow: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous.next:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.next:hover {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
  border-color: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  color: var(--primary) !important;
}

@media only screen and (max-width: 35.9375rem) {

  .dataTables_wrapper .dataTables_paginate .paginate_button.previous,
  .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    height: 2.125rem;
    width: fit-content;
    line-height: 2.125rem;
    padding: 0 0.45rem;
  }
}

.dataTables_wrapper .dataTables_paginate span .paginate_button {
  height: 24px;
  width: 24px;
  padding: 0;
  margin-left: 0rem;
  margin: 0 0.125rem;
  line-height: 25px;
  text-align: center;
  font-size: 13px;
  border-radius: 0.375rem;
  border: 0 !important;
  color: var(--primary) !important;
  cursor: pointer;
  box-shadow: none !important;
}

@media only screen and (max-width: 74.9375rem) {
  .dataTables_wrapper .dataTables_paginate span .paginate_button {
    height: 40px;
    width: 40px;
    line-height: 3rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .dataTables_wrapper .dataTables_paginate span .paginate_button {
    height: 2.125rem;
    width: 2.125rem;
    line-height: 2.325rem;
  }
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate span .paginate_button:active {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

.dataTables_wrapper input[type="search"],
.dataTables_wrapper input[type="text"],
.dataTables_wrapper select {
  border: 0.0625rem solid #e2e2e2;
  padding: .3rem 0.5rem;
  color: #715d5d;
  border-radius: 0.3125rem;
}

[data-theme-version="dark"] .dataTables_wrapper input[type="search"],
[data-theme-version="dark"] .dataTables_wrapper input[type="text"],
[data-theme-version="dark"] .dataTables_wrapper select {
  background: #1E1E1E;
  border-color: #444444;
  color: #fff;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 0.9375rem;
}

.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 5rem;
  margin: 0 0.3125rem;
  width: 70px !important;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.rounded-lg {
  min-width: 1.875rem;
}

.dataTables_scroll {
  padding: 1rem 0;
}

.dataTables_scrollFoot {
  padding-top: 1rem;
}

.dataTablesCard {
  background-color: #fff;
  border-radius: 0.375rem;
}

.dataTablesCard.border-no td {
  border-top: 0 !important;
}

@media (max-width: 991.98px) {

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #888888 !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #888888;
  border-radius: 1rem;
  padding: 0.125rem 0;
  margin-bottom: 0.35rem;
}

.paging_simple_numbers.dataTables_paginate {
  padding: 0.6125rem 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .paging_simple_numbers.dataTables_paginate {
    padding: 0.8125rem 0.3125rem;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #888888 !important;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #E6E6E6;
}

[data-theme-version="dark"] table.dataTable.row-border tbody th,
[data-theme-version="dark"] table.dataTable.row-border tbody td,
[data-theme-version="dark"] table.dataTable.display tbody th,
[data-theme-version="dark"] table.dataTable.display tbody td {
  border-color: #444444;
}

.dataTables_wrapper .dataTables_length .bootstrap-select .dropdown-toggle {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

table.tb-transaction tbody td {
  padding: 0.75rem 1.875rem;
}

table.tickettable tbody td {
  padding: 1.25rem 1.875rem;
  color: var(--secondary);
}

table.tickettable thead th {
  font-size: 1rem !important;
  padding: 1.65rem 1.875rem;
  color: var(--secondary);
  border-bottom: 1px solid #DBDBDB;
}

.table.tickettable.table-striped tbody tr:first-child:hover {
  background-color: transparent;
}

.table.tickettable.table-hover thead tr:first-child:hover {
  background-color: transparent;
}

.table.table-sell.table-hover thead tr:first-child:hover {
  background-color: transparent;
}

.table.table-sell.table-hover tbody tr:hover {
  background-color: var(--secondary);
}

.orderbookTable thead tr th {
  font-size: 1rem;
  border: 0;
}

.orderbookTable tbody tr td {
  border: 0;
  padding: 0.7375rem 0.625rem;
}

.dataTabletrade .dataTables_wrapper .dataTables_length {
  display: none;
}

.dataTabletrade .dataTables_wrapper .dataTables_filter {
  display: none;
}

.dataTabletrade .dataTables_wrapper #example {
  padding: 0rem 0 0.5rem 0;
}

.dataTabletrade .dataTables_wrapper .dataTable thead tr th {
  font-size: 1rem;
  border-bottom: 1px solid #eee;
}

.dataTabletrade .dataTables_wrapper .dataTable tbody tr td {
  font-size: 0.9rem;
  padding: 1.05rem 0.9375rem;
}

.dataTabletrade .dataTables_wrapper .dataTables_paginate {
  margin-bottom: 0;
}

.dataTabletrade .dataTables_wrapper .sorting_asc {
  background-image: unset;
}

.dataTabletrade .dataTables_wrapper .sorting {
  background-image: unset;
}

.dataTablehistory .dataTables_wrapper .dataTable thead tr th {
  font-size: 1rem;
}

.dataTablehistory .dataTables_wrapper .dataTable tbody tr td {
  font-size: 0.8rem;
  padding: 1.05rem 0.9375rem;
}

.dataTablehistory .dataTables_wrapper .dataTables_length {
  display: none;
}

.dataTablehistory .dataTables_wrapper .dataTables_filter {
  display: none;
}

.dataTablehistory .dataTables_wrapper table#example {
  padding: 0rem 0 0.6rem 0;
}

.dataTablehistory .dataTables_wrapper .sorting_asc {
  background-image: unset;
}

.dataTablehistory .dataTables_wrapper .sorting {
  background-image: unset;
}

.dataTablemarket .dataTables_wrapper .dataTables_length {
  display: none;
}

.dataTablemarket .dataTables_wrapper .dataTables_filter {
  margin-bottom: 0;
  margin-top: 0;
  float: right;
  display: none;
}

.dataTablemarket .dataTables_wrapper #example {
  padding: 0rem 0 0.5rem 0;
}

.dataTablemarket .dataTables_wrapper .dataTable thead tr th {
  font-size: 0.9rem;
  padding: 0.75rem 0.6375rem;
  border-bottom: 1px solid #eee;
}

.dataTablemarket .dataTables_wrapper .dataTable tbody tr td {
  font-size: 1rem;
  padding: 1.05rem 0.9375rem;
  color: var(--secondary);
  font-weight: 400;
}

[data-theme-version="dark"] .dataTablemarket .dataTables_wrapper .dataTable tbody tr td {
  color: #fff;
}

.dataTablemarket .dataTables_wrapper .dataTable tbody tr td a {
  font-size: 1rem;
  font-size: 400;
  color: var(--secondary);
}

.dataTablemarket .dataTables_wrapper .dataTables_paginate {
  margin-bottom: 0;
}

.dataTablemarket .dataTables_wrapper .sorting_asc {
  background-image: unset;
}

.dataTablemarket .dataTables_wrapper .sorting {
  background-image: unset;
}

.table.dataTable thead .sorting_desc {
  background-image: unset;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc:after {
  content: "";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  background-image: none;
  margin-left: 10px;
  font-size: calc(100% - 4px);
  opacity: 0.5;
}

table.dataTable thead .sorting:after {
  content: "\f0dc";
}

table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  opacity: 1;
}

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  opacity: 1;
}

.order-tbl td {
  padding: 0.5375rem 0.625rem;
}

table.dataTable thead .sorting {
  background-image: none;
}

table.dataTable thead .sorting_desc {
  background-image: none;
}

table.dataTable thead .sorting_asc {
  background-image: none;
}

#example2_wrapper #example2_filter {
  float: left;
}

.dataTables_scrollBody thead {
  display: none;
}

#example5_wrapper table.dataTable thead .sorting_asc .custom-checkbox .form-check-input:checked {
  border: 2px solid #fff;
}

.tbl-report thead tr th {
  font-weight: 600;
  font-size: 1rem;
  color: #374557;
}

/* .table-responsive{
	.dataTables_filter{
		float: right;
        margin-top: -3.313rem;
	}
} */
/* #example_wrapper,
#example2_wrapper,
#example3_wrapper,
#example4_wrapper{
	.dataTables_paginate span .paginate_button{
		padding: 2.8px 9.6px;
	}
} */
/* .dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid $border !important;
        padding: .3rem 0.5rem;
		//background-color:var(--rgba-primary-5);
        //color: $main-color;
        border-radius: $radius  !important;
		margin-left: 14px;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border!important;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 15px;
		.bootstrap-select{
			width: 70px!important;
			margin: 0 5px;

			.dropdown-toggle::after{
				border-top: 0.4em solid;
				border-right: 0.4em solid transparent;
				border-left: 0.4em solid transparent;
			}
		}
	}
} */
/*  .paging_simple_numbers.dataTables_paginate{
	border: none;
    float: right;
	//margin-top:-1.938rem;

} */
#example3 .sorting_asc:after {
  content: "" !important;
}

#example3 .sorting_desc:after {
  content: "" !important;
}

.fooicon {
  font-size: 1.25rem;
  color: #6e6e6e;
}

.jsgrid-table .jsgrid-header-row>.jsgrid-header-cell {
  color: #6e6e6e;
}

.jsgrid-table>tbody>tr>td {
  padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input,
.jsgrid-table .jsgrid-insert-row select,
.jsgrid-table .jsgrid-edit-row input,
.jsgrid-table .jsgrid-edit-row select {
  border: 0.0625rem solid #DDDFE1;
}

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 0.625rem;
}

.svg-icons-ov {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  align-items: center;
  padding: 15px 15px 75px;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
}

.svg-icons-ov .svg-icons-prev {
  margin-right: 15px;
}

.svg-icons-ov .svg-icons-prev svg path {
  stroke: var(--primary);
}

.svg-icons-ov .svg-icons-prev svg path[fill] {
  fill: var(--primary);
}

.svg-icons-ov .svg-icons-prev i {
  font-size: 24px;
  color: var(--primary);
}

.svg-icons-ov .svg-icon-popup {
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  left: 10px;
}

.svg-icons-ov .svg-icon-popup.style-1 {
  left: 0;
}

.svg-icons-ov .svg-icon-popup.style-1 .btn {
  width: 100%;
}

.svg-icons-ov .svg-icon-popup .btn {
  padding: 8px;
  background: #e8f5ff;
  margin: 0 5px;
  color: #0D99FF;
  font-size: 14px !important;
  display: inline-block;
  width: 40%;
  min-width: 40%;
  height: 40px;
}

.svg-icons-ov .svg-icon-popup .btn:hover {
  background-color: #0D99FF;
  color: #fff;
}

.svg-icons-ov:hover {
  border-color: #0D99FF;
}

.svg-icons-ov.style-1 {
  padding: 15px;
  flex-direction: column;
  cursor: pointer;
}

.svg-icons-ov.style-1 .svg-icons-prev {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.563rem;
  text-align: center;
  background-color: #fdf4f8;
  border-radius: 50%;
  margin-bottom: 0.688rem;
  margin-right: 0;
}

.svg-icons-ov.style-1 .svg-classname {
  font-size: 0.713rem;
}

.svg-area .row {
  margin: 0;
}

.svg-area .col-md-2 {
  padding: 0;
}

.svg-area .card-header {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin-top: 50px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.svg-area .card-header:first-child {
  margin-top: 0;
}

.error-page {
  height: 100vh;
  padding: 50px;
  position: relative;
  background-blend-mode: luminosity;
  background-size: cover;
}

.error-page:before {
  content: "";
  position: absolute;
  opacity: 0.8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.error-page .error-inner {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .error-inner {
    width: 100%;
  }
}

.error-page .error-head {
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 1.4;
  font-weight: 500;
  color: #1c1c4e;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .error-head {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.error-page .dz-error {
  position: relative;
  color: var(--secondary);
  font-size: 200px;
  letter-spacing: 0px;
  animation: dzError 1s infinite linear alternate-reverse;
  font-weight: 900;
  line-height: 200px;
  margin: auto;
}

@media only screen and (max-width: 61.9375rem) {
  .error-page .dz-error {
    font-size: 150px;
    line-height: 150px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .dz-error {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 5px;
  }
}

.error-page .dz-error::before,
.error-page .dz-error::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.error-page .dz-error::before {
  left: 2px;
  text-shadow: -2px 0 #f7cf47;
  clip: rect(44px, 450px, 56px, 0);
  animation: dzError1 5s infinite linear alternate-reverse;
}

.error-page .dz-error::after {
  left: -2px;
  text-shadow: -2px 0 #f7cf47, 2px 2px #f7cf47;
  clip: rect(44px, 450px, 56px, 0);
  animation: dzError2 5s infinite linear alternate-reverse;
}

@keyframes dzError1 {
  0% {
    clip: rect(287px, 9999px, 99px, 0);
    transform: skew(0.966deg);
  }

  2.5% {
    clip: rect(244px, 9999px, 344px, 0);
    transform: skew(0.306deg);
  }

  5% {
    clip: rect(174px, 9999px, 294px, 0);
    transform: skew(0.006deg);
  }

  7.5% {
    clip: rect(487px, 9999px, 241px, 0);
    transform: skew(0.824deg);
  }

  10% {
    clip: rect(390px, 9999px, 163px, 0);
    transform: skew(0.268deg);
  }

  12.5% {
    clip: rect(85px, 9999px, 66px, 0);
    transform: skew(0.2deg);
  }

  15% {
    clip: rect(345px, 9999px, 384px, 0);
    transform: skew(0.998deg);
  }

  17.5% {
    clip: rect(150px, 9999px, 387px, 0);
    transform: skew(0.516deg);
  }

  20% {
    clip: rect(471px, 9999px, 499px, 0);
    transform: skew(0.812deg);
  }

  22.5% {
    clip: rect(82px, 9999px, 36px, 0);
    transform: skew(0.368deg);
  }

  25% {
    clip: rect(25px, 9999px, 26px, 0);
    transform: skew(0.78deg);
  }

  27.5% {
    clip: rect(123px, 9999px, 470px, 0);
    transform: skew(0.316deg);
  }

  30% {
    clip: rect(66px, 9999px, 199px, 0);
    transform: skew(0.154deg);
  }

  32.5% {
    clip: rect(397px, 9999px, 117px, 0);
    transform: skew(0.666deg);
  }

  35% {
    clip: rect(155px, 9999px, 76px, 0);
    transform: skew(0.544deg);
  }

  37.5% {
    clip: rect(438px, 9999px, 53px, 0);
    transform: skew(0.564deg);
  }

  40% {
    clip: rect(412px, 9999px, 355px, 0);
    transform: skew(0.724deg);
  }

  42.5% {
    clip: rect(124px, 9999px, 171px, 0);
    transform: skew(0.406deg);
  }

  45% {
    clip: rect(183px, 9999px, 357px, 0);
    transform: skew(0.592deg);
  }

  47.5% {
    clip: rect(58px, 9999px, 289px, 0);
    transform: skew(0.478deg);
  }

  50% {
    clip: rect(58px, 9999px, 450px, 0);
    transform: skew(0.91deg);
  }

  52.5% {
    clip: rect(125px, 9999px, 469px, 0);
    transform: skew(0.982deg);
  }

  55% {
    clip: rect(198px, 9999px, 487px, 0);
    transform: skew(0.796deg);
  }

  57.5% {
    clip: rect(145px, 9999px, 228px, 0);
    transform: skew(0.354deg);
  }

  60% {
    clip: rect(60px, 9999px, 452px, 0);
    transform: skew(0.812deg);
  }

  62.5% {
    clip: rect(211px, 9999px, 499px, 0);
    transform: skew(0.194deg);
  }

  65% {
    clip: rect(451px, 9999px, 469px, 0);
    transform: skew(0.454deg);
  }

  67.5% {
    clip: rect(287px, 9999px, 84px, 0);
    transform: skew(0.774deg);
  }

  70% {
    clip: rect(178px, 9999px, 278px, 0);
    transform: skew(0.046deg);
  }

  72.5% {
    clip: rect(273px, 9999px, 313px, 0);
    transform: skew(0.1deg);
  }

  75% {
    clip: rect(403px, 9999px, 433px, 0);
    transform: skew(0.73deg);
  }

  77.5% {
    clip: rect(131px, 9999px, 317px, 0);
    transform: skew(0.268deg);
  }

  80% {
    clip: rect(72px, 9999px, 475px, 0);
    transform: skew(0.668deg);
  }

  82.5% {
    clip: rect(300px, 9999px, 205px, 0);
    transform: skew(0.536deg);
  }

  85% {
    clip: rect(272px, 9999px, 151px, 0);
    transform: skew(0.492deg);
  }

  87.5% {
    clip: rect(384px, 9999px, 39px, 0);
    transform: skew(0.044deg);
  }

  90% {
    clip: rect(223px, 9999px, 195px, 0);
    transform: skew(0.584deg);
  }

  92.5% {
    clip: rect(56px, 9999px, 479px, 0);
    transform: skew(0.308deg);
  }

  95% {
    clip: rect(287px, 9999px, 377px, 0);
    transform: skew(0.378deg);
  }

  97.5% {
    clip: rect(385px, 9999px, 195px, 0);
    transform: skew(0.762deg);
  }
}

@keyframes dzError2 {
  0% {
    clip: rect(422px, 9999px, 455px, 0);
    transform: skew(0.616deg);
  }

  2.5% {
    clip: rect(72px, 9999px, 308px, 0);
    transform: skew(0.414deg);
  }

  5% {
    clip: rect(252px, 9999px, 424px, 0);
    transform: skew(0.18deg);
  }

  7.5% {
    clip: rect(243px, 9999px, 495px, 0);
    transform: skew(0.872deg);
  }

  10% {
    clip: rect(92px, 9999px, 401px, 0);
    transform: skew(0.702deg);
  }

  12.5% {
    clip: rect(419px, 9999px, 101px, 0);
    transform: skew(0.864deg);
  }

  15% {
    clip: rect(34px, 9999px, 314px, 0);
    transform: skew(0.206deg);
  }

  17.5% {
    clip: rect(88px, 9999px, 496px, 0);
    transform: skew(0.198deg);
  }

  20% {
    clip: rect(279px, 9999px, 420px, 0);
    transform: skew(0.59deg);
  }

  22.5% {
    clip: rect(280px, 9999px, 491px, 0);
    transform: skew(0.752deg);
  }

  25% {
    clip: rect(77px, 9999px, 411px, 0);
    transform: skew(0.772deg);
  }

  27.5% {
    clip: rect(352px, 9999px, 168px, 0);
    transform: skew(0.898deg);
  }

  30% {
    clip: rect(336px, 9999px, 284px, 0);
    transform: skew(0.234deg);
  }

  32.5% {
    clip: rect(323px, 9999px, 191px, 0);
    transform: skew(0.212deg);
  }

  35% {
    clip: rect(338px, 9999px, 109px, 0);
    transform: skew(0.076deg);
  }

  37.5% {
    clip: rect(174px, 9999px, 239px, 0);
    transform: skew(0.736deg);
  }

  40% {
    clip: rect(106px, 9999px, 126px, 0);
    transform: skew(0.058deg);
  }

  42.5% {
    clip: rect(312px, 9999px, 27px, 0);
    transform: skew(0.02deg);
  }

  45% {
    clip: rect(181px, 9999px, 100px, 0);
    transform: skew(0.832deg);
  }

  47.5% {
    clip: rect(277px, 9999px, 280px, 0);
    transform: skew(0.426deg);
  }

  50% {
    clip: rect(7px, 9999px, 483px, 0);
    transform: skew(0.46deg);
  }

  52.5% {
    clip: rect(487px, 9999px, 312px, 0);
    transform: skew(0.316deg);
  }

  55% {
    clip: rect(212px, 9999px, 318px, 0);
    transform: skew(0.818deg);
  }

  57.5% {
    clip: rect(209px, 9999px, 138px, 0);
    transform: skew(0.144deg);
  }

  60% {
    clip: rect(219px, 9999px, 6px, 0);
    transform: skew(0.676deg);
  }

  62.5% {
    clip: rect(362px, 9999px, 344px, 0);
    transform: skew(0.956deg);
  }

  65% {
    clip: rect(327px, 9999px, 220px, 0);
    transform: skew(0.088deg);
  }

  67.5% {
    clip: rect(402px, 9999px, 385px, 0);
    transform: skew(0.776deg);
  }

  70% {
    clip: rect(180px, 9999px, 90px, 0);
    transform: skew(0.962deg);
  }

  72.5% {
    clip: rect(338px, 9999px, 186px, 0);
    transform: skew(0.29deg);
  }

  75% {
    clip: rect(137px, 9999px, 14px, 0);
    transform: skew(0.016deg);
  }

  77.5% {
    clip: rect(488px, 9999px, 137px, 0);
    transform: skew(0.002deg);
  }

  80% {
    clip: rect(407px, 9999px, 246px, 0);
    transform: skew(0.96deg);
  }

  82.5% {
    clip: rect(62px, 9999px, 196px, 0);
    transform: skew(0.76deg);
  }

  85% {
    clip: rect(109px, 9999px, 109px, 0);
    transform: skew(0.712deg);
  }

  87.5% {
    clip: rect(221px, 9999px, 259px, 0);
    transform: skew(0.404deg);
  }

  90% {
    clip: rect(363px, 9999px, 168px, 0);
    transform: skew(0.216deg);
  }

  92.5% {
    clip: rect(64px, 9999px, 380px, 0);
    transform: skew(0.622deg);
  }

  95% {
    clip: rect(321px, 9999px, 203px, 0);
    transform: skew(0.046deg);
  }

  97.5% {
    clip: rect(387px, 9999px, 486px, 0);
    transform: skew(0.97deg);
  }
}

@keyframes dzError {
  0% {
    transform: skew(0deg);
  }

  10% {
    transform: skew(-4deg);
  }

  20% {
    transform: skew(-3deg);
  }

  30% {
    transform: skew(5deg);
  }

  40% {
    transform: skew(0deg);
  }

  50% {
    transform: skew(1deg);
  }

  60% {
    transform: skew(3deg);
  }

  70% {
    transform: skew(-3deg);
  }

  80% {
    transform: skew(0deg);
  }

  90% {
    transform: skew(0deg);
  }
}

.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0;
}

.authincation {
  display: flex;
  min-height: 100vh;
}

.authincation .login-aside {
  background: #fff;
  padding-top: 80px;
  max-width: 560px;
  width: 100%;
  z-index: 1;
  position: relative;
}

.authincation .login-aside:after {
  content: "";
  clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  width: 140px;
  height: 100%;
  position: absolute;
  right: -140px;
  z-index: -1;
  top: 0;
  background: #fff;
  box-shadow: 2px 0px 30px rgba(0, 0, 0, 0.15);
}

.authincation .login-aside .aside-image {
  min-height: 450px;
  margin: auto 0;
  min-width: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 87.5rem) {
  .authincation .login-aside {
    max-width: 360px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside {
    max-width: 100%;
    padding-top: 0;
  }

  .authincation .login-aside:after {
    content: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .authincation .login-aside .aside-image {
    min-height: 300px;
  }
}

.auth-form {
  padding: 50px 50px;
}

@media only screen and (max-width: 35.9375rem) {
  .auth-form {
    padding: 30px;
  }
}

.auth-form .btn {
  height: 50px;
  font-weight: 700;
}

.auth-form .page-back {
  display: inline-block;
  margin-bottom: 15px;
}

.pages-left {
  background-color: #fff;
}

.login-media {
  margin-top: 80px;
}

.login-media img {
  width: 70%;
}

@media only screen and (max-width: 61.9375rem) {
  .login-media img {
    width: 60%;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .login-media {
    margin-top: 20px;
  }
}

.login-content {
  padding-left: 70px;
  padding-top: 70px;
  text-align: center;
}

.login-content p {
  font-size: 20px;
  color: #000;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

@media only screen and (max-width: 74.9375rem) {
  .login-content p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .login-content p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .login-content {
    padding-left: 51px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .login-content {
    padding-left: 14px;
    padding-top: 14px;
  }
}

.login-form {
  padding: 0 50px;
  max-width: 600px;
  margin: 0 auto;
}

@media only screen and (max-width: 61.9375rem) {
  .login-form {
    padding: 25px 25px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .login-form {
    padding: 14px 14px;
  }
}

.login-form .login-title {
  text-align: center;
  position: relative;
  margin-bottom: 48px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.login-form .login-title:before,
.login-form .login-title:after {
  content: "";
  height: 1px;
  flex: 1 1;
  left: 0;
  background-color: #E1E1F0;
  margin: 0;
  padding: 0;
}

.login-form .btn-facebook,
.login-form .btn-google-plus,
.login-form .btn-linkedin,
.login-form .btn-twitter {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  text-align: center;
  line-height: 2.5rem;
  border-radius: 100%;
}

.logo-light {
  display: none;
}

.heart {
  width: 3.75rem;
  height: 3.75rem;
  display: inline-block;
  /* background: url("../images/like.png"); */
  cursor: pointer;
  margin: -1.563rem -0.938rem;
}

.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

.input-group .search-area .form-control {
  height: 3rem;
}

.input-group .search-area .form-control::placeholder {
  color: var(--bs-card-color);
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
  z-index: -1;
}

/* new-scss-start */
.heading {
  font-size: 1rem;
  color: var(--bs-light);
}

.dz-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

.same-card .depostit-card-media {
  padding: 18px 20px;
}

.depostit-card {
  overflow: hidden;
}

.depostit-card .depostit-card-media {
  position: relative;
  z-index: 2;
}

.depostit-card .depostit-card-media h3,
.depostit-card .depostit-card-media .h3 {
  font-weight: 600;
  margin: 0;
  line-height: 1.346;
}

.depostit-card .depostit-card-media h6,
.depostit-card .depostit-card-media .h6 {
  font-weight: 400;
  margin: 0;
}

.depostit-card .depostit-card-media.style-1 {
  padding: unset;
}

.depostit-card .progress-box {
  margin-top: 0.625rem;
}

.depostit-card .progress-box .progress {
  height: 0.313rem;
  margin-top: 0.188rem;
  background-color: #E6EAEE;
}

.chart-grd {
  position: relative;
}

.chart-grd:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 5rem;
  left: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  pointer-events: none;
}

#AllProject {
  margin-left: -2.063rem;
}

.project-list {
  margin-left: -1.25rem;
}

.project-list li {
  font-size: 0.813rem;
  white-space: nowrap;
  padding: 1px 0;
}

.project-list h6,
.project-list .h6 {
  font-size: 0.938rem;
  margin: 0;
  margin-bottom: 2px;
  white-space: nowrap;
}

.icon-box {
  height: 2.5rem;
  width: 2.5rem;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 2.375rem;
  border-radius: 0.625rem;
}

.icon-box.icon-box-lg {
  height: 4.375rem;
  width: 4.375rem;
  line-height: 4.375rem;
}

.icon-box.icon-box-md {
  height: 1.75rem;
  width: 1.75rem;
  line-height: 1.55rem;
  border-radius: 6px;
}

.icon-box.icon-box-sm {
  height: 1.875rem;
  width: 1.875rem;
  line-height: 2.075;
  border-radius: 0.25rem;
}

.icon-box.task-tab {
  background-color: var(--secondary);
}

.avatar {
  height: 1.875rem;
  width: 1.875rem;
  display: inline-block;
  position: relative;
  object-fit: cover;
  border-radius: 0.375rem;
}

.avatar.avatar-md {
  height: 2.813rem;
  width: 2.813rem;
}

.avatar.avatar-lg {
  height: 2.25rem;
  width: 2.25rem;
}

.avatar-list.avatar-list-stacked .avatar {
  margin-inline-end: -13px;
  border: 2px solid #fff;
}

.avatar-list.avatar-list-stacked .avatar:hover {
  z-index: 1;
}

.ttl-project {
  display: flex;
  justify-content: space-around;
  text-align: center;
  border-top: 1px solid #E6E6E6;
  padding: 15px 0;
  overflow: hidden;
}

.ttl-project .pr-data {
  position: relative;
}

.ttl-project .pr-data h5,
.ttl-project .pr-data .h5 {
  margin-bottom: 0;
}

.ttl-project .pr-data:after {
  content: "";
  position: absolute;
  top: -20px;
  right: -50px;
  width: 1px;
  height: 85px;
  background: rgba(0, 0, 0, 0.1);
}

.ttl-project .pr-data:last-child:after {
  display: none;
}

@media only screen and (max-width: 35.9375rem) {
  .ttl-project {
    display: none;
  }
}

.cs-settiong .custome-select {
  padding-left: 6px;
}

.custome-select {
  width: auto;
  border: 0;
  color: var(--primary);
  font-size: 13px;
}

.custome-select:focus {
  box-shadow: none;
}

.custome-select:after {
  border-color: var(--secondary);
  height: 8px;
  margin-top: -7px;
  right: 3px;
  top: 51%;
  width: 8px;
  border-width: 1px;
}

.dang svg {
  margin-top: -2px;
}

.total-earning h2,
.total-earning .h2 {
  font-size: 38px;
  font-weight: 600;
}

.to-dodroup {
  height: 362px;
}

.to-dodroup .sub-card {
  border-bottom: 1px solid #E6E6E6;
}

.to-dodroup .sub-card .d-items {
  padding: 15px 20px;
  background-color: #FFF;
}

.to-dodroup .sub-card .d-items .d-items-2 {
  display: flex;
  align-items: baseline;
}

.to-dodroup .sub-card .d-items .d-items-2 svg {
  margin-right: 10px;
}

.to-dodroup .sub-card .d-items .d-items-2 label {
  font-size: 14px;
  font-weight: 400;
  color: var(--secondary);
}

.to-dodroup .sub-card .d-items .icon-box {
  cursor: pointer;
}

.to-dodroup .sub-card:last-child {
  border-bottom: 0;
}

.to-dodroup .sub-card:last-child .d-items {
  padding-bottom: 0;
}

.earning-tab {
  justify-content: center;
}

.earning-tab .nav-item .nav-link {
  color: #888888;
  box-shadow: none;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  margin: 6px 16px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: 400;
}

.earning-tab .nav-item .nav-link.active {
  background-color: transparent;
  border-color: var(--primary);
  color: var(--secondary);
  font-weight: 500;
}

.total-earning {
  text-align: center;
}

#earningChart {
  margin-top: 35px;
}

.active-projects thead tr th {
  font-size: 13px;
  padding: 0.625rem 1rem;
  font-weight: 500;
  background-color: #F0F4F9;
  border: 0;
  vertical-align: middle;
}

.active-projects thead tr th:last-child {
  /* text-align: right; */
  text-align: left;
}

.active-projects thead tr th:first-child {
  padding-right: 0;
}

.active-projects tbody tr td {
  font-size: 13px;
  font-weight: 400;
  padding: 0.625rem 1rem;
}

.active-projects tbody tr td:last-child {
  text-align: start;
}

.active-projects tbody tr td:first-child {
  padding-right: 0;
}

.active-projects .tbl-caption {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 20px;
}

.active-projects .tbl-caption button {
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;

}

.active-projects .tbl-caption button:disabled {
  cursor: auto;
}

.active-projects .tbl-caption button.active {
  background-color: #fff;
  color: #000;
  border-radius: 5px 5px 0px 0px;
}

.active-projects .dt-buttons {
  float: unset;
}

.active-projects .dt-buttons .dt-button {
  position: absolute;
  top: -47px;
  right: 20px;
  font-size: 13px !important;
  color: var(--bs-light-bg-subtle);
  font-weight: 400;
  background: var(--bs-primary);
  padding: 5px 12px;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .active-projects .dt-buttons .dt-button {
    right: -26rem;
  }
}

.active-projects .dt-buttons .dt-button:hover {
  background: var(--primary);
  color: #fff;
}

.active-projects .dataTables_info {

  padding-left: 20px;
  font-size: 12px;
  color: #888888;
}

.active-projects.selling-product tbody tr td {
  border-bottom: 1px solid #E6E6E6;
}

.active-projects.style-1 .tbl-caption {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
  background-color: rgb(44 44 44);
  gap: 10px;
}

.active-projects.style-1 .dt-buttons .dt-button {
  top: -50px;
  right: 298px;
}

@media only screen and (max-width: 100rem) {
  .active-projects.style-1 .dt-buttons .dt-button {
    right: "";
  }
}

@media only screen and (max-width: 47.9375rem) {
  .active-projects.style-1 .dt-buttons {
    display: none;
  }
}

.active-projects.style-1 tbody tr td {
  border-bottom: 1px solid #E6E6E6;
}

@media only screen and (max-width: 35.9375rem) {
  .active-projects.style-1 .heading {
    margin-bottom: 10px !important;
  }
}

.active-projects.style-1 .dataTables_wrapper .dataTables_info {
  padding-top: 1.255em;
  color: #888888;
}

.active-projects.attendance-tbl .dt-buttons .dt-button {
  right: 180px;
}

.active-projects.attendance-tbl thead span {
  font-size: 13px;
  margin-bottom: 0;
  color: var(--secondary);
}

.active-projects.attendance-tbl thead p {
  font-size: 10px;
  color: var(--primary);
  margin-bottom: 0;
}

.active-projects.attendance-tbl thead tr th {
  vertical-align: middle;
}

.active-projects.attendance-tbl thead tr th:not(:first-child) {
  padding: 15px 5px;
}

.active-projects.attendance-tbl thead tr th:last-child {
  padding-left: 25px;
}

.active-projects.attendance-tbl tbody tr td {
  color: var(--secondary);
  padding: 0.375rem 1.25rem;
}

.active-projects.attendance-tbl tbody tr td:not(:first-child) {
  padding: 15px 7px;
}

.active-projects.attendance-tbl tbody tr td i {
  font-weight: 900;
}

.active-projects.attendance-tbl tbody tr td:last-child {
  padding-left: 25px;
}

.active-projects.task-table tbody td {
  border-bottom: 1px solid #E6E6E6;
}

.active-projects.manage-client tbody tr td {
  border-bottom: 1px solid #E6E6E6;
  padding: 1.125rem 1.25rem;
}

.active-projects.manage-client tbody tr td:last-child {
  text-align: right;
}

.active-projects.manage-client thead tr th:last-child {
  text-align: right;
}

.products {
  display: flex;
  align-items: center;
}

.products h6,
.products .h6 {
  font-size: 14px;
  margin-bottom: 0;
  white-space: nowrap;
}

.products h6 a,
.products .h6 a {
  color: var(--secondary);
}

.products span {
  font-size: 12px;
}

.products img {
  margin-right: 10px;
}

.products.style-1 h6,
.products.style-1 .h6 {
  font-size: 15px;
}

.products.style-1 span {
  font-size: 13px;
}

.bg-purple {
  background: #BB6BD9;
}

.tbl-progress-box {
  display: flex;
  align-items: center;
}

.tbl-progress-box .progress {
  flex: 1;
  margin-right: 5px;
}

.active-map-main {
  position: relative;
}

.active-map-main:after {
  content: "";
  position: absolute;
  top: -91px;
  right: 0;
  height: 417px;
  background: #E6E6E6;
  width: 1px;
}

.active-map-main .active-map {
  height: 19rem !important;
}

.active-country {
  margin-top: -65px;
  position: relative;
  height: 350px;
  padding-right: 1.25rem;
}

.active-country .country-list {
  padding: 9px 0;
  display: flex;
  border-bottom: 1px solid #E6E6E6;
}

.active-country .country-list .progress-box {
  flex: 1;
}

.active-country .country-list .progress-box .c-name {
  color: var(--secondary);
}

.active-country .country-list img {
  margin-right: 12px;
  border-radius: 50%;
}

.active-country .country-list:last-child {
  border-bottom: 0;
}

.active-country:after {
  content: "";
  position: absolute;
  top: -50px;
  left: -10px;
  height: 500px;
  background: #E6E6E6;
  width: 1px;
}

@media only screen and (max-width: 74.9375rem) {
  .active-country {
    margin-top: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .active-country {
    padding-right: 1rem;
  }
}

.chat-box-area .message-received p {
  background: #E8F2FF;
  padding: 10px 15px;
  border-radius: 0px 10px 10px 10px;
  color: var(--secondary);
  font-size: 13px;
}

.chat-box-area .message-received span {
  font-size: 11px;
}

.chat-box-area .message-received .text {
  display: flex;
  flex-direction: column;
  align-items: self-start;
}

.chat-box-area .message-sent {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.chat-box-area .message-sent p {
  background: #CFDDF0;
  padding: 10px 15px;
  border-radius: 10px 10px 0px 10px;
  color: var(--secondary);
  text-align: right;
  font-size: 13px;
}

.chat-box-area.style-1 {
  height: 29.7rem;
}

.chat-box-area p {
  line-height: 1.6;
}

.type-massage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.type-massage .form-control {
  border: 0;
  resize: none;
  overflow: hidden;
  background: #f5f5f5;
  line-height: 1.6;
  padding: 8px;
}

.type-massage .form-control::placeholder {
  font-size: 14px;
  color: #888888;
}

.type-massage .btn {
  position: absolute;
  right: 34px;
  font-size: 13px;
  font-weight: 500;
}

.type-massage .btn svg {
  margin-top: -3px;
}

.message-send {
  background: #f5f5f5;
  padding: 7px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.375rem;
}

.schedules-cal .datepicker-days .picker-switch {
  font-size: 15px;
  font-weight: 500;
  color: var(--secondary);
}

.schedules-cal .datepicker-days .prev i,
.schedules-cal .datepicker-days .next i {
  height: 32px;
  width: 32px;
  border-radius: 0.375rem;
  background: var(--rgba-primary-1);
  color: var(--primary);
  line-height: 32px;
}

.schedules-cal .datepicker-days .prev:hover,
.schedules-cal .datepicker-days .next:hover {
  background: transparent;
}

.schedules-cal .datepicker-days .dow {
  font-weight: 400;
  color: var(--secondary);
  background-color: #F0F4F9;
  border-radius: 0;
}

.schedules-cal .datepicker-days tr .day.weekend:first-child {
  color: #FF5E5E;
}

.schedules-cal .datepicker-days .day {
  color: var(--secondary);
  height: 39px;
  width: 39px;
  line-height: 32px;
}

.schedules-cal .datepicker-days .day.active,
.schedules-cal .datepicker-days .day.today {
  background: var(--primary) !important;
  color: #fff;
  position: relative;
}

.schedules-cal .datepicker-days .day.active:before,
.schedules-cal .datepicker-days .day.today:before {
  content: "";
  position: absolute;
  height: 13px;
  width: 13px;
  border: 2px solid #fff;
  top: -2px;
  right: -2px;
  background: #FF5E5E;
  border-radius: 50%;
}

.schedules-cal .datepicker-days .day.old,
.schedules-cal .datepicker-days .day.new {
  opacity: 0.2;
}

.schedules-cal .bootstrap-datetimepicker-widget a[data-action] {
  display: none;
}

.events h6,
.events .h6 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--primary);
  padding: 9px 1.25rem;
  border-top: 1px solid #E6E6E6;
}

.events .event-box {
  height: 55px;
  width: 55px;
  line-height: 9px;
  border-radius: 0.375rem;
  background-color: var(--rgba-primary-1);
  text-align: center;
  padding: 4px 0;
}

.events .event-box h5,
.events .event-box .h5 {
  color: var(--primary);
  font-weight: 600;
  font-size: 20px;
}

.events .event-box span {
  color: var(--secondary);
}

.events .event-data h5,
.events .event-data .h5 {
  font-size: 15px;
}

.events .event-data h5 a,
.events .event-data .h5 a {
  color: var(--secondary);
}

.events .event-data span {
  font-size: 13px;
}

.events .event-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #E6E6E6;
}

.event-scroll {
  height: 144px;
  padding: 0 1.25rem;
}

.project-chart {
  display: flex;
  justify-content: center;
}

.project-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.project-media p {
  color: var(--secondary);
}

.form-control .dropdown-toggle::after {
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  content: "\f078";
  border: 0;
  font-size: 0.7rem;
}

.form-control.custom-image-select-1 {
  width: auto !important;
}

.form-control.custom-image-select-1 img {
  width: 1.625rem;
  margin-right: 0.625rem;
}

.form-control.custom-image-select-1 .dropdown-toggle {
  padding: 7px 10px;
}

@media only screen and (max-width: 87.5rem) {
  .form-control.custom-image-select-1 .dropdown-toggle {
    padding: 6px 10px;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .form-control.custom-image-select-1 .dropdown-toggle {
    padding: 5px 10px;
  }
}

.form-control.custom-image-select-1 .filter-option-inner-inner {
  margin-right: 1.5625rem;
  color: #000;
  font-size: 14px;
}

.form-control.custom-image-select-1 .dropdown-menu .dropdown-item {
  padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  text-align: left;
}

.form-control.custom-image-select-1 .form-control {
  width: 0 !important;
}

.form-control.custom-image-select-1 .form-control .dropdown-toggle::after {
  color: #2C2C2C;
}

.form-control.custom-image-select-2 {
  background: var(--rgba-primary-1);
  border-radius: 0.5rem;
  margin-bottom: 0.625rem;
  margin-left: 0.375rem;
}

.form-control.custom-image-select-2 .dropdown-toggle::after {
  color: var(--primary);
}

.form-control.custom-image-select-2 img {
  margin-right: 0.525rem;
  width: 1.5rem;
}

.form-control.custom-image-select-2 .dropdown-toggle {
  border: 0 !important;
  padding: 0.5625rem 1rem 0.5625rem 0.85rem;
}

.form-control.custom-image-select-2 .filter-option-inner-inner {
  margin-right: 1.25rem;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.form-control.custom-image-select-2 .dropdown-menu .dropdown-item {
  padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  text-align: left;
}

.form-control.custom-image-select-2.bit {
  background: #ffeccc;
}

.form-control.custom-image-select-2.eth {
  background: #d3edf5;
}

.form-control.custom-image-select-2.dash {
  background: var(--rgba-primary-1);
}

.form-control.custom-image-select-2.lit {
  background: #c5c5c5;
}

.form-control.custom-image-select-2.ripp {
  background: rgba(35, 41, 47, 0.1);
}

.form-control.custom-image-select-2.pink-light {
  background: rgba(172, 76, 188, 0.1);
}

.form-control.custom-image-select-2.pink-light .dropdown-toggle::after {
  color: #e83e8c;
}

@media only screen and (max-width: 87.5rem) {
  .form-control.custom-image-select-1 {
    height: 3rem;
  }

  .form-control.custom-image-select-1 img {
    width: 1.75rem;
    margin-right: 1.125rem;
  }
}

.element-area {
  display: flex;
}

.element-area .demo-view {
  width: calc(100% - 270px);
}

@media only screen and (max-width: 100rem) {
  .element-area .demo-view {
    width: calc(100% - 236px);
  }
}

@media only screen and (max-width: 74.9375rem) {
  .element-area .demo-view {
    width: calc(100% - 224px);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .element-area .demo-view {
    width: 100%;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .element-area .demo-view {
    width: 100%;
  }

  .element-area .demo-view .container-sm {
    padding: 0;
  }
}

.element-area .demo-right {
  width: 16.875rem;
  position: sticky;
  top: 5rem;
  height: 100%;
  z-index: 2;
}

@media only screen and (max-width: 74.9375rem) {
  .element-area .demo-right {
    width: 15.875rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .element-area .demo-right {
    display: none;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .element-area .demo-right {
    display: none;
  }
}

.element-area .demo-right.sticky {
  top: 100px;
  transition: 0.5s;
}

.demo-right-inner {
  padding: 1rem 1.725rem;
  background-color: #fff;
  border-radius: 0.375rem;
  overflow: hidden;
}

.demo-right-inner h4,
.demo-right-inner .h4 {
  padding: 0.625rem 0;
  font-size: 1rem;
}

.demo-right-inner li {
  font-size: 1rem;
  position: relative;
}

.demo-right-inner li a {
  display: block;
  padding: 0.638rem 0.95rem;
  font-size: 13px;
}

.demo-right-inner li:after {
  content: "";
  position: absolute;
  background-color: #dfdfdf;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 18px;
  left: 0px;
}

.demo-right-tabs {
  margin: 0 -1.625rem;
  padding: 0 1.625rem;
}

.navbar-nav .active .scroll {
  color: var(--primary);
  font-weight: 500;
}

.navbar-nav .active:after {
  background-color: var(--primary);
}

.help-desk {
  padding: 0.75rem 0.6rem;
}

.help-desk .btn {
  padding: 5px 12px;
  font-size: 0.813rem !important;
  border-radius: 0.25rem;
}

label {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.input-group-lg .form-control {
  height: 3.8rem;
}

.input-group-sm .form-control {
  height: 2.5rem;
}

.table-responsive-md thead th strong {
  font-weight: 500;
  font-size: 15px;
}

@media only screen and (min-width: 1199px) and (max-width: 1450px) {
  .wid-100 {
    width: 100%;
  }

  .active-p {
    width: 100%;
  }

  .active-p .card {
    height: auto;
  }

  .total-earning h2,
  .total-earning .h2,
  .total-earning .h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }

  #earningChart {
    margin-top: -30px;
  }

  .flag {
    width: 100%;
  }

  .t-earn {
    order: 1;
    width: 50%;
  }

  .bst-seller {
    width: 100%;
  }

  .chat-map {
    order: 1;
    width: 50%;
  }

  .chat-map .type-massage .form-control {
    line-height: 1;
  }

  .up-shd {
    width: 50%;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .t-earn {
    order: 1;
  }

  .chat-box-area.style-1 {
    height: 35.9rem;
  }
}

[data-headerbg="color_4"] .header-right .header-border .btn {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

[data-headerbg="color_4"] .header-right .notification_dropdown .nav-link:hover {
  background-color: #202020;
}

[data-nav-headerbg="color_1"] .brand-logo .logo-abbr path.react-w {
  fill: black;
}

[data-nav-headerbg="color_1"] .brand-logo .logo-abbr path.w3 {
  fill: var(--primary);
}

[data-nav-headerbg="color_1"] .brand-logo .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_4"] .brand-logo .logo-abbr path.w3 {
  fill: var(--primary) !important;
}

@media only screen and (min-width: 1199px) and (max-width: 1920px) {
  .customeoff {
    width: 800px !important;
  }
}

.inovice-logo .logo-abbr .react-w {
  fill: #000;
  stroke: #000;
}

.customeoff .btn-close {
  background: none;
  height: 24px;
  width: 24px;
  background-color: #ffdede;
  border-radius: 6px;
  color: red;
  font-size: 18px;
  margin-right: 1rem;
}

.customeoff .offcanvas-header {
  margin-left: 1rem;
}

.customeoff .offcanvas-backdrop.show {
  opacity: 0.7;
  background-color: var(--secondary);
}

.sweet-image {
  width: 20%;
}

.popover {
  border-color: transparent;
}

.popover.clockpicker-popover {
  position: absolute;
}

.upload-img .dropzone {
  border: 0.0625rem dashed #DDDFE1;
  min-height: 6.325rem;
  position: relative;
  border-radius: 0.375rem;
  text-align: center;
  padding: 14px 0;
}

.upload-img .dlab-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.upload-img .dlab-success-mark,
.upload-img .dlab-error-mark {
  display: none;
}

.finance-hr .form-control::placeholder {
  font-size: 13px;
  color: #888888;
}

.mix-chart-tab .nav-link {
  background: #F2F2F2;
  margin: 0 4px;
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .mix-chart-tab {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .mix-chart-tab.style-1 .nav-link {
    margin: 4px;
  }
}

input.form-control {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.invite input[type="email"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}

[data-nav-headerbg="color_4"] .nav-header .nav-control {
  background-color: #202020;
}

[data-nav-headerbg="color_4"] .nav-header .hamburger .line {
  background: #fff;
}

[data-headerbg="color_1"] .nav-header .nav-control {
  background-color: transparent;
}

[data-headerbg="color_1"] .nav-header .hamburger .line {
  background: #000;
}

.bootstrap-select .btn {
  border-color: #CCCCCC !important;
}

.default-select.style-1 .dropdown-toggle {
  padding: 0.375rem 0.75rem;
  height: 2.813rem;
  line-height: 30.7px;
  font-size: 13px;
  color: #a5a5a5 !important;
}

.header-profile2 .dropdown-item:hover {
  background-color: var(--rgba-primary-1);
}

@media only screen and (max-width: 35.9375rem) {
  .menu-title {
    display: none;
  }
}

.due-progress {
  margin-bottom: 10px;
}

.show-more-btn:hover {
  padding-left: 3rem !important;
}

#empoloyeestbl2 thead tr th:first-child:after {
  display: none;
}

[data-headerbg="color_4"] .header-left .search-area .form-control,
[data-headerbg="color_4"] .header-left .search-area .input-group-text {
  background-color: #202020;
}

.mix-chart-tab .nav-link.active svg path {
  fill: white;
}

.dt-filter .dataTables_filter {
  float: left;
}

.dt-filter .dataTables_filter input[type="search"] {
  border: 0;
  border: 0;
  background: #eeee;
}

.dt-filter .dataTables_filter label {
  background: #eeee;
  padding: 0 8px;
  margin: 1px 17px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  margin-right: 0;
  padding-right: 0;
}

.user-tbl .dt-buttons .dt-button {
  top: 0;
}

.user-tbl tbody tr td:first-child {
  width: 25px;
}

.user-tbl thead tr th.sorting_asc:after,
.user-tbl thead tr th.sorting_desc:after {
  content: none;
}

.shorting thead tr th.sorting_asc:after,
.shorting thead tr th.sorting_desc:after {
  content: none;
}

.exports .dt-buttons .dt-button {
  top: 0px !important;
  right: 0 !important;
}

.exports .tbl-caption {
  padding: 10px;
}

.filter {
  position: relative;
}

.filter .dropdown-menu {
  width: 275px;
}

.filter .dropdown-menu .single-select-placeholder {
  width: 100%;
  padding: 5px 5px;
  border-radius: 0.25rem;
  border-color: #eee;
}

.attendance-tbl table.dataTable thead .sorting:after,
.attendance-tbl table.dataTable thead .sorting_asc:after {
  display: none;
}

.header-search {
  background-color: transparent;
  border-color: transparent;
}

.eye {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.recentOrderTable table {
  margin-bottom: 0;
  overflow: hidden;
}

.task .task-summary {
  border-right: 1px solid #E6E6E6;
}

.task .task-summary h2,
.task .task-summary .h2 {
  font-size: 23px;
  margin-bottom: 0;
  font-weight: 600;
  margin-right: 9px;
  line-height: 1;
}

.task .task-summary h2.text-purple,
.task .task-summary .text-purple.h2 {
  color: #BB6BD9;
}

.task .task-summary span {
  font-size: 14px;
  color: var(--secondary);
  font-weight: 500;
}

.task .task-summary p {
  margin-bottom: 0px;
  font-size: 13px;
}

@media only screen and (max-width: 74.9375rem) {
  .task .task-summary {
    margin: 24px 0;
    margin-top: 0;
  }
}

.task>div:last-child .task-summary {
  border-right: 0;
}

@media only screen and (max-width: 74.9375rem) {
  .task>div:last-child .task-summary {
    border-right: 1px solid #E6E6E6;
  }
}

.status-select {
  width: auto !important;
}

.status-select .btn {
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 500;
  border: 0 !important;
  border-radius: 0.25rem;
}

.status-select [title="Testing"] {
  background-color: #ffeccc !important;
  color: #FF9F00 !important;
}

.status-select [title="Pending"],
.status-select [title="Low"] {
  background-color: #ffdede !important;
  color: #FF5E5E !important;
}

.status-select [title="In Progress"] {
  background-color: rgba(187, 107, 217, 0.1) !important;
  color: #BB6BD9 !important;
}

.status-select [title="Complete"],
.status-select [title="High"] {
  background-color: #daf5e6 !important;
  color: #3AC977 !important;
}

.status-select [title="Medium"] {
  background-color: rgba(44, 44, 44, 0.1) !important;
  color: #888888 !important;
}

.normal-select .btn {
  font-size: 13px;
  color: var(--primary) !important;
}

.normal-select .dropdown-toggle::after {
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  content: "\f078";
  border: 0;
  font-size: 0.7rem;
}

.border-purple {
  border-color: #BB6BD9 !important;
}

.bg-purple-light {
  background: rgba(187, 107, 217, 0.1);
}

.kanban-bx {
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin: 0;
}

.kanban-bx .col {
  width: 324px;
  min-width: 324px;
  padding-left: 0;
}

.kanban-bx .col .card {
  height: 100%;
  border: 0;
  margin-bottom: 0;
}

.kanban-bx::-webkit-scrollbar {
  background-color: #ECECEC;
  width: 8px;
  height: 8px;
}

.kanban-bx::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 10px;
}

@media only screen and (max-width: 47.9375rem) {
  .kanban-bx {
    display: block;
    width: auto;
  }

  .kanban-bx .col {
    width: 100%;
    min-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
}

.sub-card-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub-card-2 .icon-box h5,
.sub-card-2 .icon-box .h5 {
  line-height: 2.6;
  font-weight: 600;
}

.kanbanPreview-bx {
  background-color: transparent;
  box-shadow: none;
}

.kanbanPreview-bx .card-body {
  padding: 0;
}

.kanbanPreview-bx .card-body .sub-card {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 0.375rem;
  padding: 0.938rem 0.938rem;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  border: 1px solid #E6E6E6;
}

.kanbanPreview-bx .card-body .sub-card>.sub-title {
  margin-bottom: 10px;
  display: block;
  font-weight: 600;
  font-size: 14px;
}

@media only screen and (max-width: 35.9375rem) {
  .kanbanPreview-bx .card-body .sub-card {
    padding: 1rem;
    margin-bottom: 10px;
  }

  .kanbanPreview-bx .card-body .sub-card>.sub-title {
    margin-bottom: 2px;
  }
}

.kanbanPreview-bx .card-body .sub-card .dropzone {
  min-height: 70px;
  border-radius: 0.375rem;
  height: 70px;
  background: transparent;
  border-style: dashed;
}

.kanbanPreview-bx .card-body .sub-card .dropzone .dz-message {
  margin: 0;
}

.kanbanPreview-bx .card-body .sub-card .task-card-data {
  padding: 0.938rem 0.938rem;
}

.kanbanPreview-bx .card-body .sub-card .footer-data span {
  font-size: 12px;
  color: var(--primary);
}

.kanbanPreview-bx .card-body .sub-card .footer-data p {
  margin: 0;
  font-size: 13px;
}

.kanbanPreview-bx .card-body .card-footer {
  padding: 0.938rem 0.938rem;
}

.draggable {
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  outline: 0 !important;
}

.draggable.draggable-mirror {
  opacity: .8;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  border-radius: 0.375rem;
  padding: 1.3rem 1.6rem;
  background: #fff;
  border: 2px dashed #e4e6ef !important;
}

.draggable.draggable--original {
  opacity: 0 !important;
}

.draggable.draggable-source--is-dragging.draggable--over {
  opacity: 0 !important;
}

.draggable-handle {
  cursor: all-scroll;
}

.draggable-zone {
  outline: 0 !important;
}

.total-projects h3,
.total-projects .h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0;
}

.total-projects span {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary);
}

@media only screen and (min-width: 1199px) and (max-width: 1300px) {

  .total-projects h3,
  .total-projects .h3 {
    font-size: 20px;
  }

  .total-projects span {
    font-size: 12px;
  }
}

.box-hover:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.due-progress p {
  font-weight: 500;
}

.due-progress p span {
  font-weight: 400;
}

.photo-content {
  position: relative;
}

.photo-content .cover-photo {
  /* background: url(../images/profile/12.jpg); */
  background-size: cover;
  background-position: center;
  min-height: 15.625rem;
  width: 100%;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.user .user-info {
  display: flex;
  padding: 0.9375rem 1.25rem 1.5rem;
}

@media only screen and (max-width: 47.9375rem) {
  .user .user-info {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .user .user-info {
    display: block;
  }
}

.user .user-info .user-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .user .user-info .user-details {
    display: block;
  }
}

.user .user-info .user-details .user-contact {
  display: flex;
  align-items: center;
  padding-top: 1.125rem;
  flex-wrap: wrap;
}

.user .user-info .user-details .user-contact .user-number {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  margin-bottom: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .user .user-info .user-details .user-contact .user-number {
    margin-bottom: 0.875rem;
  }
}

.user .user-info .user-details .user-contact .user-email {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .user .user-info .user-details .user-contact .user-email {
    margin-bottom: 0.875rem;
  }
}

.user .user-info .user-details .user-contact h4,
.user .user-info .user-details .user-contact .h4 {
  margin: 0;
}

.user-photo {
  min-width: 150px;
  height: 150px;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  margin-right: 0.625rem;
}

.user-photo img {
  border: 4px solid #fff;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 35.9375rem) {
  .user-photo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.25rem;
    margin-top: -6.5rem;
    width: 150px;
  }
}

[direction="rtl"] .user-photo {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 0.9375rem;
}

@media only screen and (max-width: 74.9375rem) {
  [direction="rtl"] .user-photo {
    right: 0.9375rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .user-photo {
    width: 5rem;
    right: calc(50% - 2.5rem);
    top: -6.25rem;
  }
}

.profile-interest .row {
  margin: 0 -0.0437rem;
}

.profile-interest .row .int-col {
  padding: 0 0.0437rem;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 0.0875rem;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.3125rem;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 0.075rem;
}

.profile-tab .nav-item .nav-link {
  font-size: 1rem;
  margin-right: 1.875rem;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.0125rem solid transparent;
  color: #828690;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border: 0;
  background: transparent;
  background: transparent;
  border-bottom: 0.0125rem solid var(--primary);
  color: var(--primary);
  transition: transform 150ms ease-in-out;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-tab .nav-item .nav-link {
    margin-right: 0rem;
  }
}

.side-detail {
  display: flex;
}

.side-detail .edit-profiil {
  margin-right: 0.625rem;
}

.post-input {
  margin-bottom: 1.875rem;
}

.post-input .form-control {
  height: 47px;
  font-weight: 400;
  margin: 0.9375rem 0;
}

.post-input .btn-social {
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  border-radius: 0.375rem;
  color: #fff;
  width: 3.4375rem;
  line-height: 3.375rem;
}

.post-input .btn-social.facebook {
  background-color: #3B5998;
}

.post-input .btn-social.google-plus {
  background-color: #DE4E43;
}

.post-input .btn-social.linkedin {
  background-color: #007BB6;
}

.post-input .btn-social.instagram {
  background-color: #8A5A4E;
}

.post-input .btn-social.twitter {
  background-color: #1EA1F3;
}

.post-input .btn-social.youtube {
  background-color: #CE201F;
}

.post-input .btn-social.whatsapp {
  background-color: #01C854;
}

.post-input .btn-social i {
  margin: 0 !important;
}

.profile-uoloaded-post img {
  margin-bottom: 1.25rem;
}

.profile-uoloaded-post a h4,
.profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53;
}

.prot-blog {
  padding: 30px 20px;
  background-color: var(--primary);
  position: relative;
  overflow: hidden;
  z-index: 0;
  border-radius: 0.375rem;
}

.prot-blog .post .text {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.prot-blog .fill .text {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.prot-blog .fill a {
  background-color: white;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.375rem;
  color: #2A353A;
}

@media only screen and (max-width: 78.125rem) {
  .prot-blog .fill a {
    padding: 5px 5px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .prot-blog .fill a {
    padding: 8px 17px;
  }
}

.prot-blog .text-bla {
  color: white !important;
  font-size: 14px;
  font-weight: 500;
}

.prot-blog p {
  color: white;
  font-weight: 400;
}

.prot-blog .shape {
  position: absolute;
  bottom: -70px;
  right: -50px;
  z-index: -1;
}

.prot-blog .shape svg path {
  fill: #3ab5ff;
}

.messages .input-group.search-area {
  flex-direction: row-reverse;
  margin-right: 0.625rem;
}

.messages .input-group.search-area input.form-control {
  border-left: none;
  background-color: #f3f3f3;
  border: 0;
  overflow: hidden;
  height: 3rem;
  padding-left: 0;

}

.messages .input-group.search-area .input-group-text {
  border-right: none;
  background-color: #f3f3f3;
  border: 0;
  height: 3rem;
  padding: 0;
}

.messages .add {
  background-color: var(--primary);
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messages .student {
  border-bottom: 1px solid #E6E6E6;
}

.pie-chart2 .chart-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 85.375rem) {
  .pie-chart2 .chart-group .radius-bar {
    width: 50%;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .pie-chart2 .chart-group .radius-bar {
    flex: 0 0 50%;
    margin-bottom: 0.875rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .pie-chart2 .chart-group .radius-bar {
    flex: 0 0 100%;
  }
}

.pie-chart2 .chart-group .donut-chart-sale {
  margin-bottom: 0.625rem;
}

.pie-chart2 .chart-group .donut-chart-sale small,
.pie-chart2 .chart-group .donut-chart-sale .small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.125rem;
  font-weight: 600;
}

.pie-chart2 .chart-info {
  margin-top: 1rem;
  padding-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px dashed #6666663d;
}

@media only screen and (max-width: 35.9375rem) {
  .pie-chart2 .chart-info {
    flex-wrap: wrap;
  }

  .pie-chart2 .chart-info .btn {
    margin-top: 0.625rem;
  }
}

.pie-chart2 .chart-info p {
  margin-bottom: 0;
}

.profile-form .form-control,
.profile-form .bootstrap-select .dropdown-toggle {
  height: 45px;
  font-size: 13px;
  border-radius: 0.375rem;
  border-color: #CCCCCC;
}

.profile-form .form-control:active,
/* .profile-form .form-control:focus, */
.profile-form .form-control:hover,
.profile-form .bootstrap-select .dropdown-toggle:active,
.profile-form .bootstrap-select .dropdown-toggle:focus,
.profile-form .bootstrap-select .dropdown-toggle:hover {
  border-color: var(--primary);
}

.profile-form .bootstrap-select>.dropdown-toggle {
  background-color: #fff;
  padding: 12px 20px;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-form .bootstrap-select>.dropdown-toggle {
    padding: 10px 20px;
  }
}

.profile-form .bootstrap-select>.dropdown-toggle:after {
  font-size: 0.7rem;
}

.profile-form .form-label {
  color: #6e6e6e;
  font-size: 15px;
  margin-bottom: 12px;
  font-weight: 400;
}

.profile-form .btn-light:hover,
.profile-form .btn-light:focus {
  color: #888888;
}

.profile-card {
  border-radius: 0.375rem;
}

.profile-card .card-header {
  border-color: #CCCCCC;
  padding: 30px;
}

.profile-card .card-header .title {
  position: relative;
  margin-bottom: 0;
  color: #6e6e6e;
}

.profile-card .card-header .title:after {
  content: "";
  height: 3px;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  bottom: -31px;
  left: 0;
}

.profile-card .m-b30 {
  margin-bottom: 30px;
}

.profile-card .card-body {
  padding: 40px 40px 10px;
}

.profile-card .card-footer {
  padding: 20px 40px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: #CCCCCC;
}

.profile-card .card-footer .btn-lg,
.profile-card .card-footer .btn-group-lg>.btn {
  padding: 18px 50px;
  font-size: 15px;
}

.profile-card .card-footer .btn-link {
  font-size: 15px;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-card .card-header {
    padding: 25px;
  }

  .profile-card .card-header .title:after {
    bottom: -26px;
  }

  .profile-card .card-body {
    padding: 25px 25px 0;
  }

  .profile-card .card-footer {
    padding: 25px 25px 25px;
  }

  .profile-card .card-footer .btn-lg,
  .profile-card .card-footer .btn-group-lg>.btn {
    padding: 18px 30px;
  }
}

.author-profile {
  text-align: center;
}

.author-profile .card-body {
  padding: 0;
}

.author-profile .author-media {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: inline-block;
}

.author-profile .author-media img {
  width: 130px;
  border-radius: 100%;
}

.author-profile .author-info .title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.author-profile .author-info span {
  display: block;
  color: #888888;
}

.author-profile .info-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-top: 1px solid #CCCCCC;
  padding: 18px 30px;
  color: #6e6e6e;
}

.author-profile .info-list li a {
  color: #6e6e6e;
}

.author-profile .info-list li span {
  color: #888888;
  font-weight: 500;
}

.author-profile .card-footer {
  padding: 30px;
  display: block;
}

.author-profile .card-footer .form-control {
  height: 45px;
  border-color: #CCCCCC;
  overflow: hidden;
  line-height: 34px;
}

.author-profile .upload-link {
  position: absolute;
  width: 35px;
  height: 35px;
  line-height: 32px;
  background: var(--primary);
  bottom: 0;
  right: 0px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  border-radius: 100%;
  color: #fff;
  overflow: hidden;
  border: 2px solid #fff;
}

.author-profile .upload-link .update-flie {
  position: absolute;
  opacity: 0;
  z-index: 0;
  width: 100%;
  cursor: pointer;
  left: 0;
  height: 100%;
}

.draggable-mirror {
  padding: 0.938rem 0.938rem !important;
}

.logo-header {
  margin-bottom: 2rem;
}

.logo-header .logo-white {
  margin-left: 0.625rem;
}

.login-form .forget-pass {
  display: block;
  margin-top: 20px;
  text-align: center;
}

.dz-social {
  display: flex;
  justify-content: space-between;
}

.dz-social-icon {
  display: inline-flex;
  margin: 0 -3px;
  padding: 0;
  text-align: center;
}

.dz-social-icon li {
  font-size: 12px;
  margin-right: 0.2rem;
}

.dz-social-icon li a {
  color: #ffffff;
  font-size: 14px;
  height: 35px;
  line-height: 34px;
  padding: 0;
  width: 35px;
  border-radius: 4px;
}

div#mCSB_1 {
  margin-top: 100px;
}

.card-use-box {
  text-align: center;
}

.card-use-box .crd-bx-img {
  position: relative;
  width: 100px;
  margin: auto;
}

.card-use-box .crd-bx-img .active {
  width: 15px;
  height: 15px;
  background: #3AC977;
  border-radius: 50%;
  left: 5rem;
  position: absolute;
  bottom: 0.4rem;
}

.card-use-box .crd-bx-img .active.deactive {
  background: #FF5E5E;
}

.card-use-box .crd-bx-img img {
  border: 7px solid rgba(62, 95, 206, 0.08);
}

.card__info {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__info li .card__info__stats {
  display: block;
  color: var(--primary);
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0 40px;
}

@media only screen and (max-width: 87.5rem) {
  .card__info li .card__info__stats {
    padding: 0 25px;
  }
}

.post-pos {
  text-align: center;
  margin: 18px;
}

.post-pos .card__info__stats {
  color: var(--primary);
}

.post-pos li {
  margin-bottom: 10px;
}

.user-m-tabe {
  margin-top: 0px;
}

.user-m-tabe .nav-link {
  padding: 3px 8px;
}

.personal-info li {
  padding: 12px 20px;
}

.personal-info li:hover {
  background: var(--rgba-primary-1);
}

.action-button .btn.btn-icon-xxs {
  padding: 0.075rem 0.2375rem;
}

.action-button .btn.btn-icon-xxs i {
  transform: scale(0.8);
}

.all_user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: var(--rgba-primary-1);
}

.all_user1 {
  display: flex;
  justify-content: space-between;
  padding: 9px 20px;
}

.all_user1 .member .heading {
  padding: 0 10px;
}

.right-check .custom-checkbox {
  margin-right: 5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .right-check .custom-checkbox {
    margin-right: 1rem;
  }
}

.manage {
  padding: 0 30px;
  margin-top: -10px;
}

.role-tble tbody tr td:not(:first-child) .custom-checkbox {
  display: flex;
  justify-content: end;
}

.role-tble.table tbody tr td {
  padding: 0.4375rem 0.625rem;
  border: 0;
}

.role-tble.table thead tr th {
  padding: 0.4375rem 0.625rem;
}

.c-details ul li {
  border-bottom: 1px solid #E6E6E6;
  padding: 10px 0;
}

.c-details ul li p {
  color: black;
  margin: 0;
}

.c-social {
  display: flex;
  margin-top: 15px;
}

.c-social li a {
  padding: 5px 10px;
  margin: 0 7px;
  border-radius: 0.25rem;
}

.c-social li a:first-child {
  margin-left: 0;
}

.c-busiess .avatar {
  height: 60px;
  width: 60px;
  margin-right: 10px;
}

.c-card-details ul li {
  display: flex;
  padding: 6px 0px;
  align-items: center;
}

.c-work {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-work .c-task {
  padding: 7px 20px;
  border-radius: 0.25rem;
  margin: 0 6px;
}

.c-work .c-task p {
  margin-bottom: 0;
  color: white;
  display: flex;
  align-items: center;
}

.c-work .c-task span {
  color: white;
}

@media only screen and (max-width: 35.9375rem) {
  .c-work .c-task {
    padding: 7px 12px;
  }
}

.c-work .c-task:first-child {
  margin-left: 0;
}

.c-primium li {
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;
}

.c-status i {
  transform: scale(0.5);
  margin-right: 5px;
}

.blog-card {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.blog-card h4,
.blog-card .h4 {
  font-size: 14px;
}

.blog-card img {
  margin-bottom: 10px;
}

@media only screen and (max-width: 35.9375rem) {
  .blog-card img {
    width: 45px;
  }
}

.blog-card:hover {
  transform: translate(0, -10px);
}

@media only screen and (max-width: 35.9375rem) {
  .c-busiess {
    margin-bottom: 10px;
  }
}

.blog-title {
  position: relative;
}

.blog-title:before {
  content: "";
  height: 4px;
  width: 60px;
  background-color: var(--primary);
  position: absolute;
  bottom: -5px;
  left: 0;
  border-radius: 10px;
}

.blog-title:after {
  content: "";
  height: 5px;
  width: 5px;
  background-color: var(--primary);
  position: absolute;
  bottom: -5px;
  left: 65px;
  border-radius: 50px;
}

.blog-img {
  position: relative;
}

.blog-img .blk-img {
  width: 100%;
  height: 575px;
  object-fit: cover;
  border-radius: 0.375rem;
}

@media only screen and (max-width: 35.9375rem) {
  .blog-img .blk-img {
    height: 350px;
  }
}

.blog-img .blk-img2 {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 0.375rem;
}

@media only screen and (max-width: 35.9375rem) {
  .blog-img .blk-img2 {
    height: 300px;
  }
}

.blog-img .blog-text {
  position: absolute;
  bottom: 0;
  padding: 20px 15px;
}

.blog-img .blog-text h2,
.blog-img .blog-text .h2 {
  font-size: 30px;
  line-height: 1.1;
  color: white;
  font-weight: 600;
}

@media only screen and (max-width: 74.9375rem) {

  .blog-img .blog-text h2,
  .blog-img .blog-text .h2 {
    font-size: 20px;
  }
}

.blog-img .blog-text span {
  color: white;
}

.blog-img.style-1 .blk-img {
  height: 275px;
}

.blog-img.style-1 .blog-text h2,
.blog-img.style-1 .blog-text .h2 {
  font-size: 16px;
  font-weight: 500;
}

.blog-post .post-1 {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.blog-post .post-1 .custome-avatar {
  height: 90px;
  width: 90px;
  border-radius: 50%;
}

@media only screen and (max-width: 74.9375rem) {
  .blog-post .post-1 .custome-avatar {
    height: 70px;
    width: 70px;
  }
}

.blog-post .post-1 .post-data h4,
.blog-post .post-1 .post-data .h4 {
  font-size: 15px;
  margin-top: 5px;
}

@media only screen and (max-width: 35.9375rem) {

  .blog-post .post-1 .post-data h4,
  .blog-post .post-1 .post-data .h4 {
    font-size: 14px;
  }
}

.blog-post .post-1 .post-data span {
  font-size: 12px;
}

@media only screen and (max-width: 74.9375rem) {
  .blog-post {
    margin-top: 10px;
  }
}

.small-post {
  height: 1.275rem !important;
  width: 1.275rem !important;
}

.seconday-post {
  margin-top: 25px;
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .seconday-post {
    flex-flow: wrap;
  }
}

.seconday-post img {
  width: 160px;
  height: 130px;
  border-radius: 0.375rem;
  margin-right: 18px;
  object-fit: cover;
}

@media only screen and (max-width: 35.9375rem) {
  .seconday-post img {
    width: 100%;
    height: 200px;
  }
}

.seconday-post h4,
.seconday-post .h4 {
  font-size: 13px;
  margin-top: 6px;
}

.seconday-post span {
  font-size: 12px;
}

@media only screen and (max-width: 35.9375rem) {
  .seconday-post .post-data {
    margin-top: 15px;
  }
}

.third-post {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

@media only screen and (max-width: 74.9375rem) {
  .third-post {
    flex-flow: wrap;
  }
}

.third-post img {
  width: 300px;
  height: 180px;
  border-radius: 0.375rem;
  margin-right: 18px;
  object-fit: cover;
}

@media only screen and (max-width: 74.9375rem) {
  .third-post img {
    width: 100%;
    height: 220px;
  }
}

.third-post h4,
.third-post .h4 {
  font-size: 15px;
  margin-top: 6px;
}

.third-post span {
  font-size: 12px;
}

.third-post.style-1 {
  margin-top: 20px;
}

.third-post.style-1 img {
  width: 90px;
  height: 80px;
}

.third-post.style-1 h4,
.third-post.style-1 .h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.third-post.style-2 {
  margin-bottom: 0;
  border-bottom: 1px solid #CCCCCC;
}

.third-post.style-2 .av-post {
  height: 40px;
  width: 40px;
}

.third-post.style-2 .post-data {
  margin-bottom: 14px;
}

@media only screen and (max-width: 74.9375rem) {
  .third-post .post-data {
    margin-top: 15px;
  }
}

.notifiy {
  background-color: var(--rgba-primary-1);
  position: relative;
  margin-top: 40px;
}

.notifiy .icon-box {
  position: absolute;
  top: -15px;
  left: 46%;
}

.notifiy .notify-data {
  text-align: center;
  padding: 20px 0;
  padding-bottom: 0;
}

.notifiy .notify-data span {
  font-size: 12px;
}

/*
===================================
    Product detail
===================================*/
.produtct-detail-tag {
  display: inline-block;
}

.produtct-detail-tag a {
  font-style: 0.8125rem;
  color: #6e6e6e;
}

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 0.375rem;
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 0.25rem;
  padding: 0.125rem 0.75rem;
  color: #fff;
}

.filtaring-area h4,
.filtaring-area .h4 {
  color: #1d1d1d;
  font-size: 1rem;
  font-weight: 400;
  text-transform: lowercase;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #6e6e6e;
}

.plus-minus-input {
  display: flex;
  width: 7.5rem;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.75rem 0.5rem;
  background: #fff;
  border: 0.0625rem solid #E6E6E6;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border: 0.0625rem solid #E6E6E6;
}

.btn-reveal-trigger .avatar-xl {
  min-width: 1.875rem;
}

.share-view {
  display: inline-block;
}

.share-view ul li {
  display: inline-block;
}

.share-view .share-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border: 0.0625rem solid #E6E6E6;
  text-align: center;
  line-height: 2.5rem;
  font-style: 1rem;
  color: #E6E6E6;
  margin-right: 0.5rem;
}

/*--------tab----------*/
.veritical-line {
  padding: 1.25rem 1.875rem;
  border-top: 0.0625rem solid #E6E6E6;
  border-right: 0.0625rem solid #E6E6E6;
  border-bottom: 0.0625rem solid #E6E6E6;
  position: relative;
}

.veritical-line:before {
  background: #E6E6E6;
  bottom: 0;
  content: "";
  height: 100%;
  left: -0.0625rem;
  max-height: 40%;
  position: absolute;
  width: 0.0625rem;
}

.tab-content-text p {
  color: #6e6e6e;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5625rem;
}

.tab-item-list li a {
  background: #fff;
  border-top: 0.0625rem solid #E6E6E6;
  border-left: 0.0625rem solid #E6E6E6;
  border-right: 0.0625rem solid #E6E6E6;
  color: #6e6e6e;
  display: block;
  font-size: 1rem;
  padding: 1rem;
  text-transform: uppercase;
}

.tab-item-list li a:hover,
.tab-item-list li a:focus {
  background: #fff;
  color: #6e6e6e;
  border-right: 0rem;
}

.tab-item-list li:last-child {
  border-bottom: 0.0625rem solid #E6E6E6;
}

.tab-list li {
  margin-bottom: 0.4375rem;
  font-size: 0.8125rem;
}

.tab-list li i {
  font-size: 0.8125rem;
  margin-right: 0.875rem;
}

.slide-item-list {
  text-align: center;
  margin: 0 -0.3125rem;
  border: 0;
}

.slide-item-list li {
  display: inline-block;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 0.3125rem;
}

.slide-item-list li a {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background: transparent;
  padding-bottom: 0rem;
}

.slide-item-list li a:hover,
.slide-item-list li a:focus {
  background: transparent;
}

.slide-item-list li a img {
  width: 100%;
  border-radius: 0.375rem;
}

.slide-item-list .nav-link {
  border: 0;
  border-radius: 0.375rem;
}

.slide-item-list .nav-link.active {
  border: 0;
  border-radius: 0.375rem;
}

.product-detail-text {
  padding-top: 1.75rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 4.375rem;
}

.star-rating .product-review {
  font-style: 0.8125rem;
  color: #6e6e6e;
  font-weight: 400;
  text-decoration: underline !important;
}

.product-detail .tab-content img {
  display: inline-block;
  width: 100%;
}

.popular-tag ul {
  margin: 0rem;
  padding: 0rem;
}

.popular-tag ul li {
  display: inline-block;
  padding: 0.5rem 0.9375rem;
  background: #f8f8f8;
  font-size: 0.8125rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}

.size-filter ul li {
  display: inline-block;
}

.intro {
  border: 0.0625rem solid red;
  color: #1d1d1d;
}

#listResults .slider {
  margin: 1.5625rem 0;
}

#listResults .slider-box {
  width: 90%;
  margin: 1.5625rem auto;
}

#listResults input {
  width: 10%;
}

#listResults label {
  border: none;
  display: inline-block;
  margin-right: -0.25rem;
  vertical-align: top;
  width: 30%;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #aaaaaa;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  background: #ffffff;
  border: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .custom-btn:hover,
.plus-minus-input .custom-btn:focus,
.plus-minus-input .custom-btn.active {
  box-shadow: none;
  outline: none;
}

.plus-minus-input .form-control {
  height: 2.5rem;
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0rem solid #c8c8c8;
}

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden;
  position: relative;
}

.new-arrival-product .new-arrivals-img-contnent img {
  width: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.new-arrival-product .new-arrivals-img-contnent:after {
  content: "";
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.new-arrival-product:hover .new-arrivals-img-contnent:after {
  webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.input-number {
  line-height: 1.8;
}

.star-rating {
  margin-bottom: 0.625rem;
}

.select-size .btn:not(:first-child),
.select-size .btn:not(last-child) {
  margin: 0 5px;
  box-shadow: none;
}

/*
===================================
    list view
===================================*/
.new-arrival-content .item {
  font-size: 0.75rem;
  color: #6e6e6e;
}

.new-arrival-content h4,
.new-arrival-content .h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}

.new-arrival-content h4 a,
.new-arrival-content .h4 a {
  color: #000;
}

.new-arrival-content .price {
  font-weight: 600;
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 0;
  float: right;
}

@media only screen and (max-width: 35.9375rem) {
  .new-arrival-content .price {
    float: none;
    margin-top: 0.625rem;
  }
}

.new-arrival-content p {
  font-size: 0.875rem;
  color: #828690;
  margin-bottom: 0.375rem;
  line-height: 1.5rem;
}

.new-arrival-content .text-content {
  margin-top: 1.125rem;
}

.new-arrival-content.text-center .price {
  float: unset !important;
}

.success-icon {
  color: #3AC977;
  font-size: 1rem;
}

.comment-review {
  margin-bottom: 0.9375rem;
  display: table;
  width: 100%;
}

.comment-review .client-review {
  color: #828690;
  padding-right: 1.25rem;
  text-decoration: underline !important;
  font-size: 0.875rem;
}

.comment-review .span {
  color: #828690;
  font-size: 0.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .comment-review {
    margin-bottom: 0;
  }
}

.star-rating li {
  display: inline-block;
}

.star-rating li i {
  color: gold;
}

.order {
  font-weight: 500;
}

.cm-content-box {
  background: #fff;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
}

.cm-content-box.filter {
  border-top: 0;
}

.cm-content-box .content-title {
  padding: 0.5rem 1.875rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm-content-box .content-title .cpa {
  color: var(--primary);
  padding: 11px 0 9px 0;
  font-weight: 600;
  font-size: 1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .cm-content-box .content-title {
    padding: 0.5rem 0.938rem;
  }
}

.cm-content-box .content-title {
  border-top-left-radius: 0.425rem;
  border-top-right-radius: 0.425rem;
}

.cm-content-box.style-1 .content-title {
  border-radius: 0.375rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #E6E6E6;
}

.cm-content-box .tools a {
  display: inline-block;
}

.cm-content-box .tools a:hover i {
  opacity: 0.5;
}

.cm-content-box .tools a i {
  font-family: 'Font Awesome 6 Free';
  font-weight: 600;
  color: #c2c2c2;
  font-size: 1.25rem;
}

.cm-content-box .tools a.expand {
  transform: rotate(-180deg);
}

.cm-content-box .cm-content-body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-top: 1px solid #eee;
}

.cm-content-box .cm-content-body .table thead tr th .custm-select {
  height: 45px;
  background-color: transparent;
  border-radius: 0.375rem;
}

.cm-content-box .cm-content-body .table thead tr th .custm-select.input-small {
  width: 9.063rem !important;
}

.cm-content-box .cm-content-body .table thead tr th .form-control {
  border-radius: 0.375rem;
}

.cm-content-box .cm-content-body .table thead tr th .form-control.input-large {
  width: 20rem !important;
}

.cm-content-box .cm-content-body .table thead tr:nth-child(2) {
  background-color: #000;
  box-shadow: 10px 10px 8px #888888;
}

.cm-content-box .cm-content-body .table.table-striped th {
  padding: 1rem;
  color: #888888;
}

.cm-content-box .cm-content-body .table.table-striped td {
  padding: 1rem;
  color: #504f4f;
  white-space: nowrap;
}

.cm-content-box .cm-content-body .table.table-striped td:first-child {
  padding-left: 2rem;
}

.cm-content-box .cm-content-body .table.table-bordered thead {
  background-color: unset;
}

.cm-content-box .cm-content-body .table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0.313rem;
}

.cm-content-box .cm-content-body.excerpt .textarea {
  padding: 0.438rem 0.25rem;
}

.cm-content-box .cm-content-body.custom-fields .name-value {
  line-height: 2.375rem;
  font-size: 1rem;
  text-align: center;
}

.cm-content-box .cm-content-body .contact {
  border-radius: 0.375rem !important;
}

.cm-content-box .cm-content-body .collapse.show {
  margin-bottom: 0.625rem;
}

.contents-list .btn-icon-only {
  height: 2.25rem;
  width: 2.25rem;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  line-height: 1;
}

.contents-list .btn-icon-only i {
  font-size: 1rem;
}

.contents-list .table tbody tr td {
  padding: 0.4375rem 0.625rem;
}

.fatch-date {
  display: block;
}

.new-scroll {
  background-color: #dfdfdf;
  padding: 0.625rem;
  height: 9.375rem;
  overflow-y: auto;
  border-radius: 0.375rem;
}

.content-icon {
  width: 2.188rem;
  height: 2.188rem;
  display: inline-block;
  text-align: center;
  line-height: 2.125rem;
  font-size: 0.625rem;
  padding: 0px;
}

.dd-handle.move-media {
  padding: 0.5rem 1.125rem;
  display: flex;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
  border: 0;
  z-index: 2;
  background: none;
  position: absolute;
  background-color: var(--rgba-primary-8);
  top: 0;
  font-size: 1.25rem;
  left: 0;
  color: #fff;
  align-items: center;
}

.dd-handle.move-media+.accordion-button {
  padding-left: 4.063rem;
  background: #fff;
  position: relative;
  z-index: 1;
}

.menu-ac-item {
  position: relative;
}

.menu-ac-item .dd-collapse {
  height: 3.338rem;
  width: 3.338rem;
  border: 1px solid #d3d3d3;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  font-size: 1.75rem;
  color: black;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.menu-ac-item .dd-collapse+.accordion {
  margin-left: 3.25rem;
}

@media only screen and (max-width: 1600px) {
  .menu-ac-item .dd-collapse {
    height: 3rem;
    width: 3rem;
  }
}

.menu-ac-item .dd-expand {
  display: none;
}

.dz-menu-btn {
  margin-top: 0.625rem;
}

@media only screen and (max-width: 1600px) {
  .dz-menu-btn {
    margin-top: 0;
    margin-left: 0.313rem;
  }
}

@media only screen and (max-width: 1480px) {
  .dz-menu-btn {
    margin-top: 0.625rem;
    margin-left: 0;
  }
}

.dz-terms {
  border: 1px solid #CCCCCC;
  border-radius: 0.375rem;
  padding: 0.625rem;
}

/* menu-scss */
.required {
  color: #FF5E5E;
}

.tab-my.nav-tabs {
  border-bottom: unset;
}

.tab-my.nav-tabs .nav-link {
  background-color: #dfdfdf;
  border-radius: 0.375rem;
  border: 1px solid transparent;
  margin: 8px 2px;
  color: #888888;
}

.tab-my.nav-tabs .nav-link.active {
  background-color: var(--primary);
  color: #fff;
}

.menu-tabs {
  border: 1px solid #dfdfdf;
  margin-bottom: 1rem;
  padding: 0.625rem;
  border-radius: 0.375rem;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 0.75rem;
  z-index: 1;
  top: 0.625rem;
}

.avatar-upload .avatar-preview {
  border: 1px solid #CCCCCC;
  padding: 0.5rem;
  margin-bottom: 0.625rem;
  border-radius: 0.375rem;
}

.avatar-upload .avatar-preview>div {
  display: block;
  width: 100%;
  height: 7.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main-check {
  border: 1px solid var(--primary);
  padding: 2.125rem;
  margin-bottom: 1rem;
  border-radius: 0.375rem;
}

/* view-content */
.view-bottom {
  flex-direction: column;
  justify-content: end;
  height: 88%;
  display: flex;
}

/* add-email */
.publish-content ul {
  padding: 0.625rem;
}

.publish-content ul:hover {
  background-color: var(--rgba-primary-1);
  border-radius: 0.375rem;
}

.dd .accordion-button:not(.collapsed) {
  box-shadow: unset;
}

.dd .accordion-body {
  border: 1px solid #CCCCCC;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0;
  margin-top: -5px;
}

@media only screen and (max-width: 35.9375rem) {
  .dd .dd-list {
    padding-left: 0;
  }
}

.publish-drop ul:hover {
  background-color: unset;
}

input.form-control {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.right-sidebar-sticky {
  position: sticky;
  top: 7.5rem;
}

.content-title.box-primary,
.content-title.box-secondary,
.content-title.box-success,
.content-title.box-danger,
.content-title.box-warning,
.content-title.box-info,
.content-title.box-dark,
.content-title.box-light {
  background-color: var(--primary);
}

.content-title.box-primary .cpa,
.content-title.box-primary i,
.content-title.box-secondary .cpa,
.content-title.box-secondary i,
.content-title.box-success .cpa,
.content-title.box-success i,
.content-title.box-danger .cpa,
.content-title.box-danger i,
.content-title.box-warning .cpa,
.content-title.box-warning i,
.content-title.box-info .cpa,
.content-title.box-info i,
.content-title.box-dark .cpa,
.content-title.box-dark i,
.content-title.box-light .cpa,
.content-title.box-light i {
  color: #fff !important;
}

.content-title.box-secondary {
  background-color: var(--secondary);
}

.content-title.box-success {
  background-color: #3AC977;
}

.content-title.box-danger {
  background-color: #FF5E5E;
}

.content-title.box-warning {
  background-color: #FF9F00;
}

.content-title.box-info {
  background-color: #58bad7;
}

.content-title.box-dark {
  background-color: #6e6e6e;
}

.content-title.box-light {
  background-color: #dfdfdf;
}

/* data-picker */
.datepicker {
  padding: 10px;
}

.datepicker table tr td.today {
  color: #fff;
  background-image: none;
  background-color: var(--primary);
}

.datepicker table tr td.today:hover:hover {
  color: #fff;
  background-color: var(--primary) !important;
  background-image: none;
}

.datepicker table tr td.new,
.datepicker table tr td.old {
  opacity: 0.2;
}

.ai-tabs-1 .nav-tabs {
  border: 0;
  position: relative;
}

.ai-tabs-1 .nav-tabs .nav-link {
  border: 0;
  background-color: transparent;
  color: #888888;
  position: relative;
}

.ai-tabs-1 .nav-tabs .nav-link.active {
  color: var(--primary);
}

.ai-tabs-1 .nav-tabs .nav-link.active:after {
  content: "";
  position: absolute;
  background-color: var(--primary);
  height: 5px;
  width: 100%;
  bottom: -7px;
  left: 0;
  border-radius: 0.375rem 0.375rem 0 0;
}

.alert-ai {
  background-color: #fff;
  border-left: 3px solid #FF9F00;
}

.ai-tab {
  border-color: var(--primary);
}

.ai-tab .nav-link {
  background-color: #fff;
  color: var(--primary);
  border-radius: 0;
  border-color: var(--primary);
}

.ai-tab .nav-link.active {
  color: var(--primary);
  border-color: var(--primary);
  border-bottom: 0;
}

.bootstrap-select.ai-select {
  width: auto !important;
  background-color: transparent;
}

.bootstrap-select.ai-select .btn {
  border: 0 !important;
  color: #fff !important;
}

.bootstrap-select.custom-image-select .btn {
  border: 0 !important;
  color: #888888 !important;
}

.form-control.custom-image-select img {
  width: 24px;
  margin-right: 5px;
}

.example.style-1 .asColorPicker-trigger {
  position: unset;
  width: 45px;
  height: 45px;
}

.example.style-1 .asColorPicker-trigger span {
  border-radius: 0.375rem !important;
}

@media only screen and (max-width: 87.5rem) {
  .example.style-1 p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .example.style-1 {
    margin-bottom: 10px;
  }
}

.ai-radio-prompt {
  width: 250px;
  height: 250px;
  border: 1px solid #E6E6E6;
  border-radius: 0.375rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.check-ai .form-check {
  position: relative;
  padding: 0;
}

.check-ai .form-check .form-check-input {
  position: absolute;
  left: 6px;
  margin: 0;
  top: 12px;
}

.check-ai .form-check .form-check-input:checked {
  left: 6px;
  top: 12px;
}

.check-ai .form-check-input:checked+.form-check-label,
.check-ai .form-check-input+.form-check-label {
  border: 1px solid #CCCCCC;
  border-radius: 0.375rem;
  background-color: var(--rgba-primary-1);
  padding: 10px;
  margin: 0;
}

.check-ai .form-check-input+.form-check-label {
  background-color: #f4f4f4;
}

.ai-slider .noUi-connects {
  background-color: var(--rgba-primary-1);
  height: 5px;
}

.ai-slider .noUi-handle {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  box-shadow: 0px 0px 30px rgba(70, 49, 125, 0.5);
}

@media only screen and (max-width: 87.5rem) {
  .ai-slider .noUi-handle {
    top: -8px;
  }
}

.promt-langauage {
  display: flex;
  flex-wrap: wrap;
}

.promt-langauage li a {
  margin-right: 5px;
}

.ai-header-alert {
  border-left: 5px solid #FF9F00;
  background-color: #fff;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 47.9375rem) {
  .ai-header-alert.alert {
    padding: 0.75rem 0.7rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .ai-header-alert.alert {
    font-size: 10px;
  }
}

.ai-header-alert .remove-prompt {
  position: absolute;
  content: "";
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 35.9375rem) {
  .ai-header-alert .btn-close {
    display: none;
  }
}

.ai-tabs.nav-tabs .nav-link {
  background-color: #fff;
  color: #000;
  border-radius: 0;
  padding: 8px 25px;
}

.ai-tabs.nav-tabs .nav-link.active {
  background-color: transparent;
  border-radius: 0.375rem 0.375rem 0 0;
  border-bottom: 0;
  padding: 12px 25px;
}

.ai-check {
  border: 1px solid #CCCCCC;
  padding: 10px;
  border-radius: 0.375rem;
}

.back-theme-ai {
  display: flex;
  align-items: center;
}

.back-theme-ai .form-check {
  font-size: 0.875rem;
}

.back-theme-ai .form-check .form-check-input {
  cursor: pointer;
}

.back-theme-ai .form-check span {
  display: block;
}

.back-theme-ai .form-check span:after {
  content: "";
  position: absolute;
  content: "";
  border: 0.125rem solid #000;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  opacity: 0;
}

.back-theme-ai .form-check .form-check-input:checked+span:after {
  top: -0.125rem;
  left: -0.313rem;
  opacity: 1;
  cursor: pointer;
}

.back-theme-ai .form-check-input:checked[type="radio"] {
  background-image: none;
}

.back-theme-ai .cross-svg {
  width: 20px;
  height: 20px;
  border: 1px solid #CCCCCC;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  line-height: 19px;
}

@media only screen and (max-width: 100rem) {
  .back-theme-ai .cross-svg {
    line-height: 17px;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .back-theme-ai .cross-svg {
    line-height: 18px;
  }
}

.bg-grey {
  background-color: #9F93C0 !important;
}

.nav-tabs.style-1 {
  margin-bottom: 0;
  align-items: flex-end;
  padding: 0 30px;
  margin: 0 -30px;
}

@media only screen and (max-width: 100rem) {
  .nav-tabs.style-1 {
    padding: 0 25px;
    margin: 0 -25px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-tabs.style-1 {
    padding: 0 15px;
    margin: 0 -13px;
  }
}

.nav-tabs.style-1 li {
  display: inline-block;
}

.nav-tabs.style-1 li a.nav-link {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #CCCCCC;
  padding: 10px 25px;
  margin-left: -1px;
}

.nav-tabs.style-1 li a.nav-link.active {
  background-color: #f5f5f5;
  border-bottom: 0 !important;
  padding: 15px 25px;
  border-radius: 0.375rem 0.375rem 0 0;
}

.border-s-1 {
  border-radius: 0.375rem 0 0 0 !important;
}

.border-s-2 {
  border-radius: 0 0.375rem 0 0 !important;
}

.card-length {
  margin-left: auto;
  margin-right: auto;
  max-width: 414px;
}

.ai-table {
  border-radius: 0.375rem;
}

.tooltip-inner {
  background-color: var(--primary);
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
  max-width: 500px;
  width: auto;
  border-radius: 0.375rem;
}

.upload-img .dlab-message {
  margin: 0 !important;
}

.upload-img.dropzone {
  border: 1px solid #CCCCCC;
  border-radius: 0.375rem;
  min-height: 0;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .navbar-nav .nav-item {
  float: right;
}

.rtl .navbar-nav .nav-item+.nav-item {
  margin-right: 1rem;
  margin-left: inherit;
}

.rtl th {
  text-align: right;
}

.rtl .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem;
}

.rtl .dropdown-menu {
  right: 0;
  text-align: right;
}

.rtl .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.375rem 0.375rem 0;
}

.rtl .btn-group>.btn:last-child:not(:first-child),
.rtl .btn-group>.dropdown-toggle:not(:first-child) {
  border-radius: 0.375rem 0 0 0.375rem;
}

.rtl .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-radius: 0.375rem 0 0 0.375rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
  right: 0;
  left: inherit;
}

.rtl .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  background: var(--bs-body-bg) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
  background-size: 8px 10px;
}

.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.rtl .input-group>.input-group-append:not(:last-child)>.btn,
.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-prepend>.btn,
.rtl .input-group>.input-group-prepend>.input-group-text {
  border-radius: 0 0.375rem 0.375rem 0;
}

.rtl .input-group>.input-group-append>.btn,
.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn,
.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-radius: 0.375rem 0 0 0.375rem;
}

.rtl .input-group>.custom-select:not(:first-child),
.rtl .input-group>.form-control:not(:first-child) {
  border-radius: 0.375rem 0 0 0.375rem;
}

.rtl .input-group>.custom-select:not(:last-child),
.rtl .input-group>.form-control:not(:last-child) {
  border-radius: 0 0.375rem 0.375rem 0;
}

.rtl .input-group>.custom-select:not(:last-child):not(:first-child),
.rtl .input-group>.form-control:not(:last-child):not(:first-child) {
  border-radius: 0;
}

.rtl .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem;
}

.rtl .custom-control-indicator {
  right: 0;
  left: inherit;
}

.rtl .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: .25rem 0 0 .25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .list-group {
  padding-right: 0;
  padding-left: 40px;
}

.rtl .close {
  float: left;
}

.rtl .modal-header .close {
  margin: -15px auto -15px -15px;
}

.rtl .modal-footer> :not(:first-child) {
  margin-right: .25rem;
}

.rtl .alert-dismissible .close {
  right: inherit;
  left: 0;
}

.rtl .dropdown-toggle::after {
  margin-right: .255em;
  margin-left: 0;
}

.rtl .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .offset-1 {
  margin-right: 8.33333%;
  margin-left: 0;
}

.rtl .offset-2 {
  margin-right: 16.66667%;
  margin-left: 0;
}

.rtl .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

.rtl .offset-4 {
  margin-right: 33.33333%;
  margin-left: 0;
}

.rtl .offset-5 {
  margin-right: 41.66667%;
  margin-left: 0;
}

.rtl .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

.rtl .offset-7 {
  margin-right: 58.33333%;
  margin-left: 0;
}

.rtl .offset-8 {
  margin-right: 66.66667%;
  margin-left: 0;
}

.rtl .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

.rtl .offset-10 {
  margin-right: 83.33333%;
  margin-left: 0;
}

.rtl .offset-11 {
  margin-right: 91.66667%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .rtl .offset-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-sm-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-sm-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-sm-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-sm-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .rtl .offset-md-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-md-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-md-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-md-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-md-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .rtl .offset-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-lg-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-lg-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-lg-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-lg-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .offset-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-xl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-xl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-xl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-xl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1440) {
  .rtl .offset-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-xxl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-xxl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-xxl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-xxl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

.rtl .mr-0,
.rtl .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {

  .rtl .mr-sm-0,
  .rtl .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-sm-0,
  .rtl .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-sm-1,
  .rtl .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-sm-1,
  .rtl .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-sm-3,
  .rtl .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-sm-3,
  .rtl .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-sm-4,
  .rtl .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-sm-4,
  .rtl .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-sm-5,
  .rtl .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-sm-5,
  .rtl .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-sm-0,
  .rtl .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-sm-0,
  .rtl .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-sm-1,
  .rtl .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-sm-1,
  .rtl .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-sm-2,
  .rtl .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-sm-2,
  .rtl .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-sm-3,
  .rtl .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-sm-3,
  .rtl .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-sm-4,
  .rtl .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-sm-4,
  .rtl .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-sm-5,
  .rtl .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-sm-5,
  .rtl .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {

  .rtl .mr-md-0,
  .rtl .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-md-0,
  .rtl .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-md-1,
  .rtl .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-md-1,
  .rtl .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-md-2,
  .rtl .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-md-2,
  .rtl .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-md-3,
  .rtl .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-md-3,
  .rtl .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-md-4,
  .rtl .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-md-4,
  .rtl .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-md-5,
  .rtl .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-md-5,
  .rtl .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-md-0,
  .rtl .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-md-0,
  .rtl .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-md-1,
  .rtl .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-md-1,
  .rtl .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-md-2,
  .rtl .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-md-2,
  .rtl .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-md-3,
  .rtl .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-md-3,
  .rtl .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-md-4,
  .rtl .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-md-4,
  .rtl .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-md-5,
  .rtl .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-md-5,
  .rtl .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {

  .rtl .mr-lg-0,
  .rtl .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-lg-0,
  .rtl .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-lg-1,
  .rtl .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-lg-1,
  .rtl .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-lg-2,
  .rtl .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-lg-2,
  .rtl .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-lg-3,
  .rtl .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-lg-3,
  .rtl .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-lg-4,
  .rtl .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-lg-4,
  .rtl .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-lg-5,
  .rtl .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-lg-5,
  .rtl .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-lg-0,
  .rtl .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-lg-0,
  .rtl .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-lg-1,
  .rtl .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-lg-1,
  .rtl .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-lg-2,
  .rtl .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-lg-2,
  .rtl .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-lg-3,
  .rtl .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-lg-3,
  .rtl .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-lg-4,
  .rtl .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-lg-4,
  .rtl .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-lg-5,
  .rtl .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-lg-5,
  .rtl .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {

  .rtl .mr-xl-0,
  .rtl .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-xl-0,
  .rtl .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-xl-1,
  .rtl .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-xl-1,
  .rtl .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-xl-2,
  .rtl .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-xl-2,
  .rtl .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-xl-3,
  .rtl .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-xl-3,
  .rtl .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-xl-4,
  .rtl .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-xl-4,
  .rtl .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-xl-5,
  .rtl .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-xl-5,
  .rtl .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-xl-0,
  .rtl .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-xl-0,
  .rtl .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-xl-1,
  .rtl .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-xl-1,
  .rtl .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-xl-2,
  .rtl .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-xl-2,
  .rtl .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-xl-3,
  .rtl .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-xl-3,
  .rtl .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-xl-4,
  .rtl .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-xl-4,
  .rtl .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-xl-5,
  .rtl .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-xl-5,
  .rtl .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {

  .rtl .mr-xxl-0,
  .rtl .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-xxl-0,
  .rtl .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-xxl-1,
  .rtl .mx-xxl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-xxl-1,
  .rtl .mx-xxl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-xxl-2,
  .rtl .mx-xxl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-xxl-2,
  .rtl .mx-xxl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-xxl-3,
  .rtl .mx-xxl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-xxl-3,
  .rtl .mx-xxl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-xxl-4,
  .rtl .mx-xxl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-xxl-4,
  .rtl .mx-xxl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-xxl-5,
  .rtl .mx-xxl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-xxl-5,
  .rtl .mx-xxl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-xxl-0,
  .rtl .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-xxl-0,
  .rtl .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-xxl-1,
  .rtl .px-xxl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-xxl-1,
  .rtl .px-xxl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-xxl-2,
  .rtl .px-xxl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-xxl-2,
  .rtl .px-xxl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-xxl-3,
  .rtl .px-xxl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-xxl-3,
  .rtl .px-xxl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-xxl-4,
  .rtl .px-xxl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-xxl-4,
  .rtl .px-xxl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-xxl-5,
  .rtl .px-xxl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-xxl-5,
  .rtl .px-xxl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .text-right {
  text-align: left !important;
}

.rtl .text-left {
  text-align: right !important;
}

@media (min-width: 576px) {
  .rtl .text-sm-right {
    text-align: left !important;
  }

  .rtl .text-sm-left {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .rtl .text-md-right {
    text-align: left !important;
  }

  .rtl .text-md-left {
    text-align: right !important;
  }
}

@media (min-width: 992px) {
  .rtl .text-lg-right {
    text-align: left !important;
  }

  .rtl .text-lg-left {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .rtl .text-xl-right {
    text-align: left !important;
  }

  .rtl .text-xl-left {
    text-align: right !important;
  }
}

@media (min-width: 1440) {
  .rtl .text-xxl-right {
    text-align: left !important;
  }

  .rtl .text-xxl-left {
    text-align: right !important;
  }
}

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important;
}

.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important;
}

.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important;
}

.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important;
}

.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important;
}

.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important;
}

.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important;
}

.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important;
}

.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important;
}

.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important;
}

.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important;
}

.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important;
}

.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important;
}

.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important;
}

.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important;
}

.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important;
}

.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important;
}

.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important;
}

.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important;
}

.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important;
}

.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important;
}

.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important;
}

.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important;
}

.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .float-left {
  float: right !important;
}

.rtl .float-right {
  float: left !important;
}

.rtl .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important;
  }

  .rtl .float-sm-right {
    float: left !important;
  }

  .rtl .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important;
  }

  .rtl .float-md-right {
    float: left !important;
  }

  .rtl .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important;
  }

  .rtl .float-lg-right {
    float: left !important;
  }

  .rtl .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important;
  }

  .rtl .float-xl-right {
    float: left !important;
  }

  .rtl .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important;
  }

  .rtl .float-xxl-right {
    float: left !important;
  }

  .rtl .float-xxl-none {
    float: none !important;
  }
}

[direction="rtl"][data-theme-version="dark"] .border,
[direction="rtl"][data-theme-version="dark"] .border-left,
[direction="rtl"][data-theme-version="dark"] .border-right {
  border-color: #444444 !important;
}

[direction="rtl"] .text-right {
  text-align: left !important;
}

[direction="rtl"] .text-left {
  text-align: right !important;
}

[direction="rtl"] .border-right {
  border-left: 0.0625rem solid #E6E6E6 !important;
  border-right: 0 !important;
}

[direction="rtl"] .border-left {
  border-right: 0.0625rem solid #E6E6E6 !important;
  border-left: 0 !important;
}

[direction="rtl"] .dropdown-menu {
  left: auto;
}

[direction="rtl"] .dropdown-menu-right {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .dropdown-menu-right {
    left: 0.9375rem;
  }
}

[direction="rtl"] .notification_dropdown .dropdown-menu-right .media>span {
  margin-left: 0.625rem;
  margin-right: 0;
}

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
  left: auto;
  right: 0;
}

[direction="rtl"][data-container="wide-boxed"] .nav-header {
  left: auto;
  right: auto;
}

[direction="rtl"] .nav-header {
  text-align: right;
  right: auto;
}

[direction="rtl"] .nav-header .brand-title {
  margin-left: 0;
  margin-right: 0.9375rem;
}

[direction="rtl"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 1.75rem;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
  padding-right: 1.875rem;
}

[data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 1.875rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .nav-header .brand-logo {
    padding-right: 0;
  }
}

[direction="rtl"] .nav-control {
  right: auto;
  left: -4.0625rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .nav-control {
    left: -4.0625rem;
  }
}

@media (max-width: 575.98px) {
  [direction="rtl"] .nav-control {
    left: -2.0625rem;
  }
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  right: 0;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-right: 2.5rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
  width: 16rem;
}

[direction="rtl"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .header {
    padding-right: 5rem;
    padding-left: 0;
  }
}

[direction="rtl"] .header .header-content {
  padding-left: 1.875rem;
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header .header-content {
    padding-right: 3.5rem;
    padding-left: 0.938rem;
  }
}

[data-sidebar-style="compact"][direction="rtl"] .header .header-content {
  padding-right: 1.875rem;
}

[data-sidebar-style="modern"][direction="rtl"] .header .header-content {
  padding-right: 5.3125rem;
}

[data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
    padding-right: 0.5rem;
  }
}

[direction="rtl"] .header .nav-control {
  right: 0.4375rem;
  left: auto;
}

[direction="rtl"] .header-right>li:not(:first-child) {
  padding-left: 0;
  padding-right: 1.25rem;
  margin-right: 0 !important;
}

@media only screen and (max-width: 47.9375rem) {
  [direction="rtl"] .header-right>li:not(:first-child) {
    padding-right: 0.5rem;
  }
}

[direction="rtl"] .header-right .search-area .input-group-append .input-group-text {
  padding-right: auto;
  padding-left: 1.25rem;
}

[direction="rtl"] .header-right .search-area .form-control {
  padding-left: auto;
  padding-right: 1.25rem;
}

[direction="rtl"] .header-right .header-profile>a.nav-link {
  margin-left: auto;
  padding-left: auto;
  margin-right: 0.9375rem;
  padding-right: 1.875rem;
  border-right: 0.0625rem solid #CCCCCC;
  border-left: 0;
}

[direction="rtl"] .header-right .header-profile>a.nav-link .header-info {
  padding-right: 1.25rem;
  padding-left: auto;
  text-align: right;
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link .header-info {
    padding-right: 0.625rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link {
    margin-right: 0.625rem;
    padding-right: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link {
    margin-right: 0rem;
    padding-right: 0rem;
    border-right: 0;
  }
}

[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
  right: 2.5rem !important;
}

@media only screen and (max-width: 35.9375rem) {

  [direction="rtl"] .header-left .search_bar .dropdown-menu,
  [direction="rtl"] .header-left .search_bar .dropdown-menu.show {
    right: -6.25rem !important;
  }
}

[direction="rtl"] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header-left .search_bar .search_icon {
    border-radius: 5rem;
  }
}

[direction="rtl"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

[direction="rtl"][data-layout="horizontal"] .header .header-content {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="full"] .nav-header .brand-logo {
  padding-right: 2.5rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
  padding-right: 7.75rem;
}

[direction="rtl"][data-sidebar-style="mini"] .header {
  padding-right: 6.25rem;
}

[direction="rtl"][data-sidebar-style="compact"] .header {
  padding: 0 0.9375rem;
  padding-right: 11.25rem;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

[direction="rtl"][data-sidebar-style="modern"] .header {
  padding: 0 0.9375rem;
  padding-right: 10.625rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem;
}

[direction="rtl"] {
  text-align: right;
}

[direction="rtl"] .deznav {
  text-align: right;
}

[direction="rtl"] .deznav .metismenu ul:after {
  left: auto;
  right: 1.5625rem;
}

[direction="rtl"] .deznav .metismenu ul a:before {
  left: auto;
  right: 2.625rem;
}

[data-sidebar-style="full"][direction="rtl"] .deznav .metismenu li>a i {
  padding: 0 0 0 0;
  margin-right: 0;
  margin-left: 0.625rem;
}

[direction="rtl"] .deznav .metismenu li>a svg {
  margin-left: 0.3125rem;
  margin-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .deznav .metismenu li>a svg {
  left: auto;
  margin-left: auto;
  margin-right: auto;
}

[data-sidebar-style="icon-hover"][direction="rtl"] .deznav .metismenu li>a svg {
  margin-left: 0;
}

[direction="rtl"] .deznav .metismenu li ul a {
  padding-right: 6rem;
  padding-left: 0.625rem;
}

[direction="rtl"] .deznav .metismenu li.active>.has-arrow:after {
  transform: rotate(45deg) translateY(-50%);
}

[direction="rtl"] .deznav .metismenu .has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[data-layout="horizontal"][direction="rtl"] .deznav .metismenu .has-arrow:after {
  left: 1.125rem;
}

[data-sidebar-style="modern"][direction="rtl"] .deznav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li .has-arrow:after {
  left: 1.5rem;
  right: auto;
}

[direction="rtl"][data-sidebar-style="mini"] .deznav .metismenu>li>a>i {
  padding: 0;
}

[direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul a.has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu li>a i {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

[direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu li ul a {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul {
  right: 11.8125rem;
  left: 0;
}

[direction="rtl"] {
  /* ===== Light gallery ===== */
}

[direction="rtl"] .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 15px;
  right: auto;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-radius: 0.75rem 0 0 0.75rem;
}

[direction="rtl"] .list-group {
  padding-left: 0;
}

[direction="rtl"] .form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit;
}

[direction="rtl"] .form-check-inline .form-check-input {
  margin-right: 0;
  margin-left: 10px;
}

[direction="rtl"] .radio input,
[direction="rtl"] .radio-inline,
[direction="rtl"] .checkbox input,
[direction="rtl"] .checkbox-inline input {
  margin-left: 0;
  margin-right: 0;
}

[direction="rtl"] .content-body {
  margin-right: 21.563rem;
  margin-left: auto;
}

[data-sidebar-style="modern"][direction="rtl"] .content-body {
  margin-right: 9.375rem;
}

[direction="rtl"] .content-body .page-titles {
  text-align: right;
}

[direction="rtl"] .doctor-info-details .media-body span i,
[direction="rtl"] .recovered-chart-deta .col [class*="bg-"] {
  margin-right: 0;
  margin-left: 10px;
}

[direction="rtl"] .patients-chart-deta .col,
[direction="rtl"] .patients-chart-deta .col [class*="bg-"],
[direction="rtl"] .recovered-chart-deta .col {
  margin-right: 0;
  margin-left: 15px;
}

[direction="rtl"] .best-doctor .timeline .timeline-panel .media .number {
  left: auto;
  right: -13px;
}

[direction="rtl"] .doctor-info-details .media i {
  right: 0;
  left: -15px;
}

[direction="rtl"] .review-table .disease {
  border-left: 0;
  border-right: 1px solid #eee;
  padding-left: 0;
  padding-right: 20px;
}

[direction="rtl"] .apexcharts-legend-text {
  margin: 4px;
}

[direction="rtl"] .doctor-info-details .media-body {
  padding-left: 0;
  padding-right: 40px;
}

[direction="rtl"] .custom-control {
  margin-left: 0;
}

[direction="rtl"] .review-tab.nav-pills li:first-child a.nav-link {
  border-radius: 0 0.75rem 0 0;
}

[direction="rtl"] .review-tab.nav-pills li:last-child a.nav-link {
  border-radius: 0.75rem 0 0 0;
}

[direction="rtl"] .form-head .btn i {
  margin-left: 5px;
  margin-right: 0;
}

[direction="rtl"] .iconbox {
  padding-left: 0;
  padding-right: 70px;
}

[direction="rtl"] .iconbox i {
  left: auto;
  right: 0;
}

[direction="rtl"] .table.tr-rounded tr td:first-child,
[direction="rtl"] .table.tr-rounded tr th:first-child {
  border-radius: 0 0.375rem 0.375rem 0;
}

[direction="rtl"] .table.tr-rounded tr td:last-child,
[direction="rtl"] .table.tr-rounded tr th:last-child {
  border-radius: 0.375rem 0 0 0.375rem;
}

[direction="rtl"] .custom-switch.toggle-switch.text-right {
  padding-left: 48px;
  padding-right: 0;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-label:before {
  right: auto !important;
  left: -47px;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-label:after {
  right: auto !important;
  left: -28px;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-input:checked~.custom-control-label:after {
  left: -62px;
  right: auto !important;
}

[direction="rtl"] .check-switch {
  padding-right: 40px;
}

[direction="rtl"] .check-switch .custom-control-label:after,
[direction="rtl"] .check-switch .custom-control-label:before {
  right: -35px !important;
}

[direction="rtl"] .bar-chart .apexcharts-yaxis {
  transform: translatex(101%);
}

[direction="rtl"] .detault-daterange .input-group-text {
  padding: 0.532rem 0.75rem;
  padding-right: auto;
  padding-left: 0;
}

[direction="rtl"] .form-wrapper .input-group .form-control {
  text-align: left;
}

[direction="rtl"] .timeline-chart .apexcharts-yaxis {
  transform: translateX(0);
}

[direction="rtl"] .card-table td:first-child {
  padding-right: 30px;
  padding-left: 10px;
}

[direction="rtl"] .card-table td:last-child {
  padding-left: 30px;
  padding-right: 10px;
}

[direction="rtl"] .chatbox .img_cont {
  margin-right: 0px;
  margin-left: 10px;
}

[direction="rtl"] .profile-tab .nav-item .nav-link {
  margin-right: 0;
  margin-left: 30px;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .profile-tab .nav-item .nav-link {
    margin-left: 0px;
  }
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel {
  margin-left: 0px;
  margin-right: 40px;
}

[direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  padding: 5px 15px 5px 10px;
  border-width: 0px 5px 0px 0px;
}

[direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  right: -9px;
  width: 7px;
  left: auto;
  height: 7px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 0px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel:after {
  left: auto;
  right: -5px;
}

[direction="rtl"] .chatbox .msg_cotainer {
  margin-left: 0;
  margin-right: 10px;
}

[direction="rtl"] .new-arrival-content .price {
  float: left;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[direction="rtl"] .breadcrumb-item+.breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}

[direction="rtl"] .breadcrumb-item+.breadcrumb-item::before {
  padding-right: 0;
  padding-left: 0.5rem;
}

[direction="rtl"] .chatbox .chatbox-close {
  left: 340px;
  right: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .chatbox .chatbox-close {
    left: 280px;
  }
}

[direction="rtl"] .separator {
  margin-right: 0;
  margin-left: 9px;
}

[direction="rtl"] .card-tabs .nav-tabs {
  padding-right: 5px;
}

[direction="rtl"] .chatbox .msg_cotainer {
  margin-left: 0;
  margin-right: 10px;
  border-radius: 1.375rem 0 1.375rem 1.375rem;
}

[direction="rtl"] .chatbox .msg_cotainer:after {
  left: auto;
  right: -10px;
  transform: rotate(-90deg);
}

[direction="rtl"] .chatbox .type_msg .input-group .input-group-append .btn {
  border-top-right-radius: 38px !important;
  border-bottom-right-radius: 38px !important;
}

[direction="rtl"] .chatbox .msg_cotainer_send {
  margin-right: 0px;
  margin-left: 10px;
  border-radius: 0 6px 6px 6px;
}

[direction="rtl"] .chatbox .msg_cotainer_send:after {
  right: auto;
  left: -10px;
  transform: rotate(90deg);
}

[direction="rtl"] .new-arrival-content .price {
  float: left;
}

[direction="rtl"] .trending-menus .tr-row .num {
  margin-right: 0;
  margin-left: 15px;
}

[direction="rtl"] .default-select.style-2 .btn:after {
  margin-left: 0;
  margin-right: 0.5em;
}

[direction="rtl"] .widget-timeline-icon li .icon {
  left: auto;
  right: -2px;
}

[direction="rtl"] .widget-timeline-icon li a {
  padding: 25px 0px 0px 50px;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .widget-timeline-icon .timeline {
    margin-left: 0;
    margin-right: 10px;
  }

  [direction="rtl"] .widget-timeline-icon li {
    border-left: 0;
    border-right: 6px solid var(--primary);
  }

  [direction="rtl"] .widget-timeline-icon li a {
    padding: 0 30px 30px 0;
  }

  [direction="rtl"] .widget-timeline-icon li .icon {
    right: -12px;
  }

  [direction="rtl"] .widget-timeline-icon li:last-child {
    border-color: transparent;
  }
}

[direction="rtl"] #revenueMap .apexcharts-yaxis,
[direction="rtl"] #customerMapkm .apexcharts-yaxis {
  transform: translateX(0px);
}

[direction="rtl"] .mail-list .list-group-item i {
  padding-right: 0;
  padding-left: 0.625rem;
}

[direction="rtl"] .dz-demo-panel {
  right: auto;
  left: -380px;
}

[direction="rtl"] .dz-demo-panel.show {
  right: unset;
  left: 0;
}

[direction="rtl"] .dz-demo-panel .dz-demo-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction="rtl"][data-layout="horizontal"] .content-body {
  margin-right: 0;
}

[direction="rtl"][data-layout="horizontal"] .deznav .metismenu li li .has-arrow:after {
  -webkit-transform: rotate(-4deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 6.25rem;
}

[direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 11.25rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .content-body {
  margin-right: 0;
}

[direction="rtl"] #external-events .external-event:before {
  margin-right: 0;
  margin-left: .9rem;
}

[direction="rtl"] .post-input a i {
  margin-left: 15px;
  margin-right: 0;
}

[direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"] .deznav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

[direction="rtl"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
[direction="rtl"] .deznav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg);
}

[direction="rtl"] .chatbox {
  left: -500px;
  right: auto;
}

[direction="rtl"] .chatbox.active {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 575px) {
  [direction="rtl"] .best-doctor .timeline .timeline-panel .media {
    float: right;
    margin-right: 0 !important;
    margin-left: 15px !important;
  }
}

[direction="rtl"] .default-select.style-1 .btn:after {
  margin-left: 0;
  margin-right: 0.5em;
}

[direction="rtl"] .pagination .page-indicator {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

[direction="rtl"] .lg-outer.lg-visible {
  direction: ltr;
}

[direction="rtl"] .chart-point .chart-point-list {
  margin: 0;
  padding-right: 20px;
}

[direction="rtl"] .noUi-target {
  direction: rtl;
}

[direction="rtl"] .noUi-vertical .noUi-pips-vertical {
  left: -20px;
}

[direction="rtl"] .noUi-vertical .noUi-value-vertical {
  padding-left: 0;
  padding-right: 25px;
}

[direction="rtl"] .sidebar-right .ps--active-x>.ps__rail-x {
  display: none;
}

[direction="rtl"] .form-wizard .nav-wizard li .nav-link:after {
  right: 50%;
  left: auto;
}

[direction="rtl"] .dtp>.dtp-content {
  right: 50%;
  left: auto;
}

[direction="rtl"] .modal-header .close {
  margin: 0;
  left: 0;
  top: 0px;
  right: auto;
}

[direction="rtl"] .input-group-prepend .btn+.btn {
  border-radius: 0 !important;
}

[direction="rtl"] .form-control+.input-group-append .btn:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

[direction="rtl"] .input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}

[direction="rtl"] .custom-file-label::after {
  border-radius: .75rem 0 0 .75rem;
}

[direction="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

[direction="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-radius: 0;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction="rtl"] .content-body {
    margin-right: 17rem;
  }
}

[direction="rtl"] .sidebar-right {
  right: auto;
  left: -50rem;
}

[direction="rtl"] .sidebar-right.show {
  left: 5.25rem;
  right: unset;
}

[direction="rtl"] .sidebar-right .sidebar-right-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction="rtl"] .sidebar-right .sidebar-close-trigger {
  right: auto;
  left: -48px;
}

[direction="rtl"] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right;
}

html[dir="rtl"] [direction="rtl"] .footer {
  padding-right: 17.1875rem;
  padding-left: 0;
}

@media (max-width: 767.98px) {
  html[dir="rtl"] [direction="rtl"] .footer {
    padding-right: 0;
  }
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
  padding-right: 0;
}

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
  padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
  padding-right: 0;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
  padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
  padding-right: 9.375rem;
}

:root {
  --primary: #0D99FF;
  --secondary: #2C2C2C;
  --secondary-dark: #131313;
  --primary-hover: #007ed9;
  --primary-dark: #004373;
  --rgba-primary-1: rgba(13, 153, 255, 0.1);
  --rgba-primary-2: rgba(13, 153, 255, 0.2);
  --rgba-primary-3: rgba(13, 153, 255, 0.3);
  --rgba-primary-4: rgba(13, 153, 255, 0.4);
  --rgba-primary-5: rgba(13, 153, 255, 0.5);
  --rgba-primary-6: rgba(13, 153, 255, 0.6);
  --rgba-primary-7: rgba(13, 153, 255, 0.7);
  --rgba-primary-8: rgba(13, 153, 255, 0.8);
  --rgba-primary-9: rgba(13, 153, 255, 0.9);
  --rgba-secondary-1: rgba(44, 44, 44, 0.1);
  --rgba-secondary-2: rgba(44, 44, 44, 0.2);
  --rgba-secondary-3: rgba(44, 44, 44, 0.3);
  --rgba-secondary-4: rgba(44, 44, 44, 0.4);
  --rgba-secondary-5: rgba(44, 44, 44, 0.5);
  --rgba-secondary-6: rgba(44, 44, 44, 0.6);
  --rgba-secondary-7: rgba(44, 44, 44, 0.7);
  --rgba-secondary-8: rgba(44, 44, 44, 0.8);
  --rgba-secondary-9: rgba(44, 44, 44, 0.9);
  --font-family-base: poppins, sans-serif, sans-serif;
  --font-family-title: poppins, sans-serif, sans-serif;
  --title: #374557;
}

[data-theme-version="dark"] {
  background: #1E1E1E;
  color: #828690;
  --nav-headbg: #242424;
  --sidebar-bg: #242424;
  --headerbg: #242424;
  --bs-body-bg: #242424;
}

[data-theme-version="dark"] h1,
[data-theme-version="dark"] .h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] .h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] .h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] .h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] .h5,
[data-theme-version="dark"] h6,
[data-theme-version="dark"] .h6 {
  color: #fff !important;
}

[data-theme-version="dark"] a.link {
  color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
  color: #58bad7;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .border-right {
  border-right: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] .border-left {
  border-left: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] .border-top {
  border-top: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] .border-bottom {
  border-bottom: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] .border {
  border: 0.0625rem solid #444444 !important;
}

[data-theme-version="dark"] {
  --secondary: $white;
}

[data-theme-version="dark"] .card {
  background-color: #242424;
  box-shadow: none;
  border-color: #444444;
}

[data-theme-version="dark"] .dropdown-menu {
  background-color: #242424;
  box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item {
  color: #777777;
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:hover,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
  background-color: #242424;
  color: #fff;
}

[data-theme-version="dark"] a {
  color: #fff !important;
}

[data-theme-version="dark"] .btn-link g [fill] {
  fill: #fff;
}

[data-theme-version="dark"] .btn-light:active,
[data-theme-version="dark"] .btn-light:focus,
[data-theme-version="dark"] .btn-light:hover {
  color: #000;
}

[data-theme-version="dark"] .form-control {
  background-color: #1E1E1E;
  border-color: #444444;
  color: #fff;
}

[data-theme-version="dark"] .form-select {
  background-color: #1E1E1E;
  border-color: #444444;
  color: #fff;
}

[data-theme-version="dark"] .toggle-switch.form-check .form-check-input {
  background: #1E1E1E;
}

[data-theme-version="dark"] .toggle-switch.form-check .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .modal-content {
  background: #242424;
}

[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #444444;
}

[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .close:hover {
  color: #fff;
}

[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #444444;
}

[data-theme-version="dark"] .widget-stat .media>span {
  background: #444444;
  border-color: #444444;
  color: #fff;
}

[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #444444;
}

[data-theme-version="dark"] .custom-select {
  border-color: #444444;
  color: #828690;
  background: #1E1E1E;
}

[data-theme-version="dark"] .nav-tabs {
  border-color: var(--primary);
}

[data-theme-version="dark"] .nav-tabs .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version="dark"] hr {
  border-color: #444444;
}

[data-theme-version="dark"] .grid-col {
  background: #1E1E1E;
}

[data-theme-version="dark"] .noUi-target {
  border-color: #444444;
  border-radius: 0.5rem;
  box-shadow: none;
}

[data-theme-version="dark"] .noUi-target .noUi-connects {
  background: #444444;
}

[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #444444;
}

[data-theme-version="dark"] .input-group-text {
  background: #1E1E1E;
  color: #fff;
  border-color: #444444;
}

[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #444444;
}

[data-theme-version="dark"] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #444444;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #444444;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #444444;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link.active {
  background: #1E1E1E;
  color: #fff;
}

[data-theme-version="dark"] .list-group-item-action {
  color: #888888;
}

[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #1E1E1E;
  border-color: #1E1E1E;
}

[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item.active:focus,
[data-theme-version="dark"] .list-group-item.active:hover,
[data-theme-version="dark"] .list-group-item.active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .swal2-popup {
  background: #242424;
}

[data-theme-version="dark"] .form-head .btn-outline-primary {
  border-color: #444444;
}

[data-theme-version="dark"] .form-head .btn-outline-primary:hover {
  border-color: var(--primary);
}

[data-theme-version="dark"] .review-tab.nav-pills li a.nav-link.active {
  background: transparent;
}

[data-theme-version="dark"] .new-arrival-content h4 a,
[data-theme-version="dark"] .new-arrival-content .h4 a {
  color: #fff;
}

[data-theme-version="dark"] .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .abilities-chart .ct-chart .ct-label {
  fill: #fff;
}

[data-theme-version="dark"] .morris_chart_height text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .btn-link {
  color: #fff;
}

[data-theme-version="dark"] .order-bg {
  background: #1E1E1E;
}

[data-theme-version="dark"] .detault-daterange {
  background: #1E1E1E;
  color: #fff;
}

[data-theme-version="dark"] .detault-daterange .input-group-text {
  background: #242424;
  border: 0;
}

[data-theme-version="dark"] .dataTablesCard {
  background-color: #242424;
}

[data-theme-version="dark"] .compose-content .dropzone {
  background: #1E1E1E !important;
}

[data-theme-version="dark"] .compose-content .dropzone .dz-message .dz-button {
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker {
  background: #1E1E1E;
  border-color: var(--primary);
}

[data-theme-version="dark"] .daterangepicker .calendar-table {
  border-color: var(--primary);
  background: #1E1E1E;
}

[data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker:after {
  border-bottom: 0.375rem solid #1E1E1E;
}

[data-theme-version="dark"] .daterangepicker select.hourselect,
[data-theme-version="dark"] .daterangepicker select.minuteselect,
[data-theme-version="dark"] .daterangepicker select.secondselect,
[data-theme-version="dark"] .daterangepicker select.ampmselect {
  background: #1E1E1E;
  border: 0.0625rem solid #444444;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker td.off,
[data-theme-version="dark"] .daterangepicker td.off.in-range,
[data-theme-version="dark"] .daterangepicker td.off.start-date,
[data-theme-version="dark"] .daterangepicker td.off.end-date {
  background-color: #242424;
}

[data-theme-version="dark"] .daterangepicker td.off:hover,
[data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
[data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
[data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button {
  background-color: #1E1E1E;
  border-color: var(--primary);
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button:hover,
[data-theme-version="dark"] .app-fullcalendar .fc-button.fc-stat-hover {
  background-color: var(--primary);
}

[data-theme-version="dark"] .swal2-popup .swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem #444444, 0 0 0 0.25rem var(--rgba-primary-1);
}

[data-theme-version="dark"] .dd-handle {
  border-color: #444444;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul {
  background: #242424 !important;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  border-color: #444444;
}

[data-theme-version="dark"] .clockpicker-popover .popover-content {
  background-color: #242424;
}

[data-theme-version="dark"] .clockpicker-plate {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .clockpicker-popover .popover-title {
  background-color: #1E1E1E;
  color: #fff;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link:after {
  background: #1E1E1E;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active:after,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done:after {
  background: var(--primary);
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active span,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done span {
  background: var(--primary);
}

[data-theme-version="dark"] .check-switch .custom-control-label:after,
[data-theme-version="dark"] .check-switch .custom-control-label:before {
  border-color: var(--primary);
}

[data-theme-version="dark"] .fc-unthemed .fc-today {
  background: #1E1E1E;
}

[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-popover .fc-header {
  background: #444444;
}

[data-theme-version="dark"] .picker__box {
  background: #1E1E1E;
}

[data-theme-version="dark"] .picker__box .picker__button--clear,
[data-theme-version="dark"] .picker__box .picker__button--close,
[data-theme-version="dark"] .picker__box .picker__button--today {
  background: #242424;
  color: #fff;
}

[data-theme-version="dark"] .picker__box .picker__button--clear:hover:before,
[data-theme-version="dark"] .picker__box .picker__button--close:hover:before,
[data-theme-version="dark"] .picker__box .picker__button--today:hover:before {
  color: #fff;
}

[data-theme-version="dark"] .picker {
  color: #999;
}

[data-theme-version="dark"] .dtp>.dtp-content {
  background: #1E1E1E;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a {
  color: #68686a;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a.selected {
  color: #fff;
}

[data-theme-version="dark"] .order-request tbody tr {
  border-color: #444444;
}

[data-theme-version="dark"] .card-list li {
  color: #fff;
}

[data-theme-version="dark"] .card-bx .change-btn:hover {
  color: var(--primary);
}

[data-theme-version="dark"] .invoice-card.bg-warning {
  background-color: #5b3c1f !important;
}

[data-theme-version="dark"] .invoice-card.bg-success {
  background-color: #2a6729 !important;
}

[data-theme-version="dark"] .invoice-card.bg-info {
  background-color: #4c276a !important;
}

[data-theme-version="dark"] .invoice-card.bg-secondary {
  background-color: #1c3e52 !important;
}

[data-theme-version="dark"] .user-list li {
  border-color: #242424;
}

[data-theme-version="dark"] .toggle-switch {
  color: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-text tspan {
  fill: #969ba0;
}

[data-theme-version="dark"] .bar-chart line {
  stroke: #444444;
}

[data-theme-version="dark"] .accordion.style-1 .accordion-item,
[data-theme-version="dark"] .invoice-list {
  border-color: #444444;
}

[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info a,
[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info,
[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed>span {
  color: #fff;
}

[data-theme-version="dark"] .ic-card>a {
  background: #25479f;
}

[data-theme-version="dark"] .ic-card>a:first-child {
  border-color: #25479f;
}

[data-theme-version="dark"] .ic-card span {
  color: #fff;
}

[data-theme-version="dark"] table.dataTable thead th,
[data-theme-version="dark"] table.dataTable thead td {
  border-color: #444444 !important;
}

[data-theme-version="dark"] .paging_simple_numbers.dataTables_paginate {
  background: #242424;
}

[data-theme-version="dark"] .dataTables_info {
  color: #fff;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  background: var(--primary);
  color: #fff !important;
}

[data-theme-version="dark"] .dashboard-select {
  background: var(--rgba-primary-1);
  color: white;
  border-color: #444444;
}

[data-theme-version="dark"] .dashboard-select .list {
  background: #242424;
}

[data-theme-version="dark"] .dashboard-select .option:hover,
[data-theme-version="dark"] .dashboard-select .option.focus,
[data-theme-version="dark"] .dashboard-select .option.selected.focus {
  background: #1E1E1E;
}

[data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
  background: #242424;
}

[data-theme-version="dark"] .transaction-details {
  border-color: #444444;
}

[data-theme-version="dark"] .description {
  color: #fff;
}

[data-theme-version="dark"] .transaction-details .amount-bx {
  background: #3f250d;
}

[data-theme-version="dark"] .transaction-details .amount-bx i {
  background: #8d3b0c;
}

[data-theme-version="dark"] .nice-select .option {
  background: #242424;
}

[data-theme-version="dark"] .nice-select .option:hover,
[data-theme-version="dark"] .nice-select .option.focus,
[data-theme-version="dark"] .nice-select .option.selected.focus {
  background: #242424;
}

[data-theme-version="dark"] .card-tabs.style-1 {
  border-color: #444444;
}

[data-theme-version="dark"] .nice-select .list {
  background: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .table-search .input-group .input-group-text {
  background: #242424;
}

[data-theme-version="dark"] .dark-btn svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-legend-text {
  color: #fff !important;
}

[data-theme-version="dark"] .Sales tspan {
  fill: #fff;
}

[data-theme-version="dark"] .timeline-chart rect {
  fill: #1E1E1E;
}

[data-theme-version="dark"] .header-info span {
  color: #fff !important;
}

[data-theme-version="dark"] .smallipop-instance.blue {
  color: #fff;
}

[data-theme-version="dark"] .property-map .smallipop.smallimap-mapicon .circle-marker {
  border-color: #444444;
}

[data-theme-version="dark"] .property-features li {
  color: #fff;
}

[data-theme-version="dark"] .review-tabs-1 {
  background: #242424;
}

[data-theme-version="dark"] .coin-tabs {
  background: #2E2E40;
}

[data-theme-version="dark"] .coin-tabs .nav-link.active {
  background: var(--primary) !important;
  color: #fff;
}

[data-theme-version="dark"] .separate-row>div {
  border-color: #444444;
}

[data-theme-version="dark"] .bg-white {
  background: #1E1E1E !important;
}

[data-theme-version="dark"] .deznav .header-info2 span {
  color: #fff;
}

[data-theme-version="dark"] .brand-title path {
  fill: #fff;
}

[data-theme-version="dark"] .brand-title tspan {
  fill: #fff;
}

[data-theme-version="dark"] .shapreter-row>div {
  border-color: #444444;
}

[data-theme-version="dark"] .chartBar line {
  stroke: #444444;
}

[data-theme-version="dark"] .search-job .search-dropdown:after {
  background: #444444;
}

[data-theme-version="dark"] .chartjs line {
  stroke: #444444;
}

[data-theme-version="dark"] .form-label {
  color: #fff;
}

[data-theme-version="dark"] .datepicker-switch {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown td.day,
[data-theme-version="dark"] .datepicker.datepicker-dropdown th.next,
[data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown th.dow {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown th.datepicker-switch,
[data-theme-version="dark"] .datepicker.datepicker-dropdown th.next,
[data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
  color: #fff;
}

[data-theme-version="dark"] .btn-close {
  color: #fff !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23099'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

[data-theme-version="dark"] .bgl-secondary {
  color: #fff;
}

[data-theme-version="dark"] .application tr td .btn {
  color: #fff;
}

[data-theme-version="dark"] .btn-link svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .alert.alert-outline-secondary,
[data-theme-version="dark"] .alert.alert-outline-dark {
  color: #fff;
}

[data-theme-version="dark"] .form-control-plaintext {
  color: #fff;
}

[data-theme-version="dark"] .timeline-panel .media-body small,
[data-theme-version="dark"] .timeline-panel .media-body .small {
  color: #fff;
}

[data-theme-version="dark"] .jobs {
  border-color: #444444;
}

[data-theme-version="dark"] .apexcharts-gridlines-vertical line {
  stroke: #444444;
}

[data-theme-version="dark"] .apexcharts-text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .widget-courses .schedule-icon {
  color: #fff;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule,
[data-theme-version="dark"] .upcomming-task-widget {
  background: #242424;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 {
  background: #1E1E1E;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 .date-box {
  background: #242424;
  color: #fff;
}

[data-theme-version="dark"] .instructors-box .instructors-media .custome-badge .badge {
  color: #fff;
}

[data-theme-version="dark"] .instructors-box .instructors-media .info-box {
  background: #1E1E1E;
}

[data-theme-version="dark"] .course-details-tab .nav-tabs .nav-link {
  color: #cbc3c3;
}

[data-theme-version="dark"] .message-box,
[data-theme-version="dark"] .chat-icon,
[data-theme-version="dark"] .chat-people,
[data-theme-version="dark"] .chat-tabs .chat-bx,
[data-theme-version="dark"] .share-files,
[data-theme-version="dark"] .type-massage,
[data-theme-version="dark"] .work-ic {
  border-color: #444444;
}

[data-theme-version="dark"] .timeline-active .modulel {
  background: #242424;
}

[data-theme-version="dark"] .timeline-active li .time {
  background: #242424;
}

[data-theme-version="dark"] .achievements .achievements-content span,
[data-theme-version="dark"] .bio .bio-content {
  background: #1E1E1E;
}

[data-theme-version="dark"] .score-active.style-1 .dashboard-select {
  background: #1E1E1E;
}

[data-theme-version="dark"] #activity line {
  stroke: #444444;
}

[data-theme-version="dark"] .pagination-down ul li a {
  background: #1E1E1E;
}

[data-theme-version="dark"] .pagination-down ul li a.active {
  background: var(--primary);
}

[data-theme-version="dark"] .custome-accordion .accordion-button {
  background: #242424;
}

[data-theme-version="dark"] .custome-accordion .acc-courses {
  background: #1E1E1E;
}

[data-theme-version="dark"] .widget-timeline .side-border h4,
[data-theme-version="dark"] .widget-timeline .side-border .h4,
[data-theme-version="dark"] .widget-timeline .side-border .h4,
[data-theme-version="dark"] .widget-timeline .side-border .custom-dropdown {
  background: #242424;
}

[data-theme-version="dark"] .widget-timeline .side-border:after {
  border-color: #444444;
}

[data-theme-version="dark"] #columnChart line {
  stroke: #444444;
}

[data-theme-version="dark"] #sellingActivity svg path {
  stroke: #242424;
}

[data-theme-version="dark"] #sellingActivity line {
  stroke: #444444;
}

[data-theme-version="dark"] .review-box {
  background: #242424;
}

[data-theme-version="dark"] #NewCustomers line {
  stroke: #fff;
}

[data-theme-version="dark"] .students-list .search-area .form-control,
[data-theme-version="dark"] .students-list .search-area .input-group-text {
  background: #1E1E1E;
  border: #444444;
}

[data-theme-version="dark"] .custome-accordion.style-1 .acc-courses {
  background: #242424;
  border-color: #444444;
}

[data-theme-version="dark"] .miac,
[data-theme-version="dark"] .profile-img img,
[data-theme-version="dark"] .profile-img span,
[data-theme-version="dark"] .compose-content .dropzone,
[data-theme-version="dark"] .fc-theme-standard td,
[data-theme-version="dark"] .fc-theme-standard th,
[data-theme-version="dark"] .fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
[data-theme-version="dark"] .fc-scrollgrid,
[data-theme-version="dark"] table {
  border-color: #444444;
}

[data-theme-version="dark"] .btn-icon-end {
  border-color: #444444;
}

[data-theme-version="dark"] .raiting li {
  border-color: #444444;
}

[data-theme-version="dark"] .miac svg path {
  fill: #fff;
}

[data-theme-version="dark"] .type-massage.style-1 {
  background: #1E1E1E;
}

[data-theme-version="dark"] .header .header-content:after {
  background: #444444;
}

[data-theme-version="dark"] .search-coundry .dashboard-select {
  color: #fff;
  background: transparent;
}

[data-theme-version="dark"] .search-coundry .dashboard-select .option {
  color: #fff;
}

[data-theme-version="dark"] .search-coundry .dashboard-select:after {
  border-bottom: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option.selected {
  color: #000;
}

[data-theme-version="dark"] #redial .apexcharts-datalabels-group text {
  fill: #fff;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification {
  border-color: #444444;
}

[data-theme-version="dark"] .app-fullcalendar1 .fc .fc-button-group>.fc-button {
  border-color: #444444;
}

[data-theme-version="dark"] .app-fullcalendar1 .fc-direction-ltr .fc-daygrid-event.fc-event-end,
[data-theme-version="dark"] .app-fullcalendar1 .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background: #242424 !important;
  border-color: #444444;
}

[data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day {
  color: #ffffff !important;
}

[data-theme-version="dark"] .dz-side-menu {
  background: #242424;
}

[data-theme-version="dark"] .nice-select .option.selected {
  color: #fff;
}

[data-theme-version="dark"] .dz-cource,
[data-theme-version="dark"] .message-box,
[data-theme-version="dark"] .type-massage {
  background-color: #242424;
}

[data-theme-version="dark"] .dz-cource .form-control,
[data-theme-version="dark"] .message-box .form-control,
[data-theme-version="dark"] .type-massage .form-control {
  background-color: transparent;
}

[data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch {
  color: #fff;
}

[data-theme-version="dark"] .fc .fc-button-group>.fc-button {
  border-color: #444444;
}

[data-theme-version="dark"] .fc-daygrid-dot-event {
  background-color: #1E1E1E;
  border-color: #1E1E1E;
}

[data-theme-version="dark"] #redial .apexcharts-radialbar-hollow {
  fill: #242424;
}

[data-theme-version="dark"] .chat-tabs .chat-bx.active:after {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.day:hover,
[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.hour:hover,
[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.minute:hover,
[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.second:hover {
  background: #1E1E1E;
}

[data-theme-version="dark"] .chat-box-area .message-received p,
[data-theme-version="dark"] .message-send,
[data-theme-version="dark"] .type-massage .form-control {
  color: #fff;
  background-color: #171717;
}

[data-theme-version="dark"] .chat-icon ul li a,
[data-theme-version="dark"] .file {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .chat-icon ul li a svg path,
[data-theme-version="dark"] .file svg path {
  fill: #fff;
}

[data-theme-version="dark"] .course-dedails-bx .description .user-pic span,
[data-theme-version="dark"] .course-learn ul li {
  color: #fff;
}

[data-theme-version="dark"] .course-details-tab .user-pic2 p {
  color: #828690;
}

[data-theme-version="dark"] #columnChart .apexcharts-bar-series path {
  stroke: #242424;
}

[data-theme-version="dark"] .custome-donut tspan {
  fill: #fff;
}

[data-theme-version="dark"] #morris_donught.custome-donut svg path {
  stroke-width: 0px;
}

[data-theme-version="dark"] .card.bg-pink {
  background-color: #EB62D0;
}

[data-theme-version="dark"] #coin-ponent>div {
  background-color: #1E1E1E !important;
  color: #fff !important;
  border-color: #444444 !important;
}

[data-theme-version="dark"] #coin-ponent>div div {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-ponent div {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-ponent div div {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-ponent .cp-select-menu-item {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-ponent .cp-select-menu-item:hover {
  border-color: #444444 !important;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] #coin-chart text,
[data-theme-version="dark"] .revenueMap text {
  fill: white;
}

[data-theme-version="dark"] #coin-chart line,
[data-theme-version="dark"] .revenueMap line {
  stroke: #444444;
}

[data-theme-version="dark"] .previews-info-list:hover {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .sell-element .sell-blance .form-control,
[data-theme-version="dark"] .limit-sell .nav-tabs {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .text-start span {
  color: #fff !important;
}

[data-theme-version="dark"] .text-secondary {
  color: #fff !important;
}

[data-theme-version="dark"] .card-wiget .reward-earn span {
  color: #fff;
}

[data-theme-version="dark"] .market_chart line,
[data-theme-version="dark"] #btcStock line {
  stroke: #444444;
}

[data-theme-version="dark"] .apexcharts-datalabels-group {
  stroke: #fff;
}

[data-theme-version="dark"] .btn.tp-btn svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .coin-warpper .nav .nav-item {
  border-color: #444444;
}

[data-theme-version="dark"] .form-wrapper.trade-form .input-group .input-group-text {
  color: #fff;
}

[data-theme-version="dark"] .progress-bar.bg-secondary {
  background-color: #fff !important;
}

[data-theme-version="dark"] .profile-card {
  border-color: #444444;
}

[data-theme-version="dark"] .profile-card .card-footer,
[data-theme-version="dark"] .author-profile .info-list li {
  border-color: #444444;
}

[data-theme-version="dark"] .email-left-box {
  border-color: #444444;
}

[data-theme-version="dark"] .email-left-box .mail-list .list-group-item i {
  color: #fff;
}

[data-theme-version="dark"] .dropzone .dlab-message {
  margin: 5em 0;
}

[data-theme-version="dark"] .email-list {
  background: #242424;
}

[data-theme-version="dark"] .email-list .message {
  border-color: #444444;
}

[data-theme-version="dark"] .email-list .message .col-mail-2 .subject {
  color: #fff;
}

[data-theme-version="dark"] .email-list .message:hover {
  background: #1E1E1E;
}

[data-theme-version="dark"] .email-right-box .table-pagenation {
  background: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .email-right-box .pagination {
  background: #1E1E1E;
}

[data-theme-version="dark"] .btn.tp-btn-light.btn-secondary,
[data-theme-version="dark"] .btn.tp-btn.btn-secondary {
  color: white;
}

[data-theme-version="dark"] .list-group-item-secondary {
  color: #fff;
}

[data-theme-version="dark"] .students span {
  color: #fff;
}

[data-theme-version="dark"] .progress .bg-white {
  background-color: #fff !important;
}

[data-theme-version="dark"] .border-end {
  border-color: #444444 !important;
}

[data-theme-version="dark"] .form-check-input {
  border-color: #444444;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .menu-toggle .logo-color {
  display: none !important;
}

@media only screen and (max-width: 47.9375rem) {
  [data-theme-version="dark"] .menu-toggle .logo-color {
    display: block !important;
  }
}

[data-theme-version="dark"] #overiewChart line {
  stroke: #444444;
}

[data-theme-version="dark"] #NewCustomers #SvgjsSvg1006 {
  margin-top: 16px;
}

[data-theme-version="dark"] .chart-grd:after {
  background: transparent;
}

[data-theme-version="dark"] .chat-box-area .message-sent p {
  background: #383838;
  color: white;
}

[data-theme-version="dark"] .page-titles,
[data-theme-version="dark"] .to-dodroup .sub-card,
[data-theme-version="dark"] .ttl-project,
[data-theme-version="dark"] .active-country .country-list,
[data-theme-version="dark"] .active-projects.selling-product tbody tr td,
[data-theme-version="dark"] .events .event-media,
[data-theme-version="dark"] .active-projects.style-1 tbody tr td,
[data-theme-version="dark"] .footer,
[data-theme-version="dark"] .active-projects.manage-client tbody tr td,
[data-theme-version="dark"] .task .task-summary,
[data-theme-version="dark"] .active-projects.task-table tbody td,
[data-theme-version="dark"] .kanbanPreview-bx .card-body .sub-card {
  border-color: #444444;
}

[data-theme-version="dark"] .light.badge-primary {
  background-color: #1E1E1E !important;
}

[data-theme-version="dark"] .avatar-list.avatar-list-stacked .avatar,
[data-theme-version="dark"] .author-profile .card-footer .form-control,
[data-theme-version="dark"] .profile-card .card-header,
[data-theme-version="dark"] .author-profile .upload-link {
  border-color: #444444;
}

[data-theme-version="dark"] .email-left-box .mail-list .list-group-item {
  background: rgba(255, 255, 255, 0.1);
  margin: 13px 0px;
}

[data-theme-version="dark"] .inovice-logo .logo-abbr .react-w {
  fill: #fff;
  stroke: #fff;
}

[data-theme-version="dark"] .demo-right-inner {
  background-color: #242424;
}

[data-theme-version="dark"] .border-start {
  body-color: #444444;
}

[data-theme-version="dark"] .ttl-project .pr-data:after,
[data-theme-version="dark"] .active-country:after {
  background: #444444;
}

[data-theme-version="dark"] .to-dodroup .sub-card .d-items,
[data-theme-version="dark"] .kanbanPreview-bx .card-body .sub-card {
  background-color: #242424;
}

[data-theme-version="dark"] .to-dodroup .sub-card .d-items .d-items-2 label,
[data-theme-version="dark"] .earning-tab .nav-item .nav-link.active {
  color: #fff;
}

[data-theme-version="dark"] .active-projects thead tr th,
[data-theme-version="dark"] .schedules-cal .datepicker-days .dow {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .bootstrap-select .btn {
  background: #242424 !important;
}

[data-theme-version="dark"] .sidebar-right-inner .bootstrap-select .btn {
  background: transparent !important;
  border-color: #E6E6E6 !important;
}

[data-theme-version="dark"] .sidebar-right-inner .dropdown-menu.show {
  background-color: #fff;
}

[data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item.active,
[data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item:active {
  color: var(--primary) !important;
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item {
  color: #777777 !important;
}

[data-theme-version="dark"] .sidebar-right-inner .dropdown-item:hover,
[data-theme-version="dark"] .sidebar-right-inner .dropdown-item:focus {
  background-color: var(--bs-dropdown-link-hover-bg);
}

[data-theme-version="dark"] .badge-outline-dark,
[data-theme-version="dark"] .badge-outline-secondary {
  border-color: #444444;
}

[data-theme-version="dark"] .light.badge-info {
  border-color: #444444;
}

[data-theme-version="dark"] .email-left-box .btn.text-white.btn-block {
  background-color: var(--primary);
}

[data-theme-version="dark"] .btn-secondary .text-secondary {
  color: #000 !important;
}

[data-theme-version="dark"] .list-group-item {
  color: #fff;
}

[data-theme-version="dark"] #TabWithIcon .nav-link.active {
  background-color: #1E1E1E;
  border-color: #444444;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-theme-version="dark"] .ck.ck-editor .ck.ck-button {
  border-color: #444444;
  color: #fff;
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .dark-logo {
  display: block;
}

[data-theme-version="dark"] .light-logo {
  display: none;
}

[data-theme-version="dark"] .deznav,
[data-theme-version="dark"] .read-content-attachment .attachment>div:not(:last-child) {
  border-color: #444444;
}

[data-theme-version="dark"] #AllProject .apexcharts-series path {
  stroke: #444444;
}

[data-theme-version="dark"] .offcanvas {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .offcanvas .btn-close {
  background: transparent;
}

[data-theme-version="dark"] .events h6,
[data-theme-version="dark"] .events .h6 {
  border-color: #444444;
}

[data-theme-version="dark"] .task-tab {
  background-color: #242424;
}

[data-theme-version="dark"] .kanbanPreview-bx .card-body {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .custom-tab-1 .nav.nav-tabs {
  border-color: #444444;
}

[data-theme-version="dark"] .login-form {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .accordion-primary .accordion-header.collapsed {
  color: var(--primary) !important;
}

[data-theme-version="dark"] .accordion-item {
  background-color: transparent;
}

[data-theme-version="dark"] .mix-chart-tab .nav-link {
  background: #444444;
  color: #fff;
}

[data-theme-version="dark"] .mix-chart-tab .nav-link.active {
  background: var(--primary);
}

[data-theme-version="dark"] .active-map-main:after {
  background: #444444;
}

[data-theme-version="dark"] .upload-img .dropzone {
  border-color: #444444;
}

[data-theme-version="dark"] .upload-img .dropzone .dlab-message {
  margin: 1em 0;
}

[data-theme-version="dark"] .navbar-nav .active .scroll {
  color: var(--primary) !important;
}

[data-theme-version="dark"] .accordion-primary .accordion-header-text,
[data-theme-version="dark"] .accordion-danger-solid .accordion-header-text {
  color: #fff;
}

[data-theme-version="dark"] .header-left .search-area {
  background-color: transparent;
}

[data-theme-version="dark"] [data-headerbg="color_4"] .search-area {
  background-color: transparent;
}

[data-theme-version="dark"] .third-post.style-2,
[data-theme-version="dark"] .c-details ul li {
  border-color: #444444;
}

[data-theme-version="dark"] .dt-filter .dataTables_filter input[type="search"] {
  background: #1E1E1E;
}

[data-theme-version="dark"] .dt-filter .dataTables_filter label {
  background: #1E1E1E;
}

[data-theme-version="dark"] .card-profile .admin-user {
  background-color: rgba(0, 0, 0, 0.9);
}

[data-theme-version="dark"] .card-profile .admin-user .img-wrraper a {
  background-color: black;
  box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .card-profile .follow-list .follow-num {
  color: #fff;
}

[data-theme-version="dark"] .profile-accordion .accordion-item .accordion-header .accordion-button {
  color: #fff;
}

[data-theme-version="dark"] .c-list .search-area .form-control {
  border: 0;
}

[data-theme-version="dark"] .chat-left-area.active {
  background: #242424;
}

[data-theme-version="dark"] .logo-light {
  display: none;
}

[data-theme-version="dark"] .logo-dark {
  display: inline-block;
}

[data-theme-version="dark"] .error-page .error-head {
  color: #fff;
}

[data-theme-version="dark"] .ai-header-alert {
  background-color: #242424;
}

[data-theme-version="dark"] .ai-header-alert .btn-close {
  background: none;
}

[data-theme-version="dark"] .setting-list svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .check-ai .form-check-input+.form-check-label {
  background-color: #1E1E1E;
  border-color: #444444;
}

[data-theme-version="dark"] .nav-tabs.style-1 li a.nav-link {
  background-color: #242424;
  border-color: #444444;
}

[data-theme-version="dark"] .nav-tabs.style-1 li a.nav-link.active {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .nav-tabs.style-1 {
  border-color: #444444;
}

[data-theme-version="dark"] .cm-content-box {
  background-color: #242424;
}

[data-theme-version="dark"] .cm-content-box .cm-content-body {
  border-color: #444444;
}

[data-theme-version="dark"] .dd-handle.move-media {
  background-color: var(--primary);
}

[data-theme-version="dark"] .dd-handle.move-media+.accordion-button {
  background-color: #242424;
  color: #fff;
}

[data-theme-version="dark"] .menu-tabs,
[data-theme-version="dark"] .cm-content-box.style-1 .content-title,
[data-theme-version="dark"] .dd .accordion-body,
[data-theme-version="dark"] .dz-terms {
  border-color: #444444;
}

[data-theme-version="dark"] .login-form .login-title:before,
[data-theme-version="dark"] .login-form .login-title:after {
  background-color: #444444;
}

[data-theme-version="dark"] .new-scroll {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .tab-my.nav-tabs .nav-link {
  color: #000;
}

[data-theme-version="dark"] .svg-icons-ov .svg-icon-popup .btn {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .svg-area .card-header,
[data-theme-version="dark"] .login-content p {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown {
  border-color: #444444;
}

[data-theme-version="dark"] .accordion-button:not(.collapsed)::after {
  filter: brightness(2.5);
}

[data-theme-version="dark"] .pages-left {
  background-color: #242424;
}

[data-theme-version="dark"] .logo-dark {
  display: none;
}

[data-theme-version="dark"] .logo-light {
  display: inline-block;
}

[data-theme-version="dark"] .upload-img.dropzone {
  background-color: #242424;
}

[data-theme-version="dark"] .ai-check,
[data-theme-version="dark"] .upload-img.dropzone {
  border-color: #444444;
}

[data-theme-version="dark"] .header-left .search-area .input-group-text,
[data-theme-version="dark"] .header-left .search-area .form-control {
  border-radius: 0;
}

[data-theme-version="dark"] .email-left-body.active {
  background-color: #242424;
}

[data-theme-version="dark"] .email-tools-box.active:before {
  background-color: #fff;
  opactiy: 0.1;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .header {
  border-color: #444444;
}

[data-theme-version="dark"] .header {
  border-color: #444444;
}

[data-theme-version="dark"] .header-left .dashboard_bar {
  color: #fff;
}

[data-theme-version="dark"] .search-area .form-control {
  background: #2c2c2c;
}

[data-theme-version="dark"] .search-area .input-group-text {
  border: 0;
  background: #2c2c2c;
}

[data-theme-version="dark"] .search-area .input-group-text a {
  color: #4f7086;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  /* background: $d-bg!important; */
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #242424;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="dark"] .nav-header .hamburger .line {
  background: #fff !important;
}

[data-theme-version="dark"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff !important;
}

[data-theme-version="dark"] .header-left .search-area .input-group-text {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .header-left .search-area .form-control {
  background-color: #1E1E1E;
}

[data-theme-version="dark"] .nav-header {
  border-color: #444444;
}

[data-theme-version="dark"] .nav-control svg rect {
  fill: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: #fff;
}

[data-theme-version="dark"] .nav-header {
  border-color: #444444;
}

[data-theme-version="dark"] .nav-control svg rect {
  fill: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: #fff;
}

[data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li>ul {
  background-color: #242424;
}

[data-theme-version="dark"] .fixed-content-box {
  background-color: #242424;
}

[data-theme-version="dark"] .fixed-content-box .head-name {
  background: #242424;
  color: #fff;
  border-color: #444444;
}

[data-theme-version="dark"] .fixed-content-box+.header+.deznav {
  background-color: #1E1E1E;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .deznav {
  border-color: #444444;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a {
  background: transparent;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a i,
[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a i {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li a>i {
  color: rgba(255, 255, 255, 0.3);
}

[data-theme-version="dark"] .deznav .header-profile>a.nav-link {
  border-color: #444444;
}

[data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li>a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a {
  color: #fff !important;
  /* background:var(--rgba-primary-1); */
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a i,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="dark"] .deznav .metismenu ul:after {
  background-color: #444444;
}

[data-theme-version="dark"] .deznav .metismenu ul a:hover,
[data-theme-version="dark"] .deznav .metismenu ul a:focus,
[data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
  color: #fff !important;
}

[data-theme-version="dark"] .deznav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu a {
  color: #b3b3b3 !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  border-color: var(--rgba-primary-1) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  color: #000 !important;
}

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #CCCCCC;
}

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff;
}

[data-theme-version="dark"] .sidebar-right .default-select .list .option.selected,
[data-theme-version="dark"] .sidebar-right .default-select .list .option.focus,
[data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>h4,
[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>.h4 {
  color: #000 !important;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option {
  background: #fff;
}

[data-theme-version="dark"] .footer {
  background-color: #242424;
}

[data-theme-version="dark"] .footer .copyright {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright p {
  color: rgba(255, 255, 255, 0.7);
}

.nav-header {
  background-color: #f3f3f3;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
}

.deznav {
  position: fixed;
}

.nav-header {
  position: fixed;
  border-top-left-radius: 0;
  z-index: 9;
}

.tbl-captionn {
  padding: 20px;
}

.tbl-caption-2 {
  background-color: #2c2c2c;
  color: #fff;
}

.deznav.showMenu {
  width: 3.75rem;
  transition: all .2s ease;
  overflow: visible;
}

.mm-active.hide .nav-text {
  display: none;
}

.deznav .metismenu .mm-active.hide>.has-arrow:after {
  display: none;
}

.mm-active.hide ul {
  position: absolute;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);

  background-color: #fff;
  left: 3.75rem;
  top: 0;
}

.mm-active.hide ul a {
  white-space: nowrap;
  padding: 10px 20px;
}

.mm-active>a:hover {
  background-color: #8080806e;
}

.mm-active.active>a {
  background-color: #8080806e;
}

.dilog-class {
  width: 500px !important;
  height: 500px !important;
  margin: auto !important;
  z-index: 11111;
}

.color-picker-container input {
  width: 60%;
  height: 30px;
  border-radius: 5%;
}

.dt-button-2 {
  background: #4ee021;
  color: white;
  width: 23%;
  font-weight: 900;
}

.dt-button-2:hover {
  background-color: var(--bs-blue);
  color: white;
}

.btn-xss {
  width: 7.4rem !important;
  ;
}

.btn-xsss {}

/* .tbl-caption-2 {
  width: 111rem;
} */

.d-flex-2 {
  align-items: center !important;
}

.tbl-caption-3 {
  width: 104rem;
}

.empoloyees-tblwrapper_wrapper-2 {
  width: 1456px !important;
}

.btn-danger-2 {
  margin-bottom: 20px;
}

.badge-success-2 {
  float: left !important;
}

.mt-4-2 {
  margin-top: 30px;
}

/* TODO please don't touch */
/* .dropdownWrapper{
  padding: 10px;
} */

.p-spacing16 {
  padding: 1rem;
}

.rounded-xl {
  border-radius: 0.75rem;

}

.border-greyscale-5 {
  border: 1px solid #E6E6E6;
}

.mt-spacing8 {
  margin-top: .5rem;
}

.gap-spacing16 {
  gap: 1rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.p-spacing20 {
  padding: 1.25rem;
}

.flex-col {
  flex-direction: column;
}

.custom-checkbox-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

/* .custom-checkbox-wrapper:hover{
  background-color:#333333;
} */
.text-greyscale-1 {
  color: #333333;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
  cursor: pointer;
  height: 44px;
}

.text-bodySmall {
  color: #000;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  z-index: 111;
  width: 100%;
}


.popupfirst .modal-dialog .modal-content .modal-footer {
  justify-content: space-between;
}

.dataTables_wrapper {
  overflow: scroll;
}

.absolute {
  position: absolute;
  z-index: 111;
  width: 100%;
}

#justify-none_Tab1 {
  justify-content: flex-start;
  padding-top: .4rem;
  padding-bottom: 0;
}

input[type=file]::file-selector-button {
  display: none;
}

.login-content a {
  width: 200px;
}

.login-content a>img {
  width: 200px;
}

.h-100-2 {
  height: 100% !important;
}


.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* TODO please don't touch */


.toast {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  position: relative;
  transition: all 0.3s ease;
}

.toast p {
  margin: 0;
}

.toast-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.toast button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toast button:hover {
  background-color: #2980b9;
}

.navbar-expand .navbar-nav .dropdown-menu-2 {
  display: block !important;
  right: 0px;
  top: 100% !important;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

/*# sourceMappingURL=style-rtl.css.map */
.maincs>div {
  margin: 20px 0;
}

.dilog-box .modal-dialog {
  width: 700px !important;
  height: 700px !important;
  margin: auto !important;
}

.dilog-box2 .modal-dialog {
  width: 1100px !important;
  margin: auto !important;
}

.dilog-Locked .modal-dialog {
  width: 500px !important;
  margin: auto !important;
}

.md-checkbox {
  margin: 5px 0px;
}

.md-checkbox {
  font: unset !important;
  display: inline-block;
  position: relative;
  margin: 0px 10px;
}



.topstatementinner {
  display: flex;
  justify-content: space-between;
}

.topstatement {
  border: "1px solid black";
  margin: "15px 0"
}

.form-check-input {
  border: 1px solid black;
}

.tab_bg {
  background-color: #2c2c2c;
}

.table-responsive>.nav-tabs>.nav-item>.nav-link,
.udtfont {
  color: #fff;
}

.loginCustom-inp {
  border-radius: 5px;
}

.otpInp div input[type=number]::-webkit-outer-spin-button,
.otpInp div input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otpInp div input {
  padding: 0 25px;
}

.dataTables_wrapper {
  scrollbar-color: #2c2c2c #fff;
}

.dataTables_wrapper:hover {
  scrollbar-color: #040404fa #fff;
}

.dataTables_wrapper::-webkit-scrollbar {
  height: 14px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 14px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.overlay2 {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;
}

.preferably-square {
  width: 150px;
}

.style-11 .card {
  display: flex;
  align-items: center;
}

.perentEye {
  position: relative;
  cursor: pointer;
  height: 185px;
  overflow: hidden;
  border: 2px solid green;
  margin: 2px;
}

.perentEye img {
  margin: 0 !important;
}

.eyeV {
  position: absolute;
  top: 0;
  /* right: 48%; */
  /* top: 45%; */
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #000000a3;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.eyeV {
  display: none;
}

.style-11:hover>.card>.eyeV {
  display: flex;
}

.naomedClass>div {
  width: 500px;
}

.cusforms .form-control {
  border-radius: 2px !important;
  margin: 10px 0;
  box-shadow: none !important
}

.cusforms3 .form-control {
  border-radius: 2px !important;
  box-shadow: none !important;
}



.profile-container {
  position: relative;
  display: inline-block;
}

.profile-image {
  width: 150px;
  /* Adjust according to your needs */
  height: 150px;
  /* Adjust according to your needs */
  border-radius: 50%;
}

.edit-options {
  position: absolute;
  /* bottom: 0; */
  bottom: 38px;
  right: 46px;
  transform: translate(50%, 50%);

}

.edit-options label i {
  font-size: 10px !important;
}

.edit-icon {
  background-color: silver;
  border-radius: 50%;
  padding: 3px 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 2px solid white;
}

.box-imag {
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
  overflow: hidden;
}

.box-imag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-container .box-imag {
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
  overflow: hidden;
}

.profile-container .box-imag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 
.edit-icon:hover {
  background-color: rgba(0, 0, 0, 0.9);
} */

.edit-icon i {
  color: black;
}

.edit-options>.fa-pencil-alt {
  font-size: 0px;
}

.profile-container2 img {
  width: 200px;
  border-radius: 100%;
}


.left-section {
  background-color: #f8f9fa;
  padding: 20px;
  /* height: 100vh; */
}

.right-section {
  background-color: white;
  padding: 20px;
  /* height: 100vh; */
  overflow-y: auto;
}



.cusformsnew label {
  font-size: 13px;
  margin: 0;
}

.cusformsnew input {
  margin: 4px 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
}

.cusformsnew select {
  margin: 4px 6px !important;
  box-shadow: none !important;
  border-radius: 4px;
}

.cusformsnew .textareas {
  margin: 4px 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
}

.padding2 {
  padding: 1px !important;
}


.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: 570px;
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  /* color: #fff; */
  border-bottom-right-radius: 0;
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}

.msger-inputarea * {
  padding: 10px;
  border: none !important;
  border-radius: 3px;
  font-size: 1em;
}

.msger-input {
  flex: 1;
  background: white;
}

.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}

.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}

.color1 {
  background-color: #8bc540 !important;
  border: none;
}

.color2 {
  background-color: #2e3191 !important;
  border: none;
}



.card-header-card {
  background-color: #303392;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operator {
  background: skyblue;
  padding: 5px;
  text-align: center;
  margin: 0px;
  border-radius: 5px;
  color: #ffff;

}

.parent-set h5 {
  margin-bottom: 0px;
}

.nameAndDetails {
  display: flex;

  align-items: center;
}

.avtar-set {
  margin-right: 15px;
}

.avtar-set svg {
  font-size: 45px !important;
}

.parent-set {
  margin-right: 15px;
}

.dateandtime p {
  margin: 0px;
  color: #ffff;
  font-weight: 600;
}

.chat-first {
  border: 1px solid #e4e1e1;
}

.msg-sec {
  padding: 10px;
}

.card-header-card-2 {
  /* margin-top: 20px; */
  background-color: rgb(139 197 64);
  color: #000;
}

.dateandtime-2 p {
  color: #000;
}

.parent-set .operator-2 {
  background-color: greenyellow;
}

.nameBox {
  border: 1px solid black;
  height: 200px;
  overflow: auto;
  cursor: pointer;
}

.sizBoxs>div {
  font-size: 17px !important;
  color: black;
}

.namedSpan>span {
  font-size: 17px !important;
  color: black;
}

.retailers-container {
  background-color: white;
  border: 2px dotted #e0e0e0;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  display: flex;
  /* height: 137px; */
  /* justify-content: space-between; */
  /* width: 200px; */
  margin: 0 auto;
  margin-bottom: 10px;
}

.retailers-title {
  color: #333;
  font-size: 1.2em;
}

.retailers-count {
  color: #007bff;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 5px;
}

.boxicons {
  width: 50px;
  background-color: #dbdbdb;
  border-radius: 100%;
  height: 50px;
  position: relative;
}

.ico {
  font-size: 22px !important;
  position: absolute;
  top: 13px;
  left: 15px;
}

.cadetblueS {
  background-color: cadetblue !important;
  border-radius: 10px;
  margin-right: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Apps {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 10px;
}

.top-controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.operator-select,
.search-input,
.role-select,
.set-button {
  margin-right: 10px;
}

.search-input {
  flex-grow: 1;
}

.top-buttons button {
  margin-right: 5px;
}

.tariff-table {
  width: 100%;
  border-collapse: collapse;
}

.tariff-table th,
.tariff-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.input-field {
  width: 60px;
  text-align: right;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.switcher {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.switcher input[type="radio"] {
  display: none;
}

.switcher input[type="radio"]+label {
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.switcher input[type="radio"]:checked+label {
  background-color: #007bff;
  color: white;
}

.cGroup label {
  color: #0d99ff;
}

.sGroup label {
  color: #2c2c2c;
}

.pGroup label {
  color: #3ac977;
}

.fGroup label {
  color: #ff5e5e;
}

.cGroup1 {
  background-color: #0d99ff;
}

.sGroup1 {
  background-color: #2c2c2c;
}

.pGroup1 {
  background-color: #3ac977;
}

.fGroup1 {
  background-color: #ff5e5e;
}

/* .cGroup input{
      background-color: #0d99ff;
    }
    .sGroup input{
      background-color:#2c2c2c;
    }
    .pGroup input{
      background-color:#3ac977;
    }
    .fGroup input{
      background-color:#ff5e5e;
    } */

.divbyu {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.divbyu h4 {
  margin: 0 3px;
  padding: 10px;
  /* background-color: #2196F3; */
  color: #fff;
  border-radius: 3px;
  font-family: Arial, sans-serif;
}




/* Apply background colors */
.option-pending {
  background-color: yellow;
  color: black;
}

.option-approved {
  background-color: green;
  color: white;
}

.option-in-progress {
  background-color: blue;
  color: white;
}

.option-reject {
  background-color: red;
  color: white;
}

/* Style the selected option in the dropdown */
.form-select {
  color: black;
  /* default color */
}

.newcuss>div {
  display: flex;
  align-items: center;
}

.bg-yellow {
  background-color: #ffc107;
}

.bg-gold {
  background-color: #f47728;
}




.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card-header img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.card-header div {
  font-size: 18px;
  font-weight: bold;
}

.card-content {
  font-size: 14px;
}

.card-content div {
  margin-bottom: 10px;
}

.card-content span {
  font-weight: bold;
}

.card.recharge {
  background-color: #e3f2fd;
}

.card.bbps {
  background-color: #b2dfdb;
}

.card.dmt {
  background-color: #f0f4c3;
}

.card.payout {
  background-color: #d1c4e9;
}

.card.aeps {
  background-color: #c8e6c9;
}

.show-more {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

/* ------------------mustafa css---------------- */
.bg-grad-2 {
  background-color: #875fc0;
  background-image: linear-gradient(315deg, #875fc0, #5346ba 74%);
}

.bg-grad-3 {
  background-color: #47c5f4;
  background-image: linear-gradient(315deg, #47c5f4, #6791d9 74%);
}

.bg-grad-1 {
  background-color: #eb4786;
  background-image: linear-gradient(315deg, #eb4786, #b854a6 74%);
}

.bg-grad-4 {
  background-color: #ffb72c;
  background-image: linear-gradient(315deg, #ffb72c, #f57f59 74%);
}

.rounded-lg {
  background-color: orange;
}

.aiz-main-content {
  padding: 20px;
}



.notification {
  display: flex;
  align-items: center;
  background: white;
  margin: 10px 0;
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
}

.new-user {
  background-color: green;
}

.message {
  background-color: orange;
}

.comment {
  background-color: purple;
}

.connect {
  background-color: blue;
}

.content h4 {
  margin: 0 0 5px 0;
  font-size: 16px;
}

.content p {
  margin: 0 0 5px 0;
  color: #555;
}

.content .user {
  display: block;
  color: red;
}

.content .time {
  display: block;
  color: gray;
  font-size: 12px;
}

.openNote {
  background-color: #eeeeee !important;
  font-weight: 700 !important;
}

.notification-button {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  outline: none;
  color: #fff;
  background-color: #8B8000;
  box-shadow: 0 0 0 0 rgb(255 255 255 / 10%);
  animation: pulse 1s ease-in-out infinite;
  cursor: pointer;
  position: absolute;
  top: 2px;
}

.notification-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(0.5);
    box-shadow: 0 0 0 20px rgb(255 255 255 / 10%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(255 255 255 / 10%);
  }
}

.ant-pagination-options {
  display: none !important;
}

.ant__popups {
  max-width: 70em !important;
}

.ant-modal-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder !important;
  font-size: 1.5rem !important;
}

/* profile resulation size*/

#toast {
  visibility: hidden;
  max-width: 50px;
  height: 50px;
  /*margin-left: -125px;*/
  margin: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;

  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 30px;
  font-size: 17px;
  white-space: nowrap;
}

#toast #img {
  width: 50px;
  height: 50px;

  float: left;

  padding-top: 16px;
  padding-bottom: 16px;

  box-sizing: border-box;


  background-color: #111;
  color: #fff;
}

#toast #desc {


  color: #fff;

  padding: 16px;

  overflow: hidden;
  white-space: nowrap;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes expand {
  from {
    min-width: 50px
  }

  to {
    min-width: 350px
  }
}

@keyframes expand {
  from {
    min-width: 50px
  }

  to {
    min-width: 350px
  }
}

@-webkit-keyframes stay {
  from {
    min-width: 350px
  }

  to {
    min-width: 350px
  }
}

@keyframes stay {
  from {
    min-width: 350px
  }

  to {
    min-width: 350px
  }
}

@-webkit-keyframes shrink {
  from {
    min-width: 350px;
  }

  to {
    min-width: 50px;
  }
}

@keyframes shrink {
  from {
    min-width: 350px;
  }

  to {
    min-width: 50px;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 60px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 60px;
    opacity: 0;
  }
}

.dataTables_info_page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}